import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import TarnsformerHelper, {
  ONSET_END_COLOR,
} from "../../../helpers/TarnsformerHelper";
import DoctorAPI from "../../../api/DoctorAPI";
import {
  SLEEP_STATUS_PROPERTIES,
  sleepOnsetOffsetLabelMap,
} from "./SleepWakeTool";
import { isDebugMode } from "../../../helpers/UiHelper";
import axios from "axios";

const HOURS_PER_DAY = 24;
const MINUTES_PER_HOUR = 60;
const SECONDS_PER_DAY = 60 * HOURS_PER_DAY * MINUTES_PER_HOUR;
const IS_ASLEEP_HEIGHT = 1;
const IS_AWAKE_HEIGHT = 2;
const ONSET_COLOR = "#aC3913ff";
const IS_ASLEEP_COLOR = "#3763B5ff";

function get24HrTicks(ticksNumber, date) {
  const dateFrom = moment(date);
  let twelveHoursBefore = dateFrom.subtract(12, "hours"); // go back 12 hours to construct ticks
  const interval = Math.round(24 / ticksNumber);

  let ticks = Array.from({ length: ticksNumber + 1 }).map((x, i) => ({
    left: (100.0 * i) / ticksNumber,
  }));
  ticks = ticks.map((x, i) => {
    const tickObj = {
      ...x,
      t: twelveHoursBefore.utc().valueOf(),
      verbal: twelveHoursBefore.format("DD_HH:mm a"),
    };
    twelveHoursBefore.add(interval, "hours");
    return tickObj;
  });
  return ticks;
}

export default function SleepViewTool(props) {
  const {
    slots = [],
    vertAxisLabels = ["DEEP", "LIGHT", "REM", "AWAKE"],
    ticksNumber = 12,
    noDataText = "...",
    displayFullDay = false,
    loading,
    date,
    dd,
    uuid,
    sleepAndWakeData = {},
    sleepAndWakeUpTime = {
      time_to_sleep: undefined,
      time_to_wake_up: undefined,
      start_date: undefined,
      end_date: undefined,
    },
  } = props;
  const d = new Date();
  const diff = Math.round(d.getTimezoneOffset() / 60);
  const [tz, setTz] = useState(-diff);

  const useTimezone = false;
  const dateFrom = moment(date);
  //   const hourOffset = 12 + tz + diff;
  const hourOffset = 12;
  const twelveHoursBefore = dateFrom.clone().subtract(hourOffset, "hours");
  // console.log({ sleepAndWakeUpTime });
  const [hoveringIndex, setHoveringIndex] = useState(undefined);
  const [mousePos, setMousePos] = useState({ x: undefined, y: undefined });
  const hideTriangleMarkers =
    sleepAndWakeUpTime?.time_to_sleep === undefined ||
    sleepAndWakeUpTime?.time_to_wake_up === undefined ||
    sleepAndWakeUpTime?.start_date === undefined ||
    sleepAndWakeUpTime?.end_date === undefined ||
    !isDebugMode();

  useEffect(() => {
    // if (!useTimezone) {
    //   return;
    // }
    const controller = new AbortController();
    const { signal } = controller;
    let mounted = true;
    // this hook is for knowing the timezone of the user
    const from = +moment(date).clone().subtract(1, "hours");
    const to = +moment(date).clone().add(1, "hours");
    DoctorAPI.getPatientRriSleep(uuid, from, to, signal)
      .then((data) => {
        // console.log("sleep-measurements-continuous/sleep-data", data);
        if (data.length > 0 && mounted) {
          const _tz =
            moment.parseZone(data[data.length - 1]?.date).utcOffset() / 60;
          setTz(_tz);
        }
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error(e);
      });
    return () => {
      mounted = false;
      controller.abort();
    };
  }, [uuid, date]);

  if (slots == undefined || slots.length == 0) {
    return null;
  }

  let optSlots = displayFullDay
    ? TarnsformerHelper.getGroupedSleepPointsFullDay(
        slots,
        date,
        dd === "nt" ? tz + diff : 0
      )
    : TarnsformerHelper.getGroupedSleepPoints(slots);

  let onsetOffsetPercents = {
    onset: 0,
    onset_end: 0,
    offset: 0,
    offset_end: 0,
  };

  if (
    sleepAndWakeData?.sleepOnset?.percentage &&
    sleepAndWakeData?.sleepOnsetEnd?.percentage
  ) {
    // console.log("has new fw");
    onsetOffsetPercents = {
      onset: sleepAndWakeData?.sleepOnset?.percentage || 0,
      onset_end: sleepAndWakeData?.sleepOnsetEnd?.percentage || 0,
      offset: sleepAndWakeData?.wakeOnset?.percentage || 0,
      offset_end: sleepAndWakeData?.wakeOnsetEnd?.percentage || 0,
    };

    const generateSleepAndWakeArea = (key, name) => {
      return {
        key: `sleep-${name}-area`,
        sleepState: `${name}`,
        color: ONSET_COLOR,
        height: 4,
        start_date: sleepAndWakeData?.[key]?.date,
        end_date: moment(sleepAndWakeData?.[key]?.date)
          .add(1, "minute")
          .toISOString(),
        from: sleepAndWakeData?.[key]?.timestamp,
        to: sleepAndWakeData?.[key]?.timestamp + 60 * 1000,
        timestamp: sleepAndWakeData?.[key]?.timestamp,
        type: true,
        left_percent: sleepAndWakeData?.[key]?.percentage,
        width_percent: (100 * 60 * 1) / (60 * HOURS_PER_DAY * MINUTES_PER_HOUR),
        z: 5,
      };
    };

    const sleepOnsetArea = generateSleepAndWakeArea("sleepOnset", "onset");
    const sleepOnsetEndArea = generateSleepAndWakeArea(
      "sleepOnsetEnd",
      "onset_end"
    );
    const wakeOnsetArea = generateSleepAndWakeArea("wakeOnset", "offset");
    const wakeOnsetEndArea = generateSleepAndWakeArea(
      "wakeOnsetEnd",
      "offset_end"
    );
    if (isDebugMode()) {
      optSlots.push(sleepOnsetArea);
      optSlots.push(sleepOnsetEndArea);
      optSlots.push(wakeOnsetArea);
      optSlots.push(wakeOnsetEndArea);
    }
  }

  const SleepWakeTriangleMark = (props) => {
    const { status = "", left } = props;
    const [hoveredText, setHoverText] = useState("");
    // status: onset, onset_end, offset, offset_end
    // Bedtime, Sleep Onset, Out of Bed, Wake Up
    const _left = `${(+left).toFixed(3)}%`;
    let _color = "";
    let _rotate = "0";
    let _x_translate = "0";
    let _y_translate = "0";
    let _y_low_translate = "-285";
    // console.log({ status });

    switch (status) {
      case "onset":
        _color = ONSET_END_COLOR;
        _x_translate = "9.5";
        // _x_translate = "-20";
        _y_translate = _y_low_translate;
        _rotate = "180";
        break;
      case "onset_end":
        _color = IS_ASLEEP_COLOR;
        _x_translate = "9.5";
        _rotate = "180";
        break;
      case "offset":
        _color = IS_ASLEEP_COLOR;
        _x_translate = "9.5";
        _rotate = "180";
        break;
      case "offset_end":
        _x_translate = "9.5";
        _y_translate = _y_low_translate;
        _rotate = "180";
        // _rotate = "180";
        _color = ONSET_END_COLOR;
        break;
      default:
        break;
    }
    // if (status === "onset_end" || status === "offset") {
    //   _color = IS_AWAKE_COLOR;
    // } else {
    //   _color = IS_ASLEEP_COLOR;
    // }
    // console.log({ status, _color });

    const markerDateFormat = " (HH:mm)";
    const sleepTimestamps = {
      onset: sleepAndWakeData?.sleepOnset?.timestamp
        ? moment(sleepAndWakeData?.sleepOnset?.timestamp).format(
            markerDateFormat
          )
        : "",
      onset_end: sleepAndWakeData?.sleepOnsetEnd?.timestamp
        ? moment(sleepAndWakeData?.sleepOnsetEnd?.timestamp).format(
            markerDateFormat
          )
        : "",
      offset: sleepAndWakeData?.wakeOnset?.timestamp
        ? moment(sleepAndWakeData?.wakeOnset?.timestamp).format(
            markerDateFormat
          )
        : "",
      offset_end: sleepAndWakeData?.wakeOnsetEnd?.timestamp
        ? moment(sleepAndWakeData?.wakeOnsetEnd?.timestamp).format(
            markerDateFormat
          )
        : "",
    };

    return (
      <TriangleMark
        onMouseOver={() => {
          setHoverText(sleepTimestamps[status]);
          // setHoveredType(status);
        }}
        onMouseLeave={() => {
          setHoverText("");
          // setHoveredType("");
        }}
        className={`${status}_mark`}
        $dateString={hoveredText || ""}
        $left={_left}
        $status={status}
        $boxColor={_color}
        $xtranslate={_x_translate}
        $ytranslate={_y_translate}
        $rotate={_rotate}
      />
    );
  };

  if (optSlots.length == 0) {
    return (
      <NoDataPlaceholder>
        <NoDataText>{noDataText}</NoDataText>
      </NoDataPlaceholder>
    );
  }
  let ticks;

  if (displayFullDay) {
    ticks = get24HrTicks(ticksNumber, date);
  } else {
    ticks = Array.from({ length: ticksNumber + 1 }).map((x, i) => ({
      left: (100.0 * i) / ticksNumber,
    }));
    const t0 = optSlots[0].from;
    let w = Math.round(
      1.0 * (optSlots[optSlots.length - 1].to - optSlots[0].from)
    );
    ticks = ticks.map((x) => ({
      ...x,
      t: t0 + Math.round((w * x.left) / 100.0),
    }));
  }

  return (
    <Wrapper className="sleep-stages-wrapper">
      {hoveringIndex !== undefined &&
        (() => {
          let { type, from, to, sleepState } = optSlots[hoveringIndex];
          // console.log(optSlots[hoveringIndex]);

          let typeString = TarnsformerHelper.getSleepStageNameByType(type);
          if (sleepState === "onset" || sleepState === "offset") {
            typeString = sleepState;
          }

          const startString = moment(from).format("Do HH:mm");
          const untilString = moment(to).format("Do HH:mm");
          // console.log({ type, typeString });

          return (
            <div
              onMouseOver={(e) => {
                e.preventDefault();
              }}
            >
              <div
                style={{
                  padding: "10px",
                  position: "fixed",
                  width: "200px",
                  left: mousePos.x + 10,
                  top: mousePos.y + 10,
                  zIndex: 100,
                  background: "#fff",
                  border: "1px solid #c0c0c0",
                }}
              >
                <div>Sleep Stage: {typeString}</div>
                <div>Start: {startString}</div>
                <div>Until: {untilString}</div>
              </div>
            </div>
          );
        })()}
      <LeftPlaceholder>
        {/* <div>tz: {tz}</div> */}
        <LeftInnerPlaceholder>
          {vertAxisLabels.map((x, i) => (
            <VertAxisLabel
              className="sleep-vert-label"
              key={`${x}`}
              height={+i + 1}
            >
              {x}
            </VertAxisLabel>
          ))}
        </LeftInnerPlaceholder>
      </LeftPlaceholder>
      <RightPlaceholder>
        <TopPlaceholder className="sleep-top-placeholder">
          <ChartPlaceholder>
            {[1, 2, 3, 4].map((x) => (
              <HorLine key={x} height={x} />
            ))}
            {["onset", "onset_end", "offset", "offset_end"].map((x, i) => {
              if (hideTriangleMarkers) {
                return null;
              }
              return (
                <div
                  key={i}
                  style={{
                    display: onsetOffsetPercents.onset ? "flex" : "none",
                  }}
                >
                  <SleepWakeTriangleMark
                    key={i}
                    left={onsetOffsetPercents[x]}
                    status={x}
                  />
                </div>
              );
            })}
            {optSlots.map((opt, i) => {
              // console.log(opt);

              return (
                <Box
                  // {...opt}
                  type={""}
                  $height={opt?.height}
                  $width_percent={opt?.width_percent}
                  $left_percent={opt?.left_percent}
                  $color={opt?.color}
                  $z={opt?.z}
                  key={`opt_${i}_${opt.width_percent}`}
                  onMouseMove={(e) => {
                    setMousePos({ x: e.clientX, y: e.clientY });
                  }}
                  onMouseEnter={() => {
                    // console.log("Set", i);
                    setHoveringIndex(i);
                  }}
                  onMouseLeave={() => {
                    // console.log("set undefined", i);
                    setHoveringIndex(undefined);
                  }}
                ></Box>
              );
            })}
          </ChartPlaceholder>
        </TopPlaceholder>

        <BottomPlaceholder className="sleep-bottom-placeholder">
          {ticks.map((x, i) => {
            return (
              <Tick key={i} $left={x.left}>
                <TickInner className="sleep-tick-label">
                  {moment(x.t).format("DD dd HH:mm")}
                </TickInner>
              </Tick>
            );
          })}
        </BottomPlaceholder>
      </RightPlaceholder>
      {/*{JSON.stringify(optSlots)}*/}
    </Wrapper>
  );
}

const bottomHeight = 14;

const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoDataText = styled.div`
  font-size: 24px;
  color: grey;
`;

const BORDER_COLOR = "lightgrey";

const Tick = styled.div`
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: ${(props) => `${props.$left}%`};
`;

const TickInner = styled.div`
  width: 35px;
  box-sizing: border-box;
  // border: 1px solid red;
  // height: auto;
  // width: max-content;
  text-align: center;
  margin-left: -15px;
  font-size: 10px;
  margin-top: 2px;
  background: white;

  ${Tick}:last-of-type > & {
    margin-left: -20px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  top: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-right: 1px solid ${BORDER_COLOR};
`;

const HorLine = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  background: ${BORDER_COLOR};
  height: 1px;
  bottom: ${(props) => `calc(${(100.0 * props.height) / 4.0}% - 1px)`};
  z-index: 1;
`;

const Box = styled.div`
  position: absolute;
  bottom: 0px;
  height: ${(props) => `${(100.0 * props.$height) / 4.0}%`};
  width: ${(props) => `${props.$width_percent}%`};
  left: ${(props) => `${props.$left_percent}%`};
  box-sizing: border-box;
  background: ${(props) => props.$color};
  z-index: ${(props) => (props.$z ? props.$z : 3)};
`;

const LeftPlaceholder = styled.div`
  width: 40px;
  border-right: 1px solid ${BORDER_COLOR};
`;

const LeftInnerPlaceholder = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const VertAxisLabel = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  height: 12px;
  font-size: 10px;
  text-align: right;
  box-sizing: border-box;
  padding-right: 3px;
  bottom: ${(props) =>
    `calc(${(100.0 * props.height) / 4.0}% - 10px + ${bottomHeight}px)`};
  z-index: 1;
  :last-of-type {
    bottom: calc(100% - 6px);
  }
`;

const RightPlaceholder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TopPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  background: white;
`;

const ChartPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow-x: clip;
`;

const BottomPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${bottomHeight}px;
  background: white;
  padding: 2px;
  border-top: 1px solid ${BORDER_COLOR};
  position: relative;
`;

const TriangleMark = styled.div`
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 10px 15px 10px;
  border-color: transparent transparent ${(props) => props.$boxColor}
    transparent;
  transform: rotate(${(props) => props.$rotate}deg)
    translate(
      ${(props) => props["$xtranslate"]}px,
      ${(props) => props["$ytranslate"]}px
    );
  position: absolute;
  left: ${(props) => props.$left};
  top: -15px;
  z-index: 10;
  &::before {
    content: "${(props) =>
      sleepOnsetOffsetLabelMap[props.$status] + props.$dateString}";
    width: max-content;
    font-weight: 500;
    color: ${(props) => {
      if (props.$status === "offset_end" || props.$status === "onset") {
        return "#232323";
      } else {
        return "#232323";
      }
    }};
    transform: rotate(
      ${(props) => {
        if (props.$status === "onset_end" || props.$status === "offset") {
          return "180";
        } else {
          return "180";
        }
      }}deg
    );
    top: ${(props) => {
      if (props.$status === "offset_end" || props.$status === "onset") {
        return "-3";
      } else {
        return "1";
      }
    }}rem;
    left: -3em;
    font-size: 14px;
    position: absolute;
  }
`;
