import { useState } from "react";
import styled from "styled-components";
import Spin from "arui-feather/spin";
import SmartTrendsChart from "./SmartTrendsChart";
import ls from "local-storage";
import { OPTIONS } from "./SimpleTrendsChartTool";
import SmartMultiTrendsChart from "./SmartMultiTrendsChart";
import { ComponentHeight } from "../../patients/panels/UiComponents";

const MultiTrendsChartTool = (props) => {
  const {
    uuid,
    dataType = "activity",
    chartType = "line",
    summaries = [],
    loading = false,
    theme,
    selectedOption = "M",
    onChangeSelectedOption = () => {},
    onChangeSelectedOptionByType = () => {},
    subTypes = [],
    visibleLines,
    handleLineCheckbox,
  } = props;

  // const [summaries, setSummaries] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [selectedOption, setSelectedOption] = useState("M");
  const inFullWidth = true;
  return (
    <Wrapper className="multi-trends-wrapper">
      <TopPlaceholder className="multi-trends-top-ph">
        <SwitcherPlaceholder
          className="multi-trends-switcher-ph"
          style={{ height: ComponentHeight.topFilters }}
        >
          {OPTIONS.map((a, i) => {
            if (inFullWidth) {
              return (
                <SwitchItemNew
                  className="multi-trends-switch-item_n"
                  selected={selectedOption == a.value}
                  key={a.value}
                  theme={theme}
                  onClick={() => {
                    // setSelectedOption(a.value);
                    onChangeSelectedOption(a.value);
                  }}
                >
                  {a.label}
                </SwitchItemNew>
              );
            }
            return (
              <SwitchItem
                className="multi-trends-switch-item"
                selected={selectedOption == a.value}
                key={a.value}
                theme={theme}
                onClick={() => {
                  // setSelectedOption(a.value);
                  onChangeSelectedOption(a.value);
                  onChangeSelectedOptionByType(dataType, a.value);
                }}
              >
                {a.label}
              </SwitchItem>
            );
          })}
        </SwitcherPlaceholder>
      </TopPlaceholder>

      <ChartPlaceholder className="test-placeholder">
        <ChartInnerPlaceholder
          className="chart-inner-ph"
          style={{ opacity: loading == true ? 0.5 : 1 }}
        >
          <SmartMultiTrendsChart
            className="smart-multi-trends-chart"
            points={summaries}
            subTypes={subTypes}
            visibleLines={visibleLines}
            // points={summaries.map((x) => ({ date: x.date, ...x[dataType] }))}
            loading={loading}
            dataType={dataType}
            selectedOption={selectedOption}
            handleLineCheckbox={handleLineCheckbox}
          />

          {loading == false ? null : (
            <LoaderPlaceholder className="loader-ph">
              <LoaderInnerPlaceholder className="loader-inner-ph">
                <Spin visible={true} className="loader-spin" />
              </LoaderInnerPlaceholder>
            </LoaderPlaceholder>
          )}
        </ChartInnerPlaceholder>
      </ChartPlaceholder>
    </Wrapper>
  );
};

const ChartInnerPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const LoaderPlaceholder = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  box-sizing: border-box;
  z-index: 10;
`;

const LoaderInnerPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const SwitchItem = styled.div`
  border-bottom: ${(props) =>
    props.selected == true ? "1px solid #4aa4e3" : "1px solid transparent"};
  font-weight: ${(props) => (props.selected == true ? "bold" : "default")};
  cursor: ${(props) => (props.selected == true ? "default" : "pointer")};
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  color: ${(props) =>
    props.selected
      ? props.theme === "dark"
        ? "white"
        : "black"
      : props.theme === "dark"
      ? "#ccc"
      : "black"};

  :hover {
    opacity: 0.7;
  }
`;

const SwitchItemNew = styled.div`
  font-weight: ${(props) => (props.selected == true ? "bold" : "default")};
  background-color: ${(props) =>
    props.selected
      ? props.theme === "dark"
        ? "#fff"
        : "#000F4B"
      : props.theme === "dark"
      ? "transparent"
      : "#fff"};
  cursor: ${(props) => (props.selected == true ? "default" : "pointer")};
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  color: ${(props) =>
    props.selected
      ? props.theme === "dark"
        ? "black"
        : "white"
      : props.theme === "dark"
      ? "#fff"
      : "#666"};

  :hover {
    opacity: 0.7;
  }
`;

const TopPlaceholder = styled.div`
  margin-bottom: 10px;
  // padding-bottom: 5px;
  // border-bottom: 1px solid whitesmoke;
`;

const SwitcherPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChartPlaceholder = styled.div`
  width: 100%;
  box-sizing: border-box;
  // border: 1px solid whitesmoke;
`;
export default MultiTrendsChartTool;
