import { useEffect, useState } from "react";
import DoctorAPI from "../../../api/DoctorAPI";
import {
  mergeReadiness,
  getFromToFromOption,
  getPointsFromOption,
} from "./TrendsUtils";
import SimpleTrendsChartTool from "./SimpleTrendsChartTool";
import styled from "styled-components";
import MultiTrendsChartTool from "./MultiTrendsChartTool";
import { LINE_COLORS } from "../../ui/templates";
import moment from "moment";
import { STEP_BAR_COLOR } from "../../ui/templates/Charts";

{
  /* <VitalTrendsChartTool>
  <SimpleTrendsChartTool>
    <SmartTrendsChart />
  </SimpleTrendsChartTool>
</VitalTrendsChartTool>; */
}

{
  /* <VitalMultiTrendsChartTool>
  <MultiTrendsChartTool>
    <SmartMultiTrendsChart />
  </MultiTrendsChartTool>
</VitalMultiTrendsChartTool>; */
}

export const LegendStyles = {
  pulse_rate: {
    avg_daily_heart_rate: {
      label: "Average Daily Pulse Rate",
      color: LINE_COLORS.raw,
      tab: 0,
      tab_value: "avg_daily_heart_rate",
    },
    rest_daily_heart_rate: {
      label: "Average Resting Pulse Rate",
      color: LINE_COLORS.baseline,
      tab: 0,
      tab_value: "avg_daily_heart_rate",
    },
  },
  sleep: {
    sleep_duration: {
      label: "Sleep Duration",
      color: LINE_COLORS.raw,
      tab: 0,
      tab_value: "sleep_duration",
    },
    awake_time: {
      label: "Awake Time",
      color: "#FC0903",
      tab: 2,
      tab_value: "sleep_stages",
    },
    rem_time: {
      label: "REM Time",
      color: "#0FA9FC",
      tab: 2,
      tab_value: "sleep_stages",
    },
    light_time: {
      label: "Light Time",
      color: "#3763B5",
      tab: 2,
      tab_value: "sleep_stages",
    },
    deep_time: {
      label: "Deep Time",
      color: "#374D89",
      tab: 2,
      tab_value: "sleep_stages",
    },

    // tab 2:
    tranquility: {
      label: "Tranquility",
      color: LINE_COLORS.raw,
      tab: 3,
      tab_value: "awakes",
    },
    // tab 3:
    performance: {
      label: "Performance",
      color: LINE_COLORS.raw,
      tab: 1,
      tab_value: "sleep_score",
    },
    consistency: {
      label: "Consistency",
      color: LINE_COLORS.baseline,
      tab: 1,
      tab_value: "sleep_score",
    },
  },
  non_invasive_blood_pressure: {
    awaken_average_systolic_pressure: {
      label: "Day Systolic BP",
      color: "#129",
      tab: 0,
      tab_value: "blood_pressure",
    },
    awaken_average_diastolic_pressure: {
      label: "Day Diastolic BP",
      color: "#912",
      tab: 0,
      tab_value: "blood_pressure",
    },
    sleeping_average_systolic_pressure: {
      label: "Night Systolic BP",
      color: "#34f",
      tab: 0,
      tab_value: "blood_pressure",
    },
    sleeping_average_diastolic_pressure: {
      label: "Night Diastolic BP",
      color: "#f92",
      tab: 0,
      tab_value: "blood_pressure",
    },
    // sleeping_average_blood_pressure: {
    //   label: "Night Average BP",
    //   color: "#f92",
    //   tab: 0,
    //   tab_value: "blood_pressure",
    // },
    // awaken_average_blood_pressure: {
    //   label: "Day Average BP",
    //   color: "#f92",
    //   tab: 0,
    //   tab_value: "blood_pressure",
    // },
  },
  spo2: {
    average_spo2: {
      label: "Average Daily SpO2",
      color: LINE_COLORS.raw,
      tab: 0,
      tab_value: "spo2",
    },
    night_spo2: {
      label: "Average Nightly SpO2",
      color: "#34f",
      tab: 0,
      tab_value: "night_spo2",
    },
  },
  workout: {
    minutes: {
      label: "Minutes",
      color: LINE_COLORS.raw,
      tab: 0,
      tab_value: "workout_minutes",
    },
    total_steps: {
      label: "Steps",
      color: STEP_BAR_COLOR,
      tab: 1,
      tab_value: "workout_steps",
    },
    // tab 3:
    total_calories: {
      label: "Total Calories",
      color: STEP_BAR_COLOR,
      tab: 2,
      tab_value: "workout_total_calories",
    },
    // tab 4
    total_distance: {
      label: "Distance",
      color: LINE_COLORS.raw,
      tab: 3,
      tab_value: "workout_distance",
    },
    // tab 5:
    max_heart_rate: {
      label: "Max Heart Rate",
      color: LINE_COLORS.raw,
      tab: 4,
      tab_value: "workout_heart_rate",
    },
    avg_heart_rate: {
      label: "Average Heart Rate",
      color: LINE_COLORS.baseline,
      tab: 4,
      tab_value: "workout_heart_rate",
    },
    // tab 6
    avg_bracelet_speed: {
      label: "Bracelet Speed",
      color: LINE_COLORS.raw,
      tab: 5,
      tab_value: "workout_speed",
    },
    avg_gps_speed: {
      label: "GPS Speed",
      color: LINE_COLORS.baseline,
      tab: 5,
      tab_value: "workout_speed",
    },
  },
  respiration_rate: {
    avg_respiration_rate: {
      label: "Averate Daily Respiration Rate",
      color: LINE_COLORS.raw,
      tab: 0,
      tab_value: "respiration_rate",
    },
  },
  temperature: {
    avg_temp_sk1: {
      label: "Averate Daily Temperature",
      color: LINE_COLORS.raw,
      tab: 0,
      tab_value: "temperature",
    },
  },
};

const PulseRateKeys = ["avg_daily_heart_rate", "rest_daily_heart_rate"];
const SleepKeys = [
  // tab 1:
  "sleep_duration",
  "awake_time",
  "rem_time",
  "light_time",
  "deep_time",

  // tab 2:
  "tranquility",
  // tab 3:
  "performance",
  "consistency",
  // "sleep_duration",
  // tab 4: bedtime / sleep onset / wake / out of bed
];
const BloodPressureKeys = [
  "awaken_average_diastolic_pressure",
  "awaken_average_systolic_pressure",
  "sleeping_average_systolic_pressure",
  "sleeping_average_diastolic_pressure",
  "sleeping_average_blood_pressure",
  "awaken_average_blood_pressure",
];

const WorkoutKeys = [
  //tab 1
  "minutes",
  // tab 2:
  "total_steps",
  // tab 3:
  "total_calories",
  // tab 4
  "total_distance",
  // tab 5:
  "max_heart_rate",
  "avg_heart_rate",
  // tab 6
  "avg_bracelet_speed",
  "avg_gps_speed",

  // not used
  // "is_finished",
  // "end_date",
  // "start_date",
];

const RespirationRateKeys = ["avg_respiration_rate"];

const TemperatureKeys = ["avg_temp_sk1"];

const SpO2Keys = ["average_spo2", "night_spo2"];

const DataTypeKeyMap = {
  pulse_rate: PulseRateKeys,
  sleep: SleepKeys,
  non_invasive_blood_pressure: BloodPressureKeys,
  spo2: SpO2Keys,
  workout: WorkoutKeys,
  respiration_rate: RespirationRateKeys,
  temperature: TemperatureKeys,
};

const KeyNamesToInitalState = (keys) => {
  if (!keys || keys.length === 0) return {};
  const initialState = {};
  keys.map((x) => {
    initialState[x] = true;
  });
  return initialState;
};

const VitalMultiTrendsChartTool = (props) => {
  const {
    uuid,
    theme,
    dataType,
    subTypes = [],
    points = [],
    allWorkouts = [],
  } = props;
  // const dataType = "pulse_rate";
  // console.log(points, dataType);

  const [selectedOption, setSelectedRangeOption] = useState("M");
  // const [lightSummaryInRange, setLightSummaryInRange] = useState([])
  const [vitalSummaryInRange, setVitalSummaryInRange] = useState(points || []);
  const [loading, setLoading] = useState(false);

  const [showLine, setShowLine] = useState(
    KeyNamesToInitalState(DataTypeKeyMap[dataType])
  );

  const onChangeSelectedOption = (newOption) => {
    setSelectedRangeOption(newOption);
  };

  const handleLineCheckbox = (evt) => {
    const name = evt.target.name;
    let oldValue = showLine[name];
    const newSetting = { ...showLine, [name]: !oldValue };
    setShowLine(newSetting);
  };

  useEffect(() => {
    // console.log("VITALTRENDS", points);
    // if (points.length > 0) {
    //   return;
    // }

    // const filledPoints = .map()
    let pointsWithinRange = getPointsFromOption(points, selectedOption);
    let d_ = mergeReadiness(pointsWithinRange);
    let _type = dataType;
    if (dataType === "pulse_rate") {
      _type = "heart_rate";
    }
    let _arr = d_.map((x) => ({ date: x.date, ...x[_type] }));
    // console.log({ _arr });

    setVitalSummaryInRange(_arr);
  }, [points, uuid, dataType]);

  useEffect(() => {
    if (uuid == undefined) {
      return;
    }
    let pointsWithinRange = getPointsFromOption(points, selectedOption);
    // console.log(pointsWithinRange.length);

    let d_ = mergeReadiness(pointsWithinRange);
    let _type = dataType;
    if (dataType === "pulse_rate") {
      _type = "heart_rate";
    }
    let _arr = d_.map((x) => ({ date: x.date, ...x[_type] }));
    // console.log({ range: pointsWithinRange });

    setVitalSummaryInRange(_arr);
  }, [selectedOption]);

  return (
    <Wrapper className="multi-trend-wrapper">
      <MultiTrendsChartTool
        {...props}
        // dataType={"heart_rate"}
        dataType={dataType}
        loading={loading}
        summaries={vitalSummaryInRange}
        selectedOption={selectedOption}
        onChangeSelectedOption={onChangeSelectedOption}
        visibleLines={showLine}
        handleLineCheckbox={handleLineCheckbox}
      />
      {/* <LineFilterContainer>
        {subTypes.map((f, i) => {
          // if (externalMeasurements.length === 0 && f.value === "spot") {
          //   return null;
          // }
          if (LegendStyles[dataType][f]?.tab !== 0) {
            return null;
          }
          return (
            <LineFilterItem key={i}>
              <LineFilterCheckbox
                type="checkbox"
                name={f}
                checked={showLine[f]}
                onChange={handleLineCheckbox}
              />
              <LineLabel
                color={LegendStyles[dataType][f].color}
                theme={theme}
                $bstyle={"solid"}
              >
                {LegendStyles[dataType][f].label}
              </LineLabel>
            </LineFilterItem>
          );
        })}
      </LineFilterContainer> */}
    </Wrapper>
  );
};

export default VitalMultiTrendsChartTool;

const dataTypeMap = {
  respiration_rate: "Respiration Rate",
  spo2: "SpO2",
  temperature: "Temperature",
  pulse_rate: "Pulse Rate",
  rest_daily_heart_rate: "Resting Pulse Rate",
};

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  display: block;
`;

const LineFilterContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  // margin-left: 20px;
  justify-content: center;
`;

const LineFilterItem = styled.div`
  display: flex;
  align-items: baseline;
`;

const LineFilterCheckbox = styled.input`
  &[type="checkbox"] {
    transform: scale(1.2);
    accent-color: #1e7efa;
  }
`;

const LineLabel = styled.label`
  font-weight: 500;
  color: ${(props) => (props.theme === "dark" ? "white" : "black")};
  &:before {
    display: inline-block;
    content: "";
    border: 1.5px solid ${(props) => props.color};
    border-style: ${(props) => props.$bstyle};
    // border-top: 1rem solid ${(props) => props.color};
    width: 1rem;
    margin: 0 10px;
    transform: translateY(-4px);
  }
`;
