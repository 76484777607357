import { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import EventImg from "./eventBell.svg";
import EventConfirmed from "./eventConfirmed.svg";
import EventEscalated from "./eventEscalated.svg";
import NiceModal from "../../modals/NiceModal";
import DoctorAPI from "../../../api/DoctorAPI";
import { TabHeading } from "../../patients/panels/UiComponents";

const CorEventsTool = (props) => {
  const { id, date } = props;
  const [riskEvents, setRiskEvents] = useState([]);

  const dateObj = moment();
  const dateFrom = dateObj.clone().subtract(180, "days").toISOString();
  const dateTo = dateObj.clone().toISOString();

  const fetchCorRiskEvents = () => {
    DoctorAPI.getPatientCorRiskEvent(id, dateFrom, dateTo)
      .then((d) => {
        // console.log(d);
        if (!d) {
          return;
        }
        const _d = d.reverse();
        const items = _d.map((x, i) => {
          let changes = x?.changes || [];
          if (!changes || changes.lenght === 0) {
            verbalTime = moment(x.start_date).format("MMMM Do HH:mm");
          } else {
          }
          return {
            ...x,
            title: x.risk_name,
            t: moment(x.start_date).valueOf(),
            index: i,
            verbalTime: moment(x.start_date).format("MMMM Do HH:mm"),
          };
        });
        setRiskEvents(items);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    fetchCorRiskEvents();
  }, [props.uuid]);

  // const items = Array.from(Array(10).keys()).map((x, i) => {
  //   return {
  //     title: `Test ${i}`,
  //     t: moment().valueOf(),
  //     index: i,
  //   };
  // });

  const [showEventPopup, setShowEventPopup] = useState(undefined);

  return (
    <div style={{ display: riskEvents.length === 0 ? "none" : "" }}>
      <EventContainer>
        <TabHeading className="patient-statistics-heading">
          Cor-Events
        </TabHeading>
        <HorizontalContainer>
          {riskEvents.map((x, i) => {
            const { status } = x;
            const statusIcon = eventStatusIconMap[status] || "";
            return (
              <EventBox
                key={i}
                onClick={() => {
                  setShowEventPopup(x.index);
                }}
              >
                <EventContent>
                  <div style={{ display: "flex" }}>
                    <img
                      src={statusIcon}
                      width={20}
                      height={20}
                      style={{ marginRight: "5px" }}
                    />
                    <EventTitle>{x.title}</EventTitle>
                  </div>
                  <EventTime>
                    {moment(x.t).format("DD MMM YYYY HH:mm")}
                  </EventTime>
                </EventContent>
              </EventBox>
            );
          })}
        </HorizontalContainer>
      </EventContainer>
      {showEventPopup !== undefined && (
        <NiceModal
          onClose={() => {
            setShowEventPopup(undefined);
          }}
          styles={{
            borderRadius: "30px",
          }}
        >
          <div>
            <div
              style={{
                marginTop: "1em",
                fontWeight: 600,
                textAlign: "center",
                fontSize: "1.4rem",
              }}
            >
              <span style={{ fontWeight: 400 }}>Risk</span>
              {`: ${riskEvents[showEventPopup]?.title}`}
            </div>
            <div style={{ marginBottom: "1em", marginTop: "1em" }}>
              {`${eventsObj[riskEvents[showEventPopup]?.risk_id].content}`}
            </div>
            <div style={{ marginBottom: "1em", marginTop: "1em" }}>
              {`Event Status: `}
              <span style={{ fontWeight: 600 }}>{`${
                eventStatusMessageMap[riskEvents[showEventPopup]?.status]
              }`}</span>
            </div>
            <div style={{ marginBottom: "1em", marginTop: "1em" }}>
              {`Update Time: `}
              <span
                style={{ fontWeight: 600 }}
              >{`${riskEvents[showEventPopup]?.verbalTime}`}</span>
            </div>
            <EventButtonGroup>
              {riskEvents[showEventPopup]?.status === "new" && (
                <>
                  <EventButton onClick={() => setShowEventPopup(undefined)}>
                    <div
                      style={{ display: "flex" }}
                      onClick={() => {
                        // console.log(riskEvents[showEventPopup]);
                        // console.log({ id: showEventPopup });
                        const _id = riskEvents[showEventPopup]._id;
                        const risk_id = riskEvents[showEventPopup].risk_id;
                        console.log({ risk_id, _id });

                        DoctorAPI.updatePatientCorRiskEvent(_id, "confirmed")
                          .then((d) => {
                            console.log({ d });
                          })
                          .then((d) => {
                            fetchCorRiskEvents();
                          });
                      }}
                    >
                      <img
                        src={EventConfirmed}
                        width={20}
                        height={20}
                        style={{ marginRight: "5px" }}
                      />
                      CONFIRM
                    </div>
                  </EventButton>
                  <EventButton onClick={() => setShowEventPopup(undefined)}>
                    <div style={{ display: "flex" }}>
                      <img
                        src={EventEscalated}
                        width={20}
                        height={20}
                        style={{ marginRight: "5px" }}
                      />
                      ESCALATE
                    </div>
                  </EventButton>
                </>
              )}
              {riskEvents[showEventPopup]?.status === "confirmed" && (
                <>
                  <EventButton onClick={() => setShowEventPopup(undefined)}>
                    <div style={{ display: "flex" }}>
                      <img
                        src={EventEscalated}
                        width={20}
                        height={20}
                        style={{ marginRight: "5px" }}
                      />
                      ESCALATE
                    </div>
                  </EventButton>
                </>
              )}
            </EventButtonGroup>
          </div>
        </NiceModal>
      )}
    </div>
  );
};

const EventContainer = styled.div`
  height: 150px;
  margin-bottom: 5px;
`;

const EventBox = styled.div`
  min-width: 150px;
  max-width: 150px;
  height: 60px;
  background-color: #e59079;
  border-radius: 15px;
  display: flex;
  // justify-content: center;
  align-items: center;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const EventContent = styled.div`
  display: flex;

  flex-direction: column;
`;

const EventTime = styled.div`
  font-size: 0.8em;
  color: #dedede;
  font-weight: 500;
`;
const EventTitle = styled.div`
  color: #e1e1e1;
  font-size: 1em;
  font-weight: 600;
`;

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: auto;
  scrollbar-color: #ccc #eee;
  scrollbar-width: thin;
  padding: 10px;
  ::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0.5);
    background: transparent;
    width: 0;
    height: 0;
    border-left: 16px solid #333;
    border-top: 16px solid #333;
    border-bottom: 16px solid white;
    border-right: 16px solid white;
  }
`;

const TabSubHeading = styled.div`
  //   font-weight: light;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
  // margin-bottom: 10px;
`;

const EventButtonGroup = styled.div`
  display: flex;
  gap: 20px;
`;
const EventButton = styled.div`
  font-weight: 600;
  background: #488f8d;
  padding: 10px;
  margin: 0 auto;
  border-radius: 10px;
  height: 30px;
  color: #efeefe;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
`;

const eventsObj = {
  1: {
    event: "Bradycardia",
    condition: "PR<30",
    duration: 1,
    content: "Heart rate below 30 for at least 1 minute.",
  },
  2: {
    event: "Bradycardia",
    condition: "PR>30 AND PR<40",
    duration: 5,
    content: "Heart rate between 30 and 40 for at least 5 minutes.",
  },
  3: {
    event: "Bradypnea",
    condition: "BRPM<7 AND PR>30",
    duration: 1,
    content:
      "Respiration rate below 7 with pulse rate above 30 for at least 1 minute.",
  },
  4: {
    event: "Circulatory Collapse",
    condition: "SBP<100 AND PR<50",
    duration: null,
    content: "Low blood pressure (<100) with pulse rate below 50.",
  },
  5: {
    event: "Circulatory Collapse",
    condition: "SBP<100 AND PR>110",
    duration: 30,
    content:
      "Low blood pressure (<100) with pulse rate above 110 for at least 30 minutes.",
  },
  6: {
    event: "Circulatory Collapse",
    condition: "SBP<100 AND PR>130 AND SPO2<92",
    duration: 5,
    content:
      "Low blood pressure (<100) with pulse rate above 130 and SpO2 below 92 for at least 5 minutes.",
  },
  7: {
    event: "Circulatory Collapse",
    condition: "SBP<100 AND SPO2<92",
    duration: 10,
    content:
      "Low blood pressure (<100) with SpO2 below 92 for at least 10 minutes.",
  },
  8: {
    event: "Desaturation",
    condition: "SPO2<92",
    duration: 60,
    content: "Oxygen saturation below 92% for at least 60 minutes.",
  },
  9: {
    event: "Desaturation",
    condition: "SPO2<88",
    duration: 10,
    content: "Oxygen saturation below 88% for at least 10 minutes.",
  },
  10: {
    event: "Desaturation",
    condition: "SPO2<85",
    duration: 5,
    content: "Oxygen saturation below 85% for at least 5 minutes.",
  },
  11: {
    event: "Desaturation",
    condition: "SPO2<80",
    duration: 1,
    content: "Oxygen saturation below 80% for at least 1 minute.",
  },
  12: {
    event: "Hypertension",
    condition: "SBP>180",
    duration: 60,
    content: "Systolic blood pressure above 180 for at least 60 minutes.",
  },
  13: {
    event: "Hypertension",
    condition: "SBP>220",
    duration: 30,
    content: "Systolic blood pressure above 220 for at least 30 minutes.",
  },
  14: {
    event: "Hypotension",
    condition: "SBP<70",
    duration: 30,
    content: "Systolic blood pressure below 70 for at least 30 minutes.",
  },
  15: {
    event: "Hypotension",
    condition: "SBP<91",
    duration: 60,
    content: "Systolic blood pressure below 91 for at least 60 minutes.",
  },
  16: {
    event: "Hypoventilation",
    condition: "BRPM<11 AND SPO2<88",
    duration: 5,
    content:
      "Respiration rate below 11 with SpO2 below 88 for at least 5 minutes.",
  },
  17: {
    event: "Sepsis",
    condition: "SBP<70 AND BRPM>22",
    duration: 30,
    content:
      "Sepsis suspected with low blood pressure and respiration rate above 22 for at least 30 minutes.",
  },
  18: {
    event: "Sepsis",
    condition: "SBP<91 AND BRPM>22",
    duration: 60,
    content:
      "Sepsis suspected with low blood pressure and respiration rate above 22 for at least 60 minutes.",
  },
  19: {
    event: "Sepsis",
    condition: "SBP<100 AND BRPM>30",
    duration: 30,
    content:
      "Sepsis suspected with low blood pressure and respiration rate above 30 for at least 30 minutes.",
  },
  20: {
    event: "Sepsis",
    condition: "SBP<100 AND BRPM>24",
    duration: 60,
    content:
      "Sepsis suspected with low blood pressure and respiration rate above 24 for at least 60 minutes.",
  },
  21: {
    event: "Sepsis",
    condition: "SBP<70 AND BRPM>30",
    duration: 10,
    content:
      "Sepsis suspected with very low blood pressure and respiration rate above 30 for at least 10 minutes.",
  },
  22: {
    event: "SIRS",
    condition: "CBT>38 AND PR > 90 AND BRPM>22",
    duration: 30,
    content:
      "Systemic inflammatory response syndrome (SIRS) with high temperature, high pulse, and high respiration rate for at least 30 minutes.",
  },
  23: {
    event: "SIRS",
    condition: "CBT<36 AND PR > 90 AND BRPM>22",
    duration: 30,
    content:
      "Systemic inflammatory response syndrome (SIRS) with low temperature, high pulse, and high respiration rate for at least 30 minutes.",
  },
  24: {
    event: "CRS",
    condition: "SBP<70 AND CBT>38",
    duration: 30,
    content:
      "Critical response syndrome with low blood pressure and high temperature for at least 30 minutes.",
  },
  25: {
    event: "CRS",
    condition: "SBP<91 AND CBT>38",
    duration: 60,
    content:
      "Critical response syndrome with low blood pressure and high temperature for at least 60 minutes.",
  },
  26: {
    event: "CRS",
    condition: "CBT>38",
    duration: 30,
    content:
      "Critical response syndrome with high temperature for at least 30 minutes.",
  },
  27: {
    event: "Tachycardia",
    condition: "PR>111",
    duration: 60,
    content: "Heart rate above 111 for at least 60 minutes.",
  },
  28: {
    event: "Tachycardia",
    condition: "PR>130",
    duration: 30,
    content: "Heart rate above 130 for at least 30 minutes.",
  },
  29: {
    event: "Tachypnea",
    condition: "BRPM>24",
    duration: 5,
    content: "Respiration rate above 24 for at least 5 minutes.",
  },
  30: {
    event: "Tachypnea",
    condition: "BRPM>30",
    duration: 1,
    content: "Respiration rate above 30 for at least 1 minute.",
  },
};

// console.log(eventsObj);

const eventStatusIconMap = {
  new: EventImg,
  confirmed: EventConfirmed,
  escalated: EventEscalated,
};

const eventStatusMessageMap = {
  new: "New",
  confirmed: "Confirmed",
  escalated: "Escalated",
};

export default CorEventsTool;
