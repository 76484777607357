import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  Area,
  ReferenceDot,
  ReferenceLine,
  Bar,
  BarChart,
  Rectangle,
} from "recharts";
import useDimensions from "react-use-dimensions";
import moment from "moment";

const PARAMS = [
  {
    name: "avg_bracelet_speed",
    label: "Average Speed",
  },
  {
    name: "avg_cfi",
    label: "Average CFI",
  },
  {
    name: "avg_gps_speed",
    label: "Average GPS Speed",
  },
  {
    name: "avg_heart_rate",
    label: "Average Heart Rate",
  },
  {
    name: "max_heart_rate",
    label: "Max Heart Rate",
  },
  {
    name: "total_calories",
    label: "Total Calories",
  },
  {
    name: "total_distance",
    label: "Total Distance",
  },
  {
    name: "total_steps",
    label: "Total Steps",
  },
];

export default function WorkoutViewTool(props) {
  const {
    avg_bracelet_speed,
    avg_cfi,
    avg_gps_speed,
    avg_heart_rate,
    avg_vo2_max,
    calendar_date,
    max_heart_rate,
    total_calories,
    total_distance,
    total_steps,
    points = [],
  } = props;

  let heartRatePoints = points.filter((x) => x.heart_rate != undefined);
  let gpsPoints = points.filter((x) => x.gps_latitude != undefined);

  const [ref, { x, y, width, height }] = useDimensions();

  const [viewport, setViewport] = useState({
    latitude: 38.63738602787579,
    longitude: -121.23576311149986,
    zoom: 6.8,
    bearing: 0,
    pitch: 0,
    dragPan: true,
    width: 600,
    height: 600,
  });

  const dataOne = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: [
        ...gpsPoints.map((x) => [x.gps_longitude, x.gps_latitude]),
        // [-122.41510269913951, 37.77909036739809],
        // [39.5423, -77.0564]
      ],
    },
  };

//   console.log("dataOne = ", dataOne);
//   console.log("heartRatePoints = ", heartRatePoints);

  return (
    <Wrapper>
      <ul>
        {PARAMS.map((x) => {
          let v = props[x.name];

          if (
            ["Average GPS Speed", "Total Distance"].includes(x.label) &&
            canUseToFixed(v)
          ) {
            v = v.toFixed(2);
          }
          return (
            <li key={x.name}>
              <strong>{x.label}:</strong> {v}
            </li>
          );
        })}
      </ul>

      <div style={{ width: "100%", height: 320 }} ref={ref}>
        {heartRatePoints.length < 3 ? null : (
          <LineChart
            width={width}
            height={height}
            data={heartRatePoints}
            connectNulls={false}
            margin={{ top: 5, right: 5, left: -20, bottom: 5 }}
          >
            {/*<XAxis dataKey="date"/>*/}
            <XAxis
              dataKey="date"
              tickCount={25}
              tickFormatter={(a) => {
                if (`${a}`.length < 8) {
                  return a;
                }
                return moment(a).format("HH:mm");
              }}
            />

            <YAxis />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line
              type="monotone"
              dataKey={"heart_rate"}
              strokeWidth={2}
              animationDuration={2}
              dot={false}
            />
            <Tooltip />
          </LineChart>
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const MapPlaceholder = styled.div`
  width: 100%;
  height: 400px;
  background: palegoldenrod;
`;

function canUseToFixed(value) {
  return typeof value === "number" && Number.isFinite(value);
}
