import { useState, useEffect, useRef } from "react";
import useDimensions from "react-use-dimensions";
import ls from "local-storage";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ReferenceLine,
  Rectangle,
} from "recharts";
import moment from "moment";
import SimpleNoDataMessageTool from "./SimpleNoDataMessageTool";
import styled from "styled-components";
import { TooltipRow, TooltipWrapper } from "./SleepActivityCountTool";
import { STEP_BAR_COLOR } from "../../ui/templates/Charts";

export default function SleepActivityStepsTool(props) {
  const { offsetActivityPoints = [], id, date } = props;
  const theme = ls.get("theme");
  const [ref, { x, y, width, height }] = useDimensions();
  const [offsetActivityStepPoints, setOffsetActivityStepPoints] = useState([]);
  const [chartStartEnd, setChartStartEnd] = useState({});

  const tooltipStyleWrapper = {
    wrapperStyle: {
      background: theme === "dark" ? "#1c1c1cec" : "#fffe",
      color: theme === "dark" ? "#819a96ec" : "#212a26ec",
      padding: "1rem",
      zIndex: "100",
    },
    cursor: { stroke: "#ee5678", strokeWidth: 1 },
  };

  let xticks = [];

  function get24HrTicks(ticksNumber, date) {
    const dateFrom = moment(date);
    let twelveHoursBefore = dateFrom.subtract(12, "hours"); // go back 12 hours to construct ticks
    const interval = Math.round(24 / ticksNumber);
    let ticks = Array.from({ length: ticksNumber }).map((x, i) => ({
      left: (100.0 * i) / ticksNumber,
    }));
    ticks = ticks.map((x, i) => {
      const tickObj = {
        ...x,
        t: twelveHoursBefore.utc().valueOf(),
        verbal: twelveHoursBefore.format("DD_HH:mm a z"),
      };
      twelveHoursBefore.add(interval, "hours");
      return tickObj.t;
    });
    ticks.push(dateFrom.valueOf());
    return ticks;
  }

  xticks = get24HrTicks(12, date);
  // console.log({ offsetActivityStepPoints });

  useEffect(() => {
    if (!offsetActivityPoints || offsetActivityPoints.length === 0) {
      return;
    }
    const steps = offsetActivityPoints
      // .filter((x) => x.date.includes(":05"))
      .map((x, i) => {
        const { steps, t, date = "00:00:00", activity_count, slot } = x;
        // const prevDate = offsetActivityPoints[i - 1]?.date;
        const prevTs = offsetActivityPoints[i - 1]?.slot;
        let prevDate = prevTs ? moment(prevTs).format("Do HH:mm:ss") : "";
        let currDate = t ? moment(t).format("Do HH:mm:ss") : "";

        let label = `${prevDate} - ${currDate}`;
        // if (steps > 100) {
        //   console.log(date, steps);
        // }

        return {
          // steps: steps > 70 ? steps : steps / 10,
          steps: steps,
          t: +t,
          timestamp: +t,
          date: date,
          activity_count,
          slot,
          timeLabel: label,
        };
      });
    // console.log(
    //   steps.map((x) => {
    //     return { s: x.steps, d: x.date, t: x.timeLabel };
    //   })
    // );

    setOffsetActivityStepPoints(steps);
    return;
  }, [date, id, offsetActivityPoints]);

  // console.log("SleepActivityStepsTool, rerendered");

  return (
    <Wrapper style={{ height: 230, width: "100%" }} ref={ref}>
      {!offsetActivityPoints || offsetActivityPoints.length === 0 ? (
        <SimpleNoDataMessageTool
          message={"Sorry, there is no activity step data for this day."}
        />
      ) : (
        <div
          style={{ height: height }}
          className="activity-step-1min-container"
        >
          <BarChart
            className="bar-chart-wrapper"
            height={height}
            width={width}
            data={offsetActivityStepPoints}
            // allowDataOverflow={false}
          >
            <XAxis
              dataKey="timestamp"
              ticks={xticks}
              interval={0}
              type="number"
              tickFormatter={(x) => {
                const _t = moment(x).format("HH:mm");
                return _t;
              }}
              domain={([min, max]) => {
                return [xticks[0], xticks[xticks.length - 1]];
              }}
              // overflow={"clip"}
              allowDataOverflow
            />
            <YAxis
              width={41}
              dataKey={"steps"}
              type="number"
              domain={([min, max]) => {
                return [min, max];
              }}
              style={{
                boxSizing: "border-box",
                border: "1px solid #c0c0c0",
              }}
            />
            <Tooltip
              {...tooltipStyleWrapper}
              content={<ActivityCountToolTip />}
            />
            <Bar
              dataKey="steps"
              cursor={"pointer"}
              stroke={STEP_BAR_COLOR}
              // fill={STEP_BAR_COLOR}
              // activeBar={{ fill: "red" }}
              activeBar={<Rectangle fill="red" stroke="red" />}
              // background={{ fill: "#eee" }}
              strokeWidth={1}
              // isAnimationActive={true}
            />
          </BarChart>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

function ActivityCountToolTip(props) {
  const { active, payload, label } = props;
  const activityCount = payload[0]?.value;
  const showSeconds = true;
  if (active && payload && payload.length) {
    const { sleepState = "", timeLabel } = payload[0]?.payload || {};
    // console.log(payload[0]?.payload);

    let sleepStateVerbal = "";
    if (+sleepState > 100) {
      sleepStateVerbal = TarnsformerHelper.getSleepStageNameByType(+sleepState);
    }
    return (
      <TooltipWrapper className="custom-tooltip">
        {/* <TooltipRow className="label">{`Time: ${moment(label).format(
          showSeconds ? "HH:mm:ss" : "HH:mm"
        )}`}</TooltipRow> */}
        <TooltipRow>{`Time: ${timeLabel}`}</TooltipRow>
        <TooltipRow className="label">Step: {activityCount}</TooltipRow>
        {sleepStateVerbal.length > 0 && (
          <TooltipRow className="label">
            Sleep Stage: {sleepStateVerbal}
          </TooltipRow>
        )}
      </TooltipWrapper>
    );
  }
  return null;
}
