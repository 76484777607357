import { useState, useEffect, useRef } from "react";
import { BarChart, XAxis, YAxis, Tooltip, Bar, ReferenceLine } from "recharts";
import moment from "moment";
import styled from "styled-components";
import TarnsformerHelper, {
  ONSET_COLOR,
} from "../../../helpers/TarnsformerHelper";
import ls from "local-storage";
import DoctorAPI from "../../../api/DoctorAPI";
import { get24HrTicks, get36HrTicks } from "./AiSleepViewTool";
import useDimensions from "react-use-dimensions";
import SimpleNoDataMessageTool from "./SimpleNoDataMessageTool";
import { filterSleepData } from "./SleepWakeTool";
import { GRID_GAP_DASH_ARRAY, GRID_STROKE_COLOR } from "../../ui/templates";
import { isDebugMode } from "../../../helpers/UiHelper";

const mapToActivityCount = (sleepData) => {
  const _data = sleepData.map((x, i) => {
    return {
      activity_count: x.activity_count,
      sleepState: x.sleep_state,
      sleep_q: x.sleep_q,
      date: x.date,
      date_without_tz: x.date_without_tz,
      timestamp: x.timestamp,
    };
  });
  return _data;
};

export default function SleepActivityCountTool(props) {
  const {
    sleepData = [],
    date,
    loading = false,
    sleepAndWakeData = {},
    noonToNoonActivityCount = [],
  } = props;
  const [ref, { x, y, width, height }] = useDimensions();
  const [activityCount, setActivityCounts] = useState([]);
  const theme = ls.get("theme");
  // console.log({ onsetOffsetPercentages });
  const [sleepWakeReferenceLineLocation, setSleepWakeReferenceLineLocation] =
    useState({});
  // console.log(sleepAndWakeData);

  const dateFrom = moment(date);
  const hourOffset = 12;
  const twelveHoursBefore = dateFrom.clone().subtract(hourOffset, "hours");
  const tooltipStyleWrapper = {
    wrapperStyle: {
      background: theme === "dark" ? "#1c1c1cec" : "#fffe",
      color: theme === "dark" ? "#819a96ec" : "#212a26ec",
      padding: "1rem",
      zIndex: "100",
    },
    cursor: { stroke: "#ee5678", strokeWidth: 1 },
  };

  useEffect(() => {
    const onset = sleepAndWakeData?.sleepOnset?.timestamp;
    const onsetEnd = sleepAndWakeData?.sleepOnsetEnd?.timestamp;
    const wakeOnset = sleepAndWakeData?.wakeOnset?.timestamp;
    const wakeOnsetEnd = sleepAndWakeData?.wakeOnsetEnd?.timestamp;
    // const onset =
    const sleepTimestamps = {
      GoToSleep: onset,
      SleepOnset: onsetEnd,
      Wake: wakeOnset,
      OutOfBed: wakeOnsetEnd,
    };
    // console.log({ sleepTimestamps });

    setSleepWakeReferenceLineLocation(sleepTimestamps);
  // }, [date, sleepData, sleepAndWakeData]);
  }, [date, sleepAndWakeData]);

  // console.log(width);

  useEffect(() => {
    // const _filteredData = filterSleepData(sleepData);
    const _activityCountData = noonToNoonActivityCount;
    // console.log(_activityCountData);
    setActivityCounts(_activityCountData);
  }, [sleepData, date, noonToNoonActivityCount]);

  let xticks = [];

  function get24HrTicks(ticksNumber, date) {
    const dateFrom = moment(date);
    let twelveHoursBefore = dateFrom.subtract(12, "hours"); // go back 12 hours to construct ticks

    const interval = Math.round(24 / ticksNumber);

    let ticks = Array.from({ length: ticksNumber }).map((x, i) => ({
      left: (100.0 * i) / ticksNumber,
    }));

    ticks = ticks.map((x, i) => {
      const tickObj = {
        ...x,
        t: twelveHoursBefore.utc().valueOf(),
        verbal: twelveHoursBefore.format("DD_HH:mm a z"),
      };
      twelveHoursBefore.add(interval, "hours");
      // return twelveHoursBefore.utc().valueOf();
      return tickObj.t;
    });
    ticks.push(dateFrom.valueOf());
    return ticks;
  }

  xticks = get24HrTicks(12, date);

  if (loading) {
    return (
      <SimpleNoDataMessageTool
        showTopImg={false}
        message={`Sleep activity count data loading...`}
      />
    );
  }

  return (
    <Wrapper style={{ height: 230, width: "100%" }} ref={ref}>
      {!noonToNoonActivityCount || noonToNoonActivityCount.length === 0 ? (
        <SimpleNoDataMessageTool
          message={"Sorry, there is no activity count data for this day."}
        />
      ) : (
        <div style={{ height: height }} className="activity-count-container">
          <BarChart
            className="bar-chart-wrapper"
            height={height}
            width={width}
            data={activityCount}
            allowDataOverflow={false}
          >
            {/* <CartesianGrid strokeDasharray={"1 1"} /> */}
            <XAxis
              dataKey="timestamp"
              ticks={xticks}
              interval={0}
              // activity_count
              // tickCount={25}
              type="number"
              tickFormatter={(x) => {
                const _t = moment(x).format("HH:mm");
                return _t;
              }}
              // textRendering={}
              domain={([min, max]) => {
                return [xticks[0], xticks[xticks.length - 1]];
              }}
              // stroke="#c0c0c0"
              allowDataOverflow
            />
            <YAxis
              width={41}
              dataKey={"activity_count"}
              domain={([min, max]) => {
                return [min, max];
              }}
              style={{
                boxSizing: "border-box",
                border: "1px solid #c0c0c0",
              }}
            />
            <Bar
              dataKey="activity_count"
              // fill="#3763f5"
              stroke="#3763f5"
              background={{ fill: "#eee" }}
              strokeWidth={1.4}
              // fill="#8884d8"
              // barGap={1}
              // barCategoryGap={0}
            />
            {["GoToSleep", "SleepOnset", "Wake", "OutOfBed"].map((x, i) => {
              if (!isDebugMode()) {
                return null;
              }
              const _x = sleepWakeReferenceLineLocation?.[x];
              return <ReferenceLine key={i} x={_x} stroke={ONSET_COLOR} />;
            })}
            {/* <Bar dataKey="activity_count" fill="" /> */}
            <Tooltip
              {...tooltipStyleWrapper}
              content={<ActivityCountToolTip />}
            />
            {/* <Legend /> */}
          </BarChart>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

function ActivityCountToolTip(props) {
  const { active, payload, label } = props;
  const activityCount = payload[0]?.value;
  const showSeconds = true;
  if (active && payload && payload.length) {
    const { sleepState = "" } = payload[0]?.payload || {};
    let sleepStateVerbal = "";
    if (+sleepState > 100) {
      sleepStateVerbal = TarnsformerHelper.getSleepStageNameByType(+sleepState);
    }
    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{`${moment(label).format(
          showSeconds ? "HH:mm:ss" : "HH:mm"
        )}`}</TooltipRow>
        <TooltipRow className="label">
          Activity Count: {activityCount}
        </TooltipRow>
        {sleepStateVerbal.length > 0 && (
          <TooltipRow className="label">
            Sleep Stage: {sleepStateVerbal}
          </TooltipRow>
        )}
      </TooltipWrapper>
    );
  }
  return null;
}

export const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

export const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;
