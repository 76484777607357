import { useState, useEffect, useRef } from "react";
import moment from "moment";
import styled from "styled-components";
import TarnsformerHelper, {
  VALID_SLEEP_STATES_VALUES,
  SLEEP_VALUES,
  IS_AWAKE_COLOR,
  ONSET_COLOR,
  IS_ASLEEP_COLOR,
  SLEEP_STATUS_PROPERTIES,
  ONSET_END_COLOR,
} from "../../../helpers/TarnsformerHelper";
import DoctorAPI from "../../../api/DoctorAPI";
import { get24HrTicks, get36HrTicks } from "./AiSleepViewTool";
import useDimensions from "react-use-dimensions";
import SimpleNoDataMessageTool from "./SimpleNoDataMessageTool";
import CommonHelper from "../../../helpers/CommonHelper";

const HOURS_PER_DAY = 24;
const MINUTES_PER_HOUR = 60;
const SECONDS_PER_DAY = 60 * HOURS_PER_DAY * MINUTES_PER_HOUR;

const SleepStatusName = [
  // index suggest state
  // SS_AWAKE = 1,
  //   SS_LIGHT = 2,
  //   SS_DEEP = 3,
  //   SS_REM = 4,
  "undefined", // 0
  "Awake", // 1
  "Light", // 2
  "Asleep", // 3
  "REM", //4
  "GoToSleep", // 5
];

const getSleepStatus = (input) => {
  if (VALID_SLEEP_STATES_VALUES.includes(input)) {
    return SleepStatusName[input];
  } else {
    return "unknown";
  }
};

export const SleepOrWake = (input) => {
  /**
   *
   * SleepStatus: Int {
   *    case Undefined = 0
   *    case Awake     = 1
   *    case Light     = 2
   *    case Deep      = 3
   *    case Rem       = 4
   *     Case GoToSleep = 5
   *}
   * output: 1: awake
   *         2: sleep
   *
   **/
  if (input == 1) {
    return 1;
  } else if (SLEEP_VALUES.includes(input)) {
    return 2;
  } else {
    return 0;
  }
};

export const filterSleepData = (data) => {
  const _validPoints = data.filter((x) => {
    const _sleep_state = x?.sleep_state;
    const _date = x?.date;
    return (
      _sleep_state !== undefined &&
      _sleep_state !== null &&
      _date &&
      VALID_SLEEP_STATES_VALUES.includes(_sleep_state)
    );
  });
  return _validPoints;
};

const mapToSleepData = (data) => {
  const _sleepData = data.map((x, i) => {
    return {
      sleepState: x.sleep_state,
      date: x.date,
      sleep_q: x.sleep_q,
      date_without_tz: x.date_without_tz,
      timestamp: x.timestamp,
    };
  });
  return _sleepData;
};

export default function SleepWakeToolEnhanced(props) {
  const {
    uuid,
    date,
    sleepDataResponse = [],
    sleepDataLoading = false,
  } = props;
  const [sleepData, setSleepData] = useState([]);
  const [unprocessedSleepData, setUnprocessedSleepData] = useState([]);
  // const [sleepDataLoading, setSleepDataLoading] = useState(false);
  const [sleepDataStatus, setSleepDataStatus] = useState([]);
  const [sleepAndWakeUpTime, setSleepAndWakeUpTime] = useState({
    time_to_sleep: undefined,
    time_to_wake_up: undefined,
    start_date: undefined,
    end_date: undefined,
  });
  const [onsetOffsetPercents, setOnsetOffsetPercents] = useState({
    onset: undefined,
    onset_end: undefined,
    offset: undefined,
    offset_end: undefined,
  });
  const [showSummaryBox, setShowSummaryBox] = useState(false);
  const mode = "24"; // or "36"

  const handleTimezone = true;

  // useEffect(() => {}, [date, uuid, sleepDataResponse]);

  useEffect(() => {
    // setSleepDataLoading(true);
    // setSleepData([]);
    if (!sleepDataResponse || sleepDataResponse.length === 0) {
      return;
    }
    const d = sleepDataResponse;

    // console.log({ timeToSleep, timeToWakeUp });

    setSleepDataStatus([]);
    // console.log("getPatientNoonToNoonSleep", d);
    // console.table(d, ["sleep_state", "date", "activity_count"]);
    setUnprocessedSleepData(d);
    const _validPoints = filterSleepData(d);
    const _sleepData = mapToSleepData(_validPoints);
    let statesChange = TarnsformerHelper.findSleepStateChange(_sleepData);
    // console.table(statesChange, ["sleep_state", "width"]);
    const timeToSleepIndex = d.findIndex((x) => x.sleep_state === 101);
    const onsetIndex = d.findIndex((x) => x.sleep_state === 102);
    const wakeUpIndex = d.findLastIndex((x) => x.sleep_state === 103);
    const outOfBedIIndex = d.findLastIndex((x) => x.sleep_state === 104);
    let v = {};
    let percentages = {};

    // console.log({ timeToSleepIndex, onsetIndex, wakeUpIndex, outOfBedIIndex });

    if (
      timeToSleepIndex < 0 ||
      onsetIndex < 0 ||
      wakeUpIndex < 0 ||
      outOfBedIIndex < 0
    ) {
      setShowSummaryBox(true);
    } else {
      const startDate = d[timeToSleepIndex]?.date;
      const timeToSleep =
        +d[onsetIndex].timestamp - +d[timeToSleepIndex]?.timestamp;

      const endDate = d[wakeUpIndex]?.date;
      const timeToWakeUp =
        +d[outOfBedIIndex]?.timestamp - +d[wakeUpIndex]?.timestamp;
      // const start_date

      v = {
        time_to_sleep: timeToSleep,
        time_to_wake_up: timeToWakeUp,
        start_date: startDate,
        end_date: endDate,
      };

      const onsetPercentage =
        (100 *
          moment(+d[timeToSleepIndex]?.timestamp).diff(
            twelveHoursBefore,
            "seconds"
          )) /
        SECONDS_PER_DAY;

      const onsetEndPercentage =
        (100 *
          moment(+d[onsetIndex]?.timestamp).diff(
            twelveHoursBefore,
            "seconds"
          )) /
        SECONDS_PER_DAY;

      const offsetPercentage =
        (100 *
          moment(+d[wakeUpIndex]?.timestamp).diff(
            twelveHoursBefore,
            "seconds"
          )) /
        SECONDS_PER_DAY;

      const offsetEndPercentage =
        (100 *
          moment(+d[outOfBedIIndex]?.timestamp).diff(
            twelveHoursBefore,
            "seconds"
          )) /
        SECONDS_PER_DAY;

      percentages = {
        onset: onsetPercentage,
        onset_end: onsetEndPercentage,
        offset: offsetPercentage,
        offset_end: offsetEndPercentage,
      };
    }

    // console.log(percentages);

    setSleepAndWakeUpTime(v);
    setOnsetOffsetPercents(percentages);
    // setOnsetOffsetPercents({onset: })
    setSleepDataStatus(statesChange);
    setSleepData(_sleepData);
  }, [date, uuid, sleepDataResponse]);

  const dateFrom = moment(date);
  const hourOffset = 12;
  const twelveHoursBefore = dateFrom.clone().subtract(hourOffset, "hours");

  // console.log("sleepDataStatus", sleepDataStatus);

  const toFixedValue = 2;
  let slots = [];
  if (mode === "24") {
    slots = sleepDataStatus.map((sleepSlot, i) => {
      const _left_percent =
        i === 0
          ? // ? 0
            (100 *
              moment(sleepData[sleepSlot.indexFrom]?.date).diff(
                twelveHoursBefore,
                "seconds"
              )) /
            (60 * HOURS_PER_DAY * MINUTES_PER_HOUR)
          : (100 *
              moment(sleepData[sleepSlot.indexFrom]?.date).diff(
                twelveHoursBefore,
                "seconds"
              )) /
            (60 * HOURS_PER_DAY * MINUTES_PER_HOUR);

      const _width =
        1000 *
        MINUTES_PER_HOUR *
        60 *
        moment(sleepData[sleepSlot.indexTo]?.date).diff(
          moment(sleepData[sleepSlot.indexFrom]?.date),
          "seconds"
        );

      let _width_percent =
        (100 *
          moment(sleepData[sleepSlot.indexTo]?.date).diff(
            moment(sleepData[sleepSlot.indexFrom]?.date),
            "seconds"
          )) /
        (60 * HOURS_PER_DAY * MINUTES_PER_HOUR);

      const sleepState = TarnsformerHelper.getSleepStatus(sleepSlot.sleepState);
      if (_width_percent > 0 && _width_percent < 0.1) {
        _width_percent = 0.1;
      }
      if (+sleepSlot.sleepState > 100) {
        _width_percent = 0.1;
      }
      let z = 1;
      if (
        ["GoToSleep", "SleepOnset", "Wake", "OutOfBed"].includes(sleepState)
      ) {
        z = 10;
      }

      return {
        key: i,
        sleepState: sleepSlot.sleepState,
        color: SLEEP_STATUS_PROPERTIES[sleepState]?.color,
        start_date: sleepData[sleepSlot.indexFrom]?.date,
        end_date: sleepData[sleepSlot.indexTo]?.date,
        from: Date.parse(sleepData[sleepSlot.indexFrom]?.date),
        to: Date.parse(sleepData[sleepSlot.indexTo]?.date),
        height: SLEEP_STATUS_PROPERTIES[sleepState].height,
        timestamp: sleepData[sleepSlot.indexFrom]?.date,
        type: SLEEP_STATUS_PROPERTIES[sleepState].isAsleep,
        // raw: moment(x.raw).diff(twelveHoursBefore, "minutes") / (24 * 60),
        left_percent: _left_percent.toFixed(toFixedValue),
        width: _width.toFixed(toFixedValue), // in milliseconds
        width_percent: _width_percent.toFixed(toFixedValue),
        z: z,
      };
    });
  } else if (mode === "36") {
  } else {
  }

  let showSleepWakeMark = true;
  // if (
  //   sleepAndWakeUpTime.start_date === undefined ||
  //   sleepAndWakeUpTime.end_date === undefined ||
  //   sleepAndWakeUpTime.time_to_wake_up === undefined ||
  //   sleepAndWakeUpTime.time_to_sleep === undefined
  // ) {
  // if (showSummaryBox) {
  //   showSleepWakeMark = true;
  // }
  // console.log({ slots });

  // console.log("binary raw data:", sleepData);
  let ticksNumber = 24;
  // const ticks24Hrs = get24HrTicks(12, date);
  let xticks = [];
  xticks = get24HrTicks(12, date);
  // console.log({ xticks });

  if (sleepDataLoading) {
    return (
      <SimpleNoDataMessageTool
        showTopImg={false}
        message={`${mode} hours Sleep data loading...`}
      />
    );
  }

  return (
    <div style={{ height: 260 }}>
      {!slots || slots.length === 0 ? (
        <SimpleNoDataMessageTool
          message={"Sorry, there no sleep data for this day."}
        />
      ) : (
        <>
          <div style={{ height: 230 }}>
            <BinarySleepViewTool
              vertAxisLabels={["DEEP", "LIGHT", "REM", "AWAKE"]}
              slots={slots}
              ticks={xticks}
              mode={mode}
              //   utcHandler={adjustUTCOffset}
              //   utcOffset={utcOffset}
              sleepAndWakeUpTime={sleepAndWakeUpTime}
              onsetOffsetPercents={showSleepWakeMark ? onsetOffsetPercents : {}}
            />
          </div>
          <div style={{ display: "flex" }}>
            {/* <RangeModeSelector /> */}
            {/* <ExportButtons sleepData={unprocessedSleepData} /> */}
          </div>
        </>
      )}
    </div>
  );
}

function BinarySleepViewTool(props) {
  const {
    slots = [],
    vertAxisLabels = ["DEEP", "LIGHT", "REM", "AWAKE"],
    ticksNumber = 8,
    ticks = [],
    noDataText = "...",
    utcHandler = () => {},
    loading,
    mode = "24",
    sleepAndWakeUpTime,
    onsetOffsetPercents = {},
  } = props;
  // console.log("onsetOffsetPercents", onsetOffsetPercents);

  let showMarkers = true;
  if (onsetOffsetPercents.onset_end === onsetOffsetPercents.offset) {
    showMarkers = false;
  }

  const optSlots = slots;
  const [hoveredIndex, setHoveredIndex] = useState(undefined);
  // const chartRef = useRef(null);
  const [onsetRef, onsetSize] = useDimensions();
  // const [hoveringIndex, setHoveringIndex] = useState(undefined);
  const [mousePos, setMousePos] = useState({ x: undefined, y: undefined });

  const [ref, { y, x, left, height, width, bottom }] = useDimensions();
  // console.log(ref, size);
  const SleepWakeTriangleMark = (props) => {
    const { status = "", left } = props;
    // console.log(props);
    const [hoveredText, setHoverText] = useState("");
    const [hoveredType, setHoveredType] = useState("");
    // const markerRef = useRef(null);

    // useEffect(() => {
    //   const _marker = markerRef?.current;
    //   const { width, x, y } = _marker.getBoundingClientRect();
    //   console.log({ width, x, y, status });
    // }, [markerRef]);

    // status: onset, onset_end, offset, offset_end
    // Bedtime, Sleep Onset, Out of Bed, Wake Up
    const _left = `${(+left).toFixed(3)}%`;
    let _color = "";
    let _rotate = "0";
    let _x_translate = "0";
    let _y_translate = "0";
    // console.log({ status });

    switch (status) {
      case "onset":
        _color = ONSET_END_COLOR;
        _x_translate = "9.5";
        // _x_translate = "-20";
        _y_translate = "-216";
        _rotate = "180";
        break;
      case "onset_end":
        _color = IS_ASLEEP_COLOR;
        _x_translate = "9.5";
        _rotate = "180";
        break;
      case "offset":
        _color = IS_ASLEEP_COLOR;
        _x_translate = "9.5";
        _rotate = "180";
        break;
      case "offset_end":
        _x_translate = "9.5";
        _y_translate = "-216";
        _rotate = "180";
        // _rotate = "180";
        _color = ONSET_END_COLOR;
        break;
      default:
        break;
    }

    // if (status === "onset_end" || status === "offset") {
    //   _color = IS_AWAKE_COLOR;
    // } else {
    //   _color = IS_ASLEEP_COLOR;
    // }
    // console.log({ status, _color });
    // console.log({ sleepAndWakeUpTime });
    // const BedTimeString = moment(sleepAndWakeUpTime["start_date"]).valueOf();
    // let SleepOnsetString =
    //   moment(sleepAndWakeUpTime["start_date"]).valueOf() +
    //   sleepAndWakeUpTime["time_to_sleep"];
    // SleepOnsetString = moment(SleepOnsetString).format("DDDD HH:mm");
    const onset =
      moment(sleepAndWakeUpTime["start_date"]).valueOf() +
      +sleepAndWakeUpTime["time_to_sleep"];
    const offset = moment(sleepAndWakeUpTime["end_date"]).valueOf();
    const offsetEnd =
      moment(sleepAndWakeUpTime["end_date"]).valueOf() +
      +sleepAndWakeUpTime["time_to_wake_up"];

    const MomentFormat = " (HH:mm)";
    const sleepTimestamps = {
      onset: moment(sleepAndWakeUpTime["start_date"]).format(MomentFormat),
      onset_end: moment(onset).format(MomentFormat),
      offset: moment(offset).format(MomentFormat),
      offset_end: moment(offsetEnd).format(MomentFormat),
    };

    // console.log({ sleepTimestamps });
    // console.log({ text: status + hoveredText });

    return (
      <TriangleMark
        // ref={markerRef}
        onMouseOver={() => {
          setHoverText(sleepTimestamps[status]);
          setHoveredType(status);
        }}
        onMouseLeave={() => {
          setHoverText("");
          setHoveredType("");
        }}
        className={`${status}_mark`}
        $dateString={hoveredText || ""}
        $left={_left}
        $status={status}
        $boxColor={_color}
        $xtranslate={_x_translate}
        $ytranslate={_y_translate}
        $rotate={_rotate}
      />
    );
  };

  const tooltipLabelFormat = "Do HH:mmZ";
  // console.log({ onsetSize });

  if (optSlots.length == 0) {
    return (
      <NoDataPlaceholder>
        <NoDataText>{noDataText}</NoDataText>
      </NoDataPlaceholder>
    );
  }

  // console.log("SleepWakeToolEnhanced, rerendered");

  return (
    <ViewWrapper>
      {hoveredIndex !== undefined &&
        (() => {
          // console.log(hoveredIndex);

          let { sleepState, from, to } = optSlots[hoveredIndex];
          const typeString = ["onset", "offset"].includes(sleepState)
            ? sleepState
            : TarnsformerHelper.getSleepStageNameByType(sleepState);
          // console.log({ typeString, sleepState });

          const startString = moment(from).format(tooltipLabelFormat);
          const untilString = moment(to).format(tooltipLabelFormat);
          return (
            <div
              style={{}}
              onMouseOver={(e) => {
                e.preventDefault();
              }}
            >
              <div
                style={{
                  padding: "10px",
                  position: "fixed",
                  width: "200px",
                  left: mousePos.x + 10,
                  top: mousePos.y + 10,
                  zIndex: 1000,
                  background: "#fff",
                  border: "1px solid #c0c0c0",
                }}
              >
                {/* {"test"} */}
                {/* {`x: ${mousePos.x}; y: ${mousePos.y}`} */}
                <div>Sleep Stage: {typeString}</div>
                <div>Start: {startString}</div>
                <div>Until: {untilString}</div>
              </div>
            </div>
          );
        })()}
      <LeftPlaceholder>
        <LeftInnerPlaceholder>
          {vertAxisLabels.map((x, i) => (
            <VertAxisLabel
              className="sleep-vert-label"
              key={`${x}`}
              $height={+i + 1}
            >
              {x}
            </VertAxisLabel>
          ))}
        </LeftInnerPlaceholder>
      </LeftPlaceholder>

      <RightPlaceholder>
        <TopPlaceholder className="sleep-top-placeholder">
          <ChartPlaceholder ref={ref}>
            {ticks.map((x, i) => {
              if (window.location.href.includes(".corsano.")) {
                return null;
              }
              if (i == 0 || i == ticks.length - 1) {
                return null;
              }
              const { left } = x;
              return (
                <div
                  className="sleep-refline"
                  key={i}
                  style={{
                    position: "absolute",
                    background: "#ccc",
                    height: "100%",
                    width: "1px",
                    left: `${left}%`,
                    bottom: 0,
                    zIndex: 0,
                  }}
                />
              );
            })}
            {[1, 2, 3, 4].map((x) => (
              <HorLine key={x} $height={x} />
            ))}
            {["onset", "onset_end", "offset", "offset_end"].map((x, i) => {
              return (
                <div
                  key={i}
                  style={{
                    display:
                      showMarkers && onsetOffsetPercents.onset
                        ? "flex"
                        : "none",
                  }}
                >
                  <SleepWakeTriangleMark
                    sleepAndWakeUpTime={sleepAndWakeUpTime}
                    left={onsetOffsetPercents[x]}
                    status={x}
                  />
                </div>
              );
            })}
            {optSlots.map((opt, i) => {
              //   console.log(opt);
              let { sleepState, end_date, start_date, from, to } = opt;

              let sw = SleepOrWake(sleepState);
              let text = "";
              if (sw === 2) {
                text = "Sleep";
              } else if (sw === 1) {
                text = "Awake";
              } else {
                text = sleepState;
              }
              // console.log(opt);
              // console.log(text);

              return (
                <Box
                  // {...opt}
                  $color={opt.color}
                  $left_percent={opt.left_percent}
                  $width_percent={opt.width_percent}
                  $height={opt.height}
                  $sleepState={opt.sleepState}
                  $z={opt.z}
                  $hovering={hoveredIndex === i}
                  key={`opt_${i}_${opt.width_percent}`}
                  onMouseEnter={() => {
                    setHoveredIndex(i);
                  }}
                  onMouseLeave={() => {
                    setHoveredIndex(undefined);
                  }}
                  onMouseMove={(event) => {
                    const pageX = event.clientX;
                    const pageY = event.clientY;
                    // console.log({ pageX, pageY });
                    // console.log(
                    //   90 + pageX - (window.innerWidth * opt.left_percent) / 100
                    // );
                    // console.log(window.innerWidth, opt.left_percent);

                    setMousePos({
                      x: pageX,
                      y: pageY,
                    });
                  }}
                >
                  {/* {text.includes("nset") && (
                    <SleepWakeTriangleMark status={text} />
                  )} */}
                  {/* {hoveredIndex === i ? (
                    <BoxToolTip
                      className="box-info"
                      $mousex={mousePos.mousex}
                      $offset={(
                        mousePos.mousex -
                        x -
                        opt.left_percent * 0.01 * width -
                        100 * (mousePos.mousex / window.innerWidth)
                      ).toFixed(0)}
                      // $voffset={sw === 1 ? 60 : -40}
                      $voffset={offsetMap[text]}
                    >
                      <div>Sleep Stage: {text}</div>
                      <div>Start: {startString}</div>
                      <div>Until: {untilString}</div>
                    </BoxToolTip>
                  ) : null} */}
                </Box>
              );
            })}
          </ChartPlaceholder>
        </TopPlaceholder>

        <BottomPlaceholder className="sleep-bottom-placeholder" $mode={mode}>
          {ticks.map((x, i) => {
            return (
              <Tick key={i} $left={x.left}>
                <TickInner className="sleep-tick-label">
                  {moment(x.t).format("DD dd HH:mm")}
                  {/* {moment(x.t).format("DD dd HH:mm ZZ")} */}
                </TickInner>
              </Tick>
            );
          })}
        </BottomPlaceholder>
      </RightPlaceholder>

      {/*{JSON.stringify(optSlots)}*/}
    </ViewWrapper>
  );
}

const bottomHeight = 14;

const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoDataText = styled.div`
  font-size: 24px;
  color: grey;
`;

const BORDER_COLOR = "lightgrey";

const Tick = styled.div`
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: ${(props) => `${props.$left}%`};
`;

const TickInner = styled.div`
  width: 35px;
  box-sizing: border-box;
  text-align: center;
  margin-left: -15px;
  font-size: 10px;
  margin-top: 2px;
  // background: white;

  ${Tick}:last-of-type > & {
    margin-left: -20px;
  }
`;

const ViewWrapper = styled.div`
  box-sizing: border-box;
  //   border: 1px solid blue;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-right: 1px solid ${BORDER_COLOR};
`;

const HorLine = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  background: ${BORDER_COLOR};
  height: 1px;
  bottom: ${(props) => `calc(${(100.0 * props.$height) / 4.0}% - 1px)`};
  z-index: 0;
`;

const Box = styled.div`
  position: absolute;
  bottom: 0px;
  height: ${(props) => `${(100.0 * props.$height) / 4.0}%`};
  width: ${(props) => `${props.$width_percent}%`};
  left: ${(props) => `${props.$left_percent}%`};
  box-sizing: border-box;
  background: ${(props) => props.$color};
  box-shadow: ${(props) =>
    props.$hovering ? `${props.$color} 0px -3px 10px ` : ``};
  z-index: ${(props) => `${props.$z}`};
  //   z-index: 10;
  &:hover {
    .box-info {
      display: block;
    }
  }
`;
const BoxToolTip = styled.div`
  display: none;
  padding: 10px;
  color: #111;
  width: max-content;
  height: 60px;
  border: 1px solid lightgrey;
  background: #ffffff;
  font-size: 16px;
  position: absolute;
  // top: 2rem;
  top: ${(props) => `${props.$voffset}px`};
  left: ${(props) => `${props.$offset}px`};
  z-index: 100;
`;

const LeftPlaceholder = styled.div`
  width: 40px;
  border-right: 1px solid ${BORDER_COLOR};
`;

const LeftInnerPlaceholder = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const VertAxisLabel = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  height: 12px;
  font-size: 10px;
  text-align: right;
  box-sizing: border-box;
  padding-right: 3px;
  bottom: ${(props) =>
    `calc(${(100.0 * props.$height) / 4.0}% - 1rem + ${bottomHeight}px)`};
  z-index: 1;
  :last-of-type {
    bottom: calc(100% - 6px);
  }
`;

const RightPlaceholder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TopPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  // overflow: hidden;
  flex: 1;
  background: white;
`;

const ChartPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow-x: clip;
`;

const BottomPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${bottomHeight}px;
  background: white;
  padding: 2px;
  border-top: 1px solid ${BORDER_COLOR};
  position: relative;
  // border: 1px solid blue;
  background: ${(props) =>
    props.$mode === "24"
      ? "-webkit-linear-gradient(180deg, #ffffff00 50%, #ffffff00 50%);"
      : "-webkit-linear-gradient(180deg, #ffffff00 66.66%, #ffffff00 0%);"}
  z-index: 100;
`;

export const TriangleMark = styled.div`
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 10px 15px 10px;
  border-color: transparent transparent ${(props) => props.$boxColor}
    transparent;
  transform: rotate(${(props) => props.$rotate}deg)
    translate(
      ${(props) => props["$xtranslate"]}px,
      ${(props) => props["$ytranslate"]}px
    );
  position: absolute;
  left: ${(props) => props.$left};
  top: -15px;
  z-index: 22;
  &::before {
    content: "${(props) =>
      sleepOnsetOffsetLabelMap[props.$status] + props.$dateString}";
    width: max-content;
    font-weight: 500;
    color: ${(props) => {
      if (props.$status === "offset_end" || props.$status === "onset") {
        return "#232323";
      } else {
        return "#232323";
      }
    }};
    transform: rotate(
      ${(props) => {
        if (props.$status === "onset_end" || props.$status === "offset") {
          return "180";
        } else {
          return "180";
        }
      }}deg
    );
    top: ${(props) => {
      if (props.$status === "offset_end" || props.$status === "onset") {
        return "-3";
      } else {
        return "1";
      }
    }}rem;

    left: -3em;
    font-size: 14px;
    position: absolute;

    z-index: 31;
  }
`;

// -webkit-text-stroke: ${(props) => {
//   if (props.$status === "offset_end" || props.$status === "onset") {
//     return "0px #eee;";
//   } else {
//     return "0px #E0F7FA;";
//   }
// }}
// const offsetMap = {
//   "1": 60,
// "2":
// sw === 1 ? 60 : -40
// }

const offsetMap = (sleepState) => {
  switch (sleepState) {
    case "Awake":
      return 60;
    case "Sleep":
      return -40;
    case "Onset":
      return 50;
    case "Offset":
      return 50;
    default:
      return 0;
  }
};

const sleepOnsetOffsetLabelMap = {
  onset: "Bedtime",
  onset_end: "Sleep Onset",
  offset: "Wake Up Time",
  offset_end: "Out of Bed",
};
