import { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import moment from "moment";
import SimpleTrendsChartTool from "./SimpleTrendsChartTool";
import DoctorAPI from "../../../api/DoctorAPI";
import { mergeReadiness, getFromToFromOption } from "./TrendsUtils";

export default function TrendsSectionTool(props) {
  const { uuid } = props;
  const [lightSummaryInRange, setLightSummaryInRange] = useState([]);
  const [selectedOption, setSelectedOption] = useState("M");
  const [summaryByType, setSummaryByType] = useState({
    activity: [],
    stress: [],
    heart_rate: [],
    sleep: [],
  });

  const [selectedOptionByType, setSelectedOptionByType] = useState({
    activity: "M",
    stress: "M",
    heart_rate: "M",
    sleep: "M",
  });
  const [loading, setLoading] = useState(false);

  const onChangeSelectedOption = (newOption) => {
    setSelectedOption(newOption);
  };

  const onChangeSelectedOptionByType = (type, newOption) => {
    setSelectedOptionByType((x) => {
      return { ...x, type: newOption };
    });
  };

  useEffect(() => {
    if (uuid == undefined) {
      return;
    }
    const controller = new AbortController();
    const { signal } = controller;
    const { from, to } = getFromToFromOption(selectedOption);
    // console.log({selectedOption, from, to});

    setLoading(true);
    setLightSummaryInRange([]);
    // console.log(from, to);

    DoctorAPI.getUserLightSummariesInRange(uuid, from, to, false, signal)
      .then((d) => {
        setLoading(false);
        // console.log("getUserLightSummariesInRange", d);
        let d_ = mergeReadiness(d);
        // console.log("mergeReadiness = ", d_);
        setLightSummaryInRange(d_);

        let _activityArr = d_.map((x) => ({ date: x.date, ...x["activity"] }));
        let _stressArr = d_.map((x) => ({ date: x.date, ...x["stress"] }));
        let _heartRateArr = d_.map((x) => ({
          date: x.date,
          ...x["heart_rate"],
        }));
        let _sleepArr = d_.map((x) => ({ date: x.date, ...x["sleep"] }));
        setSummaryByType({
          activity: _activityArr,
          stress: _stressArr,
          heart_rate: _heartRateArr,
          sleep: _sleepArr,
        });
        // let dArr = d_.map((x) => ({ date: x.date, ...x[dataType] }));
        // setSummaries(dArr);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error("gULSIR", e);
      })
      .finally(() => {
        setLoading(false);
      });
    return () => {
      controller.abort();
    };
  }, [selectedOption, uuid]);

  // useEffect(() => {
  //   if (uuid == undefined) {
  //     return;
  //   }
  //   const { from, to } = getFromToFromOption(selectedOption);
  //   setLoading(true);
  //   setLightSummaryInRange([]);
  //   DoctorAPI.getUserLightSummariesInRange(uuid, from, to, false).then((d) => {
  //     // console.log("raw summaries = ", d);
  //     setLoading(false);
  //     let d_ = mergeReadiness(d);
  //     let _activityArr = d_.map((x) => ({ date: x.date, ...x["activity"] }));
  //     let _stressArr = d_.map((x) => ({ date: x.date, ...x["stress"] }));
  //     let _heartRateArr = d_.map((x) => ({ date: x.date, ...x["heart_rate"] }));
  //     let _sleepArr = d_.map((x) => ({ date: x.date, ...x["sleep"] }));
  //     setSummaryByType({
  //       activity: _activityArr,
  //       stress: _stressArr,
  //       heart_rate: _heartRateArr,
  //       sleep: _sleepArr,
  //     });
  //     setLightSummaryInRange(d_);
  //     // let dArr = d_.map((x) => ({ date: x.date, ...x[dataType] }));
  //     // setSummaries(dArr);
  //   });
  // }, [selectedOption, selectedOptionByType, uuid]);

  return (
    <Wrapper>
      <GridItem>
        <Heading className="patient-statistics-heading">Activity</Heading>
        <SimpleTrendsChartTool
          {...props}
          dataType={"activity"}
          loading={loading}
          summaries={summaryByType["activity"]}
          selectedOption={selectedOption}
          onChangeSelectedOptionByType={onChangeSelectedOptionByType}
          onChangeSelectedOption={onChangeSelectedOption}
        />
      </GridItem>
      {window.location.href.indexOf("portal.corsano") > -1 ? null : (
        <GridItem>
          <Heading className="patient-statistics-heading">Sleep</Heading>
          <SimpleTrendsChartTool
            {...props}
            dataType={"sleep"}
            loading={loading}
            summaries={summaryByType["sleep"]}
            selectedOption={selectedOption}
            onChangeSelectedOptionByType={onChangeSelectedOptionByType}
            onChangeSelectedOption={onChangeSelectedOption}
          />
        </GridItem>
      )}
      <GridItem>
        <Heading className="patient-statistics-heading">Pulse rate</Heading>
        <SimpleTrendsChartTool
          {...props}
          dataType={"heart_rate"}
          loading={loading}
          summaries={summaryByType["heart_rate"]}
          selectedOption={selectedOption}
          onChangeSelectedOptionByType={onChangeSelectedOptionByType}
          onChangeSelectedOption={onChangeSelectedOption}
        />
      </GridItem>

      {window.location.href.indexOf("portal.corsano") > -1 ? null : (
        <GridItem>
          <Heading className="patient-statistics-heading">HRV</Heading>
          <SimpleTrendsChartTool
            {...props}
            dataType={"stress"}
            loading={loading}
            summaries={summaryByType["stress"]}
            selectedOption={selectedOption}
            onChangeSelectedOptionByType={onChangeSelectedOptionByType}
            onChangeSelectedOption={onChangeSelectedOption}
          />
        </GridItem>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Heading = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 10px;
`;

const GridItem = styled.div`
  padding: 10px;
`;
