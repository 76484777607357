import { useCallback, useEffect, useState } from "react";
import ls from "local-storage";
import styled from "styled-components";
import moment from "moment";
import { LegendStyles } from "./VitalMultiTrendsChartTool";
import {
  AXES_COLORX,
  AXES_STROKE_WIDTH,
  GRID_DASH_ARRAY,
  GRID_STROKE_COLOR,
  GRID_THICKNESS,
} from "../../ui/templates";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  Area,
  ReferenceDot,
  ReferenceLine,
  Bar,
  BarChart,
  Rectangle,
  AreaChart,
  ScatterChart,
  Scatter,
  Dot,
} from "recharts";
import useDimensions from "react-use-dimensions";
import { LINE_COLORS, STEP_BAR_COLOR } from "../../ui/templates/Charts";
import { useMappedState } from "redux-react-hook";
import { ComponentHeight } from "../../patients/panels/UiComponents";

const getParamsOptions = (name) => {
  if (name == "activity") {
    return [
      {
        label: "Steps",
        value: "total_steps",
        type: "bar",
      },
      {
        label: "Distance",
        value: "distance",
        type: "bar",
      },
      {
        label: "Calories",
        value: "calories",
        type: "bar",
      },
    ];
  }
  if (name == "pulse_rate") {
    // for pulse rate tab
    return [
      {
        label: "Average daily heart rate",
        value: "avg_daily_heart_rate",
        type: "line",
      },
    ];
  }
  if (name == "sleep") {
    return [
      {
        label: "Sleep Duration",
        value: "sleep_duration",
        type: "line",
      },
      {
        label: "Sleep Score",
        value: "sleep_score",
        type: "line",
      },
      {
        label: "Sleep Stages",
        value: "sleep_stages",
        // type: "line",
        // type: "area",
        type: "stacked-bar",
      },
      {
        label: "Awakes",
        value: "awakes",
        type: "stacked-bar",
      },
      // {
      //   label: "Schedule",
      //   value: "schedule",
      //   type: "line",
      // },
    ];
  }
  if (name == "non_invasive_blood_pressure") {
    // for pulse rate tab
    return [
      {
        label: "Blood Pressure",
        value: "blood_pressure",
        type: "line",
      },
    ];
  }
  if (name == "spo2") {
    // for pulse rate tab
    return [
      {
        label: "Average SpO2",
        value: "spo2",
        type: "line",
      },
      {
        label: "Average Nightly SpO2",
        value: "night_spo2",
        type: "line",
      },
    ];
  }

  if (name == "respiration_rate") {
    // for pulse rate tab
    return [
      {
        label: "Average Respiration Rate",
        value: "respiration_rate",
        type: "line",
      },
    ];
  }

  if (name == "temperature") {
    // for pulse rate tab
    return [
      {
        label: "Average Daily Temprature",
        value: "temperature",
        type: "line",
      },
    ];
  }

  if (name === "workout") {
    const _type = "scatter";
    return [
      { label: "Minutes", value: "workout_minutes", type: _type },
      { label: "Steps", value: "workout_steps", type: "bar" },
      { label: "Calories", value: "workout_total_calories", type: "bar" },
      { label: "Distance", value: "workout_distance", type: _type },
      { label: "Heart Rate", value: "workout_heart_rate", type: _type },
      { label: "Speed", value: "workout_speed", type: _type },
    ];
  }

  return [];
};

const getXTicks = (xPoints, selectedOption) => {
  let xticks = [];
  let interval = 3;
  let _length = xPoints.length;

  switch (selectedOption) {
    case "W":
      interval = 1;
      break;
    case "M":
      interval = 2;
      break;
    case "3M":
      interval = 10;
      break;
    case "MTD":
      interval = 2;
      break;
    case "6M":
      interval = 15;
      break;
    case "YTD":
      // interval = _length <= 30 ? 7 : _length < 90 ? 15 ;
      interval = 7;
      break;
    case "1Y":
      interval = 30;
      break;
    default:
      interval = 1;
  }

  // Iterate through the source array in steps of 'n'
  for (let i = 0; i < xPoints.length; i += interval) {
    xticks.push(xPoints[i]?.date);
  }

  return xticks;
};

const CustomBar = (props) => {
  const { fill, fillColor } = props;
  let xFill = fill;
  if (fillColor != undefined) {
    xFill = fillColor;
  }

  //business logic here to update fill color explicitly
  // if(years === 'Current') {
  //     fill = '#NewFillColor';
  // }

  //use explicit fill here, or use the additional css class and make a css selector to update fill there
  return (
    <Rectangle {...props} fill={xFill} className={`recharts-bar-rectangle`} />
  );
};

const DEFAULT_BAR_COLOR = STEP_BAR_COLOR;

const typesShownInFullWidth = [
  // "pulse_rate",
  // "intab_activity",
  // "spo2",
  // "respiration_rate",
];

const SmartMultiTrendsChart = (props) => {
  const {
    loading = false,
    dataType = "activity",
    points = [],
    type = "line", // one of bar/line
    subTypes = [],
    visibleLines = {},
    selectedOption = "M",
    handleLineCheckbox,
  } = props;
  // console.log(dataType);
  // console.log("smart trends chart points", points);
  // console.log(points);
  // console.log({ subTypes, visibleLines });
  // console.log({ subTypes });
  // console.log(LegendStyles);

  const [ref, { x, y, width, height }] = useDimensions();
  const { unit } = useMappedState(
    useCallback((state) => {
      return {
        unit: state.ui.unit,
      };
    })
  );
  const theme = ls.get("theme");

  const paramsOptions = getParamsOptions(dataType);
  const [param, setParam] = useState(
    paramsOptions.length == 0 ? undefined : paramsOptions[0]
  );

  useEffect(() => {
    const paramsOptions = getParamsOptions(dataType);
    setParam(paramsOptions.length == 0 ? undefined : paramsOptions[0]);
  }, [dataType]);

  if (paramsOptions.length == 0) {
    return null;
  }

  if (!points) {
    return null;
  }

  if (points.length == 0 && loading == false) {
    return <NoDataPlaceholder>No data to display</NoDataPlaceholder>;
  }

  let xPoints = JSON.parse(JSON.stringify(points));

  if (dataType === "heart_rate" || dataType === "pulse_rate") {
    xPoints = xPoints.map((x) => {
      return {
        ...x,
        avg_daily_heart_rate:
          x.avg_daily_heart_rate == 0 ? null : x.avg_daily_heart_rate,
        rest_daily_heart_rate:
          x?.rest_daily_heart_rate == 0 ? null : x?.rest_daily_heart_rate,
      };
    });
  }

  if (dataType === "spo2") {
    xPoints = xPoints.map((x) => {
      return {
        ...x,
        average_spo2: x?.average_spo2 ? x.average_spo2 : null,
        night_spo2: x?.night_spo2 ? x.night_spo2 : null,
      };
    });
  }

  if (dataType === "non_invasive_blood_pressure") {
    xPoints = xPoints.map((x) => {
      // const {}
      return {
        ...x,
        sleeping_average_systolic_pressure:
          x?.sleeping_average_systolic_pressure
            ? x.sleeping_average_systolic_pressure
            : null,
        sleeping_average_diastolic_pressure:
          x?.sleeping_average_diastolic_pressure
            ? x.sleeping_average_diastolic_pressure
            : null,
        awaken_average_diastolic_pressure: x?.awaken_average_diastolic_pressure
          ? x.awaken_average_diastolic_pressure
          : null,
        awaken_average_systolic_pressure: x?.awaken_average_systolic_pressure
          ? x.awaken_average_systolic_pressure
          : null,
        // sleeping_average_diastolic_pressure,
        // awaken_average_systolic_pressure,
        // awaken_average_diastolic_pressure,
        avg_daily_heart_rate:
          x.avg_daily_heart_rate == 0 ? null : x.avg_daily_heart_rate,
        rest_daily_heart_rate:
          x?.rest_daily_heart_rate == 0 ? null : x?.rest_daily_heart_rate,
      };
    });
  }

  if (dataType === "sleep") {
    xPoints = xPoints.map((x) => {
      const {
        awake_time,
        deep_time,
        light_time,
        rem_time,
        sleep_duration,
        performance,
        consistency,
      } = x;
      const Ms2Minute = 60 * 60;
      const durationInMinute =
        sleep_duration === undefined ? null : sleep_duration / Ms2Minute;

      if (durationInMinute && durationInMinute < 3) {
        return { date: x?.date };
      }

      return {
        ...x,
        awake_time: awake_time === undefined ? null : awake_time / Ms2Minute,
        deep_time: deep_time === undefined ? null : deep_time / Ms2Minute,
        light_time: light_time === undefined ? null : light_time / Ms2Minute,
        rem_time: rem_time === undefined ? null : rem_time / Ms2Minute,
        sleep_duration:
          sleep_duration === undefined ? null : sleep_duration / Ms2Minute,
        performance: performance && performance > 0 ? performance : null,
        consistency: consistency && consistency > 0 ? consistency : null,
      };
    });
  }

  if (dataType === "workout") {
    xPoints = xPoints.map((x) => {
      const {
        max_heart_rate,
        avg_heart_rate,
        total_calories,
        total_distance,
        total_steps,
        avg_gps_speed,
        avg_bracelet_speed,
        minutes,
      } = x;

      return {
        ...x,
        max_heart_rate: max_heart_rate ? max_heart_rate : null,
        avg_heart_rate: avg_heart_rate ? avg_heart_rate : null,
        total_calories: total_calories ? total_calories : null,
        total_steps: total_steps ? total_steps : null,
        total_distance: total_distance ? total_distance : null,
        avg_gps_speed: avg_gps_speed ? avg_gps_speed : null,
        avg_bracelet_speed: avg_bracelet_speed ? avg_bracelet_speed : null,
        minutes: minutes ? minutes : null,
      };
    });
  }

  if (dataType === "temperature") {
    xPoints = xPoints.map((x) => {
      const { avg_temp_sk1 } = x;
      const frh_avg_temp_sk1 = avg_temp_sk1 * 1.8 + 32;
      if (avg_temp_sk1 < 10) {
        return { date: x.date };
      }

      return {
        ...x,
        frh_avg_temp_sk1: frh_avg_temp_sk1,
      };
    });
  }

  const subTypesForLegend = subTypes.filter((x) => {
    let show = false;

    if (LegendStyles[dataType][x].tab_value == param.value) {
      show = true;
    }
    return show;
  });
  // console.log({ subTypesForLegend });

  let barColorFun =
    param == undefined ||
    param?.barColor == undefined ||
    typeof param?.barColor != "function"
      ? (x_) => DEFAULT_BAR_COLOR
      : (x_) => (x_ == undefined ? DEFAULT_BAR_COLOR : param?.barColor(x_));

  let xticks = [];
  let xticksInterval = 0;
  if (xPoints.length > 20) {
    xticks = getXTicks(xPoints, selectedOption);
  } else {
    xticks = xPoints.map((x) => x.date);
    // console.log({ xticks, r: "less then" });
  }
  // console.log("xticks", xticks, xPoints.length);

  if (param?.type == "bar") {
    xPoints = xPoints.map((xx) => ({
      ...xx,
      fillColor: barColorFun(xx[param?.value]),
    }));
  }
  if (param?.transform != undefined) {
    xPoints = xPoints.map((x) => param.transform(x));
    // transform
  }

  return (
    <Wrapper className="smtc-wrapper">
      <ChartPlaceholder
        ref={ref}
        className="smtccp-area"
        style={{ height: ComponentHeight.chartHeight }}
      >
        {param?.type != "stacked-bar" ? null : (
          <BarChart
            className="sleep-area-trend-chart"
            width={width}
            height={height}
            data={xPoints}
            connectNulls={false}
            margin={{ top: 0, right: 5, left: -20, bottom: 0 }}
            reverseStackOrder
          >
            <CartesianGrid
              stroke={GRID_STROKE_COLOR}
              strokeWidth={GRID_THICKNESS}
              strokeDasharray={GRID_DASH_ARRAY}
            />
            <XAxis
              stroke={AXES_COLORX(theme)}
              // stroke={"#777"}
              strokeWidth={AXES_STROKE_WIDTH}
              dataKey="date"
              interval={typesShownInFullWidth.includes(dataType) ? 0 : 0}
              ticks={xticks.slice(0, xticks.length - 1)}
              // tickCount={12}
              // overflow={false/}
              // allowDataOverflow={false}
            />
            <YAxis
              // stroke={"#777"}
              stroke={AXES_COLORX(theme)}
              strokeWidth={AXES_STROKE_WIDTH}
              // domain={dataType === "temperature" ? [33, 45] : []}
              domain={([min, max]) => {
                let _min = min.toFixed(0);
                let _max = max.toFixed(0);
                if (dataType === "temperature") {
                  return [_min < 34 ? _min : 34, _max > 40 ? _max : 40];
                }
                if (dataType === "spo2") {
                  return [_min < 80 ? _min : 80, 100];
                }
                return [0, Math.ceil(max.toFixed(1)) + 1];
              }}
              tickFormatter={(t) => {
                if (subTypesForLegend.includes("awake_time")) {
                  return `${t}h`;
                }
                return `${t}`;
              }}
            />
            {subTypes.map((sT, i) => {
              if (
                sT === "consistency" ||
                sT === "performance" ||
                // sT === "tranquility" ||
                sT === "sleep_duration"
              ) {
                return null;
              }
              if (param.value === "sleep_stages") {
                if (sT === "tranquility") {
                  return null;
                }
              }
              if (param.value === "awakes") {
                if (
                  sT === "deep_time" ||
                  sT === "rem_time" ||
                  sT === "awake_time" ||
                  sT === "light_time"
                ) {
                  return null;
                }
              }
              let hide = LegendStyles[dataType][sT].tab_value !== param.value;
              const color = visibleLines[sT]
                ? LegendStyles[dataType][sT].color
                : "#00000000";
              const name = LegendStyles[dataType][sT].label;

              hide =
                subTypesForLegend.includes(sT) &&
                color === "#00000000" &&
                !visibleLines[sT];

              // console.log({ color, hide, sT, visibleLines, subTypesForLegend });

              return (
                <Bar
                  // type="monotone"
                  key={`bar_${i}`}
                  dataKey={sT}
                  stackId="1"
                  stroke={color}
                  fill={color}
                  hide={hide}
                  name={name}
                  animationDuration={0.4}
                  background={false}
                  // fillOpacity={1}
                  // opacity={2}
                />
              );
            })}
            <Tooltip
              cursor={{ fill: "#cccccc30" }}
              content={<TrendsTooltip param={param?.value} />}
            />
          </BarChart>
        )}
        {param?.type != "area" ? null : (
          <AreaChart
            className="sleep-area-trend-chart"
            width={width}
            height={height}
            data={xPoints}
            connectNulls={false}
            margin={{ top: 5, right: 5, left: -20, bottom: 5 }}
          >
            <CartesianGrid
              stroke={GRID_STROKE_COLOR}
              strokeWidth={GRID_THICKNESS}
              strokeDasharray={GRID_DASH_ARRAY}
            />
            <XAxis
              stroke={AXES_COLORX(theme)}
              // stroke={"#777"}
              strokeWidth={AXES_STROKE_WIDTH}
              dataKey="date"
              interval={typesShownInFullWidth.includes(dataType) ? 0 : 0}
              ticks={xticks.slice(0, xticks.length - 1)}
              // tickCount={12}
              // overflow={false/}
              // allowDataOverflow={false}
            />
            <YAxis
              // stroke={"#777"}
              stroke={AXES_COLORX(theme)}
              strokeWidth={AXES_STROKE_WIDTH}
              // domain={dataType === "temperature" ? [33, 45] : []}
              domain={([min, max]) => {
                let _min = min.toFixed(0);
                let _max = max.toFixed(0);
                if (dataType === "temperature") {
                  return [_min < 34 ? _min : 34, _max > 40 ? _max : 40];
                }
                if (dataType === "spo2") {
                  return [_min < 80 ? _min : 80, 100];
                }
                return [0, Math.ceil(max.toFixed(1)) + 1];
              }}
              tickFormatter={(t) => {
                return `${t}`;
              }}
            />
            {subTypes.map((sT, i) => {
              const hide = LegendStyles[dataType][sT].tab_value !== param.value;
              const color = visibleLines[sT]
                ? LegendStyles[dataType][sT].color
                : "#00000000";
              const name = LegendStyles[dataType][sT].label;
              // console.log({ color, hide, sT });

              return (
                <Area
                  type="monotone"
                  dataKey={sT}
                  stackId="1"
                  stroke={color}
                  fill={color}
                  hide={hide}
                  name={name}
                  animationDuration={0.4}
                  fillOpacity={1}
                  // opacity={2}
                />
              );
            })}
            <Tooltip content={<TrendsTooltip param={param?.value} />} />
          </AreaChart>
        )}
        {param?.type != "line" ? null : (
          <LineChart
            className="pr-trend-linechart"
            width={width}
            height={height}
            data={xPoints}
            connectNulls={false}
            margin={{ top: 5, right: 5, left: -20, bottom: 0 }}
          >
            <CartesianGrid
              stroke={GRID_STROKE_COLOR}
              strokeWidth={GRID_THICKNESS}
              strokeDasharray={GRID_DASH_ARRAY}
            />
            {subTypes.map((sT, i) => {
              // console.log({ sT });
              // console.log(xPoints);

              // console.log(LegendStyles[dataType])
              // console.log({ param });
              // LegendStyles
              const connectNull = param.value.includes("workout");
              const color = LegendStyles[dataType][sT].color;
              const name = LegendStyles[dataType][sT].label;

              let hide = LegendStyles[dataType][sT].tab_value !== param.value;
              let stroke = visibleLines[sT] ? color : "#00000000";
              if (dataType.includes("blood_pressure")) {
                if (sT === "sleeping_average_systolic_pressure") {
                  stroke = visibleLines["sleeping_average_systolic_pressure"]
                    ? color
                    : "#00000000";
                }
                if (sT === "sleeping_average_diastolic_pressure") {
                  stroke = visibleLines["sleeping_average_systolic_pressure"]
                    ? color
                    : "#00000000";
                }
                if (sT === "awaken_average_systolic_pressure") {
                  stroke = visibleLines["awaken_average_systolic_pressure"]
                    ? color
                    : "#00000000";
                }
                if (sT === "awaken_average_diastolic_pressure") {
                  stroke = visibleLines["awaken_average_systolic_pressure"]
                    ? color
                    : "#00000000";
                }
              }
              // console.log({ visibleLines, stroke, sT });

              return (
                <Line
                  key={i}
                  stroke={stroke}
                  type="monotone"
                  dataKey={sT}
                  name={name}
                  strokeWidth={2}
                  animationDuration={1}
                  dot={true}
                  hide={hide}
                  connectNulls={connectNull}
                />
              );
            })}
            <XAxis
              stroke={AXES_COLORX(theme)}
              // stroke={"#777"}
              strokeWidth={AXES_STROKE_WIDTH}
              dataKey="date"
              interval={typesShownInFullWidth.includes(dataType) ? 0 : 0}
              ticks={xticks.slice(0, xticks.length - 1)}
              // tickCount={12}
              // overflow={false/}
              // allowDataOverflow={false}
            />
            <YAxis
              // stroke={"#777"}
              stroke={AXES_COLORX(theme)}
              strokeWidth={AXES_STROKE_WIDTH}
              // domain={dataType === "temperature" ? [33, 45] : []}
              domain={([min, max]) => {
                let _min = min.toFixed(0);
                let _max = max.toFixed(0);
                if (dataType === "temperature") {
                  return [_min < 34 ? _min : 34, _max > 40 ? _max : 40];
                }
                if (dataType === "spo2") {
                  return [_min < 80 ? _min : 80, 100];
                }
                return [0, Math.ceil(max.toFixed(1)) + 1];
              }}
              tickFormatter={(t) => {
                if (dataType.includes("temperature")) {
                  if (unit === "F") {
                    const ft = canUseToFixed(t) ? t * 1.8 + 32 : t;
                    return `${ft}℉`;
                  }

                  return `${t}℃`;
                }
                if (subTypesForLegend.includes("performance")) {
                  return `${t}%`;
                }
                if (subTypesForLegend.includes("sleep_duration")) {
                  return `${t}h`;
                }

                if (subTypesForLegend.includes("awake_time")) {
                  return `${t}h`;
                }

                return t;
              }}
            />
            <Tooltip
              content={<TrendsTooltip param={param?.value} unit={unit} />}
            />
          </LineChart>
        )}
        {param?.type != "scatter" ? null : (
          <ScatterChart
            className="pr-trend-sactter-chart"
            width={width}
            height={height}
            data={xPoints}
            connectNulls={false}
            margin={{ top: 5, right: 5, left: -20, bottom: 5 }}
          >
            <CartesianGrid
              stroke={GRID_STROKE_COLOR}
              strokeWidth={GRID_THICKNESS}
              strokeDasharray={GRID_DASH_ARRAY}
            />
            {subTypes.map((sT, i) => {
              // console.log({ sT });
              // console.log(xPoints);

              // console.log(LegendStyles[dataType])
              // console.log({ param });
              // LegendStyles
              const connectNull = param.value.includes("workout");
              const color = LegendStyles[dataType][sT].color;
              const name = LegendStyles[dataType][sT].label;

              let hide = LegendStyles[dataType][sT].tab_value !== param.value;
              let stroke = visibleLines[sT] ? color : "#00000000";

              return (
                <Scatter
                  key={i}
                  stroke={stroke}
                  type="monotone"
                  dataKey={sT}
                  color={stroke}
                  fill={stroke}
                  name={name}
                  strokeWidth={0.4}
                  animationDuration={1}
                  dot={true}
                  hide={hide}
                  connectNulls={connectNull}
                  shape={<WorkoutDot />}
                />
              );
            })}
            <XAxis
              stroke={AXES_COLORX(theme)}
              // stroke={"#777"}
              strokeWidth={AXES_STROKE_WIDTH}
              dataKey="date"
              interval={typesShownInFullWidth.includes(dataType) ? 0 : 0}
              ticks={xticks.slice(0, xticks.length - 1)}
              // tickCount={12}
              // overflow={false/}
              // allowDataOverflow={false}
            />
            <YAxis
              // stroke={"#777"}
              stroke={AXES_COLORX(theme)}
              strokeWidth={AXES_STROKE_WIDTH}
              // domain={dataType === "temperature" ? [33, 45] : []}
              domain={([min, max]) => {
                let _min = min.toFixed(0);
                let _max = max.toFixed(0);
                if (dataType === "temperature") {
                  return [_min < 34 ? _min : 34, _max > 40 ? _max : 40];
                }
                if (dataType === "spo2") {
                  return [_min < 80 ? _min : 80, 100];
                }
                return [0, Math.ceil(max.toFixed(1)) + 1];
              }}
              tickFormatter={(t) => {
                if (subTypesForLegend.includes("performance")) {
                  return `${t}%`;
                }
                if (subTypesForLegend.includes("sleep_duration")) {
                  return `${t}h`;
                }

                return t;
              }}
            />
            <Tooltip content={<TrendsTooltip param={param?.value} />} />
          </ScatterChart>
        )}
        {param?.type != "bar" ? null : (
          <BarChart
            width={width}
            height={height}
            data={xPoints}
            margin={{
              top: 5,
              right: 10,
              left: -20,
              bottom: 5,
            }}
            animationDuration={0}
          >
            <CartesianGrid
              stroke={GRID_STROKE_COLOR}
              strokeWidth={GRID_THICKNESS}
              strokeDasharray={GRID_DASH_ARRAY}
            />
            <XAxis
              stroke={AXES_COLORX(theme)}
              strokeWidth={AXES_STROKE_WIDTH}
              dataKey="date"
              interval={typesShownInFullWidth.includes(dataType) ? 0 : 0}
              ticks={xticks.slice(0, xticks.length - 1)}
            />
            <YAxis
              stroke={AXES_COLORX(theme)}
              strokeWidth={AXES_STROKE_WIDTH}
            />
            {subTypes.map((sT, i) => {
              let hide = LegendStyles[dataType][sT].tab_value !== param.value;
              const color = visibleLines[sT]
                ? LegendStyles[dataType][sT].color
                : "#00000000";
              return (
                <Bar
                  // type="monotone"
                  key={`bar_${i}`}
                  dataKey={sT}
                  stackId="1"
                  stroke={color}
                  fill={color}
                  hide={hide}
                  name={name}
                  animationDuration={0.4}
                  background={false}
                  // fillOpacity={1}
                  // opacity={2}
                />
              );
            })}
            <Tooltip
              cursor={{ fill: "#cccccc30" }}
              content={<TrendsTooltip param={param?.value} />}
            />
            {/* <ReferenceLine y={0} stroke="#000" /> */}
          </BarChart>
        )}
      </ChartPlaceholder>
      {paramsOptions.length < 2 ? null : (
        <BottomSwitcherPlaceholder className="switcher-wrapper">
          {paramsOptions.map((a, i) => {
            let showPreLine = false;
            const label = a.label;
            const value = a.value;
            let color = "";
            if (value === "spo2") {
              color = LINE_COLORS.raw;
              showPreLine = true;
            }
            if (value === "night_spo2") {
              color = "#34f";
              showPreLine = true;
            }
            // console.log({ showPreLine, value });

            return (
              <OptItem
                className="opt-item"
                key={i}
                theme={theme}
                selected={param?.value == a.value}
                onClick={(x) => {
                  setParam(a);
                }}
                $color={color}
                $showPreLine={showPreLine}
              >
                {a.label}
              </OptItem>
            );
          })}
        </BottomSwitcherPlaceholder>
      )}
      <LineFilterContainer
        className="checkboxes-wrapper"
        style={{ height: ComponentHeight.lineFilterHeight }}
      >
        {subTypesForLegend.map((f, i) => {
          const checkboxSize = subTypesForLegend.length;
          const bloodPressureMode = dataType.includes("blood_pressure");
          let noCheckbox = false;

          if (checkboxSize <= 1) {
            if (!f.includes("spo2")) {
              noCheckbox = true;
            }
            if (f.includes("spo2")) {
              return null;
            }
            // return null;
          }
          const fontSize = noCheckbox ? "14px" : "12px";

          if (LegendStyles[dataType][f]?.tab !== 0) {
            // return null;
          }
          let label = LegendStyles[dataType][f].label;
          let color = LegendStyles[dataType][f].color;
          let secondColor = "";
          if (bloodPressureMode) {
            label = BloodPressureCheckboxLabelMap[f];
            // console.log(f);
            if (!label) {
              return null;
            }
            if (
              f === "awaken_average_systolic_pressure" ||
              f === "awaken_average_diastolic_pressure"
            ) {
              secondColor =
                LegendStyles[dataType]["awaken_average_diastolic_pressure"]
                  .color;
            }
            if (
              f === "sleeping_average_systolic_pressure" ||
              f === "sleeping_average_diastolic_pressure"
            ) {
              secondColor =
                LegendStyles[dataType]["sleeping_average_diastolic_pressure"]
                  .color;
            }
          }
          return (
            <LineFilterItem className="line-filter-item" key={i}>
              {!noCheckbox && (
                <LineFilterCheckbox
                  className="line-checkbox"
                  type="checkbox"
                  name={f}
                  // checked={showLine[f]}
                  checked={visibleLines[f]}
                  onChange={handleLineCheckbox}
                />
              )}
              {bloodPressureMode ? (
                <LineLabelDouble
                  className="bd-label"
                  $color={color}
                  $secondColor={secondColor}
                  $theme={theme}
                  $bstyle={"solid"}
                >
                  {label}
                </LineLabelDouble>
              ) : (
                <LineLabel
                  className="line-label"
                  $color={color}
                  $theme={theme}
                  $bstyle={"solid"}
                  $fontSize={fontSize}
                >
                  {label}
                </LineLabel>
              )}
            </LineFilterItem>
          );
        })}
      </LineFilterContainer>
    </Wrapper>
  );
};

const NoDataPlaceholder = styled.div`
  width: 100%;
  height: 320px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OptItem = styled.div`
  box-sizing: border-box;
  margin-right: 10px;
  cursor: pointer;
  color: ${(props) =>
    props.selected
      ? props.theme === "dark"
        ? "white"
        : "black"
      : props.theme === "dark"
      ? "#ccc"
      : "black"};
  border-bottom: ${(props) =>
    props.selected == true ? "2px solid #4aa4e3" : "2px solid transparent"};
  font-weight: ${(props) => (props.selected == true ? "bold" : "default")};
  &:before {
    display: ${(props) => (props.$showPreLine ? "inline-block" : "none")};
    content: "";
    border: 1.5px solid ${(props) => props.$color};
    border-style: solid;
    width: 3rem;
    margin: 0 10px;
    transform: translateY(-4px);
  }
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const ChartPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  // height: 320px;
`;

const BottomSwitcherPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  justify-content: center;
`;

const BloodPressureCheckboxLabelMap = {
  awaken_average_diastolic_pressure: undefined,
  sleeping_average_diastolic_pressure: undefined,
  awaken_average_systolic_pressure: "Day Blood Pressure",
  sleeping_average_systolic_pressure: "Night Blood Pressure",
};

const LineFilterContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  font-weight: normal;
  font-size: 12px;
  // margin-left: 20px;
  // margin-top: 10px;
  justify-content: center;
`;

const LineFilterItem = styled.div`
  display: flex;
  align-items: baseline;
`;

const LineFilterCheckbox = styled.input`
  &[type="checkbox"] {
    transform: scale(1.2);
    accent-color: #1e7efa;
  }
`;

const LineLabel = styled.label`
  font-weight: 500;
  color: ${(props) => (props.$theme === "dark" ? "white" : "black")};
  font-size: ${(props) => props.$fontSize};
  &:before {
    display: inline-block;
    content: "";
    border: 1.5px solid ${(props) => props.$color};
    border-style: ${(props) => props.$bstyle};
    width: 4rem;
    margin: 0 10px;
    transform: translateY(-4px);
  }
`;

const LineLabelDouble = styled.label`
  font-weight: 500;
  color: ${(props) => (props.$theme === "dark" ? "white" : "black")};
  &:before {
    display: inline-block;
    content: "";
    border: 1.5px solid;
    border-style: ${(props) => props.$bstyle};
    border-image: linear-gradient(
        to right,
        ${(props) => props.$color} 50%,
        ${(props) => props.$secondColor} 50%
      )
      1;
    width: 3rem;
    margin: 0 10px;
    transform: translateY(-4px);
  }
`;

function canUseToFixed(value) {
  return typeof value === "number" && Number.isFinite(value);
}

const TrendsTooltip = ({ active, payload, label, param, unit = "C" }) => {
  if (active && payload && payload.length) {
    const payloadSize = payload.length;

    let names = payload.map((x) => x?.name);
    let values = payload.map((x) => x?.value);
    let dataKeys = payload.map((x) => x?.dataKey);
    let sleepDuration = undefined;
    if (param === "sleep_stages") {
      names = names.reverse();
      values = values.reverse();
      dataKeys = dataKeys.reverse();
      sleepDuration = payload[0]?.payload?.sleep_duration;
      if (canUseToFixed(sleepDuration)) {
        sleepDuration = sleepDuration.toFixed(1);
      }
    }

    const date = payload[0]?.payload?.date || "";
    const fahrenheit = payload[0]?.payload?.frh_avg_temp_sk1 || "";

    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{`Date: ${date}`}</TooltipRow>
        {[
          "avg_daily_heart_rate",
          "spo2",
          "blood_pressure",
          "sleep_stages",
          "workout_minutes",
          "workout_speed",
          "workout_heart_rate",
          "workout_distance",
          "workout_total_calories",
          "workout_steps",
          "sleep_duration",
          "awakes",
          "sleep_score",
          "night_spo2",
          "respiration_rate",
          "temperature",
        ].includes(param) &&
          names.map((x, i) => {
            let v = values[i];
            let label = x;
            let color = "#111";

            if (param === "spo2" || param === "night_spo2") {
              if (canUseToFixed(v)) {
                v = v.toFixed(2);
              }
            }
            if (param === "sleep_stages") {
              // label = SleepLabelMap[label] || label;
              color = LegendStyles["sleep"][dataKeys[i]].color;
              if (isNaN(v)) {
                return null;
              }
              if (canUseToFixed(v)) {
                v = v.toFixed(1);
                v = `${v} hours`;
              }
            }

            if (param === "sleep_duration") {
              // label = SleepLabelMap[label] || label;
              if (isNaN(v)) {
                return null;
              }
              if (canUseToFixed(v)) {
                v = v.toFixed(1);
                v = `${v} hours`;
              }
            }

            if (param === "temperature") {
              if (canUseToFixed(v)) {
                if (unit === "F") {
                  v = canUseToFixed(fahrenheit) ? fahrenheit.toFixed(1) : null;
                } else {
                  v = v.toFixed(1);
                }
              }
            }
            if (param === "workout_distance") {
              if (canUseToFixed(v)) {
                v = v.toFixed(2);
              }
            }
            if (param === "workout_speed") {
              if (canUseToFixed(v)) {
                v = v.toFixed(2);
              }
            }

            if (param === "sleep_score") {
              v = `${v}%`;
            }
            return (
              <TooltipRow
                key={i}
                className="label"
                $color={color}
              >{`${label}: ${v}`}</TooltipRow>
            );
          })}

        {!isNaN(sleepDuration) && sleepDuration && param === "sleep_stages" && (
          <TooltipRow
            key={"sleep_stage_s"}
            className="label"
          >{`${"Sleep Duration"}: ${sleepDuration} hours`}</TooltipRow>
        )}
      </TooltipWrapper>
    );
  }
};

const SleepLabelMap = {
  sleep_duration: "Sleep Duration",
  awake_time: "Awake Time",
  rem_time: "REM Time",
  light_time: "Light Time",
  deep_time: "Deep Time",
};
{
  /* <>
<TooltipRow className="label">{`${"Average Daily Pulse Rate"}: ${avg_daily_heart_rate}`}</TooltipRow>
{rest_daily_heart_rate && (
  <TooltipRow className="label">{`${"Average Resting Pulse Rate"}: ${rest_daily_heart_rate}`}</TooltipRow>
)}
</> */
}
const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
  text-align: left;
`;

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
  color: ${(props) => props.$color};
`;

const WorkoutDot = ({ cx, cy }) => {
  return <Dot cx={cx} cy={cy} fill="red" r={4} />;
};

export default SmartMultiTrendsChart;
