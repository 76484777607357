import axios from "axios";
import env from "react-dotenv";

const { DOCTORS_API_ENDPOINT = "https://api.study-integration.corsano.com" } =
  env;

const NotesAPI = {
  async getPatientNotes(id, from, to, signal) {
    try {
      let pld = (
        await axios.get(`${DOCTORS_API_ENDPOINT}/patients/${id}/notes`, {
          params: {
            from: from,
            to: to,
          },
          signal: signal,
        })
      ).data.result;
      return pld;
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      console.error(error);
    }
  },

  async createNote(uuid, data) {
    let pld = (
      await axios.post(`${DOCTORS_API_ENDPOINT}/patients/${uuid}/notes`, {
        ...data,
      })
    ).data.result;
    return pld;
  },

  async updateNote(data) {
    let pld = (
      await axios.put(`${DOCTORS_API_ENDPOINT}/notes`, {
        ...data,
      })
    ).data.result;
    return pld;
  },

  async deleteNote(id) {
    let pld = (await axios.delete(`${DOCTORS_API_ENDPOINT}/notes/${id}`)).data
      .result;
    return pld;
  },
};

export default NotesAPI;
