import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import Sidebar from "arui-feather/sidebar";
import Spin from "arui-feather/spin";
import axios from "axios";
import RawAPI from "../../../api/RawAPI";
import SessionAnalysisTool from "../tools/SessionAnalysisTool";
import CardioHelper from "../../../helpers/CardioHelper";
import DoctorAPI from "../../../api/DoctorAPI";

export default function DayHrvAnalysisPanel(props) {
  // getRRIntervalsForDay
  const { uuid, dayTimestamp, theme } = props;

  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    setLoading(true);
    // RawAPI.getInstance().getRRIntervalsForDay(dayTimestamp).then(pld => {
    //     console.log('raw pld = ', pld);
    //     setLoading(false);
    //     setData(pld);
    // })
    DoctorAPI.getPatientsRawRRIntervalsForDay(uuid, dayTimestamp, signal)
      .then((pld) => {
        // console.log("raw pld = ", pld);
        setLoading(false);
        setData(pld);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setLoading(false);
      });
    return () => {
      controller.abort();
    };
  }, [dayTimestamp]);

  if (loading == true) {
    return (
      <div>
        {theme === "dark" ? (
          <Code backgroundColor={"#333"} foregroundColor={"#999"} />
        ) : (
          <Code />
        )}
      </div>
    );
  }

  if (data == undefined) {
    return null;
  }

  let miniPoints = CardioHelper.makePointsDownsampling(data).map((x) => ({
    ...x,
    rr: x.value,
  }));

  // let miniPoints = data;
  let gMiniPoints = data;
  let gPoints = data;
  let age = 29;

  return (
    <Wrapper>
      <SessionAnalysisTool
        {...props}
        miniPoints={miniPoints}
        points={gPoints}
        age={age}
        theme={theme}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 10px;
`;
