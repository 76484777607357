import SimpleNoDataMessageTool from "../../../sleep/tools/SimpleNoDataMessageTool";
import OverviewTool from "../../tools/OverviewTool";
import TrendsSectionTool from "../../../trends/tools/TrendsSectionTool";
import PatientNotesPanel from "../../../notes/panels/PatientNotesPanel";
import { getLabelsInfoData } from "../../../groups/panels/GroupsPanel";
import PatientSessionsPanel from "../PatientSessionsPanel";
import { isDebugMode } from "../../../../helpers/UiHelper";
import CommonHelper from "../../../../helpers/CommonHelper";
import {
  ComplianceHeading,
  NotesPlaceholder,
  SessionsPlaceholder,
  ModeSwitcherItem,
} from "../UiComponents";
import moment from "moment";

export default function OverviewPanel(props) {
  const {
    id,
    date,
    theme,
    summary,
    goals,
    isDemo,
    loading,
    seltT,
    workouts,
    isEmbedMode,
    sessionMode,
    appAndDeviceInfo,
    AppLogsTool,
    setSessionMode,
    gapAndNotWearingSpans = [],
    chargingSpans = [],
    lowBatterySpans = [],
  } = props;
  return (
    <div>
      {summary === undefined && goals === undefined ? (
        <SimpleNoDataMessageTool
          loading={loading}
          message={"Sorry, there is no data this day."}
        />
      ) : (
        <OverviewTool
          date={date}
          summary={summary}
          goals={goals}
          isDemo={isDemo}
          // min={+moment(seltT).startOf("day")}
          // max={+moment(seltT).endOf("day")}
          loading={loading}
          seltT={seltT}
          workouts={workouts}
          min={+moment(date).startOf("day")}
          max={+moment(date).startOf("day").add(1, "day").valueOf()}
          uuid={id}
          gapAndNotWearingSpans={gapAndNotWearingSpans}
          chargingSpans={chargingSpans}
          lowBatterySpans={lowBatterySpans}
        />
      )}

      {/*{isCorsanoMode() ? null :*/}
      {/*    <UserWeeklyRecoveryPanel*/}
      {/*        uuid={id}*/}
      {/*        t={seltT}*/}
      {/*    />*/}
      {/*}*/}

      <div>
        <ComplianceHeading className="patient-statistics-heading">
          Trends
        </ComplianceHeading>
        <TrendsSectionTool uuid={id} />
      </div>

      {isEmbedMode === true ? null : (
        <NotesPlaceholder>
          <ComplianceHeading className="patient-statistics-heading">
            Notes
          </ComplianceHeading>
          <PatientNotesPanel id={id} isDemo={isDemo} dayTimestamp={seltT} />
        </NotesPlaceholder>
      )}

      {isDebugMode() === false ? null : (
        <NotesPlaceholder>
          {appAndDeviceInfo === undefined ? null : (
            <div>
              <div style={{ marginTop: 20 }} className="notes-app">
                {`App: ${appAndDeviceInfo?.appInfo?.app_name} ${appAndDeviceInfo?.appInfo?.app_version} (${appAndDeviceInfo?.appInfo?.os_name})`}
              </div>
              <div
                style={{ marginTop: 20, marginBottom: 20 }}
                className="notes-app"
              >
                {`Bracelet: ${appAndDeviceInfo?.deviceInfo?.device_type} ${appAndDeviceInfo?.deviceInfo?.firmware_version} (${appAndDeviceInfo?.deviceInfo?.serial_number})`}
              </div>
            </div>
          )}

          <ComplianceHeading className="patient-statistics-heading">
            App logs
          </ComplianceHeading>
          <AppLogsTool
            uuid={id}
            from={+moment(seltT).startOf("day")}
            to={+moment(seltT).endOf("day")}
          />
        </NotesPlaceholder>
      )}

      {getLabelsInfoData().sessions_enabled === false ||
      isEmbedMode === true ? null : (
        <>
          <SessionsPlaceholder>
            <ComplianceHeading className="patient-statistics-heading">
              Sessions
            </ComplianceHeading>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontStyle: "normal",
                fontWeight: "normal",
                marginLeft: 50,
                fontSize: "14px",
                alignItems: "center",
                marginBottom: "1em",
              }}
            >
              <strong className="normal-text">Mode:</strong>
              <ModeSwitcherItem
                className="normal-text"
                selected={sessionMode === "all"}
                onClick={() => {
                  setSessionMode("all");
                }}
              >
                All
              </ModeSwitcherItem>
              <ModeSwitcherItem
                selected={sessionMode === "current-day"}
                className="normal-text"
                onClick={() => {
                  setSessionMode("current-day");
                }}
              >
                Current Day
              </ModeSwitcherItem>
            </div>
          </SessionsPlaceholder>

          <PatientSessionsPanel
            id={id}
            isDemo={isDemo}
            theme={theme}
            sessionMode={sessionMode}
            dayTimestamp={seltT}
          />
        </>
      )}

      {window.location.href.indexOf("portal") > -1 ||
      isEmbedMode === true ? null : (
        <div style={{ marginTop: 40, display: "none" }}>
          <span
            style={{
              cursor: "pointer",
              textDecoration: "underline",
              opacity: 0.6,
              fontSize: "12px",
            }}
            onClick={() => {
              CommonHelper.downloadFile(
                `summary_${date}.json`,
                JSON.stringify(summary)
              );
            }}
          >
            {`Export summary_${date}.json`}
          </span>
        </div>
      )}
    </div>
  );
}
