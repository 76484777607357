import { useRef, useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import DrawHelper from "../../helpers/DrawHelper";
import useDimensions from "react-use-dimensions";
import {
  AXES_COLOR,
  AXES_COLORX,
  AXES_STROKE_WIDTH,
  CHARGING_COLOR,
  DOT_STROKE,
  GRID_DASH_ARRAY,
  GRID_STROKE_COLOR,
  GRID_THICKNESS,
  LINE_COLORS,
  LOW_BATTERY_COLOR,
  NOT_WEARING_COLOR,
  XAXIS_COLOR,
  xaxisTimeFormatter,
} from "../ui/templates";
import {
  LineChart,
  CartesianGrid,
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  Label,
  ReferenceArea,
  Scatter,
} from "recharts";
import SimpleNoDataMessageTool from "../sleep/tools/SimpleNoDataMessageTool";
import BaselineHelper from "../../helpers/BaselineHelper";
import { CustomizedLabel } from "./BaselineUtils";
import { ComponentHeight } from "../patients/panels/UiComponents";

const Y_DOMAIN = [20, 120];
const WINDOW_SIZE = 5 * 60;
const SPOTS_PER_DAY = 12 * 24;
const MA_SIZE = 3;

const LINES = [
  { value: "raw", label: "Raw", color: LINE_COLORS.raw, width: 1, show: true },
  {
    value: "baseline",
    label: "Baseline",
    color: LINE_COLORS.baseline,
    width: 2,
    show: true,
  },
  {
    value: "spot",
    label: "Spot Measurement",
    color: LINE_COLORS.spot,
    width: 2,
    show: true,
  },
  // {
  //   value: "adpt",
  //   label: "Adaptive",
  //   color: LINE_COLORS.adaptive,
  //   width: 1,
  //   show: true,
  // },
  // {
  //   value: "gap",
  //   label: "Gap",
  //   show: true,
  //   color: LINE_COLORS.gap,
  //   bstyle: "dashed",
  // },
  // {
];

const calculateMovingAverageAndAdaptive = (data, maSize, delta, kValue) => {
  /*
  Not using adaptive for now.
*/

  const length = data.length;
  let spotsWithMaAndAdaptive = [];

  for (let i = 0; i < maSize; i++) {
    let adpt = undefined;
    let rawBpm = data[i].bpm;
    if (i === 0) {
      adpt = rawBpm;
    } else {
      adpt =
        rawBpm && data[i - 1].bpm
          ? rawBpm - (delta * rawBpm + kValue * (rawBpm - data[i - 1].bpm))
          : rawBpm;
    }
    spotsWithMaAndAdaptive.push({
      ...data[i],
      ma: data[i].bpm,
      maSpots: [data[i].bpm],
      adpt: adpt,
      dashed: undefined,
    });
  }

  for (let i = maSize; i < length; i++) {
    if (!data[i].bpm) {
      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: null,
        maSpots: [data[i].bpm],
        adpt: null,
        nextToNull: 0,
        dashed: undefined,
      });
      continue;
    }
    let sum = 0;
    let validSpots = 0;
    let spots = [];
    const rawBpm = data[i].bpm;
    // const rawBpm_o = data[i - maSize].bpm ? ;
    let rawBpm_o = data[i - 1].bpm ? data[i - 1].bpm : rawBpm;

    let betweenNulls;
    let betweenNums;
    let dashedValue;
    if (data[i - 1]?.bpm && data[i + 1]?.bpm) {
      betweenNums = true;
    }
    if (data[i - 1]?.bpm === null && data[i + 1]?.bpm === null) {
      betweenNulls = true;
    }

    dashedValue = !betweenNulls && !betweenNums ? rawBpm : undefined;

    for (let j = -(maSize - 1); j <= 0; j++) {
      const _rawBpm = data[i + j].bpm;
      if (_rawBpm) {
        sum += _rawBpm;
        spots.push(_rawBpm);
        validSpots += 1;
      }
    }
    const avg = (sum / validSpots).toFixed(1);

    let rateOfChange = rawBpm - rawBpm_o;
    let adaptiveBpm = avg - (delta * avg + kValue * rateOfChange);
    // console.log("rateOfChange", rawBpm_o, rateOfChange, adaptiveBpm);
    let point = {
      ...data[i],
      ma: avg,
      maSpots: spots,
      adpt: adaptiveBpm.toFixed(1),
      rateOfChange: `${rateOfChange.toFixed(4)}, (${rawBpm_o})`,
    };
    if (dashedValue && dashedValue !== null && dashedValue !== undefined) {
      point["dashed"] = dashedValue - 10;
    } else {
      point["dashed"] = undefined;
    }

    spotsWithMaAndAdaptive.push(point);
  }
  return spotsWithMaAndAdaptive;
};

const calculateAdptFromArray = (start, end, avg, delta, kValue) => {
  /*
   *remove for now
   */
  let rateOfChange = end - start;
  let adaptiveValue = avg - (delta * avg + kValue * rateOfChange);
  adaptiveValue = adaptiveValue.toFixed(1);
  return adaptiveValue;
};

const calculateMovingAverage = (data, window) => {
  const length = data.length;
  let spotsWithMaAndAdaptive = [];
  for (let i = 0; i <= length - 1; i++) {
    const currentBpm = data[i].bpm;
    const prevBpm = i > 0 ? data[i - 1].bpm : currentBpm;
    if (currentBpm) {
      let hasValid = false;
      let t0 = moment(data[i].slot);
      let _arr = [];
      let startInd = i >= 80 ? i - 80 : 0;
      for (let j = startInd; j <= i; j++) {
        let df = t0.diff(moment(data[j].slot), "minutes");
        if (df < window) {
          _arr = data.slice(j, i + 1);
          break;
        }
      }
      // console.log(_arr, currentBpm);

      let firstNonNullBpmItem = _arr.find((item) => item.bpm !== null);
      let firstNonNullBpm;

      if (firstNonNullBpmItem === undefined) {
        spotsWithMaAndAdaptive.push({
          ...data[i],
          ma: null,
          adpt: null,
          test: null,
        });
        continue;
      } else {
        firstNonNullBpm = firstNonNullBpmItem.bpm;
      }

      let _ma = BaselineHelper.calculateMAFromArray(_arr, "bpm");
      let _wma = BaselineHelper.calculateBWMAFromArray(
        _arr,
        "bpm",
        t0,
        currentBpm - prevBpm
      );
      if (_ma === 0) {
        spotsWithMaAndAdaptive.push({
          ...data[i],
          ma: null,
          adpt: null,
          test: null,
        });
        continue;
      }

      let adpt = null;

      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: _ma,
        adpt: adpt,
        test: _wma,
      });
    } else {
      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: null,
        adpt: null,
        test: null,
      });
    }
  }
  return spotsWithMaAndAdaptive;
};

const PatientDayPulseRateBaselineTool = (props) => {
  /**
   * check investigator study_187 for why no dotted line on March 8th
   */
  let {
    uuid,
    date,
    hasNoData,
    theme,
    tz = 0,
    baselineWindow = 15,
    points = [],
    loading = false,
    gapAndNotWearingSpans = [],
    chargingSpans = [],
    lowBatterySpans = [],
    externalMeasurements = [],
  } = props;

  const [ref, { width, height }] = useDimensions();
  // const [loading, setLoading] = useState(false);
  const [hrRawData, setHrRawData] = useState([]);
  const [hrProcessedData, setHrProcessedData] = useState([]);

  const [delta, setDelta] = useState(0.03);
  const [kValue, setKValue] = useState(0.2);
  const [ptime, setPtime] = useState(0);
  const [showLine, setShowLine] = useState({
    raw: true,
    ma: true,
    adpt: true,
    gap: true,
    baseline: true,
    spot: true,
  });
  const [dataLength, setDataLength] = useState(0);
  const [batteryData, setBatteryData] = useState([]);
  const [notWearingSpans, setNotWearingSpans] = useState([]); // wearing = 0  // color: grey
  const [visibleChargingSpanLabel, setVisibleChargingSpanLabel] = useState(-1);
  // const [visibleChargingSpanLabel, setVisibleChargingSpanLabel] = useState(0);
  const [visibleNotWearingSpanLabel, setVisibleNotWearingSpanLabel] =
    useState(-1);
  const [visibleLowBatterySpanLabel, setVisibleLowBatterySpanLabel] =
    useState(-1);
  const [visibleMovingSpanLabel, setVisibleMovingSpanLabel] = useState(-1);

  const refY = useRef(null);
  const [domainY, setDomainY] = useState(undefined);
  const [refDelta, setRefDelta] = useState(0);

  // console.log(
  //   "bpm",
  //   points.map((x) => {
  //     return {
  //       bpm: x.bpm,
  //       t: x.t,
  //       date: moment(x.t).format("HH:mm"),
  //     };
  //   })
  // );

  const fillHrMissingSpot = (data) => {
    if (!data || data.length === 0) {
      return;
    }
    let emptySpots = [];
    for (let i = 0; i < data.length - 1; i++) {
      let currentPoints = data[i].t;
      let nextPoints = data[i + 1].t;
      let gap = moment(nextPoints).diff(currentPoints, "minutes");
      emptySpots.push(data[i]);
      if (gap >= 10) {
        // let newSlot = moment(currentPoints).add(1, "minutes").valueOf();
        let newSlot = moment(currentPoints).add(1, "seconds").valueOf();

        // Checking if gap is due to not wearing or not
        let notWearing, charging;
        chargingSpans.map((x, i) => {
          const _from = x?.from;
          const _to = x?.to;
          if (_from >= currentPoints && _to <= nextPoints) {
            charging = true;
          }
          return x;
        });
        gapAndNotWearingSpans.map((x, i) => {
          const _from = x?.from;
          const _to = x?.to;
          if (_from >= currentPoints && _to <= nextPoints) {
            notWearing = true;
          }
          return x;
        });

        emptySpots.push({
          bpm: null,
          raw: null,
          baseline: null,
          t: newSlot,
          charging: charging,
          notWearing: notWearing,
        });
      } else {
      }
    }
    emptySpots.push(data[data.length - 1]);
    return emptySpots;
  };

  useEffect(() => {
    // console.log("REFY CHANGED!!!", refY);
    const yMap = refY.current?.state?.yAxisMap;
    // console.log(refY.current);
    // console.log(refY.current?.state);
    // console.log(refY.current?.state?.yAxisMap);
    let domain = [];
    let delta = 3.0;
    if (yMap) {
      domain = yMap[0]?.domain;
    }
    // console.log(yMap);
    // console.log(domain);
    if (domain && domain.length == 2) {
      delta = (domain[1] - domain[0]) / 40;
    }
    // console.log({ delta, domain });
    setRefDelta(delta);
  }, [refY, hrProcessedData]);

  useEffect(() => {
    if (!points || points.length === 0) {
      return;
    }
    // console.log({ points });

    // console.table(points, ["date", "bpm_q", "bpm", "baseline"]);

    // let _points = points.filter((x) => x?.spo2_q && x?.spo2);
    // let __points = addChargingFlagToData(points);
    // let spans = getChargingSpans(__points);
    // let wearingIs0Spans = getNotWearingSpans(points);
    // let gapOrWearingIs0Spans = getGapAndNotWearingSpans(points);
    // setNotWearingSpans(gapOrWearingIs0Spans);
    // console.log({ gapOrWearingIs0Spans });
    // console.log({ spans });
    // setChargingSpans(spans);
    // setBatteryData(__points.filter((x) => x.isCharging));

    let _points = points.filter((x) => {
      return x?.bpm_q;
    });
    _points = fillHrMissingSpot(_points) || [];
    let externalWithNulls = [];
    externalMeasurements.forEach((x, i) => {
      const spotObj = {
        ...x,
        bpm: null,
        slot: +x.timestamp,
        pulse_rate_spot: x.bpm,
        t: +x.timestamp,
      };
      const nullObj = {
        pulse_rate_spot: null,
        t: +x.timestamp + 1,
      };
      externalWithNulls.push(spotObj);
      externalWithNulls.push(nullObj);
    });

    _points = [..._points, ...externalWithNulls];
    // console.log({ externalMeasurements });
    setHrRawData(_points);
    setHrProcessedData(_points);
    // setDataLength(_points.length);
  }, [date, uuid, points, externalMeasurements]);

  // console.log(hrProcessedData);

  let extraLines = DrawHelper.getExtraDottedChartsData(hrProcessedData, "bpm");
  extraLines = DrawHelper.getExtraDottedChartsData(hrProcessedData, "baseline");
  // console.log({ extraLines });

  let hrWithGaps = hrProcessedData;
  for (let sp of hrWithGaps) {
    for (let el in extraLines) {
      const slot1 = extraLines[el]["points"][0].slot;
      const slot2 = extraLines[el]["points"][1].slot;
      if (slot1 === sp.slot) {
        sp[`bpm_gap_${el}`] = extraLines[el]["points"][0][`bpm_gap_${el}`];
        sp[`baseline_gap_${el}`] =
          extraLines[el]["points"][0][`baseline_gap_${el}`];
      }
      if (slot2 === sp.slot) {
        sp[`bpm_gap_${el}`] = extraLines[el]["points"][1][`bpm_gap_${el}`];
        sp[`baseline_gap_${el}`] =
          extraLines[el]["points"][1][`baseline_gap_${el}`];
      }
    }
  }

  let xticks = BaselineHelper.getXaxisTicksEachHour(
    moment(date).startOf("day")
  );
  let startOfDay = moment(date).startOf("day").valueOf();
  let startOfNextDay = moment(date).startOf("day").add(1, "day").valueOf();

  let _min = moment(startOfDay).subtract(30, "minutes").valueOf();
  let _max = moment(startOfNextDay).subtract(30, "minutes").valueOf();
  const handleLineCheckbox = (evt) => {
    const name = evt.target.name;
    let oldValue = showLine[name];
    const newSetting = { ...showLine, [name]: !oldValue };
    setShowLine(newSetting);
  };

  if (loading) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"Loading..."}
        showTopImg={false}
      />
    );
  }
  if (hasNoData) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"No Data"}
        showTopImg={false}
      />
    );
  }

  // console.log(hrProcessedData);

  return (
    <Wrapper className="pulse-rate-bl-wrapper" ref={ref}>
      <ComposedChart
        ref={refY}
        width={width}
        height={
          height
            ? height - ComponentHeight.lineFilterHeight
            : ComponentHeight.chartHeight
        }
        data={hrProcessedData}
        // connectNulls={true}
        margin={{ left: -20 }}
      >
        <CartesianGrid
          stroke={GRID_STROKE_COLOR}
          strokeWidth={GRID_THICKNESS}
          strokeDasharray={GRID_DASH_ARRAY}
        />
        <XAxis
          // stroke={theme === "dark" ? "white" : AXES_COLOR}
          stroke={AXES_COLORX(theme)}
          strokeWidth={AXES_STROKE_WIDTH}
          tickSize={10}
          interval={0}
          ticks={xticks}
          type="number"
          dataKey={"t"}
          tickCount={xticks.length}
          domain={[startOfDay, startOfNextDay]}
          tickFormatter={xaxisTimeFormatter}
          // tickFormatter={(a) => {
          //   return moment(a).subtract(tz, "hours").format("HH:mm");
          // }}
          allowDataOverflow={true}
        />
        <YAxis
          dataKey={"bpm"}
          stroke={AXES_COLORX(theme)}
          strokeWidth={AXES_STROKE_WIDTH}
          domain={([min, max]) => {
            return [0, max > 120 ? max : 120];
          }}
        />
        <Tooltip content={<HrTooltip />} />
        <Line
          key={`spot`}
          type="monotone"
          dataKey="pulse_rate_spot"
          name="spot"
          // strokeWidth={2}
          stroke={LINE_COLORS.spot}
          strokeWidth={0.9}
          // connectNulls={false}
          // strokeDasharray="5 5"
          hide={showLine["spot"] ? false : true}
          dot={true}
          activeDot={true}
          isAnimationActive={false}
        />
        <Line
          key={`bl`}
          type="monotone"
          dataKey="baseline"
          name="baseline"
          stroke={LINE_COLORS.baseline}
          strokeWidth={2}
          // connectNulls={true}
          connectNulls={false}
          // connectNulls={false}
          // strokeDasharray="5 5"
          hide={showLine["baseline"] ? false : true}
          dot={false}
          isAnimationActive={false}
        />
        <Line
          key={`raw`}
          type="monotone"
          dataKey="bpm"
          name="Raw"
          stroke={LINE_COLORS.raw}
          // dot={DOT_STROKE}
          dot={false}
          isAnimationActive={false}
          connectNulls={false}
          hide={showLine["raw"] ? false : true}
        />
        {extraLines.map((l, i) => {
          // console.log(l);
          const withGap = hrProcessedData.filter(
            (x) => x?.[`baseline_gap_${i}`]
          );
          const startIndex = hrProcessedData.indexOf(withGap[0]);
          const endIndex = hrProcessedData.indexOf(withGap[1]);

          // console.log({ gapIndex: i, withGap, startIndex, endIndex });
          // console.log({ hrProcessedData });

          const slice = hrProcessedData.slice(startIndex, endIndex + 1);
          let nullPoint;
          if (slice && slice.length === 3) {
            nullPoint = slice[1];
            // console.log({ nullPoint });
            if (nullPoint?.notWearing || nullPoint?.charging) {
              // console.log({ falsep: nullPoint });
              return;
            }
          }

          return (
            <Line
              key={`el_${i}`}
              type={"monotone"}
              // dataKey={`spo2_gap_${i}`}
              dataKey={`baseline_gap_${i}`}
              isAnimationActive={false}
              strokeWidth={2}
              strokeDasharray="2 2"
              stroke={LINE_COLORS.baseline}
              dot={false}
              name={"baselineGap"}
              legendType="none"
              connectNulls={true}
              hide={showLine["baseline"] ? false : true}
              // hide={true}
            />
          );
        })}
        {/* {extraLines.map((l, i) => {
          return (
            <Line
              key={i}
              type={"monotone"}
              dataKey={`bpm_gap_${i}`}
              isAnimationActive={false}
              strokeWidth={2}
              strokeDasharray="3 3"
              stroke="#a0a0af90"
              hide={true}
              dot={false}
              legendType="none"
              connectNulls={true}
            />
          );
        })} */}
        {/* <Legend /> */}
        {lowBatterySpans.map((span, i) => {
          const x1 = span.from < +startOfDay ? startOfDay : span.from;
          const x2 = span.to;
          const y1 = refDelta ? -refDelta : -0.5;
          const y2 = refDelta ? +refDelta : +0.5;
          if (span.from < startOfDay && span.to < startOfDay) {
            return null;
          }
          if (x2 - x1 <= 60000) {
            return null;
          }
          return (
            <ReferenceArea
              key={`lb_${i}`}
              style={{ cursor: "pointer" }}
              onMouseOver={() => {
                // console.log("charign!");
                if (visibleLowBatterySpanLabel > -1) {
                  setVisibleLowBatterySpanLabel(-1);
                } else {
                  setVisibleNotWearingSpanLabel(-1);
                  setVisibleChargingSpanLabel(-1);
                  setVisibleLowBatterySpanLabel(i);
                }
              }}
              onMouseLeave={() => {
                setVisibleChargingSpanLabel(-1);
                setVisibleNotWearingSpanLabel(-1);
                setVisibleLowBatterySpanLabel(-1);
              }}
              className="charging-ref-area"
              x1={x1}
              x2={x2}
              y1={y1}
              y2={y2}
              ifOverflow="visible"
              fill={LOW_BATTERY_COLOR}
            >
              <Label
                // value={`Charging (${moment(x1).format("HH:mm")} to ${moment(
                //   x2
                // ).format("HH:mm")})`}
                content={
                  <CustomizedLabel x1={x1} x2={x2} reason={"Battery <= 5"} />
                }
                offset={18}
                fontSize={12}
                position="insideBottom"
                visibility={
                  i === visibleLowBatterySpanLabel ? "visible" : "hidden"
                }
              />
            </ReferenceArea>
          );
        })}
        {chargingSpans.map((span, i) => {
          const x1 = span.from < +startOfDay ? startOfDay : span.from;
          const x2 = span.to;
          const y1 = refDelta ? -refDelta : -0.5;
          const y2 = refDelta ? +refDelta : +0.5;
          if (span.from < startOfDay && x2 < startOfDay) {
            return null;
          }
          if (x2 - x1 <= 60000) {
            return null;
          }
          return (
            <ReferenceArea
              key={`cs_${i}`}
              style={{ cursor: "pointer" }}
              onMouseOver={() => {
                // console.log("charign!");
                if (visibleChargingSpanLabel > -1) {
                  setVisibleChargingSpanLabel(-1);
                } else {
                  setVisibleNotWearingSpanLabel(-1);
                  setVisibleLowBatterySpanLabel(-1);
                  setVisibleChargingSpanLabel(i);
                }
              }}
              onMouseLeave={() => {
                setVisibleChargingSpanLabel(-1);
                setVisibleNotWearingSpanLabel(-1);
              }}
              className="charging-ref-area"
              x1={x1}
              x2={x2}
              y1={y1}
              y2={y2}
              ifOverflow="visible"
              fill={CHARGING_COLOR}
            >
              <Label
                // value={`Charging (${moment(x1).format("HH:mm")} to ${moment(
                //   x2
                // ).format("HH:mm")})`}
                content={
                  <CustomizedLabel x1={x1} x2={x2} reason={"Charging"} />
                }
                offset={18}
                fontSize={12}
                position="insideBottom"
                visibility={
                  i === visibleChargingSpanLabel ? "visible" : "hidden"
                }
              />
            </ReferenceArea>
          );
        })}
        {gapAndNotWearingSpans.map((span, i) => {
          const x1 = span.from < +startOfDay ? startOfDay : span.from;
          const x2 = span.to;
          const y1 = refDelta ? -refDelta : -0.5;
          const y2 = refDelta ? +refDelta : +0.5;

          // console.log({ refDelta });

          if (span.from < startOfDay && x2 < startOfDay) {
            return null;
          }
          if (x2 - x1 <= 60000) {
            return null;
          }
          const newLine = "";
          return (
            <ReferenceArea
              key={`nw_${i}`}
              style={{ cursor: "pointer" }}
              onMouseOver={() => {
                if (visibleNotWearingSpanLabel > -1) {
                  setVisibleNotWearingSpanLabel(-1);
                } else {
                  setVisibleChargingSpanLabel(-1);
                  setVisibleLowBatterySpanLabel(-1);
                  setVisibleNotWearingSpanLabel(i);
                }
              }}
              onMouseLeave={() => {
                setVisibleChargingSpanLabel(-1);
                setVisibleNotWearingSpanLabel(-1);
              }}
              className="charging-ref-area"
              x1={x1}
              x2={x2}
              y1={y1}
              y2={y2}
              ifOverflow="visible"
              fill={NOT_WEARING_COLOR}
            >
              <Label
                // value={`Not wearing (${moment(x1).format("HH:mm")} - ${moment(
                //   x2
                // ).format("HH:mm")})`}
                className="ref-area-label"
                // formatter={(value) => {
                //   console.log(value);
                //   return value;
                // }}
                content={
                  <CustomizedLabel x1={x1} x2={x2} reason={"Not wearing"} />
                }
                offset={18}
                fontSize={12}
                position="insideBottom"
                visibility={
                  i === visibleNotWearingSpanLabel ? "visible" : "hidden"
                }
              />
            </ReferenceArea>
          );
        })}
      </ComposedChart>
      <LineFilterContainer
        className="line-filter-container"
        style={{ height: ComponentHeight.lineFilterHeight }}
      >
        {LINES.map((f, i) => {
          if (externalMeasurements.length === 0 && f.value === "spot") {
            return null;
          }
          return (
            <LineFilterItem key={`lf_${i}`} className="line-filter-item">
              <LineFilterCheckbox
                type="checkbox"
                name={f.value}
                checked={showLine[f.value]}
                onChange={handleLineCheckbox}
              />
              <LineLabel
                $color={f.color}
                $bstyle={f?.bstyle ? f.bstyle : "solid"}
                $theme={theme}
              >
                {f.label}
              </LineLabel>
            </LineFilterItem>
          );
        })}
      </LineFilterContainer>
    </Wrapper>
  );
};

export default PatientDayPulseRateBaselineTool;

// const CustomizedLabel = (props) => {
//   // console.log(props);
//   const { x1, x2, offset, visibility, reason = "" } = props;
//   if (visibility === "hidden") {
//     return null;
//   }
//   if (!props?.viewBox) {
//     return null;
//   }
//   const { x, y, width, height } = props.viewBox || {};

//   return (
//     <text
//       x={x}
//       y={y - 16}
//       textAnchor="middle"
//       fill="#808080"
//       fontSize={12}
//       offset={offset}
//       className="recharts-text recharts-label"
//     >
//       <tspan x={x + width / 2} dy="0em">
//         {reason}
//       </tspan>
//       <tspan x={x + width / 2} dy="1.2em">{`(${moment(x1).format(
//         "HH:mm"
//       )} - ${moment(x2).format("HH:mm")})`}</tspan>
//     </text>
//   );
// };

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  position: relative;
`;

const HrTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let pulse_rate_spot = payload[0]?.payload?.pulse_rate_spot;
    // console.log({ pulse_rate_spot });
    let q = payload[0]?.payload?.[`bpm_q`];
    // payload[0].payload.bpm_q
    let baseline_q = payload[0]?.payload?.bpm_baseline_q;
    // console.log({ q, baseline_q });

    if (q && baseline_q && (q > 100 || baseline_q > 5)) {
      q = 1;
      baseline_q = 1;
    }
    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{`${moment(label).format(
          "HH:mm"
        )}`}</TooltipRow>
        {pulse_rate_spot ? (
          <TooltipRow className="label">{`Spot Measurement: ${pulse_rate_spot}`}</TooltipRow>
        ) : (
          <>
            <TooltipRow className="label">{`Raw BPM: ${payload[0].payload.bpm}`}</TooltipRow>
            <TooltipRow className="label">{`Baseline: ${payload[0].payload.baseline}`}</TooltipRow>
            <TooltipRow className="label">{`Q: ${q}`}</TooltipRow>
            <TooltipRow className="label">{`Baseline Q: ${baseline_q}`}</TooltipRow>
          </>
        )}
      </TooltipWrapper>
    );
  }

  return null;
};

export const LineFilterContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  // margin-left: 20px;
  justify-content: center;
`;

export const LineFilterItem = styled.div`
  display: flex;
  align-items: baseline;
`;

export const LineFilterCheckbox = styled.input`
  &[type="checkbox"] {
    transform: scale(1.2);
    accent-color: #1e7efa;
  }
`;

export const LineLabel = styled.label`
  font-weight: 500;
  color: ${(props) => (props.$theme === "dark" ? "white" : "black")};
  &:before {
    display: inline-block;
    content: "";
    border: 1.5px solid ${(props) => props.$color};
    border-style: ${(props) => props.$bstyle};
    // border-top: 1rem solid ${(props) => props.$color};
    width: 4rem;
    margin: 0 10px;
    transform: translateY(-4px);
  }
`;

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;
