import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import { Code } from "react-content-loader";
import SurveysAPI from "../../../api/SurveysAPI";
import LottieLoader from "../../loaders/LottieLoader";
import CommonHelper from "../../../helpers/CommonHelper";
import writeXlsxFile from "write-excel-file";

const getSurvItems = (items = []) => {
  let map = {};
  let sortedItems = items.sort((a, b) => +a.timestamp - +b.timestamp);
  for (let i in sortedItems) {
    let it = sortedItems[i];
    if (map[it.Survey] == undefined) {
      map[it.Survey] = {
        Survey: it.Survey,
        t: +it.timestamp,
        points: [],
      };
    }
    map[it.Survey].points = map[it.Survey].points.concat([it]);
  }
  let xItems = Object.keys(map).map((xx) => map[xx]);
  return xItems;
};

export default function NewSurveysPanel(props) {
  const { uuid, from, to } = props;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    const { signal } = controller;
    SurveysAPI.getSurveysInRange(uuid, from, to, signal)
      .then((arr) => {
        setLoading(false);
        setItems(arr);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setLoading(false);
      });
    return () => {
      controller.abort();
    };
  }, [uuid, from, to]);

  if (loading == true) {
    return <Code />;
  }
  let xItems = getSurvItems(items);
  // console.log('xItems, items = ', xItems, items);

  if (xItems.length == 0) {
    return (
      <div>
        <div style={{ width: "100%", height: 320, boxSizing: "border-box" }}>
          <LottieLoader />
        </div>
        <div style={{ textAlign: "center", marginTop: -20 }}>
          No surveys available
        </div>
      </div>
    );
  }

  return (
    <Wrapper>
      {xItems.map((a, i) => {
        return (
          <XItemRow key={i}>
            <LeftItem onClick={() => {}}>
              <span>
                <DateSpan>{moment(+a.t).format("DD.MM.YYYY HH:mm")}</DateSpan>
                <DateSpan>{a.Survey}</DateSpan>
              </span>

              <spa>
                <DateSpan
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    console.log("left item: a = ", a);
                    let pts = a?.points || [];
                    let csv = `Voucher;PatientID;DateTime;Survey;SurveyID;Language;QuestionType;Question;Answer;AnswerID`;
                    let gs = (s) => `${s}`.replace(/\n/g, "|");
                    let csvString = `${csv}\n${pts
                      .map(
                        (x) =>
                          `${x.Voucher == undefined ? "" : x.Voucher};${
                            x.PatientID
                          };${x.DateTime};${x.Survey};${x.SurveyID};${
                            x.Language
                          };${x.QuestionType};${gs(x.Question)};${gs(
                            x.Answer
                          )};${x.AnswerID}`
                      )
                      .join("\n")}`;
                    CommonHelper.downloadFile(
                      `surveys_${moment(+from).format("DD.MM.YYYY")}.csv`,
                      csvString
                    );
                  }}
                >
                  {"download .csv"}
                </DateSpan>

                <DateSpan
                  style={{ cursor: "pointer", marginLeft: 20 }}
                  onClick={async () => {
                    const HEADER_ROW = [
                      {
                        value: "Voucher",
                        fontWeight: "bold",
                      },
                      {
                        value: "PatientID",
                        fontWeight: "bold",
                      },
                      {
                        value: "DateTime",
                        fontWeight: "bold",
                      },
                      {
                        value: "Survey",
                        fontWeight: "bold",
                      },
                      {
                        value: "SurveyID",
                        fontWeight: "bold",
                      },
                      {
                        value: "Language",
                        fontWeight: "bold",
                      },
                      {
                        value: "QuestionType",
                        fontWeight: "bold",
                      },
                      {
                        value: "Question",
                        fontWeight: "bold",
                      },
                      {
                        value: "Answer",
                        fontWeight: "bold",
                      },
                      {
                        value: "AnswerID",
                        fontWeight: "bold",
                      },
                    ];
                    let pts = a?.points || [];
                    // let csvString = `${pts.map(x => `${x.Voucher == undefined ? '' : x.Voucher};${x.PatientID};${x.DateTime};${x.Survey};${x.SurveyID};${x.Language};${x.QuestionType};${gs(x.Question)};${gs(x.Answer)};${x.AnswerID}`).join('\n')}`;
                    const dataItems = pts.map((x, i) => {
                      return [
                        {
                          value: x.Voucher == undefined ? "" : x.Voucher,
                        },
                        {
                          value: x.PatientID == undefined ? "" : x.PatientID,
                        },
                        {
                          value: x.DateTime == undefined ? "" : x.DateTime,
                        },
                        {
                          value: x.Survey == undefined ? "" : x.Survey,
                        },
                        {
                          value: x.SurveyID == undefined ? "" : x.SurveyID,
                        },
                        {
                          value: x.Language == undefined ? "" : x.Language,
                        },
                        {
                          value:
                            x.QuestionType == undefined ? "" : x.QuestionType,
                        },
                        {
                          value: x.Question == undefined ? "" : x.Question,
                        },
                        {
                          value: x.Answer == undefined ? "" : x.Answer,
                        },
                        {
                          value: x.AnswerID == undefined ? "" : x.AnswerID,
                        },
                      ];
                    });
                    const data = [HEADER_ROW, ...dataItems];
                    let fileName = `surveys_${moment(+from).format(
                      "DD.MM.YYYY"
                    )}.xlsx`;
                    await writeXlsxFile(data, {
                      fileName: fileName,
                    });
                  }}
                >
                  {"download .xlsx"}
                </DateSpan>
              </spa>
            </LeftItem>
          </XItemRow>
        );
      })}
    </Wrapper>
  );
}

const XItemRow = styled.div`
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
`;

const DateSpan = styled.span`
  margin-right: 10px;
`;

const LeftItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  opacity: 0.8;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  min-height: 220px;
`;
