import { useState, useEffect, useRef } from "react";
import moment from "moment";
import styled from "styled-components";
// import TarnsformerHelper from "../../../helpers/TarnsformerHelper";
import DoctorAPI from "../../../api/DoctorAPI";
import { get24HrTicks, get36HrTicks } from "./AiSleepViewTool";
import useDimensions from "react-use-dimensions";
import SimpleNoDataMessageTool from "./SimpleNoDataMessageTool";
import CommonHelper from "../../../helpers/CommonHelper";
import TarnsformerHelper, {
  VALID_SLEEP_STATES_VALUES,
  SLEEP_VALUES,
  IS_AWAKE_COLOR,
  ONSET_COLOR,
  IS_ASLEEP_COLOR,
  ONSET_END_COLOR,
} from "../../../helpers/TarnsformerHelper";

const HOURS_PER_DAY = 24;
const MINUTES_PER_HOUR = 60;
const SECONDS_PER_DAY = 60 * HOURS_PER_DAY * MINUTES_PER_HOUR;
const IS_ASLEEP_HEIGHT = 1;
const IS_AWAKE_HEIGHT = 2;
const IS_ONSET_HEIGHT = 2;

const IS_LIGHT_HEIGHT = 2; // added 2025-02-12
const IS_REM_HEIGHT = 4; // added 2025-02-12
const SLEEP_WAKE_YLABELS = ["SLEEP", "AWAKE"];

const SleepStatusMap = {
  0: "undefined",
  1: "Awake",
  2: "Light",
  3: "Deep",
  4: "REM",
  101: "GoToSleep",
  102: "SleepOnset",
  103: "Wake",
  104: "OutOfBed",
};

const getSleepStatus = (input) => {
  if (VALID_SLEEP_STATES_VALUES.includes(input)) {
    return SleepStatusMap[input];
  } else {
    return "unknown";
  }
};

const SLEEP_STATUS_PROPERTIES = {
  undefined: {
    color: "#657",
    height: 0.0,
    isAsleep: undefined,
  },
  Awake: {
    color: IS_AWAKE_COLOR,
    height: IS_AWAKE_HEIGHT,
    isAsleep: false,
  },
  Light: {
    color: IS_ASLEEP_COLOR,
    height: IS_ASLEEP_HEIGHT,
    isAsleep: true,
  },
  Deep: {
    color: IS_ASLEEP_COLOR,
    height: IS_ASLEEP_HEIGHT,
    isAsleep: true,
  },
  REM: {
    color: IS_ASLEEP_COLOR,
    height: IS_ASLEEP_HEIGHT,
    isAsleep: true,
  },
  GoToSleep: {
    color: ONSET_COLOR,
    height: IS_ONSET_HEIGHT,
    isAsleep: true,
  },
  Onset: {
    color: ONSET_COLOR,
    height: IS_ONSET_HEIGHT,
  },
  Offset: {
    color: ONSET_COLOR,
    height: IS_ONSET_HEIGHT,
  },
  SleepOnset: {
    color: ONSET_COLOR,
    height: IS_ONSET_HEIGHT,
  },
  Wake: {
    color: ONSET_COLOR,
    height: IS_ONSET_HEIGHT,
  },
  OutOfBed: {
    color: ONSET_COLOR,
    height: IS_ONSET_HEIGHT,
  },
};

export const SleepOrWake = (input) => {
  /**
   *
   * SleepStatus: Int {
   *    case Undefined = 0
   *    case Awake     = 1
   *    case Light     = 2
   *    case Deep      = 3
   *    case Rem       = 4
   *     Case GoToSleep = 5
   *}
   * output: 1: awake
   *         2: sleep
   *
   **/
  if (input == 1) {
    return 1;
  } else if (SLEEP_VALUES.includes(input)) {
    return 2;
  } else {
    return 0;
  }
};

export const filterSleepData = (data) => {
  const _validPoints = data.filter((x) => {
    const _sleep_state = x?.sleep_state;
    const _date = x?.date;
    return (
      _sleep_state !== undefined &&
      _sleep_state !== null &&
      _date &&
      VALID_SLEEP_STATES_VALUES.includes(_sleep_state)
    );
  });
  return _validPoints;
};

const mapToSleepData = (data) => {
  const _sleepData = data.map((x, i) => {
    return {
      sleepState: x.sleep_state,
      date: x.date,
      sleep_q: x.sleep_q,
      date_without_tz: x.date_without_tz,
      timestamp: x.timestamp,
    };
  });
  return _sleepData;
};

const mapToBinarySleepData = (data) => {
  // only using it for sleep/wake chart
  const _binarySleepData = data.map((x, i) => {
    let sleepState = x?.sleepState;
    if (x?.sleepState == 2 || x?.sleepState == 3 || x?.sleepState == 4) {
      sleepState = 3;
    }
    return {
      sleepState: sleepState,
      date: x.date,
      sleep_q: x.sleep_q,
      date_without_tz: x.date_without_tz,
      timestamp: x.timestamp,
    };
  });
  return _binarySleepData;
};

export default function SleepWakeTool(props) {
  const {
    uuid,
    date,
    sleepDataResponse = [],
    sleepDataLoading = false,
    sleepAndWakeData = {},
    sleepAndWakeUpTime = {
      time_to_sleep: undefined,
      time_to_wake_up: undefined,
      start_date: undefined,
      end_date: undefined,
    },
  } = props;
  const [sleepData, setSleepData] = useState([]);
  const [unprocessedSleepData, setUnprocessedSleepData] = useState([]);
  const [sleepDataStatus, setSleepDataStatus] = useState([]);
  const [mode, setMode] = useState("24"); // or "36"
  const [timeToSleep, setTimeToSleep] = useState(
    sleepAndWakeUpTime.time_to_sleep
  );
  const [timeToWakeUp, setTimeToWakeup] = useState(
    sleepAndWakeUpTime.time_to_wake_up
  );

  useEffect(() => {
    const d = sleepDataResponse;
    setSleepDataStatus([]);
    setUnprocessedSleepData(d);
    const _validPoints = filterSleepData(d);
    const _sleepData = mapToSleepData(_validPoints);
    const _binarySleepData = mapToBinarySleepData(_sleepData);

    let statesChange = TarnsformerHelper.findSleepStateChange(_binarySleepData);
    setSleepDataStatus(statesChange);
    setSleepData(_sleepData);
  }, [date, uuid, sleepDataResponse]);

  const dateFrom = moment(date);
  const hourOffset = 12;
  const twelveHoursBefore = dateFrom.clone().subtract(hourOffset, "hours");
  const toFixedValue = 2;

  let slots = [];
  slots = sleepDataStatus.map((sleepSlot, i) => {
    const _left_percent =
      i === 0
        ? (100 *
            moment(sleepData[sleepSlot.indexFrom]?.date).diff(
              twelveHoursBefore,
              "seconds"
            )) /
          (60 * HOURS_PER_DAY * MINUTES_PER_HOUR)
        : (100 *
            moment(sleepData[sleepSlot.indexFrom]?.date).diff(
              twelveHoursBefore,
              "seconds"
            )) /
          (60 * HOURS_PER_DAY * MINUTES_PER_HOUR);

    const _width =
      1000 *
      MINUTES_PER_HOUR *
      60 *
      moment(sleepData[sleepSlot.indexTo]?.date).diff(
        moment(sleepData[sleepSlot.indexFrom]?.date),
        "seconds"
      );

    let _width_percent =
      (100 *
        moment(sleepData[sleepSlot.indexTo]?.date).diff(
          moment(sleepData[sleepSlot.indexFrom]?.date),
          "seconds"
        )) /
      (60 * HOURS_PER_DAY * MINUTES_PER_HOUR);
    const sleepState = sleepSlot?.sleepState;
    if (_width_percent > 0 && _width_percent < 0.1) {
      _width_percent = 0.1;
    }
    // console.log({ sleepState });

    if (+sleepState > 100) {
      _width_percent = 0.1;
    }
    // let premappedSleepState =
    //   sleepState === 1 ? 1 : sleepState ? 4 : sleepState;
    const sleepStatus = getSleepStatus(sleepState);
    // console.log(sleepStatus);
    let z = 1;
    // console.log({ sleepState });

    if (
      ["GoToSleep", "SleepOnset", "Wake", "OutOfBed"].includes(
        TarnsformerHelper.getSleepStatus(sleepState)
      )
    ) {
      z = 10;
    }

    return {
      key: i,
      sleepState: sleepSlot.sleepState,
      color: SLEEP_STATUS_PROPERTIES[sleepStatus].color,
      start_date: sleepData[sleepSlot.indexFrom]?.date,
      end_date: sleepData[sleepSlot.indexTo]?.date,
      from: Date.parse(sleepData[sleepSlot.indexFrom]?.date),
      to: Date.parse(sleepData[sleepSlot.indexTo]?.date),
      height: SLEEP_STATUS_PROPERTIES[sleepStatus].height,
      timestamp: sleepData[sleepSlot.indexFrom]?.date,
      type: SLEEP_STATUS_PROPERTIES[sleepStatus].isAsleep,
      // raw: moment(x.raw).diff(twelveHoursBefore, "minutes") / (24 * 60),
      left_percent: _left_percent.toFixed(toFixedValue),
      width: _width.toFixed(toFixedValue), // in milliseconds
      width_percent: _width_percent.toFixed(toFixedValue),
      z: z,

      // left_percent: (100 * x.indexFrom) / sleepDataLength,
      // width: 1000 * 60 * (x.indexTo - x.indexFrom + 1),
      // width_percent: (100 * (x.indexTo - x.indexFrom + 1)) / sleepDataLength,
    };
  });

  // console.log(slots);
  // console.log("startadta", moment(sleepAndWakeUpTime["start_date"]));
  // console.log(sleepAndWakeUpTime);

  // construct sleep on/offset boxes
  let onsetBox = {
    key: "sleep-onset-area",
    sleepState: "onset",
    color: SLEEP_STATUS_PROPERTIES["Onset"].color,
    height: SLEEP_STATUS_PROPERTIES["Onset"].height,
    start_date: moment(sleepAndWakeUpTime["start_date"]).subtract(
      sleepAndWakeUpTime["time_to_sleep"],
      "second"
    ),
    end_date: moment(sleepAndWakeUpTime["start_date"]),
    from: moment(sleepAndWakeUpTime["start_date"])
      .subtract(sleepAndWakeUpTime["time_to_sleep"], "second")
      .valueOf(),
    to: moment(sleepAndWakeUpTime["start_date"]).valueOf(),
    timestamp: moment(sleepAndWakeUpTime["start_date"])
      .subtract(sleepAndWakeUpTime["time_to_sleep"], "second")
      .valueOf(),
    type: true,
    left_percent:
      (100 *
        moment(sleepAndWakeUpTime["start_date"])
          .subtract(sleepAndWakeUpTime["time_to_sleep"], "second")
          .diff(twelveHoursBefore, "seconds")) /
      SECONDS_PER_DAY,
    width_percent:
      (100 * sleepAndWakeUpTime["time_to_sleep"]) / SECONDS_PER_DAY,
  };

  const wakeUpTime = moment(sleepAndWakeUpTime["end_date"]);
  const wakeUpTimeTimestamp = wakeUpTime.valueOf();
  const oobTime = moment(sleepAndWakeUpTime["end_date"]).add(
    sleepAndWakeUpTime["time_to_wake_up"],
    "second"
  );
  const oobTimestamp = oobTime.valueOf();

  let offsetBox = {
    key: "sleep-offset-area",
    sleepState: "offset",
    color: SLEEP_STATUS_PROPERTIES["Offset"].color,
    height: SLEEP_STATUS_PROPERTIES["Offset"].height,
    start_date: wakeUpTime,
    end_date: oobTime,
    from: wakeUpTimeTimestamp,
    to: oobTimestamp,
    timestamp: moment(sleepAndWakeUpTime["end_date"])
      .subtract(sleepAndWakeUpTime["time_to_wake_up"], "second")
      .valueOf(),
    type: true,
    left_percent:
      (100 *
        moment(sleepAndWakeUpTime["end_date"]).diff(
          twelveHoursBefore,
          "seconds"
        )) /
      SECONDS_PER_DAY,
    width_percent:
      (100 * sleepAndWakeUpTime["time_to_wake_up"]) / SECONDS_PER_DAY,
  };

  let onsetOffsetPercents = {
    onset: 0,
    onset_end: 0,
    offset: 0,
    offset_end: 0,
  };

  onsetOffsetPercents = {
    onset: sleepAndWakeData?.sleepOnset?.percentage || 0,
    onset_end: sleepAndWakeData?.sleepOnsetEnd?.percentage || 0,
    offset: sleepAndWakeData?.wakeOnset?.percentage || 0,
    offset_end: sleepAndWakeData?.wakeOnsetEnd?.percentage || 0,
  };
  // console.log({ onsetOffsetPercents });

  // console.log(onsetBox.width_percent);

  if (onsetBox.width_percent < 0.1) {
    onsetBox = {
      ...onsetBox,
      width_percent: 0.1,
    };
  }

  if (offsetBox.width_percent < 0.1) {
    offsetBox = {
      ...offsetBox,
      width_percent: 0.1,
    };
  }

  // console.log({ onsetBox });
  // console.log(onsetOffsetPercents);
  // console.log(sleepAndWakeUpTime);

  // let showSleepWakeMark = true;
  // if (
  //   sleepAndWakeUpTime.start_date === undefined ||
  //   sleepAndWakeUpTime.end_date === undefined ||
  //   sleepAndWakeUpTime.time_to_wake_up === undefined ||
  //   sleepAndWakeUpTime.time_to_sleep === undefined
  // ) {
  //   showSleepWakeMark = false;
  // }
  // if (showSleepWakeMark) {
  // slots.push(onsetBox);
  // slots.push(offsetBox);
  // }
  // console.log("binary raw data:", sleepData);
  let ticksNumber = 24;
  // const ticks24Hrs = get24HrTicks(12, date);
  let xticks = [];
  if (mode === "24") {
    xticks = get24HrTicks(12, date);
  } else {
    xticks = get36HrTicks(12, date);
  }
  // console.log(xticks);

  if (sleepDataLoading) {
    return (
      <SimpleNoDataMessageTool
        showTopImg={false}
        message={`${mode} hours Sleep/Wake data loading...`}
      />
    );
  }

  return (
    <div style={{ height: 260 }}>
      {!slots || slots.length === 0 ? (
        <SimpleNoDataMessageTool
          message={"Sorry, there no sleep/wake data for this day."}
        />
      ) : (
        <>
          <div style={{ height: 230 }}>
            <BinarySleepViewTool
              vertAxisLabels={SLEEP_WAKE_YLABELS}
              slots={slots}
              ticks={xticks}
              mode={mode}
              //   utcHandler={adjustUTCOffset}
              //   utcOffset={utcOffset}
              sleepAndWakeUpTime={sleepAndWakeUpTime}
              onsetOffsetPercents={onsetOffsetPercents}
              sleepAndWakeData={sleepAndWakeData}
            />
          </div>
        </>
      )}
    </div>
  );
}

function BinarySleepViewTool(props) {
  const {
    slots = [],
    vertAxisLabels = SLEEP_WAKE_YLABELS,
    ticksNumber = 8,
    ticks = [],
    noDataText = "...",
    utcHandler = () => {},
    loading,
    mode = "24",
    sleepAndWakeData = {},
    sleepAndWakeUpTime,
    onsetOffsetPercents = {},
  } = props;
  // console.log("onsetOffsetPercents", onsetOffsetPercents);

  const optSlots = slots;
  const [hoveredIndex, setHoveredIndex] = useState(undefined);
  // const chartRef = useRef(null);
  const [onsetRef, onsetSize] = useDimensions();
  const [mousePos, setMousePos] = useState({
    x: undefined,
    y: undefined,
  });
  const [ref, { y, x, left, height, width, bottom }] = useDimensions();
  // console.log(ref, size);

  const SleepWakeTriangleMark = (props) => {
    const { status = "", left } = props;
    // status: onset, onset_end, offset, offset_end
    // Bedtime, Sleep Onset, Out of Bed, Wake Up
    const [hoveredText, setHoverText] = useState("");
    const _left = `${(+left).toFixed(3)}%`;
    let _color = "";
    let _rotate = "0";
    let _x_translate = "0";
    let _y_translate = "0";
    // console.log({ status });

    switch (status) {
      case "onset":
        _color = ONSET_END_COLOR;
        _x_translate = "9.5";
        // _x_translate = "-20";
        _y_translate = "-216";
        _rotate = "180";
        break;
      case "onset_end":
        _color = IS_ASLEEP_COLOR;
        _x_translate = "9.5";
        _rotate = "180";
        break;
      case "offset":
        _color = IS_ASLEEP_COLOR;
        _x_translate = "9.5";
        _rotate = "180";
        break;
      case "offset_end":
        _x_translate = "9.5";
        _y_translate = "-216";
        _rotate = "180";
        // _rotate = "180";
        _color = ONSET_END_COLOR;
        break;
      default:
        break;
    }
    const markerDateFormat = " (HH:mm)";
    const sleepTimestamps = {
      onset: sleepAndWakeData?.sleepOnset?.timestamp
        ? moment(sleepAndWakeData?.sleepOnset?.timestamp).format(
            markerDateFormat
          )
        : "",
      onset_end: sleepAndWakeData?.sleepOnsetEnd?.timestamp
        ? moment(sleepAndWakeData?.sleepOnsetEnd?.timestamp).format(
            markerDateFormat
          )
        : "",
      offset: sleepAndWakeData?.wakeOnset?.timestamp
        ? moment(sleepAndWakeData?.wakeOnset?.timestamp).format(
            markerDateFormat
          )
        : "",
      offset_end: sleepAndWakeData?.wakeOnsetEnd?.timestamp
        ? moment(sleepAndWakeData?.wakeOnsetEnd?.timestamp).format(
            markerDateFormat
          )
        : "",
    };

    return (
      <TriangleMark
        onMouseOver={() => {
          setHoverText(sleepTimestamps[status]);
        }}
        onMouseLeave={() => {
          setHoverText("");
        }}
        className={`${status}_mark`}
        $dateString={hoveredText || ""}
        $left={_left}
        $status={status}
        $boxColor={_color}
        $xtranslate={_x_translate}
        $ytranslate={_y_translate}
        $rotate={_rotate}
      />
    );
  };

  // console.log({ onsetSize });

  if (optSlots.length == 0) {
    return (
      <NoDataPlaceholder>
        <NoDataText>{noDataText}</NoDataText>
      </NoDataPlaceholder>
    );
  }

  return (
    <ViewWrapper>
      {/* <div>
          <div
            style={{ height: "10px", width: "10px", background: "red" }}
            onClick={() => utcHandler(1)}
          />
          <div
            style={{ height: "10px", width: "10px", background: "blue" }}
            onClick={() => utcHandler(-1)}
          />
          <div>{props.utcOffset}</div>
        </div> */}
      {hoveredIndex !== undefined &&
        (() => {
          // console.log(hoveredIndex);

          let { sleepState, from, to } = optSlots[hoveredIndex];
          const typeString = ["onset", "offset"].includes(sleepState)
            ? sleepState
            : TarnsformerHelper.getSleepStageNameByType(sleepState);

          let sw = SleepOrWake(sleepState);
          let text = "";
          if (sw === 2) {
            text = "Sleep";
          } else if (sw === 1) {
            text = "Awake";
          } else {
            text = SleepStatusMap[sleepState];
          }
          // console.log({ typeString, sleepState });

          const startString = moment(from).format("Do HH:mm");
          const untilString = moment(to).format("Do HH:mm");
          return (
            <div
              style={{}}
              onMouseOver={(e) => {
                e.preventDefault();
              }}
            >
              <div
                style={{
                  padding: "10px",
                  position: "fixed",
                  width: "200px",
                  left: mousePos.x + 10,
                  top: mousePos.y + 10,
                  zIndex: 1000,
                  background: "#fff",
                  border: "1px solid #c0c0c0",
                }}
              >
                {/* {"test"} */}
                {/* {`x: ${mousePos.x}; y: ${mousePos.y}`} */}
                <div>Sleep Stage: {text}</div>
                <div>Start: {startString}</div>
                <div>Until: {untilString}</div>
              </div>
            </div>
          );
        })()}
      <LeftPlaceholder>
        <LeftInnerPlaceholder>
          {vertAxisLabels.map((x, i) => (
            <VertAxisLabel
              className="sleep-vert-label"
              key={`${x}`}
              $height={+i + 1}
            >
              {x}
            </VertAxisLabel>
          ))}
        </LeftInnerPlaceholder>
      </LeftPlaceholder>

      <RightPlaceholder>
        <TopPlaceholder className="sleep-top-placeholder">
          <ChartPlaceholder ref={ref}>
            {[1, 2].map((x) => (
              <HorLine key={x} $height={x} />
            ))}
            {["onset", "onset_end", "offset", "offset_end"].map((x, i) => {
              return (
                <div
                  key={i}
                  style={{
                    display: onsetOffsetPercents.onset ? "flex" : "none",
                  }}
                >
                  <SleepWakeTriangleMark
                    key={i}
                    left={onsetOffsetPercents[x]}
                    status={x}
                  />
                </div>
              );
            })}
            {optSlots.map((opt, i) => {
              // console.log(opt);
              let { sleepState, end_date, start_date, from, to } = opt;
              // let adjustPosition = 0;
              // if (+sleepState == 101 || +sleepState == 104) {
              //   adjustPosition = -12;
              // }
              // if (+sleepState == 102 || +sleepState == 103) {
              //   adjustPosition = 4;
              // }

              return (
                <Box
                  // {...opt}
                  $height={opt.height}
                  $width_percent={opt.width_percent}
                  $left_percent={opt.left_percent}
                  $color={opt.color}
                  $z={opt.z}
                  key={`opt_${i}_${opt.width_percent}`}
                  // $adjustPosition={adjustPosition}
                  onMouseEnter={() => {
                    setHoveredIndex(i);
                  }}
                  onMouseLeave={() => {
                    setHoveredIndex(undefined);
                  }}
                  onMouseMove={(event) => {
                    const pageX = event.clientX;
                    const pageY = event.clientY;
                    setMousePos({
                      x: pageX,
                      y: pageY,
                    });
                  }}
                ></Box>
              );
            })}
          </ChartPlaceholder>
        </TopPlaceholder>

        <BottomPlaceholder className="sleep-bottom-placeholder" $mode={mode}>
          {ticks.map((x, i) => {
            return (
              <Tick key={i} $left={x.left}>
                <TickInner className="sleep-tick-label">
                  {moment(x.t).format("DD dd HH:mm")}
                </TickInner>
              </Tick>
            );
          })}
        </BottomPlaceholder>
      </RightPlaceholder>

      {/*{JSON.stringify(optSlots)}*/}
    </ViewWrapper>
  );
}

const bottomHeight = 14;

const NoDataPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoDataText = styled.div`
  font-size: 24px;
  color: grey;
`;

const BORDER_COLOR = "lightgrey";

const Tick = styled.div`
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: ${(props) => `${props.$left}%`};
`;

const TickInner = styled.div`
  width: 35px;
  box-sizing: border-box;
  text-align: center;
  margin-left: -15px;
  font-size: 10px;
  margin-top: 2px;
  // background: white;

  ${Tick}:last-of-type > & {
    margin-left: -20px;
  }
`;

const ViewWrapper = styled.div`
  box-sizing: border-box;
  //   border: 1px solid blue;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-right: 1px solid ${BORDER_COLOR};
`;

const HorLine = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  background: ${BORDER_COLOR};
  height: 1px;
  bottom: ${(props) => `calc(${(100.0 * props.$height) / 2.0}% - 1px)`};
  z-index: 0;
`;

const Box = styled.div`
  position: absolute;
  bottom: 0px;
  z-index: 5;
  height: ${(props) => `${(100.0 * props.$height) / 2.0}%`};
  width: ${(props) => `${props.$width_percent}%`};
  left: ${(props) => `${props.$left_percent}%`};
  box-sizing: border-box;
  background: ${(props) => props.$color};
  z-index: ${(props) => `${props.$z}`};
  &:hover {
    .box-info {
      display: block;
    }
  }
`;

const LeftPlaceholder = styled.div`
  width: 40px;
  border-right: 1px solid ${BORDER_COLOR};
`;

const LeftInnerPlaceholder = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const VertAxisLabel = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  height: 12px;
  font-size: 10px;
  text-align: right;
  box-sizing: border-box;
  padding-right: 3px;
  bottom: ${(props) =>
    `calc(${(100.0 * props.$height) / 2.0}% - 1rem + ${bottomHeight}px)`};
  z-index: 1;
  :last-of-type {
    bottom: calc(100% - 6px);
  }
`;

const RightPlaceholder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TopPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  // overflow: hidden;
  flex: 1;
  background: white;
`;

const ChartPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow-x: clip;
`;

const BottomPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: ${bottomHeight}px;
  background: white;
  padding: 2px;
  border-top: 1px solid ${BORDER_COLOR};
  position: relative;
  // border: 1px solid blue;
  background: ${(props) =>
    props.$mode === "24"
      ? "-webkit-linear-gradient(180deg, #ffffff00 50%, #ffffff00 50%);"
      : "-webkit-linear-gradient(180deg, #ffffff00 66.66%, #ffffff00 0%);"}
  z-index: 100;
`;
const Wrapper = styled.div``;

// const SleepStatusMap = {

// }

const ModeSelector = styled.div``;

const ModeOption = styled.span`
  cursor: pointer;
`;

const TriangleMark = styled.div`
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 10px 15px 10px;
  border-color: transparent transparent ${(props) => props.$boxColor}
    transparent;
  transform: rotate(${(props) => props.$rotate}deg)
    translate(
      ${(props) => props["$xtranslate"]}px,
      ${(props) => props["$ytranslate"]}px
    );
  position: absolute;
  left: ${(props) => props.$left};
  top: -15px;
  z-index: 9;
  &::before {
    content: "${(props) =>
      sleepOnsetOffsetLabelMap[props.$status] + props.$dateString}";
    width: max-content;
    font-weight: 500;
    color: ${(props) => {
      if (props.$status === "offset_end" || props.$status === "onset") {
        return "#232323";
      } else {
        return "#232323";
      }
    }};
    transform: rotate(
      ${(props) => {
        if (props.$status === "onset_end" || props.$status === "offset") {
          return "180";
        } else {
          return "180";
        }
      }}deg
    );
    top: ${(props) => {
      if (props.$status === "offset_end" || props.$status === "onset") {
        return "-3";
      } else {
        return "1";
      }
    }}rem;
    left: -3em;
    font-size: 14px;
    position: absolute;
  }
`;

// const offsetMap = {
//   "1": 60,
// "2":
// sw === 1 ? 60 : -40
// }

const offsetMap = (sleepState) => {
  switch (sleepState) {
    case "Awake":
      return 60;
    case "Sleep":
      return -40;
    case "Onset":
      return 50;
    case "Offset":
      return 50;
    default:
      return 0;
  }
};

export const sleepOnsetOffsetLabelMap = {
  onset: "Bedtime",
  onset_end: "Sleep Onset",
  offset: "Wake Up Time",
  offset_end: "Out of Bed",
};
