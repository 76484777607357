import { useState, useEffect, useRef, PureComponent } from "react";
import moment from "moment";
import styled from "styled-components";
import { Code } from "react-content-loader";
import DoctorAPI from "../../../api/DoctorAPI";
import useDimensions from "react-use-dimensions";
import { STEP_BAR_COLOR } from "../../ui/templates/Charts";
import SimpleNoDataMessageTool from "../../sleep/tools/SimpleNoDataMessageTool";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

const FIVE_MINUTES = 5 * 60 * 1000;
export default function CsnStepsTool(props) {
  const {
    uuid,
    from,
    to,
    startTimestamp,
    points = [],
    stepsTotal = {},
    tempActivityLoading,
    dataKey = "",
    totalLabel,
    totalKey = "",
  } = props;
  // console.log(points);

  const [chartRef, { x, y, width, height }] = useDimensions();
  // console.log({ points });
  if (totalKey === "phTotal") {
    // console.log({ tempActivityLoading, totalKey });
  }
  if (totalKey === "cnTotal") {
    // console.log(points);
  }
  // if ( tempActivityLoading == true) {
  //   return <Code />;
  // }

  // if (points.length == 0) {
  //     return null;
  // }

  const xticks = generateHourlyTimestamps(from);
  const hasActive = stepsTotal?.[totalKey];

  return (
    <Wrapper ref={chartRef}>
      {!points || points.length === 0 ? (
        <SimpleNoDataMessageTool
          // loading={tempActivityLoading}
          message={"Sorry, there is no steps data for this day."}
        />
      ) : (
        <div style={{ height: height }} className="cn-steps-container">
          <div style={{ textAlign: "right", fontWeight: 500 }}>
            {totalKey === "phTotal" && (
              <div>{`Total Steps: ${stepsTotal?.[totalKey]}`}</div>
            )}
            {totalKey === "clrActive" && (
              <>
                <div
                  style={{ display: hasActive ? "" : "none" }}
                >{`Active Calories: ${stepsTotal?.[totalKey]} Cal`}</div>
                <div>{`Total Calories: ${stepsTotal?.clrTotal} Cal`}</div>
              </>
            )}
            {totalKey === "cnTotal" && (
              <div>{`Total Steps (Cn): ${stepsTotal?.[totalKey]}`}</div>
            )}
            {/* {totalLabel ? (
              <div>{`${totalLabel}${stepsTotal?.[totalKey]}`}</div>
            ) : (
              <div>Cn Total Steps: {stepsTotal.cnTotal}</div>
            )} */}
          </div>
          <BarChart
            className="bar-chart-wrapper"
            height={height}
            width={width}
            data={points}
            allowDataOverflow={false}
          >
            <XAxis
              dataKey="timestamp"
              ticks={xticks}
              interval={0}
              type="number"
              domain={[from, to]}
              tickFormatter={(x) => {
                const _t = moment(x).format("HH:mm");
                return _t;
              }}
              tick={<CustomizedAxisTick />}
              allowDataOverflow
            />
            <YAxis
              width={41}
              dataKey={dataKey}
              domain={([min, max]) => {
                return [min, max];
              }}
              tickFormatter={(x) => {
                if (dataKey === "calorie") {
                  return x.toFixed(0);
                }
                return x;
              }}
              style={{
                boxSizing: "border-box",
                border: "1px solid #c0c0c0",
              }}
            />
            <Bar
              dataKey={dataKey}
              // stroke={STEP_BAR_COLOR}
              fill={STEP_BAR_COLOR}
              // background={{ fill: "#eee" }}
              strokeWidth={1.4}
              animationDuration={200}
            />
            {xticks.map((x, i) => {
              const c = x;
              return <ReferenceLine key={`srl_${i}`} x={x} opacity={0.5} />;
            })}
            <Tooltip
              cursor={{ fill: "#cccccc90" }}
              content={<StepsToolTip dataKey={dataKey} />}
            />
          </BarChart>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  height: 300px;
  // background: pink;
  box-sizing: border-box;
  position: relative;
`;

function StepsToolTip(props) {
  const { active, payload, label, dataKey = "" } = props;
  const activityCount = payload[0]?.value;
  const showSeconds = true;
  if (active && payload && payload.length) {
    const { label = "" } = payload[0]?.payload || {};
    const steps = payload[0]?.value;
    let l = "Steps";

    if (dataKey === "energy") {
      l = "Calories";
    }
    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{label}</TooltipRow>
        <TooltipRow className="label">
          {l}: {steps}
        </TooltipRow>
      </TooltipWrapper>
    );
  }
  return null;
}

export const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

export const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;

function generateHourlyTimestamps(startOfDay) {
  const ONE_HOUR = 3600 * 1000; // seconds in an hour
  const result = [];

  for (let i = 0; i < 24; i++) {
    result.push(startOfDay + i * ONE_HOUR);
  }

  return result;
}

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={38}
          y={0}
          dy={6}
          textAnchor="end"
          fill="#666"
          // transform="rotate(-35)"
        >
          {moment(payload.value).format("HH:mm")}
        </text>
      </g>
    );
  }
}
