import {useState} from "react";
import styled from "styled-components";

import {Input, Button} from "../ui";

import passwordVisible from "../assets/passwordVisible.svg";
import passwordInvisible from "../assets/passwordInvisible.svg";
import Spin from "arui-feather/spin";
import {toast} from "react-hot-toast";
import CommonHelper from "../../../helpers/CommonHelper";

export default function StyledLoginForm(props) {
    const {isOtp = false} = props;
    const {
        onSubmit = () => {
        }, loading = false
    } = props;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Wrapper>
            <TopPlaceholder></TopPlaceholder>

            <SubTopPlaceholder></SubTopPlaceholder>

            <ContentPlaceholder>
                <Field>
                    <Input
                        placeholder={"Email"}
                        name={"email"}
                        className="email-input"
                        // autofill={false}
                        value={email}
                        type={"email"}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                    />
                </Field>
                <Field style={{position: "relative"}}>
                    <Input
                        value={password}
                        type={showPassword ? "text" : "password"}
                        placeholder={"Password"}
                        name={"password"}
                        className="password-input"
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                onSubmit({
                                    email: email.toLowerCase(),
                                    password,
                                    otpCode: otpCode,
                                });
                            }
                        }}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        // autofill={false}
                    />
                    <span
                        style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            cursor: "pointer",
                            transform: "translateY(-50%)",
                        }}
                        onClick={() => {
                            setShowPassword(!showPassword);
                        }}
                    >
            <img
                src={showPassword ? passwordInvisible : passwordVisible}
                width={"20px"}
                height={"20px"}
            />
          </span>
                </Field>

                {isOtp == false ? null : (
                    <Field>
                        <Input
                            value={otpCode}
                            type={"text"}
                            placeholder={"OTP 6 digits Code"}
                            name={"digitscode"}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    onSubmit({
                                        email: email.toLowerCase(),
                                        password,
                                        otpCode: otpCode,
                                    });
                                }
                            }}
                            onChange={(e) => {
                                setOtpCode(e.target.value);
                            }}
                            // autofill={false}
                        />
                    </Field>
                )}

                {/*<div style={{padding: 10, paddingBottom: 20, fontSize: '13px', color: 'red'}} >*/}
                {/*    Too many login attempts.*/}
                {/*    Please try again after 60 minutes.*/}
                {/*</div>*/}

                <Field>
                    <Button
                        className="signin-button"
                        onClick={() => {
                            if (
                                isOtp == true &&
                                (otpCode == undefined || otpCode.length != 6)
                            ) {
                                toast.error("OTP Code must be 6 digits long.");
                                return;
                            }
                            onSubmit({
                                email: email.toLowerCase(),
                                password,
                                otpCode: otpCode,
                            });
                        }}
                    >
                        <Spin theme={"alfa-on-color"} visible={loading}/>
                        {loading == true ? null : "Sign in"}
                    </Button>
                    <PasswordRecoveryAndSignUp/>

                    <div>
                        <PrivacyPlaceholder>
                            Corsano Portal displays Personal Health Information.
                            <br/>
                            By logging in, you agree to keep <strong>all data</strong> confidential.
                        </PrivacyPlaceholder>
                    </div>

                </Field>
            </ContentPlaceholder>
        </Wrapper>
    );
}

const PasswordRecoveryAndSignUp = () => {
    return (
        <div style={{display: "flex", marginTop: "1rem"}}>
            <div
                style={{
                    // display: "none",
                    marginLeft: "auto",
                    marginRight: "auto",
                    textDecoration: "underline",
                    fontSize: "small",
                    cursor: "pointer",
                    opacity: "60%",
                }}
                onClick={() => {
                    CommonHelper.linkTo("/password_reset");
                }}
            >
                Forgot password?
            </div>
            <div
                style={{
                    // display: "none",
                    marginLeft: "auto",
                    marginRight: "auto",
                    textDecoration: "underline",
                    fontSize: "small",
                    cursor: "pointer",
                    opacity: "60%",
                }}
                onClick={() => {
                    CommonHelper.linkTo("/registration");
                }}
            >
                Create an account
            </div>
        </div>
    );
};

export const PrivacyPlaceholder = styled.div`
  padding: 10px;
  font-size: 12px;
  opacity: 0.6;
  text-align: center;
  font-style: italic;
`;

const SignupSpan = styled.span`
  cursor: pointer;
  color: #0072bc;
  letter-spacing: -0.0642857px;
  text-decoration-line: underline;
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  margin-left: 16px;

  :hover {
    opacity: 0.7;
  }
`;

const ForgotPlaceholder = styled.div`
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  letter-spacing: -0.0642857px;
  color: #202224;
  mix-blend-mode: normal;
  opacity: 0.6;
`;

const ForgotSpan = styled.span`
  cursor: pointer;

  :hover {
    opacity: 0.9;
  }
`;

const BottomPlaceholder = styled.div`
  color: #202224;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
`;

const Wrapper = styled.div`
  width: 400px;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const SubTopPlaceholder = styled.div`
  color: #202224;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.0642857px;
  opacity: 0.8;
  text-align: center;
  margin-bottom: 25px;
`;

const TopPlaceholder = styled.div`
  width: 100%;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 46px;
  color: #465e6f;
  text-align: center;
`;

const ContentPlaceholder = styled.div``;

const Field = styled.div`
  margin-bottom: 25px;
  width: 100%;
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 5px;
  opacity: 0.8;
  letter-spacing: -0.0642857px;

  color: #202224;
`;

const Link = styled.div`
  color: #999999;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;

  :hover {
    opacity: 0.8;
  }
`;
