import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import styled from "styled-components";
import GroupsAPI from "../../../api/GroupsAPI";

import { Code } from "react-content-loader";

import Select from "react-select";
import leftChevronImage from "../../patients/icons/left_chevron.svg";
import ls from "local-storage";
import triangle_up from "../../../assets/images/badges/triangle_up.svg";
import triangle from "../../../assets/images/badges/triangle.svg";
import { isDebugMode } from "../../../helpers/UiHelper";
import { getTrackBackground } from "react-range";
import DoctorAPI from "../../../api/DoctorAPI";
import NiceModal from "../../modals/NiceModal";
import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  ReferenceArea,
  Legend,
  Tooltip,
} from "recharts";
import axios from "axios";
import CommonHelper from "../../../helpers/CommonHelper";

const DES_ARROW = "⬎";
const DEF_ARROW = "";
const ASC_ARROW = "⬏";

const generateUniquePriority = (alarmSystem, extra = "") => {
  console.log({ alarmSystem, extra });

  if (alarmSystem && alarmSystem.includes("news")) {
    console.log(`generating NEWS2 options`);
    return [
      {
        label: "Low",
        value: "low",
        color: "#CCC",
        setting: "news",
      },
      {
        label: "Low-Medium",
        value: "low-medium",
        color: "#FFF0A7",
        setting: "news",
      },
      {
        label: "Medium",
        value: "medium",
        color: "#F6C387",
        setting: "news",
      },
      {
        label: "High",
        value: "high",
        color: "#EB9079",
        setting: "news",
      },
      {
        label: "No Alarm",
        value: "no_alarm",
        color: NO_PRIORITY.color,
        setting: "news",
      },
    ];
  } else {
    console.log(`generating bf options`);
    return [
      {
        label: "Low",
        value: "low",
        color: PR_3.color,
        setting: "bf",
      },
      {
        label: "Medium",
        value: "medium",
        color: PR_2.color,
        setting: "bf",
      },
      {
        label: "High",
        value: "high",
        color: PR_1.color,
        setting: "bf",
      },
      {
        label: "No Alarm",
        value: "no_alarm",
        color: NO_PRIORITY.color,
        setting: "bf",
      },
    ];
  }
};

export default function DashboardAlarmsTool(props) {
  const darkmode = ls.get("theme") === "dark";
  const [allGroups, setAllGroups] = useState([]);
  const [selectedCode, setSelectedCode] = useState(undefined);

  // const [uniquePriority, setUniquePriority] = useState([]);
  // const [uniqueVital, setUniqueVital] = useState([]);
  const [uniquePatient, setUniquePatient] = useState([]);
  // const currentGroupUISetting = useRef(null);
  const [currentGroupUISetting, setCurrentGroupUISetting] = useState(null);
  const [multiOptions, setMultiOptions] = useState(
    generateUniquePriority(currentGroupUISetting, "2")
  );

  // const uniquePriority = generateUniquePriority(currentGroupUISetting.current);

  const generateUniqueVital = (alarmSystem) => {
    // console.log({ alarmSystem });

    if (alarmSystem === "news") {
      const vs = ["NEWS2"].map((x) => {
        return {
          label: VITALS_NAMES_NAME[x],
          value: x,
        };
      });
      return vs;
    } else {
      const vs = [
        "spo2",
        "pulse_rate",
        "respiration_rate",
        "blood_pressure",
        "temperature",
      ].map((x) => {
        return {
          label: VITALS_NAMES_NAME[x],
          value: x,
        };
      });
      return vs;
    }
  };

  const uniqueVital = generateUniqueVital(currentGroupUISetting);
  // const uniqueVital = generateUniqueVital("fb");
  // console.log({ _uniqueVital });

  const eventsOptions = [
    {
      label: "Alarm Condition",
      value: `alarm condition`,
    },
    {
      label: "Alarm Inactivation",
      value: "alarm inactivation",
    },
    // {
    //   label: "NEWS2 History",
    //   value: "news2 history",
    // },
    {
      label: "All",
      value: "clear",
    },
  ];

  const [selectedKeyValue, setSelectedKeyValue] = useState({
    event: {
      value: "",
    },
    priority: {
      value: ["high", "medium"],
    },
    type: {
      value: "",
    },
    patient: {
      value: "",
    },
  });

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortKey, setSorkKey] = useState("name");
  const [sortOrder, setSortOrder] = useState("");
  const [sortedItems, setSortedItems] = useState([]);
  const [selectedPatientUUID, setSelectedPatientUUID] = useState("");

  const [patients, setPatients] = useState([]);

  const [selectedDayTimestamp, setSelectedDayTimestamp] = useState(
    +moment().startOf("day")
  );
  // const startOfDay

  const [news2HistoryMap, setNEWS2HistoryMap] = useState({});
  const [news2HistoryList, setNEWS2HistoryList] = useState([]);
  const [total, setTotal] = useState(0);
  const [progress, setProgress] = useState(0);

  const priorityToNum = (priority) => {
    switch (priority) {
      case "low":
        return 1;
      case "medium":
        return 2;
      case "high":
        return 3;
      default:
        return 0;
    }
  };

  useEffect(() => {
    setLoading(true);
    GroupsAPI.getGroups().then((res) => {
      setAllGroups(res);
      setLoading(false);
      if (res != undefined && res.length > 0) {
        console.log(res[0]);
        setSelectedCode(res[0]?.code);
        let currentGroup = res.find((x) => x.code === res[0]?.code) || {};
        // currentGroupUISetting.current =
        // currentGroup?.uiSettings?.ui_settings?.alarms_system;
        const currentSystem =
          currentGroup?.uiSettings?.ui_settings?.alarms_system;
        const currentMultiOptions = generateUniquePriority(currentSystem, "3");
        console.log({ currentMultiOptions });

        setCurrentGroupUISetting(currentSystem);
        setMultiOptions(currentMultiOptions);
      }
    });
  }, []);

  let patientsMap = patients.reduce((mp, x) => ({ ...mp, [x.uuid]: x }), {});
  // let currentGroup = allGroups.find((x) => x.code === selectedCode) || {};
  // currentGroupUISetting.current =
  //   currentGroup?.uiSettings?.ui_settings?.alarms_system;

  // console.log({ currentGroup, currentGroupUISetting });

  // useEffect(() => {
  //   uniquePatient.forEach((p, i) => {
  //     if (p?.value === "clear" || !p?.value) return;
  //     const uuid = p.value;
  //     const name = p.label;
  //     console.log(selectedDayTimestamp);
  //     const from = selectedDayTimestamp;
  //     const to = moment(selectedDayTimestamp).add(1, "day").valueOf();
  //     const dateFrom = new Date(from).toISOString();
  //     const dateTo = new Date(to).toISOString();

  //     DoctorAPI.getPatientNEWS2History(uuid, from, to).then((d) => {
  //       console.log({ d, l: name });
  //       setNEWS2HistoryMap((oldMap) => {
  //         return { ...oldMap, [uuid]: d };
  //       });
  //     });
  //   });
  // }, [selectedDayTimestamp, uniquePatient]);

  useEffect(() => {
    // setNEWS2HistoryMap({});
    const fetchAllAlarm = async () => {
      try {
        const from = +moment(selectedDayTimestamp).startOf("day");
        const to = +moment(selectedDayTimestamp).endOf("day");
        const news2From = selectedDayTimestamp;
        const news2To = moment(selectedDayTimestamp).add(1, "day").valueOf();
        const news2TrendTo = moment(selectedDayTimestamp)
          .add(1, "day")
          .valueOf();
        const news2TrendFrom = moment(selectedDayTimestamp)
          .subtract(30, "day")
          .valueOf();
        const groupAlarms = await GroupsAPI.getGroupAlarmsHistory(
          selectedCode,
          from,
          to
        );
        const uuidList = groupAlarms.map((x) => x.user_uuid);
        const uuidSet = [...new Set(uuidList)];
        // console.log(uuidSet);

        const news2DataPromises = uuidSet.map((uuid) => {
          const _endpoint = `${"https://api.study-integration.corsano.com"}/users/${uuid}/news-score-history`;
          return axios.get(_endpoint, {
            params: {
              from: news2From,
              to: news2To,
              // from: news2TrendFrom,
              // to: news2TrendTo,
            },
          });
        });
        const news2DataResponses = await Promise.all(news2DataPromises);
        const news2DataHistory = news2DataResponses.map((res) => {
          const rd = res.data || [];
          // if (rd && rd.length > 0) {
          //   let _rd = rd;
          //   setNEWS2HistoryMap((oldMap) => {
          //     return { ...oldMap, [rd[0]?.user_uuid]: _rd };
          //   });
          // }
          return rd;
        });

        const flattedNEWS2DataHistory = news2DataHistory.flat();
        const flattedNEWS2DataHistoryWithExtraInfo = flattedNEWS2DataHistory
          // .filter((x) => x.value !== null)
          .map((x) => {
            const { value } = x;
            const color = getColorByScore(value);
            const status = getNEWS2StatusByScore(value);
            return { ...x, type: "NEWS2", color: color, status: status };
          });
        // console.log(flattedNEWS2DataHistoryWithExtraInfo);
        // console.log(groupAlarms);

        const merged = [
          ...groupAlarms,
          ...flattedNEWS2DataHistoryWithExtraInfo,
        ];
        const sortedMerged = merged.sort((a, b) => +a.timestamp - +b.timestamp);
        let _res = merged;
        let filteredResults = _res.filter(
          (a) => a.priority === "medium" || a.priority === "high"
        );

        setItems(_res);
        setSortedItems(filteredResults);
        //   console.log("low", filteredResults.filter((x) => x.quality < 2));

        // const vitals = filteredResults.map((x) => x.type);
        // const priority = filteredResults.map((x) => x.priority);
        // const vitalOptions = [...new Set(vitals)];
        // const priorityOptions = [...new Set(priority)];

        // const _uniqueVital = vitalOptions.map((x) => {
        //   return {
        //     value: x,
        //     label: VITALS_NAMES_NAME[x] ? VITALS_NAMES_NAME[x] : x,
        //   };
        // });
        // const _uniquePriority = priorityOptions.map((x) => {
        //   return {
        //     value: x,
        //     label: PRIORITY_NAMES_NAME[x] ? PRIORITY_NAMES_NAME[x] : x,
        //     color: PR_MAP[x]?.color,
        //   };
        // });
        // _uniqueVital.push({
        //   value: "clear",
        //   label: "Clear Filter",
        // });
        // _uniquePriority.push({
        //   value: "clear",
        //   label: "Clear Filter",
        // });
        setLoading(false);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchAllAlarm();
  }, [patients, selectedCode, selectedDayTimestamp]);

  // useEffect(() => {
  //   if (selectedCode == undefined) {
  //     return;
  //   }

  //   const news2From = selectedDayTimestamp;
  //   const news2To = moment(selectedDayTimestamp).add(1, "day").valueOf();

  //   setLoading(true);
  //   setItems([]);
  //   let from = +moment(selectedDayTimestamp).startOf("day");
  //   let to = +moment(selectedDayTimestamp).endOf("day");
  //   GroupsAPI.getGroupAlarmsHistory(selectedCode, from, to).then((res) => {
  //     let _res = res;
  //     let filteredResults = _res.filter(
  //       (a) => a.priority === "medium" || a.priority === "high"
  //     );

  //     setItems(_res);
  //     setSortedItems(filteredResults);
  //     //   console.log("low", filteredResults.filter((x) => x.quality < 2));

  //     const vitals = filteredResults.map((x) => x.type);
  //     const priority = filteredResults.map((x) => x.priority);
  //     const vitalOptions = [...new Set(vitals)];
  //     const priorityOptions = [...new Set(priority)];

  //     const _uniqueVital = vitalOptions.map((x) => {
  //       return {
  //         value: x,
  //         label: VITALS_NAMES_NAME[x] ? VITALS_NAMES_NAME[x] : x,
  //       };
  //     });
  //     const _uniquePriority = priorityOptions.map((x) => {
  //       return {
  //         value: x,
  //         label: PRIORITY_NAMES_NAME[x] ? PRIORITY_NAMES_NAME[x] : x,
  //         color: PR_MAP[x]?.color,
  //       };
  //     });
  //     _uniqueVital.push({
  //       value: "clear",
  //       label: "Clear Filter",
  //     });
  //     _uniquePriority.push({
  //       value: "clear",
  //       label: "Clear Filter",
  //     });
  //     setLoading(false);
  //   });
  // }, [patients, selectedCode, selectedDayTimestamp]);

  useEffect(() => {
    // console.log(patients);
    const uuids = items.map((x) => x.user_uuid);
    // console.log("uuids", uuids);
    const nameOptions = [...new Set(uuids)];
    let _uniqueID = nameOptions.map((x) => {
      let _patient = patients.find((pt) => pt.uuid === x);
      const first_name = _patient?.first_name;
      const last_name = _patient?.last_name;
      //   console.log("first_name, last_name", first_name, last_name);
      if (first_name || last_name) {
        return {
          value: x,
          label: `${first_name} ${last_name}`,
        };
      }
      return {};
    });
    _uniqueID.push({
      value: "clear",
      label: "All",
    });
    // console.log("nameOptions", _uniqueID);
    setUniquePatient(_uniqueID.filter((x) => x.value));
  }, [sortedItems]);

  useEffect(() => {
    if (selectedCode == undefined) {
      return;
    }
    GroupsAPI.getGroupShortPatients(selectedCode).then((res) => {
      setPatients(res);
    });
    let currentGroup = allGroups.find((x) => x.code === selectedCode) || {};
    // currentGroupUISetting.current =
    // currentGroup?.uiSettings?.ui_settings?.alarms_system;
    const currentSystem = currentGroup?.uiSettings?.ui_settings?.alarms_system;
    const currentMultiOptions = generateUniquePriority(currentSystem, "3");
    console.log({ currentMultiOptions });

    setCurrentGroupUISetting(currentSystem);
    setMultiOptions(currentMultiOptions);

    // console.log("current", currentGroupUISetting.current);
  }, [selectedCode]);

  useEffect(() => {
    const sortItems = () => {
      const sortedArray = [...items].sort((a, b) => {
        let keyA, keyB;
        if (sortKey === "patient") {
          let patientA = patientsMap[a.user_uuid] || {};
          let patientB = patientsMap[b.user_uuid] || {};
          let first_name_a = patientA.first_name;
          let last_name_a = patientA.last_name;
          let email_a = patientA.email;
          let first_name_b = patientB.first_name;
          let last_name_b = patientB.last_name;
          let email_b = patientB.email;
          keyA = first_name_a == undefined ? email_a : first_name_a;
          keyB = first_name_b == undefined ? email_b : first_name_b;
          // console.log("keya", keyA, keyB);
        }
        if (sortKey === "date") {
          keyA = moment(a.date).valueOf();
          keyB = moment(b.date).valueOf();
        }
        if (sortKey === "time") {
          keyA = moment(a.created_at).valueOf();
          keyB = moment(b.created_at).valueOf();
        }
        if (sortKey === "priority") {
          keyA = priorityToNum(a.priority);
          keyB = priorityToNum(b.priority);
        }
        if (sortKey === "vital") {
          keyA = a.type;
          keyB = b.type;
        }
        if (sortKey === "value") {
          keyA = a.value;
          keyB = b.value;
        }

        if (sortKey === "status" || sortKey === "event-type") {
          keyA = a.is_audio_on;
          keyB = b.is_audio_on;
        }

        if (keyA < keyB) return sortOrder === "ASC" ? -1 : 1;
        if (keyA > keyB) return sortOrder === "ASC" ? 1 : -1;
        return 0;
      });
      setSortedItems(sortedArray);
    };
    // sortItems();
  }, [items, sortKey, sortOrder]);

  useEffect(() => {
    const filterItems = () => {
      let filteredItems = items;
      // filteredItems = [
      //   ...filteredItems,
      //   ...news2HistoryList
      //     .filter((x) => x.value !== null)
      //     // .filter((x) => x.value > 4)
      //     .map((x) => {
      //       return { ...x, type: "NEWS2", color: getColorByScore(x.value) };
      //     }),
      // ];
      for (const kv in selectedKeyValue) {
        // console.log(
        //   "KVKV",
        //   kv,
        //   selectedKeyValue[kv],
        //   selectedKeyValue[kv]?.value
        // );
        if (selectedKeyValue[kv]?.value === "") {
          continue;
        }
        if (selectedKeyValue[kv]?.value === "clear") {
          filteredItems = filteredItems.filter((i) => {
            return true;
          });
          continue;
        }
        filteredItems = filteredItems.filter((i) => {
          // console.log(i, i[kv], selectedKeyValue[kv]?.selectedVal);
          if (kv === "patient") {
            return i["user_uuid"] === selectedKeyValue[kv]?.value;
          }
          if (kv === "event") {
            let _alarm =
              i.is_audio_on == true ? "alarm condition" : "alarm inactivation";

            if (i.type === "NEWS2") {
              _alarm = "news2 history";
            }

            // console.log("iiiiiiii", i);
            // console.log(
            //   "selectedKeyValue[kv]?.value",
            //   selectedKeyValue[kv]?.value,
            //   _alarm,
            //   _alarm === selectedKeyValue[kv]?.value
            // );
            return _alarm === selectedKeyValue[kv]?.value;
          }
          if (kv === "priority" && selectedKeyValue[kv]?.value) {
            // console.log(
            //   "selectedKeyValue[kv]?.value",
            //   selectedKeyValue[kv]?.value
            // );

            if (
              (selectedKeyValue[kv]?.value).length === 0 ||
              (selectedKeyValue[kv]?.value).includes("clear")
            ) {
              return true;
            }
            return (selectedKeyValue[kv]?.value).includes(i[kv]);
            // return i[kv] === "medium" || i[kv] === "high";
          }
          // console.log("ikv", i[kv]);

          return i[kv] === selectedKeyValue[kv]?.value;
          // return true;
        });
      }
      setSortedItems(filteredItems);
    };
    filterItems();
  }, [items, selectedKeyValue]);

  const getPatientNameFromUUID = (uuid) => {
    let patient = patientsMap[uuid] || {};
    let { first_name = "", last_name = "", code = "" } = patient;
    let uName =
      first_name === null || first_name === undefined
        ? `Patient ${code}`
        : `${first_name} ${last_name}`;
    return uName;
  };

  const options = allGroups.map((x) => ({
    value: x.code,
    label: `${x.name} [${x.code}]`,
  }));

  if (allGroups.length == 0 && loading == true) {
    return <Code />;
  }
  let patientsUUIDSMap = patients
    .map((x) => x.uuid)
    .reduce((mp, x) => ({ ...mp, [x]: 1 }), {});

  let goodItems = sortedItems;

  goodItems = goodItems.filter(
    (s) => patientsUUIDSMap[s.user_uuid] != undefined
  );
  // console.log({ goodItems });

  // goodItems = [
  //   ...goodItems,
  //   ...news2HistoryList
  //     .filter((x) => x.value !== null)
  //     // .filter((x) => x.value > 4)
  //     .map((x) => {
  //       return { ...x, type: "NEWS2", color: getColorByScore(x.value) };
  //     }),
  // ];
  // goodItems = goodItems.sort((a, b) => {
  //   return +a?.timestamp - +b?.timestamp;
  // });

  // console.log('render: items = ', items);
  // console.log("render: code = ", selectedCode);
  // console.log(patientsMap);

  const NEWS2Table = () => {
    console.log("LOADING NEWS2 TABLE");
    // console.log({ news2Items: items });
    return (
      <Table>
        <Thead>
          <Tr style={{ color: darkmode ? "white" : "" }}>
            {/* <Th>Event Type</Th> */}
            <Th>Date</Th>
            <Th>Time</Th>
            <Th>Study</Th>
            <Th>Patient</Th>
            <Th>Vital</Th>
            <Th>Value</Th>
            {/* <Th>Q</Th> */}
            {/* <Th>Priority</Th> */}
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items
            .filter((x) => x.type === "NEWS2")
            .filter((x) => x.value !== null)
            .filter((x, i) => {
              let patientPriorityMatch = true;
              const value = x?.value;
              const priority = getNEWS2ValueByScore(value);
              if (
                selectedKeyValue["priority"]?.value.length === 0 ||
                selectedKeyValue["priority"]?.value.includes(priority)
              ) {
                patientPriorityMatch = true;
              } else {
                patientPriorityMatch = false;
              }

              let patientNameMatch =
                x["user_uuid"] === selectedKeyValue["patient"]?.value;
              if (
                selectedKeyValue["patient"]?.value === "all" ||
                selectedKeyValue["patient"]?.value === "" ||
                selectedKeyValue["patient"]?.value === "clear"
              ) {
                patientNameMatch = true;
              }
              // console.log({ patientNameMatch, patientPriorityMatch });
              // console.log({
              //   selected: selectedKeyValue["priority"]?.value,
              //   priority,
              // });

              return patientPriorityMatch && patientNameMatch;
            })
            .map((a, i) => {
              // console.log({ i, a });

              let patient = patientsMap[a.user_uuid] || {};
              if (Object.keys(patient).length === 0) {
                return null;
              }

              let { first_name, last_name, email, code } = patient;
              let uName =
                first_name === null || first_name === undefined
                  ? `Patient ${code}`
                  : `${first_name} ${last_name}`;

              let pr = PR_MAP[a.priority];
              let color = pr?.color;
              let vitalValue = a.value;
              if (a.type === "blood_pressure") {
                let bpParts = vitalValue.split("/");
                vitalValue = parseInt(bpParts[0]);
                // console.log(bpParts);
              }
              let eventType =
                a?.is_audio_on == true
                  ? "Alarm condition"
                  : "Alarm inactivation";

              let vitalType = VITALS_NAMES_NAME[a?.type] || a?.type;
              if (a.type === "NEWS2") {
                color = a.color;
                // console.log({ color });
                eventType = "";
                vitalType = "NEWS2";
              }
              return (
                <Tr key={i} style={{ color: darkmode ? "white" : "" }}>
                  {/* <Td>{eventType}</Td> */}
                  <Td>{moment(a?.timestamp).format("YYYY-MM-DD")}</Td>
                  <Td>{moment(a?.timestamp).format("HH:mm:ss")}</Td>
                  <Td>{selectedCode}</Td>
                  <Td
                  // onClick={() => {
                  //   setSelectedPatientUUID(a?.user_uuid);
                  // }}
                  // style={{ cursor: "pointer" }}
                  >
                    {uName}
                  </Td>
                  <Td>{vitalType}</Td>
                  <Td
                    style={{
                      color: color,
                      fontWeight: "bold",
                    }}
                  >
                    {vitalValue}
                  </Td>
                  {/* <Td>{a?.quality}</Td> */}
                  {/*<Td>{a.limit}</Td>*/}
                  <Td>{a?.status}</Td>
                  {/*<Td>{a.status}</Td>*/}
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    );
  };

  const BetweenFlagTable = () => {
    return (
      <Table>
        <Thead>
          <Tr style={{ color: darkmode ? "white" : "" }}>
            <Th
            // clickable
            // onClick={(e) => {
            //   // setSorkKey("event-type");
            //   // setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
            //   setSortedItems(items);
            // }}
            >
              Event Type
              {sortKey === "event-type"
                ? sortOrder === "DES"
                  ? DES_ARROW
                  : ASC_ARROW
                : DEF_ARROW}
            </Th>
            <Th
            // clickable
            // onClick={(e) => {
            //   setSorkKey("date");
            //   setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
            // }}
            >
              Date
              {sortKey === "date"
                ? sortOrder === "DES"
                  ? DES_ARROW
                  : ASC_ARROW
                : DEF_ARROW}
            </Th>
            <Th
            // clickable
            // onClick={(e) => {
            //   setSorkKey("time");
            //   setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
            // }}
            >
              Time
              {sortKey === "time" ? (
                sortOrder === "DES" ? (
                  <img src={triangle} width={"14px"} height={"14px"} />
                ) : (
                  // DES_ARROW
                  // ASC_ARROW
                  <img src={triangle_up} width={"14px"} height={"14px"} />
                )
              ) : (
                DEF_ARROW
              )}
            </Th>
            <Th>Study</Th>
            <Th
            // clickable
            // onClick={(e) => {
            //   setSorkKey("patient");
            //   setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
            // }}
            >
              Patient
              {sortKey === "patient" ? (
                sortOrder === "DES" ? (
                  <img src={triangle} width={"14px"} height={"14px"} />
                ) : (
                  // DES_ARROW
                  // ASC_ARROW
                  <img src={triangle_up} width={"14px"} height={"14px"} />
                )
              ) : (
                DEF_ARROW
              )}
            </Th>
            <Th
            // clickable
            // onClick={(e) => {
            //   setSorkKey("vital");
            //   setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
            // }}
            >
              Vital
              {sortKey === "vital" ? (
                sortOrder === "DES" ? (
                  <img src={triangle} width={"14px"} height={"14px"} />
                ) : (
                  // DES_ARROW
                  // ASC_ARROW
                  <img src={triangle_up} width={"14px"} height={"14px"} />
                )
              ) : (
                DEF_ARROW
              )}
            </Th>
            <Th
            // clickable
            // onClick={(e) => {
            //   setSorkKey("value");
            //   setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
            // }}
            >
              Value
              {sortKey === "value" ? (
                sortOrder === "DES" ? (
                  <img src={triangle} width={"14px"} height={"14px"} />
                ) : (
                  // DES_ARROW
                  // ASC_ARROW
                  <img src={triangle_up} width={"14px"} height={"14px"} />
                )
              ) : (
                DEF_ARROW
              )}
            </Th>
            <Th>Q</Th>
            {/*<Th>Limit</Th>*/}
            <Th
            // clickable
            // onClick={(e) => {
            //   setSorkKey("priority");
            //   setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
            // }}
            >
              Priority
              {sortKey === "priority" ? (
                sortOrder === "DES" ? (
                  <img src={triangle} width={"14px"} height={"14px"} />
                ) : (
                  // DES_ARROW
                  // ASC_ARROW
                  <img src={triangle_up} width={"14px"} height={"14px"} />
                )
              ) : (
                DEF_ARROW
              )}
            </Th>
            <Th
            // clickable
            // onClick={(e) => {
            //   setSorkKey("status");
            //   setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
            // }}
            >
              Status
              {sortKey === "status" ? (
                sortOrder === "DES" ? (
                  <img src={triangle} width={"14px"} height={"14px"} />
                ) : (
                  // DES_ARROW
                  // ASC_ARROW
                  <img src={triangle_up} width={"14px"} height={"14px"} />
                )
              ) : (
                DEF_ARROW
              )}{" "}
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {goodItems.map((a, i) => {
            // {sortedItems.map((a, i) => {
            // console.log("Alarm item", a);

            let patient = patientsMap[a.user_uuid] || {};
            let { first_name, last_name, email, code } = patient;
            let uName =
              first_name === null || first_name === undefined
                ? `Patient ${code}`
                : `${first_name} ${last_name}`;
            let pr = PR_MAP[a.priority];
            let color = pr?.color;
            let vitalValue = a.value;
            if (a.type === "blood_pressure") {
              let bpParts = vitalValue.split("/");
              vitalValue = parseInt(bpParts[0]);
              // console.log(bpParts);
            }
            let eventType =
              a?.is_audio_on == true ? "Alarm condition" : "Alarm inactivation";

            let vitalType = VITALS_NAMES_NAME[a?.type] || a?.type;
            if (a.type === "NEWS2") {
              color = a.color;
              // console.log({ color });
              eventType = "";
              vitalType = "NEWS2";
              return null;
            }
            return (
              <Tr key={i} style={{ color: darkmode ? "white" : "" }}>
                <Td>{eventType}</Td>
                <Td>{moment(a?.timestamp).format("YYYY-MM-DD")}</Td>
                <Td>{moment(a?.timestamp).format("HH:mm:ss")}</Td>
                <Td>{selectedCode}</Td>
                <Td
                // onClick={() => {
                //   setSelectedPatientUUID(a?.user_uuid);
                // }}
                // style={{ cursor: "pointer" }}
                >
                  {uName}
                </Td>
                <Td>{vitalType}</Td>
                <Td
                  style={{
                    color: color,
                    fontWeight: "bold",
                  }}
                >
                  {vitalValue != undefined && window.isNaN(vitalValue) == false
                    ? a?.type !== "temperature"
                      ? Math.round(vitalValue)
                      : vitalValue.toFixed(1)
                    : vitalValue}
                  {/* ,{a.value} */}
                </Td>
                <Td>{a?.quality}</Td>
                {/*<Td>{a.limit}</Td>*/}
                <Td>{PRIORITY_NAMES_NAME[a?.priority]}</Td>
                {/*<Td>{a.status}</Td>*/}
                <Td>
                  {a?.is_audio_on == true
                    ? a?.priority === "low"
                      ? "Alarm on"
                      : "Audio on"
                    : ""}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    );
  };

  return (
    <Wrapper>
      <TopPlaceholder>
        <TopLeft>
          <ComplianceMonthPlaceholder>
            <ChevronImage
              className="chevron-icon"
              src={leftChevronImage}
              onClick={() => {
                setSelectedDayTimestamp(
                  +moment(selectedDayTimestamp).add(-1, "days").startOf("day")
                );
              }}
            />
            <MonthSpan
              style={{
                width: 120,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>{moment(selectedDayTimestamp).format("MMMM D")}</span>
            </MonthSpan>
            <ChevronImage
              src={leftChevronImage}
              className="chevron-icon"
              style={{ transform: `rotate(180deg)` }}
              onClick={() => {
                setSelectedDayTimestamp(
                  +moment(selectedDayTimestamp).add(1, "days").startOf("day")
                );
              }}
            />
          </ComplianceMonthPlaceholder>
        </TopLeft>

        <TopRight>
          <Select
            // isClearable
            placeholder={"Select department..."}
            options={options}
            value={options.find((x) => x.value == selectedCode)}
            onChange={(x) => setSelectedCode(x.value)}
          />
          {currentGroupUISetting &&
          currentGroupUISetting.includes("news") ? null : (
            <SelectorContainer>
              <Select
                isClearable
                placeholder={"Event"}
                styles={customStyles}
                options={eventsOptions}
                onChange={(x) =>
                  setSelectedKeyValue({
                    ...selectedKeyValue,
                    event: {
                      value: x ? x.value : "clear",
                    },
                  })
                }
              />
            </SelectorContainer>
          )}
          <SelectorContainer style={{ width: "150px" }}>
            <Select
              isClearable
              // menuIsOpen={true}
              placeholder={"All Patients"}
              options={uniquePatient}
              // styles={{ width: "150px" }}
              styles={customStyles}
              // value={}
              onChange={(x) =>
                setSelectedKeyValue({
                  ...selectedKeyValue,
                  patient: {
                    value: x ? x.value : "clear",
                  },
                })
              }
            />
          </SelectorContainer>
          <SelectorContainer>
            <Select
              isClearable
              contentEditable={false}
              placeholder={"All Vital"}
              options={uniqueVital}
              defaultInputValue={uniqueVital.length === 1 ? "NEWS2" : ""}
              // value={}
              styles={customStyles}
              onChange={(x) =>
                setSelectedKeyValue({
                  ...selectedKeyValue,
                  type: {
                    value: x ? x.value : "clear",
                  },
                })
              }
            />
          </SelectorContainer>
          <SelectorContainer>
            <Select
              className="alarm-select"
              classNamePrefix={"alarm-select"}
              isMulti
              isClearable
              placeholder={"All Priorities"}
              // options={uniquePriority}
              options={
                // currentGroupUISetting && currentGroupUISetting.includes("news")
                //   ? generateUniquePriority("news")
                //   : generateUniquePriority("bf")
                multiOptions
              }
              styles={customStyles}
              // value={uniquePriority.filter((x) =>
              //   selectedKeyValue.priority.value.includes(x.value)
              // )}
              // defaultValue={uniquePriority.find((x) => x.value === "default")}
              // defaultValue={(currentGroupUISetting &&
              // currentGroupUISetting.includes("news")
              //   ? generateUniquePriority("news")
              //   : generateUniquePriority("bf")
              // ).filter((x) => {
              //   // console.log(x);
              //   // console.log(currentGroupUISetting);
              //   const cond = x.value === "medium" || x.value === "high";
              //   return cond;
              // })}
              defaultValue={multiOptions.filter((x) => {
                // console.log(x);
                // console.log(currentGroupUISetting);
                const cond = x.value === "medium" || x.value === "high";
                return cond;
              })}
              onChange={(x) => {
                let _options = x.map((a) => a.value);
                setSelectedKeyValue({
                  ...selectedKeyValue,
                  priority: {
                    value: x ? _options : "clear",
                  },
                });
              }}
            />
          </SelectorContainer>
          {/* <SelectorTool
            currentSettings={currentGroupUISetting}
            generateUniquePriority={generateUniquePriority}
            setSelectedKeyValue={setSelectedKeyValue}
            selectedKeyValue={selectedKeyValue}
          /> */}
        </TopRight>
      </TopPlaceholder>

      {currentGroupUISetting && currentGroupUISetting.includes("news") ? (
        <Wrapper>{NEWS2Table()}</Wrapper>
      ) : (
        <Wrapper>{BetweenFlagTable()}</Wrapper>
      )}

      {/*<div>*/}
      {/*    Alarms*/}
      {/*</div>*/}
      {/*<div style={{maxHeight: '400px', overflowY: 'auto'}}>*/}
      {/*    <pre dangerouslySetInnerHTML={{__html: JSON.stringify(items, null, 2)}}></pre>*/}
      {/*</div>*/}

      {/*<div>*/}
      {/*    Patients map*/}
      {/*</div>*/}
      {/*<div style={{maxHeight: '400px', overflowY: 'auto'}}>*/}
      {/*    <pre dangerouslySetInnerHTML={{__html: JSON.stringify(patientsMap, null, 2)}}></pre>*/}
      {/*</div>*/}
      {selectedPatientUUID && selectedPatientUUID.length > 0 && (
        <NEWS2TrendPlot
          uuid={selectedPatientUUID}
          selectedDayTimestamp={selectedDayTimestamp}
          name={getPatientNameFromUUID(selectedPatientUUID)}
          onClose={() => {
            setSelectedPatientUUID("");
          }}
        />
      )}
    </Wrapper>
  );
}

const SelectorTool = (props) => {
  const {
    currentSettings,
    generateUniquePriority = () => {},
    setSelectedKeyValue,
    selectedKeyValue,
  } = props;

  const [setting, setSettings] = useState(currentSettings);
  const [options, setOptions] = useState(
    generateUniquePriority(currentSettings)
  );
  useEffect(() => {
    if (currentSettings) {
      setSettings(currentSettings);
      setOptions(generateUniquePriority(currentSettings));
    }
  }, [currentSettings, selectedKeyValue]);

  const defaultValues = generateUniquePriority(setting).filter((x) => {
    const cond = x.value === "medium" || x.value === "high";
    console.log({ cssetting: x.setting, cscond: cond });
    return cond;
  });

  return (
    <SelectorContainer>
      <Select
        id={`${moment().valueOf()}`}
        className="alarm-select-c"
        classNamePrefix={"alarm-select-c"}
        isMulti
        isClearable
        placeholder={"All Priorities"}
        options={options}
        // value={uniquePriority.filter((x) =>
        //   selectedKeyValue.priority.value.includes(x.value)
        // )}
        // defaultValue={uniquePriority.find((x) => x.value === "default")}
        // defaultValue={options} // not working
        defaultValue={defaultValues}
        // defaultValue={generateUniquePriority(setting).filter((x) => {
        //   // const cond = x.value === "medium" || x.value === "high";
        //   console.log({ xxx: x });
        //   return true;
        // })}
        onChange={(x) => {
          let _options = x.map((a) => a.value);
          setSelectedKeyValue({
            ...selectedKeyValue,
            priority: {
              value: x ? _options : "clear",
            },
          });
        }}
        styles={customStyles}
      />
    </SelectorContainer>
  );
};

const customStyles = {
  control: (provided) => ({
    ...provided,
    display: "flex",
    flexWrap: "nowrap",
    // borderColor: "hsl(0deg 78.56% 55.56%);",
    // width: "7em",
  }),
  menu: (provided) => ({
    ...provided,
    width: "100%",
  }),
  dropdownIndicator: (provided) => {
    // console.log(provided);
    return {
      ...provided,
      // width: "1rem",
      // display: "none",
    };
  },
  clearIndicator: (provided) => {
    // console.log(provided);
    return {
      ...provided,
      // display: "none",
    };
  },
  multiValueLabel: (provided, data) => {
    console.log({ multiValue: provided, multiData: data });
    console.log(data?.data?.setting);

    return {
      ...provided,
      backgroundColor: data.data.color,
      color: "#000",
    };
  },
  multiValueRemove: (provided, data) => {
    return {
      ...provided,
      // display: "none",
      backgroundColor: data.data.color,
    };
  },
};

const PRIORITY_NAMES_NAME = {
  low: "Low",
  medium: "Medium",
  high: "High",
  no_alarm: "No Alarm",
};

const VITALS_NAMES_NAME = {
  pulse_rate: "Pulse Rate",
  temperature: "Temperature",
  spo2: "SpO2",
  respiration_rate: "Respiration Rate",
  blood_pressure: "Systolic Blood Pressure",
  NEWS2: "NEWS2",
};

const PR_1 = {
  color: "#EA3223",
  priority: 10,
};

const PR_2 = {
  color: "#e5c347",
  priority: 9,
};

const PR_3 = {
  color: "#56c3c3",
  priority: 8,
};

const NO_PRIORITY = {
  color: "#8A98AB",
  priority: 0,
};

const PR_MAP = {
  low: PR_3,
  medium: PR_2,
  high: PR_1,
  no_alarm: NO_PRIORITY,
};

const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr``;

const Th = styled.th`
  border: 1px solid lightgrey;
  text-align: left;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 3px;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;

const ComplianceMonthPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #8798ad;
  font-size: 18px;
  line-height: 24px;
`;

const ChevronImage = styled.img`
  height: 12px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const MonthSpan = styled.span`
  margin-left: 28px;
  margin-right: 28px;
  font-weight: bold;
  width: 110px;
  text-align: center;
`;

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopLeft = styled.div``;

const TopRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SelectorContainer = styled.div`
  // width: 150px;
  flex: 1;
  margin-left: 1rem;
`;

const getColorByScore = (score) => {
  if (score === undefined || score === null) {
    return "white";
  }
  if (+score > 6) {
    return "#EB9079";
  } else if (score > 4) {
    return "#F6C387";
  } else if (score === 3 || score === 4) {
    return "#f2d546";
  } else {
    return "#ccc";
  }
};

const getNEWS2StatusByScore = (score) => {
  if (score === undefined || score === null) {
    return "";
  }
  if (+score > 6) {
    return "High Risk";
  } else if (score > 4) {
    return "Medium Risk";
  } else if (score === 3 || score === 4) {
    return "Low-Medium Risk";
  } else if (score < 3) {
    return "Low Risk";
  } else {
    return "";
  }
};

const getNEWS2ValueByScore = (score) => {
  if (score === undefined || score === null) {
    return "";
  }
  if (+score > 6) {
    return "high";
  } else if (score > 4) {
    return "medium";
  } else if (score === 3 || score === 4) {
    return "low-medium";
  } else if (score < 4) {
    return "low";
  } else {
    return "";
  }
};

const createHalfHourTimestamp = (date) => {};
const NEWS2HistoryToHalfHourSlots = (NEWS2Data) => {
  console.log(NEWS2Data);
};

const NEWS2TrendPlot = (props) => {
  const { uuid, selectedDayTimestamp, name = "", onClose = () => {} } = props;
  const trendDateFormat = "MMMDD";
  const [dailyAverageNEWS2Score, setDailyAverageNEWS2Score] = useState({});

  const past30Days = Array.from({ length: 30 }, (_, i) =>
    moment().subtract(i, "days").format(trendDateFormat)
  );

  useEffect(() => {
    setDailyAverageNEWS2Score({});
    const dailyTrends = {};
    past30Days.forEach((a, i) => {
      dailyTrends[a] = { sum: 0, count: 0, mean: 0, scoreList: [] };
    });
    try {
      const news2TrendTo = moment(selectedDayTimestamp).add(1, "day").valueOf();
      const news2TrendFrom = moment(selectedDayTimestamp)
        .subtract(30, "day")
        .valueOf();
      const _endpoint = `${"https://api.study-integration.corsano.com"}/users/${uuid}/news-score-history`;
      axios
        .get(_endpoint, {
          params: {
            from: news2TrendFrom,
            to: news2TrendTo,
          },
        })
        .then((response) => {
          console.log({ response });
          const data = response.data;
          data.forEach((a, i) => {
            const { value, timestamp } = a;
            const curerntDay = moment(timestamp).format(trendDateFormat);
            // console.log({ curerntDay, d: dailyTrends[curerntDay] });
            if (!dailyTrends[curerntDay]) {
              return;
            }

            if (value !== null && value !== undefined && value > 0) {
              dailyTrends[curerntDay].sum += value;
              dailyTrends[curerntDay].count += 1;
            }
            dailyTrends[curerntDay].scoreList.push({ timestamp, value });
          });
        })
        .then(() => {
          past30Days.forEach((p) => {
            const _mean = dailyTrends[p].sum / dailyTrends[p].count;
            // console.log({ sum: dailyTrends[p].sum, p: dailyTrends[p].count });
            // console.log({ _mean, p });
            dailyTrends[p].mean = _mean;
          });
          console.log(dailyTrends);
        });
    } catch (e) {
      console.error("error:", e);
    } finally {
      setDailyAverageNEWS2Score(dailyTrends);
    }
  }, [uuid]);

  console.log({ dailyAverageNEWS2Score });

  return (
    <NiceModal onClose={onClose}>
      <div
        style={{ margin: "0 auto", textAlign: "center" }}
        onClick={() => {
          // NEWS2HistoryToHalfHourSlots(news2HistoryMap[selectedPatientUUID]);
        }}
      >
        NEWS2 Score History for {name}
        {` on ${moment(selectedDayTimestamp).format("MMM Do")}`}
      </div>
      <div style={{ height: 200, width: 500 }}>
        {/* <LineChart
          width={500}
          height={200}
          margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          data={news2HistoryMap[selectedPatientUUID]}
        >
          <XAxis
            type="number"
            dataKey={"timestamp"}
            domain={[
              selectedDayTimestamp,
              selectedDayTimestamp + 60 * 60 * 1000 * 24,
            ]}
            ticks={[
              selectedDayTimestamp,
              selectedDayTimestamp + 60 * 60 * 1000 * 6,
              selectedDayTimestamp + 60 * 60 * 1000 * 12,
              selectedDayTimestamp + 60 * 60 * 1000 * 18,
              selectedDayTimestamp + 60 * 60 * 1000 * 24,
            ]}
            tickFormatter={(x) => {
              return moment(+x).format("Do HH:mm");
            }}
          />

          <YAxis
            domain={([min, max]) => {
              return [0, max > 9 ? max : 9];
            }}
            dataKey={"value"}
            allowDecimals={false}
          />
          <Line
            type="linear"
            dataKey="value"
            stroke={"#11111100"}
            connectNulls={false}
            animationDuration={0.1}
            dot={(dotProps) => {
              const { cx, cy, payload } = dotProps;
              const v = payload.value;

              if (v === null) {
                return null;
              }
              let dotColor = "#ccc";
              if (v === 5 || v === 6) {
                dotColor = "#F6C387";
              } else if (v > 6) {
                dotColor = "#EB9079";
              }

              return (
                <circle
                  key={`${cx}${cy}`}
                  cx={cx}
                  cy={cy}
                  r={3}
                  stroke={dotColor}
                  fill={dotColor}
                />
              );
            }}
          />
          <Tooltip
            content={({ active, payload, label }) => {
              if (active && payload && payload.length) {
                const score = payload[0]?.value;
                const ts = payload[0]?.payload?.timestamp;
                // console.log(payload[0]);

                const date = moment(ts).format("Do HH:mm");
                return (
                  <div
                    className="custom-tooltip"
                    style={{ background: "#fff", padding: 10 }}
                  >
                    <div className="label">{`NEWS2 Score : ${score}`}</div>
                    <div className="label">{`Date: ${date}`}</div>
                  </div>
                );
              }

              return null;
            }}
          />
          <ReferenceArea
            x1={selectedDayTimestamp}
            x2={selectedDayTimestamp + 60 * 60 * 1000 * 24}
            y1={5}
            y2={7}
            stroke="#F6C387"
            fill="#F6C387"
            strokeOpacity={0.3}
            ifOverflow={"hidden"}
          />
          <ReferenceArea
            x1={selectedDayTimestamp}
            x2={selectedDayTimestamp + 60 * 60 * 1000 * 24}
            y1={7}
            y2={20}
            strokeOpacity={0.3}
            stroke="#EB9079"
            ifOverflow={"hidden"}
            fill="#EB9079"
          />
        </LineChart> */}
      </div>
      <div
        style={{
          textDecoration: "underline",
          textAlign: "center",
          fontWeight: 500,
          cursor: "pointer",
        }}
        onClick={() => {
          const departmentOrStudy = window.location.href.includes("study")
            ? "studies"
            : "departments";
          CommonHelper.linkTo(
            `${departmentOrStudy}/${selectedCode}/user/${selectedPatientUUID}`
          );
        }}
      >
        Direct To User
      </div>
    </NiceModal>
  );
};
