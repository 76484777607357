import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import styled from "styled-components";
import GithubSleepSessionsView from "../GithubSleepSessionsView";

import * as actions from "../../../redux/actions/DataActions";
import InlineDateSelector from "../../dates/tools/InlineDateSelector";
import SleepAPI from "../../../api/SleepAPI";
import SleepViewTool from "../tools/SleepViewTool";
import HeartRateAPI from "../../../api/HeartRateAPI";
import SimpleBpmSlotsChart from "../../heart_rate/tools/SimpleBpmSlotsChart";
import SevenDaysSleepChartTool from "../tools/SevenDaysSleepChartTool";
import SevenDaysSleepScheduleTool from "../tools/SevenDaysSleepScheduleTool";
import CommonHelper from "../../../helpers/CommonHelper";
import { Code } from "react-content-loader";
import SimpleNoDataMessageTool from "../tools/SimpleNoDataMessageTool";
import RonasBasicAPI from "../../../api/RonasBasicAPI";
import SleepGoalsTool from "../tools/SleepGoalsTool";

const getStartAndEndOfSleep = (sleepSlots, t0 = +new Date()) => {
  if (sleepSlots.length == 0) {
    return {
      from: +moment(t0).startOf("day"),
      to: +moment(t0).startOf("day").add(8, "hours"),
    };
  }
  // let start = +new Date(sleepSlots[0].start_date);
  // let end = +new Date(sleepSlots[sleepSlots.length - 1].end_date);
  let start = CommonHelper.getTimestampFromDateString(sleepSlots[0].start_date);
  let end = CommonHelper.getTimestampFromDateString(
    sleepSlots[sleepSlots.length - 1].end_date
  );

  return {
    from: start,
    to: end,
  };
};

export default function SleepPanel(props) {
  let { currentUserObj, loading, updatedTimestamp, selectedTimestamp, goals } =
    useMappedState(
      useCallback((state) => {
        return {
          currentUserObj: state.auth.currentUserObj,
          loading: state.auth.loading || state.data.loading,
          updatedTimestamp: state.data.updatedTimestamp,
          selectedTimestamp: state.data.selectedTimestamp,
          goals:
            state.auth.currentUserObj?.goals == undefined
              ? []
              : state.auth.currentUserObj?.goals,
        };
      }, [])
    );
  const dispatch = useDispatch();
  const [yearTimestamp, setYearTimestamp] = useState(+moment().startOf("year"));
  const [selectedDaySlots, setSelectedDaySlots] = useState([]);
  const [selectedDayHeartRateSlots, setSelectedDayHeartRateSlots] = useState();
  const [heartRateFetching, setHeartRateFetching] = useState(false);

  const [sleepDayData, setSleepDayData] = useState(undefined);

  useEffect(() => {
    // console.log("---((((");
    // dispatch(actions.loadYearSummaries(yearTimestamp));
    dispatch(actions.selectDay(undefined));
    dispatch(actions.loadYearSummaries(yearTimestamp)).then((yPld) => {
      let pts = (yPld?.data ? yPld.data : [])
        .filter((x) => x.sleep != undefined)
        .sort((a, b) => +new Date(a.date) - +new Date(b.date));
      // console.log("--->>> pts = ", pts);
      if (pts.length > 0) {
        dispatch(actions.selectDay(+new Date(pts[pts.length - 1].date)));
      }
    });
  }, [+moment(yearTimestamp).startOf("year")]);

  // useEffect(() => {
  //     console.log('---((((');
  //     dispatch(actions.loadYearSummaries(yearTimestamp)).then(yPld => {
  //         let pts = (yPld?.data ? yPld.data : []).filter(x => x.sleep != undefined).sort((a, b) => (+new Date(a.date) - +new Date(b.date)));
  //         console.log('--->>> pts = ', pts);
  //         if (pts.length > 0) {
  //             dispatch(actions.selectDay(+new Date(pts[pts.length - 1].date)));
  //         }
  //     });
  // }, []);

  useEffect(() => {
    let mounted = true;
    if (loading == true || selectedTimestamp == undefined) {
      return;
    }
    // console.log(
    //   "loading main data! loading, selectedTimestamp = ",
    //   loading,
    //   selectedTimestamp
    // );
    setHeartRateFetching(true);
    RonasBasicAPI.getInstance()
      .getUserSummariesFor7DaysFromCloud(selectedTimestamp, true)
      .then(() => {
        if (mounted == false) {
          return;
        }
        SleepAPI.getInstance()
          .getSleepForDayFromCache(selectedTimestamp)
          .then((d) => {
            // console.log(
            //   "getSleepForDayFromCache: selectedTimestamp, d = ",
            //   selectedTimestamp,
            //   d
            // );
            if (mounted == false) {
              return;
            }
            if (d?.slots == undefined) {
              setSelectedDaySlots([]);
            } else {
              setSelectedDaySlots(d.slots);
            }
            HeartRateAPI.getInstance()
              .getHeartRateSlotsFromYesterdayAndToday(selectedTimestamp)
              .then((hrSlots) => {
                if (mounted == false) {
                  return;
                }
                setSelectedDayHeartRateSlots(hrSlots);
              });
            SleepAPI.getInstance()
              .getSleepForDayFromCloud(selectedTimestamp)
              .then((d2) => {
                if (mounted == false) {
                  return;
                }
                // console.log("d2 = ", d2);
                setSleepDayData(d2);
                if (d2?.slots == undefined) {
                  setSelectedDaySlots([]);
                } else {
                  setSelectedDaySlots(d2.slots);
                }
                HeartRateAPI.getInstance()
                  .getHeartRateSlotsFromYesterdayAndToday(selectedTimestamp)
                  .then((hrSlots) => {
                    if (mounted == false) {
                      return;
                    }
                    // console.log("--->>> hrSlots = ", hrSlots);
                    setSelectedDayHeartRateSlots(hrSlots);
                    setHeartRateFetching(false);
                  });
              });
          });
      });
    return () => {
      mounted = false;
    };
  }, [selectedTimestamp, loading]);

  let isSomethingLoading = heartRateFetching || loading;

  let sleepBounds = getStartAndEndOfSleep(selectedDaySlots, selectedTimestamp);
  // console.log("sleepBounds = ", sleepBounds);

  return (
    <Wrapper>
      <YearPlaceholder>
        <GithubSleepSessionsView
          loading={loading}
          onYearTimestampChange={(t) => {
            if (isSomethingLoading == true) {
              return;
            }
            setYearTimestamp(t);
          }}
          onDayClick={(t) => {
            if (isSomethingLoading == true) {
              return;
            }
            dispatch(actions.selectDay(t));
          }}
        />
      </YearPlaceholder>

      {selectedTimestamp == undefined ? (
        <>
          {isSomethingLoading == true ? (
            <>
              <CurrentDaySleepChartPlaceholder>
                <Code />
              </CurrentDaySleepChartPlaceholder>
              <CurrentDaySleepChartPlaceholder>
                <Code />
              </CurrentDaySleepChartPlaceholder>
            </>
          ) : null}
        </>
      ) : (
        <React.Fragment>
          <MainContentPlaceholder>
            <MainContentHeading>
              <TopSleepHeadingName>Sleep summary</TopSleepHeadingName>

              <InlineDateSelector
                loading={heartRateFetching || loading}
                selectedTimestamp={selectedTimestamp}
                onDaySelect={(t) => {
                  // console.log("InlineDateSelector: selectDay: t = ", t);
                  dispatch(actions.selectDay(t));
                }}
              />
            </MainContentHeading>

            <CurrentDaySleepChartPlaceholder
              className={`${loading}_c-${yearTimestamp}_${selectedTimestamp}`}
              key={`qqr${yearTimestamp}_${selectedTimestamp}`}
            >
              {isSomethingLoading == true ? (
                <Code />
              ) : (
                <>
                  {selectedDaySlots.length == 0 ? (
                    <SimpleNoDataMessageTool
                      message={"Sorry, there is no sleep data for this day."}
                    />
                  ) : (
                    <SleepViewTool slots={selectedDaySlots} loading={loading} />
                  )}
                </>
              )}
            </CurrentDaySleepChartPlaceholder>

            <HeartRateChartPlaceholder
              key={`zz${yearTimestamp}_${selectedTimestamp}`}
            >
              {isSomethingLoading == true ? (
                <Code />
              ) : (
                <>
                  {selectedDayHeartRateSlots == undefined ||
                  selectedDayHeartRateSlots.length == 0 ? (
                    <SimpleNoDataMessageTool
                      message={
                        "Sorry, there is not hear rate data for this day."
                      }
                    />
                  ) : (
                    <SimpleBpmSlotsChart
                      slots={selectedDayHeartRateSlots}
                      min={sleepBounds.from}
                      max={sleepBounds.to}
                    />
                  )}
                </>
              )}
            </HeartRateChartPlaceholder>
          </MainContentPlaceholder>

          <RowPlaceholder></RowPlaceholder>
        </React.Fragment>
      )}

      <RowPlaceholder>
        <Half>
          <TopSleepHeadingName style={{ marginBottom: 10 }}>
            Sleep Goals
          </TopSleepHeadingName>

          <div
            style={{
              boxSizing: "border-box",
              paddingRight: 20,
              marginBottom: 20,
            }}
          >
            {isSomethingLoading == true ? (
              <>
                <Code />
                <Code />
              </>
            ) : (
              <SleepGoalsTool goals={goals} {...sleepDayData} />
            )}
          </div>

          <TopSleepHeadingName style={{ marginBottom: 10 }}>
            Sleep Time
          </TopSleepHeadingName>
          <SevenDaysChartPlaceholder>
            {isSomethingLoading == true ? (
              <Code />
            ) : (
              <>
                {selectedTimestamp == undefined ? (
                  <SimpleNoDataMessageTool message={"Please select day"} />
                ) : (
                  <SevenDaysSleepChartTool
                    loading={loading}
                    dayTimestamp={selectedTimestamp}
                    transformValue={(x) => +(+x / 3600.0).toFixed(1)}
                  />
                )}
              </>
            )}
          </SevenDaysChartPlaceholder>
        </Half>

        <Half>
          <TopSleepHeadingName style={{ marginBottom: 20 }}>
            Sleep Performance
          </TopSleepHeadingName>
          <SevenDaysChartPlaceholder>
            {isSomethingLoading == true ? (
              <Code />
            ) : (
              <>
                {selectedTimestamp == undefined ? (
                  <SimpleNoDataMessageTool message={"Please select day"} />
                ) : (
                  <SevenDaysSleepChartTool
                    loading={loading}
                    param={"performance"}
                    dayTimestamp={selectedTimestamp}
                  />
                )}
              </>
            )}
          </SevenDaysChartPlaceholder>

          <TopSleepHeadingName style={{ marginBottom: 20 }}>
            Sleep Schedule
          </TopSleepHeadingName>
          <SevenDaysChartPlaceholder>
            {isSomethingLoading == true ? (
              <Code />
            ) : (
              <>
                {selectedTimestamp == undefined ? (
                  <SimpleNoDataMessageTool message={"Please select day"} />
                ) : (
                  <SevenDaysSleepScheduleTool
                    loading={loading}
                    dayTimestamp={selectedTimestamp}
                  />
                )}
              </>
            )}
          </SevenDaysChartPlaceholder>
        </Half>
      </RowPlaceholder>
    </Wrapper>
  );
}

const SevenDaysChartPlaceholder = styled.div`
  margin-bottom: 20px;
  height: 200px;
`;

const RowPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

const Half = styled.div`
  flex: 1;
  box-sizing: border-box;

  :first-of-type {
    padding-right: 15px;
  }

  :last-of-type {
    padding-right: 15px;
  }
`;

const HeartRateChartPlaceholder = styled.div`
  height: 200px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
`;

const CurrentDaySleepChartPlaceholder = styled.div`
  height: 200px;
  margin-top: 10px;
`;

const MainContentHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopSleepHeadingName = styled.div`
  font-weight: bold;
`;

const MainContentPlaceholder = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Wrapper = styled.div``;

const YearPlaceholder = styled.div``;
