import { useEffect, useState } from "react";
import ls from "local-storage";
import styled from "styled-components";
import moment from "moment";
import {
  AXES_COLORX,
  AXES_STROKE_WIDTH,
  GRID_DASH_ARRAY,
  GRID_STROKE_COLOR,
  GRID_THICKNESS,
} from "../../ui/templates";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  Area,
  ReferenceDot,
  ReferenceLine,
  Bar,
  BarChart,
  Rectangle,
} from "recharts";
import useDimensions from "react-use-dimensions";
import { STEP_BAR_COLOR } from "../../ui/templates/Charts";

const getParamsOptions = (name) => {
  if (name == "activity") {
    return [
      {
        label: "Steps",
        value: "total_steps",
        type: "bar",
      },
      {
        label: "Distance",
        value: "distance",
        type: "bar",
      },
      {
        label: "Calories",
        value: "calories",
        type: "bar",
      },
    ];
  }
  if (name == "intab_activity") {
    // for activity data within tab
    return [
      {
        label: "Steps",
        value: "total_steps",
        type: "bar",
      },
    ];
  }
  if (name == "spo2") {
    // for spo2 summary data within tab
    return [
      {
        label: "SpO2",
        value: "avg_spo2",
        type: "line",
      },
    ];
  }
  if (name == "temperature") {
    // for temperature summary data within tab
    return [
      {
        label: "Temperature",
        value: "avg_temp_sk1",
        type: "line",
      },
    ];
  }
  if (name == "sleep") {
    return [
      {
        label: "Sleep duration",
        value: "sleep_duration",
        type: "line",
        transform: (x) => ({
          ...x,
          sleep_duration:
            x.sleep_duration == undefined
              ? null
              : +x.sleep_duration / 60.0 / 60.0,
        }),
      },
      {
        label: "Performance",
        value: "performance",
        type: "line",
      },
      {
        label: "Consistency",
        value: "consistency",
        type: "line",
      },
    ];
  }
  if (name == "respiration_rate") {
    return [
      {
        label: "Average respiration rate",
        value: "avg_respiration_rate",
        type: "line",
      },
    ];
  }
  if (name == "heart_rate") {
    // for overview tab
    return [
      {
        label: "Average daily heart rate",
        value: "avg_daily_heart_rate",
        type: "line",
      },
      {
        label: "Max daily heart rate",
        value: "max_daily_heart_rate",
        type: "line",
      },
      {
        label: "Resting heart rate",
        value: "rest_daily_heart_rate",
        type: "line",
      },
    ];
  }
  if (name == "pulse_rate") {
    // for pulse rate tab
    return [
      {
        label: "Average daily heart rate",
        value: "avg_daily_heart_rate",
        type: "line",
      },
    ];
  }
  if (name == "stress") {
    return [
      {
        label: "Average HRV",
        value: "avg_rmssd",
        type: "line",
      },
      {
        label: "Readiness",
        value: "readiness",
        type: "bar",
        customColor: true,
        barColor: (v) =>
          +v > 66.0 ? "#019C46" : +v > 33 ? "#F4C142" : "#C40118",
      },
      {
        label: "Average stress index",
        value: "avg_si",
        type: "line",
      },
    ];
  }
  return [];
};

const getXTicks = (xPoints, selectedOption) => {
  let xticks = [];
  let interval = 3;
  let _length = xPoints.length;
  switch (selectedOption) {
    case "W":
      interval = 1;
      break;
    case "M":
      interval = 2;
      break;
    case "3M":
      interval = 10;
      break;
    case "MTD":
      interval = 2;
      break;
    case "6M":
      interval = 15;
      break;
    case "YTD":
      interval = 30;
      break;
    case "1Y":
      interval = 30;
      break;
    default:
      interval = 1;
  }

  // Iterate through the source array in steps of 'n'
  for (let i = 0; i < xPoints.length; i += interval) {
    xticks.push(xPoints[i]?.date);
  }
  return xticks;
};

const CustomBar = (props) => {
  const { fill, fillColor } = props;
  // console.log('CustomBar: props = ', props);
  let xFill = fill;
  if (fillColor != undefined) {
    xFill = fillColor;
  }

  //business logic here to update fill color explicitly
  // if(years === 'Current') {
  //     fill = '#NewFillColor';
  // }

  //use explicit fill here, or use the additional css class and make a css selector to update fill there
  return (
    <Rectangle {...props} fill={xFill} className={`recharts-bar-rectangle`} />
  );
};

const DEFAULT_BAR_COLOR = STEP_BAR_COLOR;

const typesShownInFullWidth = [
  "pulse_rate",
  "intab_activity",
  "spo2",
  "respiration_rate",
];
export default function SmartTrendsChart(props) {
  const {
    loading = false,
    dataType = "activity",
    points = [],
    type = "line", // one of bar/line
    selectedOption = "M",
  } = props;

  // console.log(dataType);
  // console.log("smart trends chart points",points);

  const [ref, { x, y, width, height }] = useDimensions();
  const theme = ls.get("theme");

  const paramsOptions = getParamsOptions(dataType);
  const [param, setParam] = useState(
    paramsOptions.length == 0 ? undefined : paramsOptions[0]
  );

  useEffect(() => {
    const paramsOptions = getParamsOptions(dataType);
    setParam(paramsOptions.length == 0 ? undefined : paramsOptions[0]);
  }, [dataType]);

  if (paramsOptions.length == 0) {
    return null;
  }

  if (!points) {
    return null;
  }

  if (points.length == 0 && loading == false) {
    return <NoDataPlaceholder>No data to display</NoDataPlaceholder>;
  }

  let xPoints = JSON.parse(JSON.stringify(points));

  if (dataType === "heart_rate" || dataType === "pulse_rate") {
    xPoints = xPoints.map((x) => {
      return {
        ...x,
        avg_daily_heart_rate:
          x.avg_daily_heart_rate == 0 ? null : x.avg_daily_heart_rate,
        rest_daily_heart_rate:
          x?.rest_daily_heart_rate == 0 ? null : x?.rest_daily_heart_rate,
      };
    });
  }
  if (dataType === "temperature") {
    xPoints = xPoints.map((x) => {
      return {
        ...x,
        avg_temp_sk1: x.avg_temp_sk1 == 0 ? null : x.avg_temp_sk1,
      };
    });
  }
  if (dataType === "respiration_rate") {
    xPoints = xPoints.map((x) => {
      return {
        ...x,
        avg_respiration_rate:
          x.avg_respiration_rate == 0 ? null : x.avg_respiration_rate,
      };
    });
  }
  // console.log("PARAMS", paramsOptions, param, dataType);
  // console.log(xPoints);

  let barColorFun =
    param == undefined ||
    param?.barColor == undefined ||
    typeof param?.barColor != "function"
      ? (x_) => DEFAULT_BAR_COLOR
      : (x_) => (x_ == undefined ? DEFAULT_BAR_COLOR : param?.barColor(x_));

  let xticks = [];
  // console.log(xPoints);
  if (xPoints.length > 20) {
    xticks = getXTicks(xPoints, selectedOption);
  } else {
    xticks = xPoints.map((x) => x.date);
  }
  // console.log("xticks", xticks, xPoints.length);

  if (param?.type == "bar") {
    xPoints = xPoints.map((xx) => ({
      ...xx,
      fillColor: barColorFun(xx[param?.value]),
    }));
  }
  if (param?.transform != undefined) {
    xPoints = xPoints.map((x) => param.transform(x));
    // console.log('xPoints after transform = ', xPoints);
    // transform
  }
  // console.log(xPoints);

  // console.log("render: param = ", width);

  return (
    <Wrapper>
      <ChartPlaceholder ref={ref}>
        {param?.type != "line" ? null : (
          <LineChart
            className="pr-trend-chart"
            width={width}
            height={height}
            data={xPoints}
            connectNulls={false}
            margin={{ top: 5, right: 5, left: -20, bottom: 5 }}
          >
            <CartesianGrid
              stroke={GRID_STROKE_COLOR}
              strokeWidth={GRID_THICKNESS}
              strokeDasharray={GRID_DASH_ARRAY}
            />
            <Line
              stroke={"#ff1f1fcc"}
              type="monotone"
              dataKey={param?.value}
              strokeWidth={2}
              animationDuration={2}
              dot={true}
            />
            <XAxis
              stroke={AXES_COLORX(theme)}
              // stroke={"#777"}
              strokeWidth={AXES_STROKE_WIDTH}
              dataKey="date"
              interval={typesShownInFullWidth.includes(dataType) ? 0 : 2}
              ticks={xticks.slice(0, xticks.length - 1)}
              // tickCount={12}
              // overflow={false/}
              // allowDataOverflow={false}
            />
            <YAxis
              // stroke={"#777"}
              stroke={AXES_COLORX(theme)}
              strokeWidth={AXES_STROKE_WIDTH}
              // domain={dataType === "temperature" ? [33, 45] : []}
              domain={([min, max]) => {
                let _min = min.toFixed(0);
                let _max = max.toFixed(0);
                if (dataType === "temperature") {
                  return [_min < 34 ? _min : 34, _max > 40 ? _max : 40];
                }
                if (dataType === "spo2") {
                  return [_min < 80 ? _min : 80, 100];
                }
                return [0, Math.ceil(max.toFixed(1)) + 1];
              }}
            />
          </LineChart>
        )}

        {param?.type != "bar" ? null : (
          <BarChart
            width={width}
            height={height}
            data={xPoints}
            margin={{
              top: 5,
              right: 10,
              left: -20,
              bottom: 5,
            }}
            animationDuration={0}
          >
            <CartesianGrid
              stroke={GRID_STROKE_COLOR}
              strokeWidth={GRID_THICKNESS}
              strokeDasharray={GRID_DASH_ARRAY}
            />
            <XAxis
              dataKey="date"
              tickCount={xticks.length}
              // interval={0}
              interval={typesShownInFullWidth.includes(dataType) ? 0 : 2}
              ticks={xticks}
              stroke={AXES_COLORX(theme)}
              strokeWidth={AXES_STROKE_WIDTH}
            />
            <YAxis
              stroke={AXES_COLORX(theme)}
              strokeWidth={AXES_STROKE_WIDTH}
            />
            <Bar
              shape={CustomBar}
              {...param}
              customColor={param.customColor}
              dataKey={param?.value}
              fill={DEFAULT_BAR_COLOR}
              animationDuration={0.0}
            />
            {/* <ReferenceLine y={0} stroke="#000" /> */}
          </BarChart>
        )}

        {/*{JSON.stringify(points)}*/}
      </ChartPlaceholder>
      {paramsOptions.length < 2 ? null : (
        <BottomSwitcherPlaceholder>
          {paramsOptions.map((a, i) => {
            return (
              <OptItem
                key={i}
                theme={theme}
                selected={param?.value == a.value}
                onClick={(x) => {
                  setParam(a);
                }}
              >
                {a.label}
              </OptItem>
            );
          })}
        </BottomSwitcherPlaceholder>
      )}
    </Wrapper>
  );
}

const NoDataPlaceholder = styled.div`
  width: 100%;
  height: 320px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OptItem = styled.div`
  box-sizing: border-box;
  margin-right: 10px;
  cursor: pointer;
  color: ${(props) =>
    props.selected
      ? props.theme === "dark"
        ? "white"
        : "black"
      : props.theme === "dark"
      ? "#ccc"
      : "black"};
  border-bottom: ${(props) =>
    props.selected == true ? "2px solid #4aa4e3" : "2px solid transparent"};
  font-weight: ${(props) => (props.selected == true ? "bold" : "default")};
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const ChartPlaceholder = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 320px;
`;

const BottomSwitcherPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  justify-content: center;
`;
