import MMTCloud from "./MMTCloud";
import RonasBasicAPI from "./RonasBasicAPI";
import TarnsformerHelper from "../helpers/TarnsformerHelper";
import moment from "moment";
import * as ls from 'local-storage'
import axios from "axios";

class StudySessionsAPI {

    private static instance: StudySessionsAPI
    private cloud?: MMTCloud;
    private ronasBasicAPI: RonasBasicAPI;

    constructor() {
        this.cloud = MMTCloud.getInstance();
        this.ronasBasicAPI = RonasBasicAPI.getInstance();
    }

    public static getInstance(): StudySessionsAPI {
        if (!StudySessionsAPI.instance) {
            StudySessionsAPI.instance = new StudySessionsAPI();
        }
        return StudySessionsAPI.instance;
    }

    public async getBinaryMeasurements(uuid: string, isDemo: boolean, signal: AbortSignal) {
        let health_token = ls.get('health_cloud_token');
        let users_token = ls.get<string>('users_cloud_token');
        let arr = (await axios.get(`https://api.study-integration.corsano.com/health/user/${uuid}/binary-measurements`, {
            params: {
                // health_token: health_token,
                // users_token: users_token,
                demo: (isDemo == true) ? 1 : undefined
            },
            signal: signal
        })).data.result;
        // console.log('getBinaryMeasurements: arr = ', arr);
        return arr;
    }

    public async getBinaryMeasurementFileUrl(id: string, sequence_timestamp: number) {
        let health_token = ls.get('health_cloud_token');
        // return `https://api.health.cloud.corsano.com/binary-measurements/${id}/${sequence_timestamp}`;
        let pld = (await axios.get(`https://api.study-integration.corsano.com/health/binary-measurements/${id}/${sequence_timestamp}`, {
            params: {
                // health_token: health_token
            }
        })).data;
        return pld;
    }

    public async getContinuousMeasurements(userId: string, type: string, from: number, to: number) {
        let health_token = ls.get('health_cloud_token');
        // return `https://api.health.cloud.corsano.com/binary-measurements/${id}/${sequence_timestamp}`;
        let pld = (await axios.get(`https://api.study-integration.corsano.com/health/user/${userId}/continuous-measurements/${type}/${from}/${to}`, {
            params: {
                health_token: health_token
            }
        })).data.result.data;
        return pld;
    }

    public getContinuousMeasurementFileUrl(userId: string, type: string, start_timestamp: number) {
        return `https://api.study-integration.corsano.com/health/user/${userId}/continuous-measurements-file/${type}/${start_timestamp}`;
    }

    public getContinuousMeasurementsRangeFileUrl(userId: string, type: string, from: number, to: number, types: any[] = []) {
        let typesString = types.map(x => `types[]=${x}`).join('&');
        let s = `https://api.study-integration.corsano.com/health/user/${userId}/continuous-measurements/${type}/${from}/${to}/zip`;
        if (types.length > 0) {
            s = `${s}?${typesString}`;
        }
        return s;
    }

    public getContinuousMeasurementsPPG2FileUrl(userId: string, type: string, from: number, to: number, types: any[] = [], name: string = '') {
        let s = `https://study.corsano.com/export-api/users/${userId}/${type}/${from}/${to}`;
        console.log('getContinuousMeasurementsPPG2FileUrl: types = ', types);
        if (types.length > 0) {
            let typesString = types.map(x => `types[]=${x}`).join('&');
            s = `${s}?${typesString}`;
        }
        if (name != '') {
            if (types.length > 0) {
                s = `${s}&name=${name}`;
            } else {
                s = `${s}?name=${name}`;
            }
        }
        return s;
    }

    public getContinuousMeasurementsSinglePPG2FileUrl(userId: string, type: string, t: number, name: string = '') {
        let s = `https://study.corsano.com/export-api/users/${userId}/type/${type}/t/${t}`;
        if (name != '') {
            s = `${s}?name=${name}`
        }
        return s;
    }

}

export default StudySessionsAPI;