import axios from "axios";
import { API_ENDPOINT, CORSANO_API_ENDPOINT } from "../constants/config";

const CalcAPI = {
  async calcParams(rrs = [], signal) {
    const xRrs = rrs.filter((x) => x !== undefined);
    const pld = (
      await axios.post(
        `${API_ENDPOINT}/calc/params`,
        {
          rrs: xRrs,
        },
        { signal: signal }
      )
    ).data;
    if (pld.error !== undefined) {
      throw pld.error;
    }
    return pld;
  },

  async getParamsPlot(rrs = []) {
    const xRrs = rrs.filter((x) => x !== undefined);
    const pld = (
      await axios.post(
        `${API_ENDPOINT}/calc/params/plot`,
        {
          rrs: xRrs,
        },
        {
          params: {
            r: 0,
          },
        }
      )
    ).data;
    if (pld.error !== undefined) {
      console.log("error: pld.err = ", pld.err);
      // throw pld.error
      return [];
    }
    console.log("getParamsPlot: returning = ", pld);
    return pld;
  },

  async getAISleepStages(uuid, date) {
    const pld = (
      await axios.get(`${CORSANO_API_ENDPOINT}/v1/mit/predicted-sleep-stages`, {
        params: {
          uuid,
          date,
        },
      })
    ).data;
    return pld;
  },

  async getAISleepStages2(uuid, date) {
    const pld = (
      await axios.get(
        `${CORSANO_API_ENDPOINT}/v2/ds/users/${uuid}/ai-sleep-stages/${date}`,
        {
          params: {
            uuid,
            date,
          },
        }
      )
    ).data;
    return pld;
  },

  async getAISleepStages3(uuid, date) {
    const pld = (
      await axios.get(
        `${CORSANO_API_ENDPOINT}/v2/ds/users/${uuid}/ai-sleep-stages-with-skipped-data/${date}`,
        {
          params: {
            uuid,
            date,
          },
        }
      )
    ).data;
    return pld;
  },

  async getNTrainSleepStages3(uuid, date) {
    const pld = (
      await axios.get(
        `${CORSANO_API_ENDPOINT}/v2/ds/users/${uuid}/ntrain/${date}/cached`
      )
    ).data;
    // pld = (pld.points || []);
    return pld;
  },

  async getNTrainIo(uuid, date) {
    const pld = (
      await axios.get(
        `${CORSANO_API_ENDPOINT}/v2/ds/users/${uuid}/ntrain-io/${date}`
      )
    ).data;
    return pld;
  },
};

export default CalcAPI;
