import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
} from "react";
import styled from "styled-components";

import MmtTemplate from "../templates/MmtTemplate";
import ActivityPanelOld from "../activity/panels/ActivityPanel";

function ActivityApp() {
  return (
    <MmtTemplate active={"activity"}>
      <Wrapper>{/* <ActivityPanelOld/> */}</Wrapper>
    </MmtTemplate>
  );
}

const Wrapper = styled.div``;

export default ActivityApp;
