import { isDebugMode } from "../../../../helpers/UiHelper";
import { useEffect, useState, useMemo } from "react";
import SimpleNoDataMessageTool from "../../../sleep/tools/SimpleNoDataMessageTool";
import SleepViewTool from "../../../sleep/tools/SleepViewTool";
import SleepActivityCountTool from "../../../sleep/tools/SleepActivityCountTool";
import SleepRriTool from "../../../sleep/tools/SleepRriTool";
import CommonHelper from "../../../../helpers/CommonHelper";
import SleepWakeTool from "../../../sleep/tools/SleepWakeTool";
import SleepActivitityTool from "../../../sleep/tools/SleepActivitityTool";
import { TabHeading, ComplianceHeading } from "../UiComponents";
import SleepWakeToolEnhanced from "../../../sleep/tools/SleepWakeToolEnhanced";
import TarnsformerHelper from "../../../../helpers/TarnsformerHelper";
import moment from "moment";
import SleepActivityStepsTool from "../../../sleep/tools/SleepActivityStepsTool";
import VitalMultiTrendsChartTool from "../../../trends/tools/VitalMultiTrendsChartTool";

export default function SleepTabPanel(props) {
  const {
    id,
    date,
    summary = {},
    theme,
    vitalSummaryInRange,
    hasNoActivityData = false,
    loading = false,
    displayFullDay = true,
    noonToNoonSleepData = [], // for corsano sleep
    noonToNoonActivityCount = [], // for steps and activity counts
    noonToNoonSleepDataLoading,
  } = props;

  // console.log("Rendering SleepTabPanel");
  // console.log({ noonToNoonSleepData });

  const [csvError, setCsvError] = useState("");
  /**
   * onset/offset percentages from ali
   * onset/offset percentages from summary
   *
   * onset/offset box from summary
   */

  const noPhilipsData =
    !summary || !summary?.sleep?.slots || summary?.sleep?.slots.length === 0;

  const noCorsanoData =
    !noonToNoonSleepData || noonToNoonSleepData.length === 0;

  // const todayTimezone = moment.parseZone().utcOffset();
  // const dateSpecificTimezone = moment
  //   .parseZone(summary?.sleep?.start_date)
  //   .utcOffset();
  // console.log({ todayTimezone, dateSpecificTimezone, s: summary?.created_at });

  const isDST = moment(date).isDST();
  // console.log({ date, isDST });

  const [browserTimezone, setBrowserTimezone] = useState(
    moment.parseZone().utcOffset() / 60
  );
  const [remoteTimezone, setRemoteTimezone] = useState(
    moment.parseZone().utcOffset() / 60
  );
  const [timezoneOffsetNoonToNoonSleep, setTimezoneOffsetNoonToNoonSleep] =
    useState(noonToNoonSleepData);

  const [timezoneOffsetActivityCounts, setTimezoneOffsetActivityCounts] =
    useState(noonToNoonActivityCount); // for blue activity count chart and yellow step chart

  const dateFrom = moment(date);
  const hourOffset = 12;
  const twelveHoursBefore = dateFrom.clone().subtract(hourOffset, "hours");
  // console.log("SleepTabPanel rerendered");

  const findTimezoneDiff = (noonToNoonSleepData) => {
    // console.log({ noonToNoonSleepData });

    if (!noonToNoonSleepData || noonToNoonSleepData.length === 0) {
      return 0;
    }
    let d = noonToNoonSleepData;
    const isoString = d[0]?.date || new Date();
    const _remoteTimezoneOffset = moment.parseZone(isoString).utcOffset() / 60;
    let diff = _remoteTimezoneOffset - browserTimezone;
    if (!isDST) {
      diff += 1;
    }
    // console.log({ isoString, diff });
    return diff;
  };

  useEffect(() => {
    /**
     * further offset to user timezone
     */
    if (!noonToNoonSleepData || noonToNoonSleepData.length === 0) {
      return;
    }
    // console.log("further offset to user timezone");
    let d = noonToNoonSleepData;
    const ac = noonToNoonActivityCount;
    const diff = findTimezoneDiff(noonToNoonSleepData);
    // const diff = 0;
    // console.log({ noonToNoonSleepData, diff });
    const adjustTimestamp = (timestamp) => timestamp + diff * 3600000; // Convert hours to ms

    d = d.map((x, i) => {
      const _timestamp = adjustTimestamp(+x.timestamp);
      const _date = moment(_timestamp).toISOString();
      return {
        ...x,
        timestamp: _timestamp,
        date: _date,
      };
    });

    const _ac = ac.map((x) => {
      return {
        ...x,
        timestamp: adjustTimestamp(+x.timestamp),
      };
    });

    setTimezoneOffsetNoonToNoonSleep(d);
    setTimezoneOffsetActivityCounts(_ac);
  }, [date, noonToNoonSleepData, noonToNoonActivityCount]);

  const sleepAndWakeData = useMemo(
    () => findSleepWakeData(timezoneOffsetNoonToNoonSleep, twelveHoursBefore),
    [timezoneOffsetNoonToNoonSleep]
  );

  // useEffect(() => {
  //   const sleepMarkersData = findSleepWakeData(timezoneOffsetNoonToNoonSleep, twelveHoursBefore);
  //   setSleepAndWakeData(sleepMarkersData);
  // }, [id, date, timezoneOffsetNoonToNoonSleep]);

  return (
    <div>
      <div className="sleep-stages-area" style={{ width: "100%", height: 350 }}>
        {noPhilipsData ? (
          <div>
            {loading ? (
              <>
                <TabHeading>Sleep Stages</TabHeading>
                <SimpleNoDataMessageTool
                  loading={loading}
                  message={"Loading..."}
                />
              </>
            ) : (
              <>
                <TabHeading>Sleep Stages</TabHeading>
                <SimpleNoDataMessageTool
                  loading={loading}
                  message={"Sorry, there is no sleep summary for this day."}
                />
              </>
            )}
          </div>
        ) : (
          <div>
            <TabHeading>Sleep Stages</TabHeading>
            <div style={{ width: "100%", height: 300 }}>
              <SleepViewTool
                date={date}
                slots={summary?.sleep?.slots}
                sleepAndWakeData={sleepAndWakeData}
                sleepAndWakeUpTime={
                  noPhilipsData
                    ? undefined
                    : {
                        time_to_sleep: summary.sleep?.time_to_sleep,
                        time_to_wake_up: summary.sleep?.time_to_wake_up,
                        start_date: summary.sleep?.start_date,
                        end_date: summary.sleep?.end_date,
                      }
                }
                displayFullDay={displayFullDay}
                dd={"philips"}
                uuid={id}
              />
            </div>
          </div>
        )}
      </div>

      {isDebugMode() === false ? null : (
        <div className="sleep-wake-area" style={{ marginTop: 48 }}>
          <ComplianceHeading className="heading">Sleep/Wake</ComplianceHeading>
          <div style={{ width: "100%", height: 260 }}>
            {/* <AiSleepViewTool date={date} uuid={id} /> */}
            <SleepWakeTool
              uuid={id}
              date={date}
              // sleepDataResponse={noonToNoonSleepData}
              sleepDataResponse={timezoneOffsetNoonToNoonSleep}
              sleepDataLoading={noonToNoonSleepDataLoading}
              sleepAndWakeData={sleepAndWakeData}
              sleepAndWakeUpTime={
                noPhilipsData
                  ? undefined
                  : {
                      time_to_sleep: summary.sleep?.time_to_sleep,
                      time_to_wake_up: summary.sleep?.time_to_wake_up,
                      start_date: summary.sleep?.start_date,
                      end_date: summary.sleep?.end_date,
                    }
              }
            />
          </div>
        </div>
      )}

      {isDebugMode() === false ? null : (
        // {true ? null : (
        <div className="sleep-wake-raw-area" style={{ marginTop: 30 }}>
          <ComplianceHeading className="heading">Sleep Data</ComplianceHeading>
          <div style={{ width: "100%", height: 260 }}>
            {/* <AiSleepViewTool date={date} uuid={id} /> */}
            <SleepWakeToolEnhanced
              uuid={id}
              date={date}
              // sleepDataResponse={noonToNoonSleepData}
              sleepDataResponse={timezoneOffsetNoonToNoonSleep}
              sleepDataLoading={noonToNoonSleepDataLoading}
              sleepAndWakeData={sleepAndWakeData}
            />
          </div>
        </div>
      )}

      {isDebugMode() === false ? null : (
        <div
          className="sleep-export-area"
          style={{
            // width: "100%",
            display: "inline-block",
            height: 25,
            marginTop: "0.5em",
            cursor: "pointer",
          }}
        >
          <div
            style={{ fontWeight: 600 }}
            onClick={() => {
              // console.log();
              const noPhilipsData =
                !summary?.sleep?.slots || summary?.sleep?.slots.length === 0;
              const noCorsanoData =
                !noonToNoonSleepData || noonToNoonSleepData.length === 0;

              if (noPhilipsData) {
                setCsvError("No Philips Data");
                // return;
              }
              if (noCorsanoData) {
                setCsvError("No Corsano Sleep Data");
                if (!noPhilipsData) {
                  setCsvError("No Corsano Sleep Data.");
                  // console.log(summary?.sleep?.slots);
                  const philips = summary?.sleep?.slots;
                  let outputArray = Array(philips.length).fill({});
                  philips.forEach((element, i) => {
                    const { end_date, from, start_date, timestamp, to, type } =
                      element;
                    outputArray[i] = {
                      sleep_state: type,
                      sleep_state_verbal:
                        TarnsformerHelper.getSleepStageNameByType(type),
                      from: from,
                      to: to,
                      start_date: start_date,
                      end_date: end_date,
                    };
                  });
                  let head = `sleep_state;sleep_state_verbal;from;to;start_date;end_date`;
                  let csv = `${head}\n${outputArray
                    .map(
                      (x, i) =>
                        `${x.sleep_state};${x.sleep_state_verbal};${x.from};${x.to};${x.start_date};${x.end_date}`
                    )
                    .join("\n")}`;

                  CommonHelper.downloadFile(
                    `sleep_data_${id}_${date}.csv`,
                    csv
                  );
                }
                return;
              }
              const philipsData = summary?.sleep?.slots;
              // console.log(philipsData);

              let d = noonToNoonSleepData;
              let outputArray = Array(d.length).fill({});

              const philipsTimeData = philipsData.map((x) => [+x.from, +x.to]);

              //
              d.forEach((element, i) => {
                const {
                  sleep_state,
                  movement_level,
                  activity_count,
                  data_source_id,
                  _id,
                  timestamp,
                  date,
                  date_without_tz,
                  calendar_date,
                  updated_at,
                  created_at,
                } = element;
                let withinPhilips = false;

                for (const [pindex, philipsTime] of philipsTimeData.entries()) {
                  const pfrom = philipsTime[0];
                  const pto = philipsTime[1];
                  if (+timestamp >= pfrom && +timestamp < pto) {
                    const row = {
                      sleep_state: philipsData[pindex].type,
                      sleep_state_verbal:
                        TarnsformerHelper.getSleepStageNameByType(
                          philipsData[pindex].type
                        ),
                      corsano_sleep_state: sleep_state,
                      corsano_sleep_state_verbal:
                        sleep_state == 0
                          ? ""
                          : TarnsformerHelper.getSleepStatus(sleep_state),
                      timestamp: timestamp,
                      date: date,
                      date_without_tz: date_without_tz,
                      calendar_date: calendar_date,
                      movement_level: movement_level,
                      activity_count: activity_count,
                      data_source_id: data_source_id,
                      _id: _id,
                    };
                    outputArray[i] = row;
                    withinPhilips = true;
                    break;
                  }
                }
                if (!withinPhilips) {
                  const row = {
                    sleep_state: "NA",
                    sleep_state_verbal: "NA",
                    corsano_sleep_state: sleep_state,
                    corsano_sleep_state_verbal:
                      sleep_state == 0
                        ? ""
                        : TarnsformerHelper.getSleepStatus(sleep_state),
                    timestamp: timestamp,
                    date: date,
                    date_without_tz: date_without_tz,
                    calendar_date: calendar_date,
                    movement_level: movement_level,
                    activity_count: activity_count,
                    data_source_id: data_source_id,
                    _id: _id,
                  };
                  outputArray[i] = row;
                }
              });

              let head = `sleep_state;sleep_state_verbal;corsano_sleep_state;corsano_sleep_state_verbal;timestamp;date;date_without_tz;calendar_date;movement_level;activity_count;data_source_id;_id`;
              let csv = `${head}\n${outputArray
                .map(
                  (x, i) =>
                    `${x.sleep_state};${x.sleep_state_verbal};${x.corsano_sleep_state};${x.corsano_sleep_state_verbal};${x.timestamp};${x.date};${x.date_without_tz};${x.calendar_date};${x.activity_count};${x.activity_count};${x.data_source_id};${x._id}`
                )
                .join("\n")}`;

              CommonHelper.downloadFile(`sleep_data_${id}_${date}.csv`, csv);
            }}
          >
            Export Sleep Data as CSV
          </div>
        </div>
      )}

      {hasNoActivityData ? null : (
        <div
          className="sleep-activity-count-area"
          style={{
            marginTop: "4rem",
            // border: "1px solid red",
          }}
        >
          <ComplianceHeading>Activity Count </ComplianceHeading>
          <SleepActivityCountTool
            // sleepData={noonToNoonSleepData}
            sleepData={timezoneOffsetNoonToNoonSleep}
            // onsetOffsetPercentages={onsetOffsetPercentages}
            noonToNoonActivityCount={timezoneOffsetActivityCounts}
            // noonToNoonActivityCount={noonToNoonActivityCount}
            sleepAndWakeData={sleepAndWakeData}
            date={date}
            loading={noonToNoonSleepDataLoading}
          />
        </div>
      )}

      {isDebugMode() === false ? null : (
        <div
          className="sleep-rri-area"
          style={{ width: "100%", height: 350, marginTop: 20 }}
        >
          <ComplianceHeading>Sleep RRI</ComplianceHeading>

          <div style={{ width: "100%", height: 240, marginTop: 10 }}>
            {/* <AiSleepTool date={date} uuid={id} /> */}
            {/* <SimpleNoDataMessageTool
            message={"No sleep RRI data for this day."}
          /> */}
            <SleepRriTool
              uuid={id}
              date={date}
              diff={remoteTimezone - browserTimezone}
            />
          </div>
        </div>
      )}

      {isDebugMode() === false || hasNoActivityData ? null : (
        <div
          className="sleep-activity-1min-steps-area"
          style={{ marginTop: 30 }}
        >
          <ComplianceHeading className="heading">Steps</ComplianceHeading>
          <SleepActivityStepsTool
            offsetActivityPoints={timezoneOffsetActivityCounts}
            date={date}
            id={id}
          />
        </div>
      )}

      {
        // {true ? null : (
        <div className="sleep-multi-trends-area" style={{ marginTop: 30 }}>
          <ComplianceHeading className="heading">
            Sleep Trends
          </ComplianceHeading>
          <div style={{ width: "100%", height: 390 }}>
            <VitalMultiTrendsChartTool
              dataType={"sleep"}
              theme={theme}
              subTypes={[
                // tab 1:
                "sleep_duration",
                "awake_time",
                "rem_time",
                "light_time",
                "deep_time",

                // tab 2:
                "tranquility",
                // tab 3:
                "performance",
                "consistency",
              ]}
              uuid={id}
              points={vitalSummaryInRange}
            />
          </div>
        </div>
      }

      {/* {isDebugMode() === false || hasNoActivityData ? null : (
        <div className="sleep-activity-area" style={{ marginTop: 30 }}>
          <ComplianceHeading className="heading">Activity</ComplianceHeading>
          <SleepActivitityTool
            points={offsetActivityPoints5Min}
            date={date}
            uuid={id}
          />
        </div>
      )} */}

      {/* {isDebugMode() === false ? null : (
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <span
          style={{ cursor: "pointer" }}
          onClick={async () => {
            setRrsDownloading(true);
            let pld = await DoctorAPI.getSleepRRIntervals(id, date);
            console.log("sleep rr intervals = ", pld);
            CommonHelper.downloadFile(
              `rr_intervals_${date}.json`,
              JSON.stringify(pld)
            );
            setRrsDownloading(false);
          }}
        >
          {rrsDownloading === true
            ? "loading..."
            : "download RR-intervals data"}
        </span>
      </div>
    )} */}
    </div>
  );
}

const HOURS_PER_DAY = 24;
const MINUTES_PER_HOUR = 60;
const SECONDS_PER_DAY = 60 * HOURS_PER_DAY * MINUTES_PER_HOUR;

const findSleepWakeData = (
  timezoneOffsetNoonToNoonSleep,
  twelveHoursBefore
) => {
  // console.log("finding Sleep Wake Data");

  if (
    !timezoneOffsetNoonToNoonSleep ||
    timezoneOffsetNoonToNoonSleep.length === 0
  )
    return;

  // console.log({ timezoneOffsetNoonToNoonSleep });
  // const d = noonToNoonSleepData;
  const d = timezoneOffsetNoonToNoonSleep;

  const sleepOnsetIndex = d.findIndex((x) => x.sleep_state === 101);
  const sleepOnsetEndIndex = d.findIndex((x) => x.sleep_state === 102);
  const wakeOnsetIndex = d.findLastIndex((x) => x.sleep_state === 103);
  const wakeOnsetEndIndex = d.findLastIndex((x) => x.sleep_state === 104);

  let sleepMarkersData = {
    sleepOnset: { percentage: 0.0, date: "", timestamp: "" },
    sleepOnsetEnd: { percentage: 0.0, date: "", timestamp: "" },
    wakeOnset: { percentage: 0.0, date: "", timestamp: "" },
    wakeOnsetEnd: { percentage: 0.0, date: "", timestamp: "" },
  };

  if (
    sleepOnsetIndex < 0 ||
    sleepOnsetEndIndex < 0 ||
    wakeOnsetIndex < 0 ||
    wakeOnsetEndIndex < 0
  ) {
    // console.log({
    //   timeToSleepIndex: sleepOnsetIndex,
    //   onsetIndex: sleepOnsetEndIndex,
    //   wakeUpIndex: wakeOnsetIndex,
    //   outOfBedIIndex: wakeOnsetEndIndex,
    // });
  } else {
    const sleepOnsetDate = d[sleepOnsetIndex]?.date;
    // const sleepOnsetPercentage =
    const sleepOnsetTimestamp = d[sleepOnsetIndex]?.timestamp;
    const sleepOnsetPercentage =
      (100 * moment(+sleepOnsetTimestamp).diff(twelveHoursBefore, "seconds")) /
      SECONDS_PER_DAY;

    const sleepOnsetEndDate = d[sleepOnsetEndIndex]?.date;
    const sleepOnsetEndTimestamp = d[sleepOnsetEndIndex]?.timestamp;
    const sleepOnsetEndPercentage =
      (100 *
        moment(+sleepOnsetEndTimestamp).diff(twelveHoursBefore, "seconds")) /
      SECONDS_PER_DAY;

    const wakeOnsetDate = d[wakeOnsetIndex]?.date;
    const wakeOnsetTimestamp = d[wakeOnsetIndex]?.timestamp;
    const wakeOnsetPercentage =
      (100 * moment(+wakeOnsetTimestamp).diff(twelveHoursBefore, "seconds")) /
      SECONDS_PER_DAY;

    const wakeOnsetEndDate = d[wakeOnsetEndIndex]?.date;
    const wakeOnsetEndTimestamp = d[wakeOnsetEndIndex]?.timestamp;
    const wakeOnsetEndPercentage =
      (100 *
        moment(+wakeOnsetEndTimestamp).diff(twelveHoursBefore, "seconds")) /
      SECONDS_PER_DAY;

    sleepMarkersData = {
      sleepOnset: {
        percentage: sleepOnsetPercentage,
        date: sleepOnsetDate,
        timestamp: sleepOnsetTimestamp,
      },
      sleepOnsetEnd: {
        percentage: sleepOnsetEndPercentage,
        date: sleepOnsetEndDate,
        timestamp: sleepOnsetEndTimestamp,
      },
      wakeOnset: {
        percentage: wakeOnsetPercentage,
        date: wakeOnsetDate,
        timestamp: wakeOnsetTimestamp,
      },
      wakeOnsetEnd: {
        percentage: wakeOnsetEndPercentage,
        date: wakeOnsetEndDate,
        timestamp: wakeOnsetEndTimestamp,
      },
    };
  }
  // console.log({ sleepMarkersData });
  return sleepMarkersData;
};
