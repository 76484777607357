import { useState, useEffect, useRef } from "react";
import moment from "moment";
import styled from "styled-components";
import DrawHelper from "../../helpers/DrawHelper";
import useDimensions from "react-use-dimensions";
import {
  AXES_COLOR,
  DOT_STROKE,
  AXES_STROKE_WIDTH,
  GRID_STROKE_COLOR,
  xaxisTimeFormatter,
  LINE_COLORS,
  GRID_THICKNESS,
  GRID_DASH_ARRAY,
  NOT_WEARING_COLOR,
  LOW_BATTERY_COLOR,
  CHARGING_COLOR,
  GRID_GAP_COLOR,
} from "../ui/templates";
import {
  CartesianGrid,
  ComposedChart,
  XAxis,
  YAxis,
  ReferenceArea,
  Label,
  Tooltip,
  Line,
} from "recharts";
import SimpleNoDataMessageTool from "../sleep/tools/SimpleNoDataMessageTool";
import BaselineHelper from "../../helpers/BaselineHelper";
import {
  GRID_GAP_DASH_ARRAY,
  GRID_GAP_THICHNESS,
  GRID_THICHNESS_GAP,
} from "../ui/templates/Charts";
import { CustomizedLabel, isSameDay } from "./BaselineUtils";
import { isDebugMode } from "../../helpers/UiHelper";

const LINES = [
  { value: "raw", label: "Raw", color: LINE_COLORS.raw, width: 1, show: true },
  {
    value: "baseline",
    label: "Baseline",
    color: LINE_COLORS.baseline,
    width: 2,
    show: true,
  },
  {
    value: "spot",
    label: "Spot Measurement",
    color: LINE_COLORS.spot,
    width: 2,
    show: true,
  },
  // {
  //   value: "adpt",
  //   label: "Adaptive",
  //   color: LINE_COLORS.adaptive,
  //   width: 1,
  //   show: true,
  // },
  // {
  //   value: "gap",
  //   label: "Gap",
  //   show: true,
  //   color: LINE_COLORS.gap,
  //   bstyle: "dashed",
  // },
];
const fillMissingSpots = (data, from) => {
  if (!data || data.length === 0) {
    return;
  }
  const zeroPoint = data[0].t;
  let _m = zeroPoint;
  let emptySpots = [];
  for (let i = 0; i < data.length - 3; i++) {
    let currentPoints = data[i].t;
    let nextPoints = data[i + 1].t;
    let gap = moment(nextPoints).diff(currentPoints, "minutes");

    if (gap >= 10) {
      let newSlot = moment(currentPoints).add(10, "minutes").valueOf();
      emptySpots.push({
        raw: null,
        t: newSlot,
      });
    } else {
      emptySpots.push(data[i]);
    }

    // let match = data.find((x) => x.slot === _m);
    // if (!match) {
    //   const missingPoint = {
    //     slot: _m,
    //     bpm: null,
    //   };
    //   emptySpots.push(missingPoint);
    // } else {
    //   emptySpots.push(match);
    // }
  }
  return emptySpots;
};

const calculateMovingAverage = (data, window) => {
  const length = data.length;
  let spotsWithMaAndAdaptive = [];
  for (let i = 0; i <= length - 1; i++) {
    const currentVal = data[i].temp_sk1;
    const prevVal = i > 0 ? data[i - 1].temp_sk1 : currentVal;
    if (currentVal) {
      let hasValid = false;
      let t0 = moment(data[i].slot);
      let _arr = [];
      let startInd = i >= 80 ? i - 80 : 0;
      for (let j = startInd; j <= i; j++) {
        let df = t0.diff(moment(data[j].slot), "minutes");
        if (df < window) {
          _arr = data.slice(j, i + 1);
          break;
        }
      }

      let _ma = BaselineHelper.calculateMAFromArray(_arr, "temp_sk1");
      let _wma = BaselineHelper.calculateBWMAFromArray(
        _arr,
        "temp_sk1",
        t0,
        currentVal - prevVal
      );
      // console.log(`maa ${i}, ${_ma}`);
      if (_ma === 0) {
        spotsWithMaAndAdaptive.push({
          ...data[i],
          ma: null,
          adpt: null,
        });
        continue;
      }

      let adpt = null;
      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: _ma,
        adpt: adpt,
        test: _wma,
      });
    } else {
      spotsWithMaAndAdaptive.push({
        ...data[i],
        ma: null,
        adpt: null,
      });
    }
  }
  return spotsWithMaAndAdaptive;
};

const PatientDayTemperatureBaselineTool = (props) => {
  let {
    uuid,
    date,
    hasNoData,
    theme,
    unit,
    baselineWindow = 15,
    tempPoints = [],
    loading = false,
    tz,
    gapAndNotWearingSpans = [],
    chargingSpans = [],
    temperatureSpot = [],
    lowBatterySpans = [],
    externalMeasurements = [],
  } = props;
  const [ref, { x, y, width, height }] = useDimensions();

  //   const [loading, setLoading] = useState(false);
  const [TemperatureRawData, setTemperatureRawData] = useState([]);
  const [TemperatureProcessedData, setTemperatureProcessedData] = useState([]);
  const [visibleChargingSpanLabel, setVisibleChargingSpanLabel] = useState(-1);
  const [visibleNotWearingSpanLabel, setVisibleNotWearingSpanLabel] =
    useState(-1);
  const [visibleLowBatterySpanLabel, setVisibleLowBatterySpanLabel] =
    useState(-1);
  const [hasExternalData, setHasExternalData] = useState(false);

  const [delta, setDelta] = useState(0.03);
  const [kValue, setKValue] = useState(0.2);
  const [ptime, setPtime] = useState(0);
  const [showLine, setShowLine] = useState({
    raw: true,
    ma: true,
    adpt: true,
    gap: true,
    weighted: true,
    baseline: true,
    spot: true,
  });
  const [dataLength, setDataLength] = useState(0);
  const refY = useRef(null);
  const [domainY, setDomainY] = useState(undefined);
  const [refDelta, setRefDelta] = useState(0);
  const [yaxisStart, setYaxisStart] = useState(80);

  const windowSize = 10;
  let windowSec = windowSize;

  let from = moment(date).startOf("day").valueOf();
  let to = moment(date).endOf("day").valueOf();
  let dataFrom = moment(date).startOf("day").subtract(1, "hour").valueOf();
  let dataTo = moment(date).endOf("day").subtract(1, "hour").valueOf();

  useEffect(() => {
    setTemperatureProcessedData([]);
    // setLoading(true);
    // DoctorAPI.getAggregatedPointsWithAbsoluteTime(
    //   console.log(`raw windowSec: ${windowSec} temperature data from api`, d);
    let d = tempPoints;
    if (!d || d.length === 0) {
      return;
    }
    setTemperatureRawData(d);
    let _Temperature = d;
    let _temperature = fillMissingSpots(_Temperature) || [];
    // console.log(externalMeasurements);
    const externalWithNulls = [];
    externalMeasurements.forEach((x, i) => {
      const spotObj = {
        ...x,
        temperature: null,
        slot: +x.timestamp,
        temperature_spot: x.temperature,
        t: +x.timestamp,
      };
      const nullObj = {
        temperature_spot: null,
        t: +x.timestamp + 1,
      };
      externalWithNulls.push(spotObj);
      externalWithNulls.push(nullObj);
    });

    const tempSpots = temperatureSpot.filter((x) => {
      return isSameDay(x.timestamp, date);
    });
    // console.log({ tempSpots, date });
    tempSpots.forEach((x, i) => {
      const spotObj = {
        ...x,
        temperature: null,
        slot: +x?.timestamp,
        temperature_spot: x?.temperature_value,
        t: +x?.timestamp,
      };
      const nullObj = {
        temperature_spot: null,
        t: +x?.timestamp + 1,
      };
      externalWithNulls.push(spotObj);
      externalWithNulls.push(nullObj);
    });
    if (externalWithNulls.length > 0) {
      setHasExternalData(true);
    }

    _temperature = [..._temperature, ...externalWithNulls];
    setTemperatureProcessedData(_temperature);
  }, [date, tempPoints, externalMeasurements, temperatureSpot]);

  useEffect(() => {
    const yMap = refY.current?.state?.yAxisMap;
    let domain = [];
    let delta = 0.0;
    if (yMap) {
      domain = yMap[0]?.domain;
    } else {
      return;
    }
    if (domain && domain.length == 2) {
      delta = (domain[1] - domain[0]) / 40;
    } else {
      return;
    }
    // console.log({ delta, domain });
    setRefDelta(delta);
    setYaxisStart(domain[0]);
  }, [refY, TemperatureProcessedData]);

  // useEffect(() => {
  //   // setLoading(true);
  //   setTemperatureProcessedData([]);
  //   if (!TemperatureRawData || TemperatureRawData.length === 0) {
  //     //   setLoading(false);
  //     return;
  //   }
  //   console.log({ TemperatureRawData });

  //   let _temperature = TemperatureRawData;
  //   let _t0 = performance.now();

  //   _temperature = calculateMovingAverage(
  //     TemperatureRawData,
  //     baselineWindow,
  //     delta,
  //     kValue
  //   );
  //   _temperature = _temperature.map((s) => {
  //     return {
  //       ...s,
  //       t: moment(s.slot).valueOf(),
  //     };
  //   });
  //   _temperature = fillMissingSpots(_temperature);
  //   // console.log("_temp", _temperature);

  //   let elapsed = performance.now() - _t0;
  //   setTemperatureProcessedData(_temperature);
  //   setPtime(elapsed.toFixed(3));
  //   // setLoading(false);
  //   console.log("ELAPSED TIME", elapsed, "mm");
  // }, [date, tempPoints, TemperatureRawData]);

  // let extraLines = DrawHelper.getExtraDottedChartsData(
  //   TemperatureProcessedData,
  //   "temp_sk1"
  // );
  // let TemperatureWithGaps = TemperatureProcessedData;
  // for (let sp of TemperatureWithGaps) {
  //   for (let el in extraLines) {
  //     const slot1 = extraLines[el]["points"][0].slot;
  //     const slot2 = extraLines[el]["points"][1].slot;
  //     if (slot1 === sp.slot) {
  //       sp[`temp_sk1_gap_${el}`] =
  //         extraLines[el]["points"][0][`temp_sk1_gap_${el}`];
  //     }
  //     if (slot2 === sp.slot) {
  //       sp[`temp_sk1_gap_${el}`] =
  //         extraLines[el]["points"][1][`temp_sk1_gap_${el}`];
  //     }
  //   }
  // }
  let yticks = [];

  let startOfDay = moment(date).startOf("day").valueOf();
  let startOfNextDay = moment(date).startOf("day").add(1, "day").valueOf();
  const handleLineCheckbox = (evt) => {
    const name = evt.target.name;
    console.log(name);
    let oldValue = showLine[name];
    const newSetting = { ...showLine, [name]: !oldValue };
    setShowLine(newSetting);
  };
  let min = startOfDay;
  let max = startOfNextDay;
  let tickNum = 24;

  let xticks;

  let _min;
  let _max;
  if (tz !== undefined) {
    // xticks = BaselineHelper.getXaxisTicksEachHourWithTz(min, tz);
    xticks = BaselineHelper.getXaxisTicksEachHour(min);
    _min = moment(min).add(tz, "hours").valueOf();
    _max = moment(max).add(tz, "hours").valueOf();
  } else {
    xticks = [];
    for (let i = 0; i < tickNum; i++) {
      xticks.push(moment(min).add(i, "hour").valueOf());
    }
    _min = min;
    _max = max;
  }

  // console.log(TemperatureProcessedData);

  if (loading) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"Calculating..."}
        showTopImg={false}
      />
    );
  }

  if (hasNoData) {
    return (
      <SimpleNoDataMessageTool
        loadind={true}
        message={"No Data"}
        showTopImg={false}
      />
    );
  }
  // console.log(TemperatureProcessedData);

  return (
    <Wrapper ref={ref}>
      {TemperatureProcessedData.length === 0 ? (
        <SimpleNoDataMessageTool
          loadind={true}
          message={"Fetching data..."}
          showTopImg={false}
        />
      ) : (
        <div>
          <ComposedChart
            ref={refY}
            width={width}
            height={height}
            data={TemperatureProcessedData}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <CartesianGrid
              stroke={GRID_STROKE_COLOR}
              strokeWidth={GRID_THICKNESS}
              strokeDasharray={GRID_DASH_ARRAY}
            />
            <XAxis
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              strokeWidth={AXES_STROKE_WIDTH}
              // interval={11}
              // dataKey={"slot"}
              dataKey={"t"}
              ticks={xticks}
              tickSize={10}
              type="number"
              allowDataOverflow={true}
              domain={[startOfDay, startOfNextDay]}
              //   domain={[_min, _max]}
              interval={0}
              tickCount={xticks.length}
              tickFormatter={xaxisTimeFormatter}
            />
            <YAxis
              dataKey={"temp_sk1"}
              //   ticks={yticks}
              //   domain={([min, max]) => [35, 42]}
              domain={([min, max]) => [33, 42]}
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              type="number"
              strokeWidth={AXES_STROKE_WIDTH}
              tickFormatter={(x) => {
                let xx = unit === "F" ? (+x * 1.8 + 32.0).toFixed(1) : x;
                xx = unit === "F" ? `${x}°F` : `${x}°C`;

                return xx;
              }}
            />
            <Tooltip content={<TemperatureTooltip unit={unit} />} />
            <Line
              key={"temp_bl"}
              type="monotone"
              dataKey="baseline"
              name="Baseline"
              stroke={LINE_COLORS.baseline}
              connectNulls={false}
              hide={showLine["baseline"] ? false : true}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              key={"temp_raw"}
              type="monotone"
              dataKey="temp_sk1"
              name="Raw"
              stroke={LINE_COLORS.raw}
              strokeWidth={1}
              connectNulls={false}
              // strokeDasharray="5 5"
              hide={showLine["raw"] ? false : true}
              //   dot={DOT_STROKE}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              key={"temp_spot"}
              type="monotone"
              dataKey="temperature_spot"
              name="spot"
              // stroke={"none"}
              // strokeWidth={0.9}
              stroke={LINE_COLORS.spot}
              connectNulls={false}
              hide={showLine["spot"] ? false : true}
              dot={true}
              activeDot={true}
              isAnimationActive={false}
            />
            {/* {extraLines.map((l, i) => {
              return (
                <Line
                  key={i}
                  type={"monotone"}
                  dataKey={`temp_sk1_gap_${i}`}
                  isAnimationActive={false}
                  strokeWidth={GRID_GAP_THICHNESS}
                  strokeDasharray={GRID_GAP_DASH_ARRAY}
                  stroke={GRID_GAP_COLOR}
                  dot={false}
                  name={"D"}
                  legendType="none"
                  connectNulls={true}
                  //   hide={showLine["raw"] ? false : true}
                  hide={true}
                />
              );
            })} */}
            {lowBatterySpans.map((span, i) => {
              // console.log({ span });

              const yMap = refY.current?.state?.yAxisMap;
              let domain = [];
              let delta = 0.0;
              if (yMap) {
                domain = yMap[0]?.domain;
              }
              // console.log(yMap);
              // console.log(domain);
              if (domain && domain.length == 2) {
                delta = (domain[1] - domain[0]) / 40;
              }
              const x1 = span.from < +startOfDay ? startOfDay : span.from;
              const x2 = span.to;
              const y1 = refDelta ? yaxisStart - refDelta : yaxisStart - 0.5;
              const y2 = refDelta ? yaxisStart + refDelta : yaxisStart + 0.5;
              if (span.from < startOfDay && span.to < startOfDay) {
                return null;
              }
              if (x2 - x1 <= 60000) {
                return null;
              }
              return (
                <ReferenceArea
                  key={`lb_${i}`}
                  style={{ cursor: "pointer" }}
                  onMouseOver={() => {
                    // console.log("charign!");
                    if (visibleLowBatterySpanLabel > -1) {
                      setVisibleLowBatterySpanLabel(-1);
                    } else {
                      setVisibleNotWearingSpanLabel(-1);
                      setVisibleChargingSpanLabel(-1);
                      setVisibleLowBatterySpanLabel(i);
                    }
                  }}
                  onMouseLeave={() => {
                    setVisibleChargingSpanLabel(-1);
                    setVisibleNotWearingSpanLabel(-1);
                    setVisibleLowBatterySpanLabel(-1);
                  }}
                  className="charging-ref-area"
                  x1={x1}
                  x2={x2}
                  y1={y1}
                  y2={y2}
                  ifOverflow="visible"
                  fill={LOW_BATTERY_COLOR}
                >
                  <Label
                    // value={`Charging (${moment(x1).format("HH:mm")} to ${moment(
                    //   x2
                    // ).format("HH:mm")})`}
                    content={
                      <CustomizedLabel
                        x1={x1}
                        x2={x2}
                        reason={"Battery <= 5"}
                      />
                    }
                    offset={18}
                    fontSize={12}
                    position="insideBottom"
                    visibility={
                      i === visibleLowBatterySpanLabel ? "visible" : "hidden"
                    }
                  />
                </ReferenceArea>
              );
            })}
            {chargingSpans.map((span, i) => {
              const x1 = span.from < +startOfDay ? startOfDay : span.from;
              const x2 = span.to;
              const y1 = refDelta ? yaxisStart - refDelta : yaxisStart - 0.5;
              const y2 = refDelta ? yaxisStart + refDelta : yaxisStart + 0.5;
              if (span.from < startOfDay && span.to < startOfDay) {
                return null;
              }
              if (x2 - x1 <= 60000) {
                return null;
              }
              return (
                <ReferenceArea
                  key={`cs_${i}`}
                  style={{ cursor: "pointer" }}
                  onMouseOver={() => {
                    // console.log("charign!");
                    if (visibleChargingSpanLabel > -1) {
                      setVisibleChargingSpanLabel(-1);
                    } else {
                      setVisibleNotWearingSpanLabel(-1);
                      setVisibleChargingSpanLabel(i);
                    }
                  }}
                  onMouseLeave={() => {
                    setVisibleChargingSpanLabel(-1);
                    setVisibleNotWearingSpanLabel(-1);
                  }}
                  className="charging-ref-area"
                  x1={x1}
                  x2={x2}
                  y1={y1}
                  y2={y2}
                  ifOverflow="visible"
                  fill={CHARGING_COLOR}
                >
                  <Label
                    // value={`Charging (${moment(x1).format("HH:mm")} to ${moment(
                    //   x2
                    // ).format("HH:mm")})`}
                    content={
                      <CustomizedLabel x1={x1} x2={x2} reason={"Charging"} />
                    }
                    offset={18}
                    fontSize={12}
                    position="insideBottom"
                    visibility={
                      i === visibleChargingSpanLabel ? "visible" : "hidden"
                    }
                  />
                </ReferenceArea>
              );
            })}
            {gapAndNotWearingSpans.map((span, i) => {
              const x1 = span.from < +startOfDay ? startOfDay : span.from;
              const x2 = span.to;
              const y1 = refDelta ? yaxisStart - refDelta : yaxisStart - 0.5;
              const y2 = refDelta ? yaxisStart + refDelta : yaxisStart + 0.5;
              // console.log({ x1, x2, y1, y2 });

              if (span.from < startOfDay && x2 < startOfDay) {
                return null;
              }
              if (x2 - x1 <= 60000) {
                return null;
              }
              return (
                <ReferenceArea
                  key={`nw_${i}`}
                  style={{ cursor: "pointer" }}
                  onMouseOver={() => {
                    if (visibleNotWearingSpanLabel > -1) {
                      setVisibleNotWearingSpanLabel(-1);
                    } else {
                      setVisibleChargingSpanLabel(-1);
                      setVisibleNotWearingSpanLabel(i);
                    }
                  }}
                  onMouseLeave={() => {
                    setVisibleChargingSpanLabel(-1);
                    setVisibleNotWearingSpanLabel(-1);
                  }}
                  className="charging-ref-area"
                  x1={x1}
                  x2={x2}
                  y1={y1}
                  y2={y2}
                  ifOverflow="visible"
                  fill={NOT_WEARING_COLOR}
                >
                  <Label
                    // value={`Not wearing (${moment(x1).format(
                    //   "HH:mm"
                    // )} - ${moment(x2).format("HH:mm")})`}
                    content={
                      <CustomizedLabel x1={x1} x2={x2} reason={"Not wearing"} />
                    }
                    offset={18}
                    fontSize={12}
                    position="insideBottom"
                    visibility={
                      i === visibleNotWearingSpanLabel ? "visible" : "hidden"
                    }
                  />
                </ReferenceArea>
              );
            })}
          </ComposedChart>
          <LineFilterContainer>
            {isDebugMode() && (
              <div
                style={{
                  position: "absolute",
                  left: 20,
                  // fontWeight: 600,
                  fontSize: 16,
                  textDecoration: "underline",
                }}
                onClick={() => {
                  let d = externalMeasurements;
                  // let head = `sleep_state;sleep_q;movement_level;activity_count;reserved1;data_source_id;_id;timestamp;date;date_without_tz;calendar_date;updated_at;created_at`;
                  // let csv = `${head}\n${d
                  //   .map(
                  //     (x, i) =>
                  //       `${x.sleep_state};${x.sleep_q};${x.movement_level};${x.activity_count};${x.reserved1};${x.data_source_id};${x._id};${x.timestamp};${x.date};${x.date_without_tz};${x.calendar_date};${x.updated_at};${x.created_at}`
                  //   )
                  //   .join("\n")}`;
                  // CommonHelper.downloadFile(`corsano_sleep_${uuid}_${date}.csv`, csv);
                }}
              >
                {/* Export Spot Measurement */}
              </div>
            )}
            {LINES.map((f, i) => {
              if (!hasExternalData && f.value === "spot") {
                return null;
              }
              return (
                <LineFilterItem key={i}>
                  <LineFilterCheckbox
                    type="checkbox"
                    name={f.value}
                    checked={showLine[f.value]}
                    onChange={handleLineCheckbox}
                  />
                  <LineLabel
                    $color={f.color}
                    $bstyle={f?.bstyle ? f.bstyle : "solid"}
                    theme={theme}
                  >
                    {f.label}
                  </LineLabel>
                </LineFilterItem>
              );
            })}
          </LineFilterContainer>
          {/* <label>
            Delta
            <input
              value={delta}
              onChange={(e) => {
                setDelta(e.target.value);
              }}
            />
          </label>
          <label>
            k
            <input
              value={kValue}
              onChange={(e) => {
                setKValue(e.target.value);
              }}
            />
          </label> */}
        </div>
      )}
    </Wrapper>
  );
};

export default PatientDayTemperatureBaselineTool;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  position: relative;
`;

const TemperatureTooltip = ({ active, payload, label, unit }) => {
  if (active && payload && payload.length) {
    let temp = payload[0].payload.temp_sk1 ? payload[0].payload.temp_sk1 : null;
    let temperature_spot = payload[0]?.payload?.temperature_spot;
    let device = "";
    let tempString;
    let tempSpotString;
    let unitChar = "°C";
    if (unit === "F") {
      unitChar = "°F";
    }
    if (temp) {
      if (unit === "F") {
        tempString = (+temp * 1.8 + 32.0).toFixed(1);
      } else {
        tempString = temp.toFixed(1);
      }
    } else {
      tempString = "null";
    }

    if (temperature_spot) {
      device = payload[0]?.payload?.thermometer_name || "";
      if (unit === "F") {
        tempSpotString = (+temperature_spot * 1.8 + 32.0).toFixed(1);
      } else {
        tempSpotString = temperature_spot.toFixed(1);
      }
    } else {
      tempSpotString = "null";
    }
    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{`${moment(label).format(
          "HH:mm"
        )}`}</TooltipRow>
        {temperature_spot ? (
          <>
            <TooltipRow className="label">{`Spot Measurement: ${tempSpotString}${unitChar}`}</TooltipRow>
            {device && device.length > 0 && (
              <TooltipRow className="label">{`Spot Measurement Device: ${device}`}</TooltipRow>
            )}
          </>
        ) : (
          <>
            <TooltipRow className="label">{`Raw Temperature: ${tempString}${unitChar}`}</TooltipRow>
            <TooltipRow className="label">{`Baseline: ${payload[0].payload.baseline}${unitChar}`}</TooltipRow>
          </>
        )}
      </TooltipWrapper>
    );
  }

  return null;
};

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;

const LineFilterContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  // margin-left: 20px;
  justify-content: center;
  // border: 1px solid green;
`;

const LineFilterItem = styled.div`
  display: flex;
  align-items: baseline;
`;

const LineFilterCheckbox = styled.input`
  &[type="checkbox"] {
    transform: scale(1.2);
    accent-color: #1e7efa;
  }
`;

const LineLabel = styled.label`
  font-weight: 500;
  color: ${(props) => (props.$theme === "dark" ? "white" : "black")};
  &:before {
    display: inline-block;
    content: "";
    border: 1.5px solid ${(props) => props.$color};
    border-style: ${(props) => props.$bstyle};
    // border-top: 1rem solid ${(props) => props.$color};
    width: 4rem;
    margin: 0 10px;
    transform: translateY(-4px);
  }
`;

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;
