import React, { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import ls from "local-storage";
import { Code } from "react-content-loader";
import DoctorAPI from "../../api/DoctorAPI";
import { Sidebar } from "arui-feather/sidebar";
import axios from "axios";
import { EditButton } from "../patients/panels/PatientPanel";
import BpFilterHelper from "../../helpers/BpFilterHelper";
import { NOTIFICATION_TO_DETAILS } from "./BpNotifications";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Dot,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Tabs, { TabItem } from "../ui/Tabs";
import UserInitBpMeasurementsTool from "../spo2/tools/UserInitBpMeasurementsTool";
import { isDebugMode } from "../../helpers/UiHelper";
import InfoToolBadge from "../info/tools/InfoToolBadge";
import BpSquareTool from "./tools/BpSquareTool";
import SimpleNoDataMessageTool from "../sleep/tools/SimpleNoDataMessageTool";
import { AXES_COLOR, AXES_COLORX, AXES_STROKE_WIDTH } from "../ui/templates";
import VitalMultiTrendsChartTool from "../trends/tools/VitalMultiTrendsChartTool";

const CLOUD = "cloud";
const SHORT_TERM = "short-term";
const LONG_TERM = "long-term";
const CUFF_BP = "cuff-bp";
const COMBINE = "combine";
const BP_FILTERS = [
  { value: CLOUD, label: "CL" },
  { value: SHORT_TERM, label: "Bracelet ST" },
  { value: LONG_TERM, label: "Bracelet LT" },
  { value: CUFF_BP, label: "Cuff" },
  { value: COMBINE, label: "Cor" },
];

const BP_Y_DOMAIN = [0, 200];
const BP_COLORS = [
  {
    name: CLOUD,
    sbp: "#3333FF",
    dbp: "#FF4E45",
  },
  {
    name: SHORT_TERM,
    sbp: "#33e3FF",
    dbp: "#aF4E45",
  },
  {
    name: LONG_TERM,
    sbp: "#06402b",
    dbp: "#ff5c00",
  },
  {
    name: CUFF_BP,
    sbp: "#1e1",
    dbp: "#e1e",
  },
  {
    name: COMBINE,
    sbp: "#2ae",
    dbp: "#f1a",
  },
];

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    // console.log(
    //   "shortterm payload",
    //   payload.map((x) => x.dataKey)
    // );
    // console.log("shortterm payload", payload);
    let data = {
      sbp: undefined,
      dbp: undefined,
      nibp_sbp: undefined,
      nibp_dbp: undefined,
      nibp_sbp_long_term: undefined,
      nibp_dbp_long_term: undefined,
      cuff_sbp: undefined,
      cuff_dbp: undefined,
      nibp_diastolic_combine: undefined,
      nibp_systolic_combine: undefined,
      bpm: undefined,
    };

    let dd = payload[0]?.payload || {};
    let t1 = payload[0]?.payload?.t || undefined;
    payload.map((p, i) => {
      if (p?.payload?.bpm) {
        data.bpm = p?.payload?.bpm;
      }
      if (p?.dataKey === "sbp") {
        data.sbp = p?.value;
      }
      if (p?.dataKey === "dbp") {
        data.dbp = p?.value;
      }
      if (p?.dataKey === "nibp_systolic") {
        data.nibp_sbp = p?.value;
      }
      if (p?.dataKey === "nibp_diastolic") {
        data.nibp_dbp = p?.value;
      }
      if (p?.dataKey === "nibp_diastolic_long_term") {
        data.nibp_dbp_long_term = p?.value;
      }
      if (p?.dataKey === "nibp_systolic_long_term") {
        data.nibp_sbp_long_term = p?.value;
      }
      if (p?.dataKey === "cuffSbp") {
        data.cuff_sbp = p?.value;
      }
      if (p?.dataKey === "cuffDbp") {
        data.cuff_dbp = p?.value;
      }
      if (p?.dataKey === "nibp_systolic_combine") {
        data.nibp_systolic_combine = p?.value;
      }
      if (p?.dataKey === "nibp_diastolic_combine") {
        data.nibp_diastolic_combine = p?.value;
      }
    });
    // console.log("data.bpm", data.bpm);

    let bp = dd["blood pressure"];
    let is_init = dd.is_init;
    let tLabel = dd.recorded_t;
    let hr = dd["heart rate"];
    let notifications = dd?._raw_response?.notification_codes;
    // let messages = dd?._raw_response?.messages;
    if (tLabel === "Invalid date") {
      tLabel = ``;
    }
    let quality = dd?._raw_response?.Quality;
    return (
      <TooltipWrapper>
        {tLabel === "" ? null : (
          <>
            <>
              <b>{tLabel}</b>
            </>
            <div>
              {t1 ? moment(t1).format(" HH:mm") : ""}
              {/* <p>{t2 ? moment(t2).format("HH:mm") : ""} </p>
              <p>{t3 ? moment(t3).format("HH:mm") : ""} </p>
              <p>{t4 ? moment(t4).format("HH:mm") : ""} </p> */}
            </div>
            <div>
              {bp === undefined ? null : `SBP/DBP >48hrs: ${bp[1]}/${bp[0]}`}
            </div>
            {notifications ? (
              <div style={{ display: "none" }}>
                Errors:{" "}
                {notifications.map((n, i) => {
                  return <span key={i}>{n === "OK" ? "None" : n}</span>;
                })}
              </div>
            ) : null}
            {bp && bp[0] !== null ? (
              <div>Q: {quality ? mapPPGQuality(quality) : ""}</div>
            ) : null}
            {data.nibp_dbp && data.nibp_dbp ? (
              <div>{`SBP/DBP <48hrs: ${data.nibp_sbp}/${data.nibp_dbp}`}</div>
            ) : null}
            {data.nibp_sbp_long_term && data.nibp_dbp_long_term ? (
              <div>{`SBP/DBP Long Term: ${data.nibp_sbp_long_term}/${data.nibp_dbp_long_term}`}</div>
            ) : null}
            {data.nibp_systolic_combine && data.nibp_diastolic_combine ? (
              <div>{`SBP/DBP Combine: ${data.nibp_systolic_combine}/${data.nibp_diastolic_combine}`}</div>
            ) : null}
            {data.cuff_dbp && data.cuff_sbp ? (
              <div>{`Cuff SBP/DBP: ${data.cuff_sbp}/${data.cuff_dbp}`}</div>
            ) : null}
            {data.bpm && <div>{`BPM: ${data.bpm}`}</div>}
          </>
        )}
      </TooltipWrapper>
    );
  }

  return null;
};

function showBpAMessage(a) {
  try {
    return a?._raw_response == undefined
      ? ""
      : JSON.parse(a?._raw_response)?.Message;
  } catch (exc) {}
  return "";
}

function mapPPGQuality(num) {
  if (num < 0 || num > 100) {
    return "Invalid PPG quality.";
  }
  return Math.floor(num / 25);
}

function getAverageSbpDbp(points) {
  let sbpSum = 0;
  let dbpSum = 0;
  let count = 0;
  points.forEach((x) => {
    if (x.sbp != undefined) {
      sbpSum += +x.sbp;
      count++;
    }
    if (x.dbp != undefined) {
      dbpSum += +x.dbp;
      // count++;
    }
  });
  return {
    sbp: count == 0 ? "" : Math.round((1.0 * +sbpSum) / count),
    dbp: count == 0 ? "" : Math.round((1.0 * +dbpSum) / count),
  };
}

function getUniqueStrings(arr) {
  let map = {};
  for (let i in arr) {
    map[arr[i]] = 1;
  }
  return Object.keys(map);
}

function getBpWarnings(points) {
  let warnings = [];
  for (let i in points) {
    let { _raw_response } = points[i];
    if (_raw_response != undefined) {
      warnings = warnings.concat(_raw_response?.model_warnings || []);
    }
  }
  warnings = warnings.filter((x) => x != undefined && x != "");
  if (warnings.length == 0) {
    return [];
  }
  return getUniqueStrings([warnings[warnings.length - 1]]);
}

function getBpMessages(points) {
  let warnings = [];
  for (let i in points) {
    let { _raw_response } = points[i];
    if (
      _raw_response != undefined &&
      _raw_response.model_message != undefined
    ) {
      warnings.push(_raw_response.model_message);
    }
  }
  warnings = warnings.filter((x) => x != undefined && x != "");
  if (warnings.length == 0) {
    return [];
  }
  return getUniqueStrings([warnings[warnings.length - 1]]);
}

function getEvery30MinHHmm(startTimestamp) {
  let hhmmStrings = [];
  const pointsNum = 24 * 2;
  for (let i = 0; i < pointsNum; i++) {
    let _timeObject = moment(startTimestamp).add(30 * i, "minutes");
    let _string = _timeObject.format("HH:mm");
    let _ts = _timeObject.valueOf();
    hhmmStrings.push([_string, +_ts]);
  }
  hhmmStrings.push([
    "24:00",
    +moment(hhmmStrings[pointsNum - 1][1])
      .add(30, "minutes")
      .valueOf(),
  ]);
  return hhmmStrings;
}

export default function PatientDayBpTool(props) {
  const {
    uuid,
    dayTimestamp,
    min,
    max,
    cuffInit = [],
    activityPoints = [],
    cloudBpPoints = [],
    activityPointsLoading = false,
    mode = "day",
    toggleBpMode = () => {},
    freshestData = [],
    freshestNotification = [],
    userBpSummaries = [],
  } = props;
  // TODO: add interval
  // TODO: add gaps for short term
  // TODO: check long term
  // TODO: add combine

  // console.debug("Rendering PatientDayBpTool Ac", activityPoints.map(x=>moment(x.t).format("DD HH:mm")));
  // console.log(activityPoints);

  const debugModeOn = isDebugMode();

  const [points, setPoints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [recalculating, setRecalculating] = useState(false);
  // const [mode, setMode] = useState("day");
  const [loadingId, setLoadingId] = useState(undefined);
  const [selectedBar, setSelectedBar] = useState(undefined);
  const [aiWarnings, setAiWarnings] = useState([]);
  const [noWarning, setNoWarning] = useState(false);
  // const [freshestData, setFreshestData] = useState([]);
  // const [freshestNotification, setFreshestNotification] = useState([]);

  const [shortTerm, setShortTerm] = useState([]);
  const [longTerm, setLongTerm] = useState([]);
  const [extraMissingLines, setExtraMissingLines] = useState([]);
  const [extraMissingLinesShortTerm, setExtraMissingLinesShortTerm] = useState(
    []
  );
  const [extraMissingLinesLongTerm, setExtraMissingLinesLongTerm] = useState(
    []
  );
  const [extraMissingLinesCombine, setExtraMissingLinesCombine] = useState([]);
  const [bpCombine, setBpCombine] = useState([]);

  const [bpMergedTable, setBpMergedTable] = useState([]);
  const [bpMergedTableLoading, setBpMergedTableLoading] = useState(false);
  const [xDataAi, setXDataAi] = useState([]);

  const [dayAiStats, setDayAiStats] = useState([]);
  const [dayStats, setDayStats] = useState([]);
  const [nightAiStats, setNightAiStats] = useState([]);
  const [nightStats, setNightStats] = useState([]);

  const [bpFilter, setBpFilter] = useState([
    CLOUD,
    SHORT_TERM,
    LONG_TERM,
    CUFF_BP,
    COMBINE,
  ]);
  const [xDomain, setXDomain] = useState([]);
  const [xticks, setXticks] = useState([]);

  const [cuffData, setCuffData] = useState([]);
  // const [init, setInit] = useState([]);

  const theme = ls.get("theme");
  let endOfDay = +moment(+dayTimestamp).endOf("day");
  let startOfDay = +moment(+dayTimestamp).startOf("day");

  let startOfNextDay = moment(startOfDay).add(1, "day").valueOf();

  let startOfDay12 = +moment(+dayTimestamp).startOf("day").add(-12, "hours");
  let endOfDay12 = +moment(+dayTimestamp).startOf("day").add(12, "hours");
  // console.log("shortterm endofda", startOfDay, startOfNextDay);

  useEffect(() => {
    let xticks = [];
    let tickNum = 24;
    let _min;
    let _max;
    if (mode === "day") {
      _min = min;
      _max = max;
      setXDomain([_min, _max]);
      for (let i = 0; i < tickNum; i++) {
        xticks.push(moment(min).add(i, "hour").valueOf());
      }
      setXticks(xticks);
    } else {
      for (let i = 0; i < tickNum; i++) {
        _min = moment(min).subtract(12, "hours");
        _max = moment(max).subtract(12, "hours");
        setXDomain([_min, _max]);
        xticks.push(
          moment(min)
            .add(i - 12, "hour")
            .valueOf()
        );
        setXticks(xticks);
      }
    }
  }, [uuid, dayTimestamp, mode]);

  useEffect(() => {
    setCuffData([]);
    setBpMergedTable([]);
    DoctorAPI.getPatientsRawBPSpotsEnhancedFromZero(uuid, dayTimestamp).then(
      (pld) => {
        // console.log("getPatientsRawBPSpotsEnhancedForDay: raw pld = ", pld);
        if (!pld || pld.length === 0) {
          return;
        }

        let currentDay = +moment(+dayTimestamp).date();
        let currentMonth = +moment(+dayTimestamp).month() + 1;
        let currentYear = +moment(+dayTimestamp).year();
        let _pld = pld.filter((x) => {
          let t = moment(x.timestamp);
          // let currentD = currentDay.day();
          let _currentDay = +moment(+t).date();
          let _currentMonth = +moment(+t).month() + 1;
          let _currentYear = +moment(+t).year();
          if (
            _currentDay === currentDay &&
            _currentMonth === currentMonth &&
            _currentYear === currentYear
          ) {
            // console.log("currentDay is", currentDay, currentMonth, currentYear);
            return true;
          }
        });

        const groupedCuff = _pld.reduce((acc, item) => {
          if (!acc[item.session_code]) {
            acc[item.session_code] = [];
          }
          acc[item.session_code].push(item);
          return acc;
        }, {});

        let calculatedMeans = Object.entries(groupedCuff).map(
          ([session_code, session]) => {
            // console.log("gourpedCuff sc", session_code, session);
            // console.log(init);
            let _inits_success = [];

            session.map((x) => {
              const t = x.client_timestamp;
              const _init = cuffInit.find(
                (x) => x.client_start_timestamp === t
              );
              // console.log("_init", _init, cuffInit);
              const _raw_response = _init?._raw_response;
              _inits_success.push(_raw_response ? _raw_response.Success : true);
            });
            let hasBadCuff = _inits_success.some((x) => !x);
            // console.log("t, _init", _inits_success, hasBadCuff);
            if (hasBadCuff) {
              return {
                cuffSbp: null,
                cuffDbp: null,
                timestamp: null,
              };
            }

            // const sessionInit = init.filter(
            //   (x) => x.client_start_timestamp === session
            // );

            let meanSbp =
              session.reduce((sum, session) => sum + session.sbp, 0) /
              session.length;
            let meanDbp =
              session.reduce((sum, session) => sum + session.dbp, 0) /
              session.length;

            meanSbp = meanSbp.toFixed(0);
            meanDbp = meanDbp.toFixed(0);

            return {
              cuffSD: [+meanDbp, +meanSbp],
              cuffSbp: +meanSbp,
              cuffDbp: +meanDbp,
              t: +session[0].timestamp,
              timestamp: +session[0].timestamp,
            };
            // return acc;
            // return [];
          },
          {}
        );
        // console.log("gourpedCuff", groupedCuff);
        // console.log("gourpedCuff calculatedMeans", calculatedMeans);
        // console.log("_pld", _pld);

        _pld = _pld.map((x) => {
          return {
            // ...x,
            t: +x.timestamp,
            cuffSbp: +x.sbp,
            cuffDbp: +x.dbp,
            cuffSD: [+x.dbp, +x.sbp],
          };
        });
        // console.log("_pld", _pld);
        setCuffData(calculatedMeans);
      }
    );
  }, [uuid, dayTimestamp, mode]);

  // useEffect(() => {
  //   let arr = cloudBpPoints;
  //   setPoints(arr);
  //   let AIwarning = arr.slice(0, arr.length).map((x) => {
  //     let notification = x?._raw_response?.notification_codes;
  //     let aiModelSuccess = x?._raw_response?.Success;
  //     return {
  //       notification: notification,
  //       ts: x?.client_start_timestamp,
  //       aiModelSuccess: aiModelSuccess,
  //     };
  //   });
  //   AIwarning = AIwarning.filter((a) => a.aiModelSuccess);
  //   let checkAllWarnings = arr.map((x) => {
  //     let notification = x?._raw_response?.notification_codes;
  //     if (notification === undefined || notification[0] === "OK") {
  //       return null;
  //     }
  //     return notification;
  //   });
  //   setAiWarnings([]);
  //   setNoWarning(checkAllWarnings.every((x) => x === null));
  // }, [uuid, dayTimestamp, mode, cloudBpPoints]);

  // let messages = getBpMessages(points);
  // let warnings = getBpWarnings(points);

  useEffect(() => {
    // recalculating Cloud
    setBpMergedTable([]);
    if (recalculating == true) {
      return;
    }
    let from = +moment(+dayTimestamp).startOf("day");
    let to = +moment(+dayTimestamp).endOf("day");
    if (mode != "day") {
      from = +moment(+dayTimestamp).startOf("day").add(-12, "hours");
      to = +moment(+dayTimestamp).startOf("day").add(12, "hours");
    }
    setPoints([]);
    // setBpSeries({ ...bpSeries, cloud: [] });
    DoctorAPI.getAIBpData(uuid, from, to).then((arr) => {
      setPoints(arr);
      // setBpSeries({ ...bpSeries, cloud: arr });
    });
  }, [recalculating, mode]);

  // useEffect(() => {
  //   let n = 10;
  //   setFreshestData([]);
  //   setFreshestNotification([]);
  //   DoctorAPI.getFreshestAIBpData(uuid, n).then((arr) => {
  //     if (arr.length === 0) {
  //     } else {
  //       let _freshestNotification = arr[0].notification_codes;
  //       setFreshestData(arr[0]);
  //       setFreshestNotification(_freshestNotification || []);
  //     }
  //   });
  // }, [dayTimestamp]);

  useEffect(() => {
    // Getting short term
    let from = +moment(+dayTimestamp).startOf("day");
    let to = +moment(+dayTimestamp).endOf("day");
    if (mode != "day") {
      from = +moment(+dayTimestamp).startOf("day").add(-12, "hours");
      to = +moment(+dayTimestamp).startOf("day").add(12, "hours");
    }

    // setBpSeries({ ...bpSeries, "short-term": [] });

    // setLoading(true);
    setShortTerm([]);
    setLongTerm([]);
    setBpCombine([]);
    setBpMergedTable([]);
    setPoints([]);
    // DoctorAPI.getActivityPoints(uuid, from, to).then((arr) => {
    let arr = cloudBpPoints;
    setPoints(arr);
    if (activityPoints) {
      let arr = activityPoints;
      // console.log(
      //   "checking ActivityPoints",
      //   arr.map((x) => moment(x.t).format("DD HH:mm"))
      // );
      // setLoading(false);

      let noNullArr = BpFilterHelper.removeNullPoinst(
        arr,
        "nibp_systolic",
        "nibp_diastolic"
      );
      let noNullArrLongterm = BpFilterHelper.removeNullPoinst(
        arr,
        "nibp_systolic_long_term",
        "nibp_diastolic_long_term"
      );
      let noNullArrCombine = BpFilterHelper.removeNullPoinst(
        arr,
        "nibp_systolic_combine",
        "nibp_diastolic_combine"
      );

      let allDayPoints = getEvery30MinHHmm(from);
      // console.log("allDayPoints", allDayPoints);

      let _shortTerm = BpFilterHelper.alignToHalfHour(
        noNullArr,
        "nibp_systolic",
        "nibp_diastolic"
      );
      let _longTerm = BpFilterHelper.alignToHalfHour(
        noNullArrLongterm,
        "nibp_systolic_long_term",
        "nibp_diastolic_long_term"
      );
      let _combine = BpFilterHelper.alignToHalfHour(
        noNullArrCombine,
        "nibp_systolic_combine",
        "nibp_diastolic_combine"
      );

      let _filledShortTerm = [];
      let _filledLongTerm = [];
      let _filledCombine = [];
      allDayPoints.map((slot, i) => {
        let date = slot[0];
        let ts = slot[1];
        let point = _shortTerm.find((x) => x.date === date);
        let pointLt = _longTerm.find((x) => x.date === date);
        let pointCb = _combine.find((x) => x.date === date);

        if (point === undefined) {
          _filledShortTerm.push({
            nibp_diastolic: null,
            nibp_systolic: null,
            bpm: null,
            t: ts,
            date: date,
          });
          _filledLongTerm.push({
            nibp_diastolic_long_term: null,
            nibp_systolic_long_term: null,
            t: ts,
            date: date,
          });
          _filledCombine.push({
            nibp_diastolic_combine: null,
            nibp_systolic_combine: null,
            t: ts,
            date: date,
          });
        } else {
          _filledShortTerm.push({
            nibp_systolic: point?.nibp_systolic,
            nibp_diastolic: point?.nibp_diastolic,
            bpm: point.bpm,
            t: ts,
            date: date,
          });
          _filledLongTerm.push({
            nibp_diastolic_long_term: pointLt?.nibp_diastolic_long_term,
            nibp_systolic_long_term: pointLt?.nibp_systolic_long_term,
            t: ts,
            date: date,
          });
          _filledCombine.push({
            nibp_diastolic_combine: pointCb?.nibp_diastolic_combine,
            nibp_systolic_combine: pointCb?.nibp_systolic_combine,
            t: ts,
            date: date,
          });
        }
      });

      setShortTerm(_filledShortTerm);
      setLongTerm(_filledLongTerm);
      setBpCombine(_filledCombine);
    }
  }, [uuid, dayTimestamp, mode, activityPoints]);

  useEffect(() => {
    setBpMergedTable([]);

    // let xPoints = points.map((x) => ({
    //   t: +x.start_timestamp,
    //   ...x,
    //   date: moment(+x.start_timestamp).format("HH:mm"),
    // }));

    // let gridPoints = DrawHelper.getBpMonotonePoints(
    //   xPoints,
    //   mode == "day"
    //     ? +dayTimestamp
    //     : moment(+dayTimestamp).startOf("day").add(-12, "hours"),
    //   mode
    // );
    const _start = performance.now();

    let gridPoints = BpFilterHelper.calculateGridPoints(
      points,
      mode,
      dayTimestamp
    );
    // calculateGridPoints

    // let xData = gridPoints.map((x) => {
    //   return {
    //     ["blood pressure"]: [x.dbp, x.sbp],
    //     t: +x.t,
    //     ["heart rate"]: x.hr,
    //     dbp: x?.dbp,
    //     sbp: x?.sbp,
    //     date: x.date,
    //   };
    // });
    let xData = BpFilterHelper.calculateXData(gridPoints, "sbp", "dbp");

    // let xDataAi = gridPoints.map((x) => { //   let _ts = +x.t;
    //   let timeObject = alignTimeToHalfHour(x.t, x?._raw_response?.SBP_ai);

    //   return {
    //     // ['blood pressure']: [x?._raw_response?.DBP_ai, x?._raw_response?.SBP_ai],
    //     ["blood pressure"]: [
    //       x?._raw_response?.DBP_ai,
    //       x?._raw_response?.SBP_ai,
    //     ],
    //     ["blood_pressure"]: [
    //       x?._raw_response?.DBP_ai_debug,
    //       x?._raw_response?.SBP_ai_debug,
    //     ],
    //     // t: +x.t,
    //     t: timeObject ? timeObject.valueOf() : null,
    //     ["heart rate"]: x.hr,
    //     ["_raw_response"]: x?._raw_response,
    //     // date: x.date,
    //     date: timeObject ? timeObject.format("HH:mm") : x.date,
    //     dbp: x?._raw_response?.DBP_ai,
    //     d: x?._raw_response?.DBP_ai,
    //     dbp_: x?._raw_response?.DBP_ai_debug,
    //     sbp: x?._raw_response?.SBP_ai,
    //     s: x?._raw_response?.SBP_ai,
    //     sbp_: x?._raw_response?.SBP_ai_debug,
    //   };
    // });
    let xDataAi = BpFilterHelper.calculateXDataAi(gridPoints);
    setXDataAi(xDataAi);

    let nightXDataAi = xDataAi.filter((x) => x.date <= "07:00");
    let nightXData = xData.filter((x) => x.date <= "07:00");
    let dayXDataAi = xDataAi.filter((x) => x.date > "07:00");
    let dayXData = xData.filter((x) => x.date > "07:00");

    // setDayAiStats(getAverageSbpDbp(dayXDataAi));
    // setDayStats(getAverageSbpDbp(dayXData));
    // setNightAiStats(getAverageSbpDbp(nightXDataAi));
    // setNightStats(getAverageSbpDbp(nightXData));

    let _gridPoints = BpFilterHelper.calculateGridPoints(
      shortTerm,
      mode,
      dayTimestamp
    );

    let _xDataAi = BpFilterHelper.calculateXDataAiForCombine(
      _gridPoints,
      "nibp_systolic",
      "nibp_diastolic"
    );
    // setXDataAi(_xDataAi);
    // console.log(bpCombine);

    // 00 - 7am night
    // 10am -9pm day
    let _nightXDataAi = _xDataAi.filter((x) => x.date <= "07:00");
    let _dayXDataAi = _xDataAi.filter(
      (x) => x.date >= "10:00" && x.date <= "21:00"
    );
    // console.table(_nightXDataAi, ["date", "dbp", "sbp"]);
    // console.table(_dayXDataAi, ["date", "dbp", "sbp"]);
    // let d = _nightXDataAi.reduce((x, cur)=>{return x + cur.sbp}, 0)
    // console.log(d / _nightXDataAi.length);

    setDayAiStats(getAverageSbpDbp(_dayXDataAi));
    setNightAiStats(getAverageSbpDbp(_nightXDataAi));

    const _mid = performance.now();

    let fPoints = BpFilterHelper.calculateFPoints(xDataAi, mode, startOfDay);
    let stPoints = shortTerm;
    stPoints = BpFilterHelper.calculateFPoints(stPoints, mode, startOfDay);
    stPoints = BpFilterHelper.mapNullValuesToUndefined(
      stPoints,
      "nibp_systolic",
      "nibp_diastolic"
    );

    let longTermPoints = longTerm;
    longTermPoints = BpFilterHelper.calculateFPoints(
      longTermPoints,
      mode,
      startOfDay
    );
    longTermPoints = BpFilterHelper.mapNullValuesToUndefined(
      longTermPoints,
      "nibp_systolic_long_term",
      "nibp_diastolic_long_term"
    );

    let combinePoints = bpCombine;
    combinePoints = BpFilterHelper.calculateFPoints(
      combinePoints,
      mode,
      startOfDay
    );
    combinePoints = BpFilterHelper.mapNullValuesToUndefined(
      combinePoints,
      "nibp_systolic_combine",
      "nibp_diastolic_combine"
    );

    let extraLines = [];
    extraLines = BpFilterHelper.calculateExtralinesFromFPoints(
      fPoints,
      "sbp",
      "dbp"
    );

    let extraLinesShortTerm = [];
    extraLinesShortTerm = BpFilterHelper.calculateExtralinesFromFPoints(
      stPoints,
      "nibp_systolic",
      "nibp_diastolic"
    );

    let extraLinesLongTerm = [];
    extraLinesLongTerm = BpFilterHelper.calculateExtralinesFromFPoints(
      longTermPoints,
      "nibp_systolic_long_term",
      "nibp_diastolic_long_term"
    );

    let extraLinesCombine = [];
    extraLinesCombine = BpFilterHelper.calculateExtralinesFromFPoints(
      combinePoints,
      "nibp_systolic_combine",
      "nibp_diastolic_combine"
    );

    // merge fpoints and shortterm
    const shortTermWithoutNull = stPoints;
    // const ltPoints = longTerm;
    // const cbPoints = bpCombine;
    const ltPoints = longTermPoints;
    const cbPoints = combinePoints;
    const endTime = performance.now();
    // console.debug(`bpfilter calculated in ${(endTime - _start).toFixed(1)} ms`);
    // const shortTermWithoutNull = stPoints.filter((x) => x.nibp_diastolic);
    // console.log("PatientDayBpTool shortTermWithoutNull", shortTermWithoutNull);
    let merged = [];
    // if (
    //   shortTermWithoutNull.length > 0 &&
    //   shortTermWithoutNull[0].date === "00:00"
    // ) {
    //   merged.push({
    //     ...shortTermWithoutNull[0],
    //     merged: 1,
    //   });
    // }
    fPoints.map((point, i) => {
      let _date = point.date;
      let shortTermSameDate = shortTermWithoutNull.find((x) => {
        let __date = x.date;
        if (__date === _date) {
          return x;
        }
      });
      let longTermSameDate = ltPoints.find((x) => {
        let __date = x.date;
        if (__date === _date) {
          return x;
        }
      });
      let combinedSameDate = cbPoints.find((x) => {
        let __date = x.date;
        if (__date === _date) {
          return x;
        }
      });
      // console.log("shortTermSameDate", shortTermSameDate);

      if (shortTermSameDate) {
        merged.push({
          ...shortTermSameDate,
          ...longTermSameDate,
          ...combinedSameDate,
          ...point,
          merged: 2,
        });
      } else {
        merged.push({
          ...point,
          merged: 1,
        });
      }
    });
    merged.push(...cuffData);
    // console.log("merger", merged);

    setExtraMissingLines(extraLines);
    setExtraMissingLinesShortTerm(extraLinesShortTerm);
    setExtraMissingLinesLongTerm(extraLinesLongTerm);
    setExtraMissingLinesCombine(extraLinesCombine);

    setBpMergedTable(merged);
  }, [points, shortTerm, bpCombine, dayTimestamp, activityPoints]);

  const handleBPFilterChange = (evt) => {
    const item = evt.target.name;
    setBpFilter((prev) => {
      if (prev.includes(item)) {
        return prev.filter((checkedItem) => checkedItem !== item);
      } else {
        return [...prev, item];
      }
    });
  };

  let hideErrorIfThereIs = !moment(endOfDay).isAfter(
    freshestData.start_timestamp
  );
  // let xticks = [];
  // let tickNum = 24;
  // let _min;
  // let _max;
  // if (mode === "day") {
  //   _min = min;
  //   _max = max;
  //   for (let i = 0; i < tickNum; i++) {
  //     xticks.push(moment(min).add(i, "hour").valueOf());
  //   }
  // } else {
  //   for (let i = 0; i < tickNum; i++) {
  //     _min = moment(min).subtract(12, "hours");
  //     _max = moment(max).subtract(12, "hours");
  //     xticks.push(
  //       moment(min)
  //         .add(i - 12, "hour")
  //         .valueOf()
  //     );
  //   }
  // }

  // dayTimestamp < freshestData.start_timestamp ||
  // !moment(dayTimestamp).isSame(freshestData.start_timestamp, "day");
  if (activityPoints.length == 0 && activityPointsLoading == true) {
    return (
      <div style={{ marginBottom: "1em" }}>
        <TabHeading className="patient-statistics-heading">
          <div>Blood Pressure</div>
        </TabHeading>
        <Code />
      </div>
    );
    // return <Code color="#1ae" />;
  }

  if (activityPoints.length == 0 && activityPointsLoading == false) {
    if (
      !freshestNotification[0] ||
      freshestNotification.length === 0 ||
      !freshestData ||
      hideErrorIfThereIs
    ) {
      // console.log("daytimestamp RETURN early");

      return (
        <div style={{ marginBottom: "1em" }}>
          <TabHeading className="patient-statistics-heading">
            <div>Blood Pressure</div>
          </TabHeading>
          <SimpleNoDataMessageTool
            // loading={loading}
            message={"Sorry, there is no blood pressure data for this day."}
          />
        </div>
      );
    }
    // console.log("freshestNotification", freshestNotification);

    return (
      <div style={{ marginBottom: "1em" }}>
        <TabHeading className="patient-statistics-heading">
          <div>Blood Pressure</div>
        </TabHeading>
        <SimpleNoDataMessageTool
          // loading={loading}
          message={"Sorry, there is no blood pressure data for this day."}
        />
        <div
          className="normal-text"
          style={{
            display: freshestNotification[0] ? "block" : "none",
            fontWeight: "500",
            fontSize: "20px",
          }}
        >
          Warnings:
        </div>
        <WarningArea>
          <div
            className="normal-text"
            style={{ width: "80%", marginBottom: "1rem" }}
          >
            <ul>
              {freshestNotification
                .filter((m) => {
                  return NOTIFICATION_TO_DETAILS[m];
                })
                .map((n, _i) => {
                  return (
                    <li key={_i}>
                      <strong>{NOTIFICATION_TO_DETAILS[n].title}</strong>
                      {": "}
                      {NOTIFICATION_TO_DETAILS[n].verbal}
                    </li>
                  );
                })}
            </ul>
          </div>
        </WarningArea>
      </div>
    );
  }

  // console.log("shortterm", bpSeries);
  // let xticks = [];
  // for (let i = 0; i < 24; i++) {
  //   if (mode === "day") {
  //     xticks.push(moment(startOfDay).add(i, "hour").valueOf());
  //   } else {
  //     xticks.push(moment(startOfDay12).add(i, "hour").valueOf());
  //   }
  // }
  // console.log("shortterm ticks", xticks);

  return (
    <Wrapper>
      {/* <Heading> */}
      {/* Experimental AI Spo2 */}
      {/* </Heading> */}
      <TabHeading className="patient-statistics-heading">
        <div style={{ width: "400px" }}>Blood Pressure</div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontStyle: "normal",
            fontWeight: "normal",
            marginLeft: 20,
            fontSize: "14px",
            alignItems: "center",
          }}
        >
          <strong>Mode:</strong>
          <ModeSwitcherItem
            selected={mode == "day"}
            onClick={() => {
              // setMode("day");
              toggleBpMode("day");
            }}
          >
            00:00-24:00
          </ModeSwitcherItem>
          <ModeSwitcherItem
            selected={mode == "12-12"}
            onClick={() => {
              // setMode("12-12");
              toggleBpMode("12-12");
            }}
          >
            12:00-12:00
          </ModeSwitcherItem>
        </div>
        {debugModeOn ? (
          <BpFilterContainer>
            <strong>Filters:</strong>
            {BP_FILTERS.map((f, i) => {
              return (
                <BpFilterItem key={i}>
                  <BpFilterCheckbox
                    type="checkbox"
                    name={f.value}
                    checked={bpFilter.includes(f.value)}
                    onChange={handleBPFilterChange}
                  />
                  {f.label}
                </BpFilterItem>
              );
            })}
            <div style={{ marginLeft: 40 }}>
              <InfoToolBadge type={"BLOOD_PRESSURE"} />
            </div>
          </BpFilterContainer>
        ) : null}
      </TabHeading>
      <TopDayNight
        style={{ flexDirection: "flex-start", justifyContent: "space-between" }}
      >
        <div style={{ paddingLeft: 20 }}>
          <div
            style={{
              // display: "grid",
              // gridTemplateColumns: "repeat(5, 1fr)",
              display: "flex",
              flexDirection: "row",
              gap: 10,
              alignItems: "center",
            }}
          >
            {debugModeOn && bpFilter.includes(CLOUD) ? (
              <SysDiaLegendBox>
                <SysDiaColorSpan color={BP_COLORS[0].sbp} />
                <span style={{ marginRight: "0.5rem", fontSize: "13px" }}>
                  CL SYS
                </span>
                <SysDiaColorSpan color={BP_COLORS[0].dbp} />
                <span style={{ fontSize: "13px" }}>CL DIA</span>
              </SysDiaLegendBox>
            ) : null}
            {debugModeOn && bpFilter.includes(SHORT_TERM) ? (
              <SysDiaLegendBox>
                <SysDiaColorSpan color={BP_COLORS[1].sbp} />
                <span style={{ marginRight: "0.5rem", fontSize: "13px" }}>
                  ST SYS
                </span>
                <SysDiaColorSpan color={BP_COLORS[1].dbp} />
                <span style={{ fontSize: "13px" }}>ST DIA</span>
              </SysDiaLegendBox>
            ) : null}
            {debugModeOn && bpFilter.includes(LONG_TERM) ? (
              <SysDiaLegendBox>
                <SysDiaColorSpan color={BP_COLORS[2].sbp} />
                <span style={{ marginRight: "0.5rem", fontSize: "13px" }}>
                  LT SYS
                </span>
                <SysDiaColorSpan color={BP_COLORS[2].dbp} />
                <span style={{ fontSize: "13px" }}>LT DIA</span>
              </SysDiaLegendBox>
            ) : null}
            {debugModeOn && bpFilter.includes(CUFF_BP) ? (
              <SysDiaLegendBox>
                <SysDiaColorSpan color={BP_COLORS[3].sbp} />
                <span style={{ marginRight: "0.5rem", fontSize: "13px" }}>
                  Cuff SYS
                </span>
                <SysDiaColorSpan color={BP_COLORS[3].dbp} />
                <span style={{ fontSize: "13px" }}>Cuff DIA</span>
              </SysDiaLegendBox>
            ) : null}
            {debugModeOn && bpFilter.includes(COMBINE) ? (
              <SysDiaLegendBox>
                <SysDiaColorSpan color={BP_COLORS[4].sbp} />
                <span style={{ marginRight: "0.5rem", fontSize: "13px" }}>
                  Cor SYS
                </span>
                <SysDiaColorSpan color={BP_COLORS[4].dbp} />
                <span style={{ fontSize: "13px" }}>Cor DIA</span>
              </SysDiaLegendBox>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          ></div>
        </div>

        <div className="bp-daynight">
          <div style={{ width: "max-content" }}>{`Night: ${
            nightAiStats.sbp ? nightAiStats.sbp : ""
          }/${nightAiStats.dbp ? nightAiStats.dbp : ""}`}</div>
          <div style={{ width: "max-content" }}>{`Day: ${
            dayAiStats.sbp ? dayAiStats.sbp : ""
          }/${dayAiStats.dbp ? dayAiStats.dbp : ""}`}</div>
        </div>
      </TopDayNight>
      <ChartPlaceholder>
        <ResponsiveContainer height={320}>
          {/* <ComposedChart data={fPoints}> */}
          <ComposedChart data={bpMergedTable}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              // xAxisId={1}
              stroke={AXES_COLORX(theme)}
              strokeWidth={AXES_STROKE_WIDTH}
              tickCount={24}
              dataKey={"t"}
              ticks={xticks}
              interval={0}
              // domain={[_min, _max]}
              domain={xDomain}
              tickFormatter={xFormatter}
              overflow={false}
            />

            <YAxis
              type="number"
              stroke={AXES_COLORX(theme)}
              strokeWidth={AXES_STROKE_WIDTH}
              domain={([dataMin, dataMax]) => {
                const _max = dataMax > 200 ? dataMax : 200;
                return [0, _max];
              }}
            />

            <Line
              // xAxisId={1}
              // data={bpSeries["cloud"]}
              dataKey={"dbp"}
              stroke={BP_COLORS[0].dbp}
              strokeWidth={2}
              connectNulls={false}
              hide={!bpFilter.includes(CLOUD)}
              isAnimationActive={false}
            />
            <Line
              // xAxisId={1}
              // data={bpSeries["cloud"]}
              dataKey={"sbp"}
              stroke={BP_COLORS[0].sbp}
              strokeWidth={2}
              connectNulls={false}
              hide={!bpFilter.includes(CLOUD)}
              isAnimationActive={false}
            />
            {/* {cuffData.map((x, i) => {
              return <Dot cx={x.timestamp} cy={x.cuffDbp} r={2000} fill="#ee1"  />;
            })} */}
            <Line
              // xAxisId={1}
              // data={cuffData}
              dataKey={"cuffDbp"}
              name={"cuff_dbp"}
              stroke={"#e1e"}
              strokeWidth={2}
              connectNulls={false}
              hide={!bpFilter.includes(CUFF_BP)}
              // hide={false}
              isAnimationActive={false}
            />
            <Line
              // xAxisId={1}
              // data={cuffData}
              dataKey={"cuffSbp"}
              name={"cuff_sbp"}
              stroke={"#1e1"}
              strokeWidth={2}
              connectNulls={false}
              hide={!bpFilter.includes(CUFF_BP)}
              // hide={false}
              isAnimationActive={false}
            />
            <Line
              // xAxisId={1}
              // data={bpSeries["short-term"]}
              dataKey={"nibp_systolic"}
              stroke={BP_COLORS[1].sbp}
              strokeWidth={2}
              dot={true}
              connectNulls={false}
              hide={!bpFilter.includes(SHORT_TERM)}
              isAnimationActive={false}
            />
            <Line
              // xAxisId={1}
              // data={bpSeries["short-term"]}
              dataKey={"nibp_diastolic"}
              stroke={BP_COLORS[1].dbp}
              strokeWidth={2}
              dot={true}
              connectNulls={false}
              hide={!bpFilter.includes(SHORT_TERM)}
              isAnimationActive={false}
            />
            <Line
              // xAxisId={1}
              // data={bpSeries["short-term"]}
              dataKey={"nibp_systolic_long_term"}
              stroke={BP_COLORS[2].sbp}
              strokeWidth={2}
              dot={true}
              connectNulls={false}
              hide={!bpFilter.includes(LONG_TERM)}
              isAnimationActive={false}
            />
            <Line
              // xAxisId={1}
              // data={bpSeries["short-term"]}
              dataKey={"nibp_diastolic_long_term"}
              stroke={BP_COLORS[2].dbp}
              strokeWidth={2}
              dot={true}
              connectNulls={false}
              hide={!bpFilter.includes(LONG_TERM)}
              isAnimationActive={false}
            />

            <Line
              // xAxisId={1}
              // data={bpSeries["short-term"]}
              dataKey={"nibp_systolic_combine"}
              stroke={BP_COLORS[4].sbp}
              strokeWidth={2}
              dot={true}
              connectNulls={false}
              hide={!bpFilter.includes(COMBINE)}
              isAnimationActive={false}
            />
            <Line
              // xAxisId={1}
              // data={bpSeries["short-term"]}
              dataKey={"nibp_diastolic_combine"}
              stroke={BP_COLORS[4].dbp}
              strokeWidth={2}
              dot={true}
              connectNulls={false}
              hide={!bpFilter.includes(COMBINE)}
              isAnimationActive={false}
            />
            <Line
              // xAxisId={1}
              // data={bpSeries["short-term"]}
              dataKey={"bpm"}
              hide={true}
              isAnimationActive={false}
            />

            {extraMissingLines.map((l, i) => {
              return (
                <React.Fragment key={i}>
                  <Line
                    hide={!bpFilter.includes("cloud")}
                    key={`${i}_dbp`}
                    // data={bpSeries["cloud"]}
                    type={"monotone"}
                    dataKey={`dbp_gap_${i}`}
                    isAnimationActive={false}
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    stroke="#ff730080"
                    dot={false}
                    connectNulls={true}
                    activeDot={false}
                    // xAxisId={1}
                  />
                  <Line
                    hide={!bpFilter.includes("cloud")}
                    key={`${i}_sbp`}
                    // data={bpSeries["cloud"]}
                    type={"monotone"}
                    dataKey={`sbp_gap_${i}`}
                    isAnimationActive={false}
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    stroke="#0000ff80"
                    dot={false}
                    connectNulls={true}
                    activeDot={false}
                    // xAxisId={1}
                  />
                </React.Fragment>
              );
            })}

            {extraMissingLinesShortTerm.map((l, i) => {
              return (
                <React.Fragment key={i}>
                  <Line
                    hide={!bpFilter.includes(SHORT_TERM)}
                    // data={bpSeries["cloud"]}
                    type={"monotone"}
                    dataKey={`nibp_diastolic_gap_${i}`}
                    isAnimationActive={false}
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    stroke={BP_COLORS[1].dbp}
                    dot={false}
                    connectNulls={true}
                    activeDot={false}
                    // xAxisId={1}
                  />
                  <Line
                    hide={!bpFilter.includes(SHORT_TERM)} // data={bpSeries["cloud"]}
                    type={"monotone"}
                    dataKey={`nibp_systolic_gap_${i}`}
                    isAnimationActive={false}
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    stroke={BP_COLORS[1].sbp}
                    dot={false}
                    connectNulls={true}
                    activeDot={false}
                    // xAxisId={1}
                  />
                </React.Fragment>
              );
            })}

            {extraMissingLinesLongTerm.map((l, i) => {
              return (
                <React.Fragment key={i}>
                  <Line
                    hide={!bpFilter.includes(LONG_TERM)}
                    type={"monotone"}
                    dataKey={`nibp_diastolic_long_term_gap_${i}`}
                    isAnimationActive={false}
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    stroke={BP_COLORS[2].dbp}
                    dot={false}
                    connectNulls={true}
                    activeDot={false}
                    // xAxisId={1}
                  />
                  <Line
                    hide={!bpFilter.includes(LONG_TERM)}
                    type={"monotone"}
                    dataKey={`nibp_systolic_long_term_gap_${i}`}
                    isAnimationActive={false}
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    stroke={BP_COLORS[2].sbp}
                    dot={false}
                    connectNulls={true}
                    activeDot={false}
                    // xAxisId={1}
                  />
                </React.Fragment>
              );
            })}

            {extraMissingLinesCombine.map((l, i) => {
              return (
                <React.Fragment key={i}>
                  <Line
                    hide={!bpFilter.includes(COMBINE)}
                    // data={bpSeries["cloud"]}
                    type={"monotone"}
                    dataKey={`nibp_diastolic_combine_gap_${i}`}
                    isAnimationActive={false}
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    stroke={BP_COLORS[4].dbp}
                    dot={false}
                    connectNulls={true}
                    activeDot={false}
                    // xAxisId={1}
                  />
                  <Line
                    hide={!bpFilter.includes(COMBINE)} // data={bpSeries["cloud"]}
                    type={"monotone"}
                    dataKey={`nibp_systolic_combine_gap_${i}`}
                    isAnimationActive={false}
                    strokeWidth={2}
                    strokeDasharray="5 5"
                    stroke={BP_COLORS[4].sbp}
                    dot={false}
                    connectNulls={true}
                    activeDot={false}
                    // xAxisId={1}
                  />
                </React.Fragment>
              );
            })}

            <Tooltip
              content={<CustomTooltip />}
              labelFormatter={(t) => moment(t).format("HH:mm")}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </ChartPlaceholder>
      {noWarning || hideErrorIfThereIs ? (
        <WarningArea>
          <div style={{ fontWeight: "700", fontSize: "20px" }}>{""}</div>
        </WarningArea>
      ) : (
        <WarningArea>
          {aiWarnings
            .slice(aiWarnings.length - 1, aiWarnings.length)
            .map((x, i) => {
              let nfs = x.notification;
              if (!nfs) return null;

              return (
                <div key={i} style={{ width: "80%", marginBottom: "1rem" }}>
                  <div style={{ fontWeight: "500", fontSize: "20px" }}>
                    Warnings:
                  </div>
                  {/* <TimeText>{moment(x.ts).format("HH:mm")}</TimeText> */}
                  <ul>
                    {nfs
                      .filter((m) => NOTIFICATION_TO_DETAILS[m])
                      .map((n, _i) => {
                        return (
                          <li key={_i}>
                            <strong>{NOTIFICATION_TO_DETAILS[n].title}</strong>
                            {": "}
                            {NOTIFICATION_TO_DETAILS[n].verbal}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              );
            })}
        </WarningArea>
      )}
      <BpSquareTool
        items={shortTerm.map((x) => {
          // console.log("combin", x?.["nibp_systolic"], x?.["nibp_diastolic"]);
          return {
            s: x?.nibp_systolic,
            d: x?.nibp_diastolic,
          };
        })}
      />
      <>
        <TabHeading className="patient-statistics-heading">BP AI</TabHeading>

        <TopDayNight>
          <div className="bp-daynight">
            <div>{`Night: ${nightAiStats.sbp}/${nightAiStats.dbp}`}</div>
            <div>{`Day: ${dayAiStats.sbp}/${dayAiStats.dbp}`}</div>
          </div>
        </TopDayNight>
        <ChartPlaceholder>
          <ResponsiveContainer height={320}>
            <ComposedChart
              data={xDataAi}
              onClick={(x) => {
                // console.log("ComposedChart: onClick: x = ", x);
                let d = (x?.activePayload || []).find(
                  (x) => x.dataKey == "blood_pressure"
                );
                setSelectedBar(d?.payload);
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="t"
                stroke={AXES_COLORX(theme)}
                tickFormatter={(a) => {
                  return moment(a).format("HH:mm");
                }}
              />
              <YAxis stroke={AXES_COLORX(theme)} />
              <Tooltip labelFormatter={(t) => moment(t).format("HH:mm")} />
              <Legend />
              <Bar
                name="Blood Pressure"
                dataKey="blood_pressure"
                fill="#8884d8"
              />
              <Line
                name="Heart Rate"
                type="monotone"
                dataKey="heart rate"
                stroke="#ff7300"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </ChartPlaceholder>
        <BottomDiv>
          <RecordsSpan
            onClick={() => {
              setModalVisible(true);
            }}
          >
            records list
          </RecordsSpan>
        </BottomDiv>
      </>

      {isDebugMode() && (
        <div className="nibp-multi-trend-area">
          <TabHeading className="patient-statistics-heading">
            BP Trends
          </TabHeading>
          <div
            style={{
              width: "100%",
              height: 360,
              marginBottom: 20,
            }}
          >
            {/* <PulseRateTrendsChartTool uuid={id} /> */}
            <VitalMultiTrendsChartTool
              dataType={"non_invasive_blood_pressure"}
              theme={theme}
              subTypes={[
                "awaken_average_diastolic_pressure",
                "awaken_average_systolic_pressure",
                "sleeping_average_systolic_pressure",
                "sleeping_average_diastolic_pressure",
              ]}
              uuid={uuid}
              points={userBpSummaries}
            />
          </div>
        </div>
      )}

      <Sidebar
        visible={modalVisible}
        onCloserClick={() => {
          setModalVisible(false);
        }}
      >
        {modalVisible == false ? null : (
          <div>
            <Tabs
              tabs={[
                {
                  label: "measurements",
                  content: (
                    <TabItem>
                      {points.map((a, i) => {
                        let isLoading = a._id == loadingId;
                        let hasError = a.dbp == undefined || a.dbp == 0;
                        // console.log('loop: a._raw_response = ', a._raw_response);
                        // console.log('loop: a._raw_response?.Quality = ', a._raw_response?.Quality);
                        let dd = a?._raw_response || {};
                        let Q = dd["Quality"] || dd["Quality:"];
                        // try{
                        //     dd = JSON.parse(JSON.stringify(a?._raw_response));
                        //     console.log('loop: dd?.Quality = ', dd?.Quality);
                        //     console.log('loop: dd = ', dd);
                        // }catch(exc){
                        //
                        // }
                        return (
                          <RowItem key={i}>
                            <span>
                              <span>
                                {`${moment(a.start_timestamp).format(
                                  "DD.MM.YYYY HH:mm:ss"
                                )} - ${a.dbp_ai} - ${a.sbp_ai}`}
                                <span
                                  style={{
                                    fontSize: 12,
                                    opacity: 0.5,
                                    marginLeft: 5,
                                  }}
                                >
                                  {Q}
                                </span>
                              </span>
                              <br />
                              {hasError == false ? null : (
                                <span
                                  style={{
                                    fontSize: 10,
                                    fontStyle: "italic",
                                    opacity: 0.5,
                                    color: "red",
                                  }}
                                >
                                  {showBpAMessage(a)}
                                </span>
                              )}
                            </span>
                            {isLoading == true ? (
                              <RowDownload2>loading...</RowDownload2>
                            ) : (
                              <RowDownload
                                onClick={async () => {
                                  setLoadingId(a._id);
                                  let pld = (
                                    await axios.get(
                                      `https://api.study-integration.corsano.com/v2/ds/user/${uuid}/blood-pressure-measurements/${a._id}/download`
                                    )
                                  ).data;
                                  setLoadingId(false);
                                  let txt = JSON.stringify(pld);
                                  download(
                                    `${moment(a.start_timestamp).format(
                                      "DD_MM_YYYY_HH_mm_ss"
                                    )}_spo2_raw_ppg2.json`,
                                    txt
                                  );
                                }}
                              >
                                download
                              </RowDownload>
                            )}
                          </RowItem>
                        );
                      })}

                      <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <EditButton
                          onClick={async () => {
                            if (recalculating == true) {
                              return;
                            }
                            setRecalculating(true);
                            let from = +moment(+dayTimestamp).startOf("day");
                            let to = +moment(+dayTimestamp).endOf("day");
                            await DoctorAPI.recalculateSpo2(uuid, +from, +to);
                            setRecalculating(false);
                            setModalVisible(false);
                          }}
                        >
                          {recalculating == false
                            ? "Recalculate"
                            : "Recalculating..."}
                        </EditButton>
                      </div>
                    </TabItem>
                  ),
                },
                {
                  label: "init",
                  content: (
                    <TabItem>
                      <UserInitBpMeasurementsTool uuid={uuid} />
                    </TabItem>
                  ),
                },
              ]}
            />
          </div>
        )}
      </Sidebar>
      <Sidebar
        visible={selectedBar != undefined}
        width={Math.min(window.innerWidth, 820)}
        onCloserClick={() => {
          setSelectedBar(undefined);
        }}
      >
        {selectedBar == undefined ? null : (
          <div style={{ zIndex: 1 }}>
            <pre
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(fixSelectedBar(selectedBar), null, 2),
              }}
            ></pre>
          </div>
        )}
      </Sidebar>
    </Wrapper>
  );
}
function xFormatter(a) {
  let ss = moment(a).format("HH:mm");
  if (ss == "Invalid date") {
    return "";
  }
  return ss;
}

function fixSelectedBar(d) {
  let res = { ...d };
  if (
    d != undefined &&
    d._raw_response != undefined &&
    typeof d._raw_response == "string"
  ) {
    res._raw_response = JSON.parse(d._raw_response);
  }
  return res;
}

export function alignTimeToHalfHour(ts, cond) {
  /**
   * align timestamp to nearest :00 or :30 before
   **/
  let timeObject;
  if (cond) {
    timeObject = moment(ts);
    let minute = timeObject.minutes();
    if (minute >= 30) {
      timeObject.minutes(30);
      timeObject.seconds(0);
      timeObject.millisecond(0);
    } else {
      timeObject.minutes(0);
      timeObject.seconds(0);
      timeObject.millisecond(0);
    }
  } else {
    timeObject = null;
  }
  return timeObject;
}

const TabHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #000f4b;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
`;

const ModeSwitcherItem = styled.div`
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  cursor: ${(props) => (props.selected ? "default" : "pointer")};
  text-decoration: ${(props) => (props.selected ? "underline" : "none")};
  margin-left: 5px;
  margin-right: 5px;
  width: max-content;
`;

const TopDayNight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const RowItem = styled.div`
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid whitesmoke;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const RowDownload = styled.a`
  font-size: 10px;
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const RowDownload2 = styled.div`
  font-size: 10px;
  opacity: 0.5;
`;

const BottomDiv = styled.div`
  text-align: right;
`;

const RecordsSpan = styled.span`
  font-style: italic;
  opacity: 0.5;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  width: 100%;

  .sidebar__inner {
    background: white;
  }
`;

const TooltipWrapper = styled.div`
  background: #ffffffea;
  padding: 5px;
  border: 1px solid whitesmoke;
  border-radius: 4px;
  width: max-content;
`;

const BpFilterContainer = styled.div`
  display: flex;
  // flex-direction: column;
  gap: 8px;
  align-items: center;

  font-weight: normal;
  font-size: 14px;
  margin-left: 20px;
  // line-height: 28px;
  // border-left: 1px solid grey;

  &:before {
    content: "";
    border: 1px solid #17f;
    margin-right: 1vw;
    align-self: stretch;
  }
`;

const BpFilterItem = styled.div`
  display: flex;
  align-items: baseline;
  // border: 1px solid red;
  // box-sizing: content-box;
  width: max-content;
  max-width: 200px;
`;

const BpFilterCheckbox = styled.input`
  &[type="checkbox"] {
    accent-color: #1e7efa;
  }
`;
const ChartPlaceholder = styled.div`
  height: 320px;
  width: 100%;
`;

const Heading = styled.div`
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-right: 10px;
  font-size: 12px;
  opacity: 0.8;
`;

export function download(filename, text) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
const WarningArea = styled.div`
  margin-left: 1rem;
`;

const SysDiaLegendBox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.8rem;
  // border: 1px solid blue;
`;
const SysDiaColorSpan = styled.span`
  display: inline-block;
  height: 4px;
  opacity: 0.8;
  width: 40px;
  background-color: ${(props) => props.color} !important;
  margin-right: 10px;
`;
