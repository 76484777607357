import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';

import DoctorTemplate from '../templates/DoctorTemplate'
import GroupsPanel from "../groups/panels/GroupsPanel";
import FullPagePreloader from "../preloader/FullPagePreloader";
import DoctorAPI, {gup} from "../../api/DoctorAPI";
import PatientPanel from "../patients/panels/PatientPanel";
import GroupPanel from "../groups/panels/GroupPanel";

function EmbedIndexApp() {
    let health_token = gup('health_token');
    
    let code = gup('code');

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        if (health_token == undefined) {
            return;
        }
        setLoading(true);
        DoctorAPI.getEmbedMe().then(dd => {
            setData(dd);
            setLoading(false);
        });
    }, [health_token]);

    if (health_token == undefined) {
        return (
            <div style={{color: 'red', padding: '10px', textAlign: 'center'}}>
                {'"health_token" query parameter is not provided'}
            </div>
        )
    }

    if (data == undefined && loading == true) {
        return (<FullPagePreloader/>)
    }

    if ((data == undefined || data?.health == undefined) && (loading == true)) {
        return (
            <div>
                user is not found
            </div>
        )
    }

    if (data?.health?.uuid == undefined){
        return null;
    }



    return (
        <Wrapper className='embed-wrapper'>

            {code == undefined ?
                <PatientPanel
                    id={data?.health?.uuid}
                    isEmbedMode={true}
                />
                :
                <GroupPanel
                    code={code}
                />
            }



        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 15px;
  width: 1280px;
  margin: 0 auto;
  background:white;
  @media (max-width: 1280px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export default EmbedIndexApp
