import { useState, useEffect, useRef, PureComponent } from "react";
import moment from "moment";
import styled from "styled-components";
import { Code } from "react-content-loader";
import DoctorAPI from "../../../api/DoctorAPI";
import useDimensions from "react-use-dimensions";
import { STEP_BAR_COLOR, STEP_BAR_COLOR_2ND } from "../../ui/templates/Charts";
import SimpleNoDataMessageTool from "../../sleep/tools/SimpleNoDataMessageTool";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

export default function CsnMixStepsTool(props) {
  const {
    uuid,
    from,
    to,
    startTimestamp,
    // phSteps = [],
    points = [],
    tempActivityLoading,
    stepsTotal = {},
  } = props;

  // console.log({ points });

  const [chartRef, { x, y, width, height }] = useDimensions();

  if (points.length == 0 && tempActivityLoading == true) {
    return <Code />;
  }

  // if (points.length == 0) {
  //     return null;
  // console.log({ points });
  // console.log(points.map((x) => x.label));

  // }

  const xticks = generateHourlyTimestamps(from);
  const barWidth = 1;
  const barSize = 50;

  return (
    <Wrapper ref={chartRef}>
      {!points || points.length === 0 ? (
        <SimpleNoDataMessageTool
          message={"Sorry, there is no steps data for this day."}
        />
      ) : (
        <div style={{ height: height }} className="cn-steps-container">
          <div style={{ textAlign: "right", fontWeight: 500 }}>
            <div>Total Steps (Cn): {stepsTotal.cnTotal}</div>
            <div>Total Steps (Ph): {stepsTotal.phTotal}</div>
          </div>
          <BarChart
            className="bar-chart-wrapper"
            height={height}
            width={width}
            data={points}
            allowDataOverflow={false}
          >
            <XAxis
              dataKey="timestamp"
              ticks={xticks}
              interval={0}
              type="number"
              domain={[from, to]}
              tickFormatter={(x) => {
                const _t = moment(x).format("HH:mm");
                return _t;
              }}
              tick={<CustomizedAxisTick />}
              allowDataOverflow
            />
            <YAxis
              width={41}
              style={{
                boxSizing: "border-box",
                border: "1px solid #c0c0c0",
              }}
            />
            <Bar
              dataKey="last_steps"
              // stroke={STEP_BAR_COLOR}
              key={`cn_steps`}
              fill={STEP_BAR_COLOR}
              barSize={barSize}
            />
            <Bar
              key={`ph_steps`}
              dataKey="ph_steps"
              // stroke={STEP_BAR_COLOR_2ND}
              fill={STEP_BAR_COLOR_2ND}
              barSize={barSize}
            />
            {xticks.map((x, i) => {
              return <ReferenceLine key={`msrl_${i}`} x={x} opacity={0.5} />;
            })}
            <Tooltip
              cursor={{ fill: "#cccccc90" }}
              content={<StepsToolTip />}
            />
          </BarChart>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  height: 300px;
  // background: pink;
  box-sizing: border-box;
  position: relative;
`;

function StepsToolTip(props) {
  const { active, payload, label } = props;
  if (!payload || payload.length < 2) {
    return null;
  }
  // console.log(payload);

  const cnSteps = payload[0]?.value;
  const phSteps = payload[1]?.value;
  if (active && payload && payload.length) {
    const { label = "" } = payload[0]?.payload || {};
    // console.log({ payload });

    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{label}</TooltipRow>
        <TooltipRow className="label" style={{ color: STEP_BAR_COLOR }}>
          Cn Steps: {cnSteps}
        </TooltipRow>
        <TooltipRow className="label" style={{ color: STEP_BAR_COLOR_2ND }}>
          Ph Steps: {phSteps}
        </TooltipRow>
      </TooltipWrapper>
    );
  }
  return null;
}

export const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

export const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;

function generateHourlyTimestamps(startOfDay) {
  const ONE_HOUR = 3600 * 1000; // seconds in an hour
  const result = [];

  for (let i = 0; i < 24; i++) {
    result.push(startOfDay + i * ONE_HOUR);
  }

  return result;
}

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={36}
          y={0}
          dy={6}
          textAnchor="end"
          fill="#666"
          // transform="rotate(-35)"
        >
          {moment(payload.value).format("HH:mm")}
        </text>
      </g>
    );
  }
}
