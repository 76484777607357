import { useState, useEffect, useMemo, useCallback, memo } from "react";
import { useMappedState } from "redux-react-hook";
import axios from "axios";
import ls from "local-storage";
import moment from "moment";
import DoctorAPI from "../../../api/DoctorAPI";

import BinaryMeasurementsPanel from "../binary/panels/BinaryMeasurementsPanel";
// import PatientSurveysPanel from "../../surveys/panels/PatientSurveysPanel";
import Sidebar from "arui-feather/sidebar";
import ContinuousMeasurementsPanel from "./ContinuousMeasurementsPanel";
import NiceModal from "../../modals/NiceModal";
import PpgTypesSelector from "../../export/tools/PpgTypesSelector";
// import BinaryMeasurementsAPI from "../../../api/BinaryMeasurementsAPI";
import ExportServiceTool from "../../export/tools/ExportServiceTool";

// import UserWeeklyRecoveryPanel from "../../recovery/panels/UserWeeklyRecoveryPanel";
import NewExportServiceTool from "../../export/tools/NewExportServiceTool";

import { getLabelsInfoData } from "../../groups/panels/GroupsPanel";
// import PatientDaySpo2Tool from "../../spo2/tools/PatientDaySpo2Tool";
import { isDebugMode, isSleepDebugMode } from "../../../helpers/UiHelper";
// import SimpleTrendsChartTool from "../../trends/tools/SimpleTrendsChartTool";
import PatientDayBpTool from "../../bp/PatientDayBpTool";

import HealthBatteryWidgetTool from "../../health_battery/tools/HealthBatteryWidgetTool";

import AfNsrTool from "../../afib/tools/AfNsrTool";
import AppLogsTool from "../../logs/tools/AppLogsTool";

import {
  fillSummariesEmptyDates,
  getFromToFromOption,
} from "../../trends/tools/TrendsUtils";
import WeightPanel from "./tabs/WeightPanel";
import RiskPanel from "./tabs/RiskPanel";
import SurveyPanel from "./tabs/SurveyPanel";
import SpiroPanel from "./tabs/SpiroPanel";
import OverviewPanel from "./tabs/OverviewPanel";
import {
  TabHeading,
  LogsInnerPlaceholder,
  ContentPlaceholder,
  Wrapper,
  TabNameItem,
  TopPlaceholder,
  TabNamesPlaceholder,
} from "./UiComponents";
import NibpPanel from "./tabs/NibpPanel";
import ActivityPanel from "./tabs/ActivityPanel";
import HrvPanel from "./tabs/HrvPanel";
import AfPanel from "./tabs/AfPanel";
import PulseRatePanel from "./tabs/PulseRatePanel";
import BiozPanel from "./tabs/BiozPanel";
import SleepTabPanel from "./tabs/SleepTabPanel";
import CbtPanel from "./tabs/CbtPanel";
import Spo2Panel from "./tabs/Spo2Panel";
import BrpmPanel from "./tabs/BrpmPanel";
import {
  getChargingSpans,
  getGapAndNotWearingSpans,
  getLowBatterySpans,
} from "../../baselines/BaselineUtils";

const isAFMode = window.location.href.indexOf("af.") > -1;
const hasTopTabs = isAFMode === false;

export const isCorsanoMode = () => {
  try {
    return window.location.href.indexOf("corsano") > -1;
  } catch (exc) {}
  return false;
};

const TABS = [
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "Overview",
        value: "overview",
      },
  {
    label: "Pulse Rate",
    value: "heart_rate",
  },

  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "HRV",
        value: "hrv",
      },

  // window.location.href.indexOf("portal") > -1
  //   ? undefined
  //   : {
  //       label: "AF",
  //       value: "af",
  //     },
  { label: "AF", value: "af" },

  {
    label: "SPO2",
    value: "spo2",
  },
  {
    // label: "Respiration",
    label: "BRPM",
    value: "respiration",
  },

  {
    // label: "Temperature",
    label: "CBT",
    value: "temperature",
  },

  getLabelsInfoData().bioz_enabled === false
    ? undefined
    : {
        label: "NIBP",
        value: "blood_pressure",
      },
  getLabelsInfoData().spiro_enabled === false ||
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "Spiro",
        value: "spiro",
      },
  getLabelsInfoData().weight_enabled === false ||
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "Weight",
        value: "weight",
      },
  getLabelsInfoData().bioz_enabled === false ||
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "BIOZ",
        value: "bioz",
      },
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "Sleep",
        value: "sleep",
      },

  getLabelsInfoData().surveys_enabled === false ||
  window.location.href.indexOf("portal") > -1
    ? undefined
    : {
        label: "Surveys",
        value: "surveys",
      },

  // window.location.href.indexOf("portal") > -1
  //   ? undefined
  //   :
  {
    label: "Activity",
    value: "activity",
  },
  {
    label: "RISK",
    value: "mpi",
  },
  // (getLabelsInfoData().health_battery_enabled === false ? undefined : {
  //     label: 'ER',
  //     value: 'health_battery'
  // }),
].filter((x) => x !== undefined);

function getTemperaturePoints(summary) {
  let pts = summary?.temperature?.slots || [];
  let isSk1 = pts.reduce(
    (res, pt) => (pt.temp_sk1 !== undefined && pt.temp_sk1 !== 0) || res,
    false
  );
  let newPoints = pts
    .map((x) => ({
      ...x,
      // temperature: isSk1 ? x.temp_sk1 : x.temp_sk2,
      temperature: x.temp_sk1,
    }))
    .filter((x) => x.temperature !== undefined && +x.temperature > 25);
  return newPoints;
}

export default function PatientDayAnalyticsPanel(props) {
  const {
    user,
    hasSleepTab = true,
    isDemo = false,
    patientName,
    isEmbedMode = false,
    appAndDeviceInfo,
    id,
    date, // in format YYYY-MM-DD
    externalDeviceMeasurements,
  } = props;

  const [rrsDownloading, setRrsDownloading] = useState(false);

  const d = new Date();
  let diff = Math.round(d.getTimezoneOffset() / 60);

  let default_tab =
    window.location.href.indexOf("portal") > -1 ? "heart_rate" : "overview";
  // window.location.href.indexOf("portal") > -1 ? "heart_rate" : "blood_pressure";
  const [selectedTab, setSelectedTab] = useState(default_tab);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState(undefined);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [aiSleepLoading, setAiSleepLoading] = useState(false);
  const [aiSleepLoading3, setAiSleepLoading3] = useState(false);
  const [aiSleepStages, setAiSleepStages] = useState(undefined);
  const [aiSleepStages2, setAiSleepStages2] = useState(undefined);
  const [aiSleepStages3, setAiSleepStages3] = useState(undefined);

  const [ntrainSleepStages, setNtrainSleepStages] = useState(undefined);
  const [ntrainId, setNtrainId] = useState(undefined);

  const [goals, setGoals] = useState(undefined);
  const [canShowPhilipsSpo2, setCanShowPhilipsSpo2] = useState(false);

  const [workouts, setWorkouts] = useState([]);
  const [allWorkouts, setAllWorkouts] = useState([]);

  const [exportPpg2Visible, setExportPpg2Visible] = useState(false);

  const [exportToolData, setExportToolData] = useState(undefined);
  const [newExportToolData, setNewExportToolData] = useState(undefined);

  const [debugVisible, setDebugVisible] = useState(false);
  const [baselineWindow, setBaselineWindow] = useState(15); // in minutes

  const [tz, setTz] = useState(moment().utcOffset() / 60);
  const [tempActivityPoints, setTempActivityPoints] = useState([]); // for debug Pulse rate
  const [tempActivityPointsLoading, setTempActivityPointsLoading] =
    useState(false);

  const [cloudBpPoints, setCloudBpPoints] = useState([]);
  const [activityPoints, setActivityPoints] = useState([]); // for day bp
  const [activityPointsLoading, setActivityPointsLoading] = useState(false);
  const [bpTimeMode, setBpTimeMode] = useState("day");
  const [freshestData, setFreshestData] = useState([]);
  const [freshestNotification, setFreshestNotification] = useState([]);

  const [userBpSummaries, setUserBpSummaries] = useState([]);
  const [userSleepSpo2Summaries, setUserSleepSpo2Summaries] = useState([]);

  const [tempPoints, setTempPoints] = useState([]); // for temperature
  const [tempPointsLoading, setTempPointsLoading] = useState(false);

  const [bpmBaselinePoints, setBpmBaselinePoints] = useState([]);
  const defaultEmptyAllTypesMap = {
    bpm: [],
    respiration_rate: [],
    spo2: [],
    temperature: [],
  };
  const [allTypeBaselineMap, setAllTypeBaselineMap] = useState(
    defaultEmptyAllTypesMap
  );
  const [loadingAllTypeBaseline, setLoadingAllTypeBaseline] = useState(false);

  const [gapAndNotWearingSpans, setGapAndNotWearingSpans] = useState([]);
  const [chargingSpans, setChargingSpans] = useState([]);
  const [lowBatterySpans, setLowBatterySpans] = useState([]);
  const [moveAlertSpans, setMoveAlertSpans] = useState([]);

  const [cuffInit, setCuffInit] = useState([]);

  const [sessionMode, setSessionMode] = useState("current-day");
  const [vitalParams, setVitalParams] = useState({});

  const [vitalSummaryInRange, setVitalSummaryInRange] = useState([]);

  const [noonToNoonSleepData, setNoonToNoonSleepData] = useState([]);
  const [noonToNoonActivityCount, setNoonToNoonActivityCount] = useState([]);
  const [noonToNoonSleepDataLoading, setNoonToNoonSleepDataLoading] =
    useState(false);

  const [temperatureSpot, setTemperatureSpot] = useState([]);
  // const [externalDeviceMeasurements, setExternalDeviceMeasurements] = useState({
  //   pulse_rate: [],
  //   respiration_rate: [],
  //   spo2: [],
  //   temperature: [],
  //   temperature_spots: [],
  //   blood_pressure: [],
  // });

  const { unit } = useMappedState(
    useCallback((state) => {
      return {
        unit: state.ui.unit,
      };
    })
  );
  const theme = ls.get("theme");
  const displayFullDay = true;

  const fetchData = async (setLoading, tz, signal) => {
    /**
     * load bpm, respiration rate, spo2 data from api
     */
    // console.log("fetching data...");

    let rrDataFrom = moment(date).startOf("day").subtract(1, "hour").valueOf();
    let rrDataTo = moment(date).endOf("day").subtract(0, "hour").valueOf();
    if (setLoading) {
      setLoadingAllTypeBaseline(true);
    }
    setAllTypeBaselineMap(defaultEmptyAllTypesMap);
    DoctorAPI.getUserBaselineAllType(id, rrDataFrom, rrDataTo, signal)
      .then((d) => {
        // console.log("alltypes", d);
        setAllTypeBaselineMap(d);
        // TODO: cache
        // const nowTs = Date.now()
        // ls.set(`${nowTs}_${id}_types`, d)
        const _gapAndNotWearingSpans = getGapAndNotWearingSpans(d["bpm"]);
        setGapAndNotWearingSpans(_gapAndNotWearingSpans);

        const _lowBatterySpans = getLowBatterySpans(d["bpm"]);
        // console.log({lowBatterySpans});
        setLowBatterySpans(_lowBatterySpans);

        const _chargingSpans = getChargingSpans(d["bpm"]);
        // console.log({ _chargingSpans });
        setChargingSpans(_chargingSpans);

        if (setLoading) {
          setLoadingAllTypeBaseline(false);
        }
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error(e);
      });
  };

  const toggleBpMode = (newMode) => {
    if (newMode !== "day" && newMode !== "12-12") {
      return;
    }
    setBpTimeMode(newMode);
  };
  let { customer_tag, healthUser } = user;

  useEffect(() => {
    if (customer_tag == undefined) {
      return;
    }
    const controller = new AbortController();
    const { signal } = controller;
    DoctorAPI.getStudyHealthUserSettings(customer_tag, signal)
      .then((d) => {
        setVitalParams(d?.vital_parameters);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error("gSHUS", e);
      });
    return () => {
      controller.abort();
    };
  }, [user]);

  useEffect(() => {
    const secondsPerUpdate = 60 * 1000; // fetch chart contents every 60 seconds
    let dateObj = moment(date);
    let isToday = dateObj.isSame(new Date(), "day");
    // console.log("istoday", isToday);
    let tz = 0; // reserved for timezone
    const controller = new AbortController();
    const { signal } = controller;
    fetchData(true, tz, signal);
    if (!isToday) {
      return () => {
        controller.abort();
      };
    }
    const interval = setInterval(() => {
      fetchData(false, tz, signal);
    }, secondsPerUpdate);
    return () => {
      controller.abort();
      clearInterval(interval);
    };
  }, [date, id]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (window.location.href.indexOf("study.corsano.com") > -1) {
      DoctorAPI.getPatientGoals(id, isDemo, signal)
        .then((d) => {
          setGoals(d);
        })
        .catch((e) => {
          if (axios.isCancel(e)) {
            return;
          }
          console.error("gPG", e);
        });
    }
    return () => {
      controller.abort();
    };
  }, [id]);

  useEffect(() => {
    if (date === undefined) {
      return;
    }
    const yearAgo = moment().subtract(1, "year").format("YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");

    const controller = new AbortController();
    const { signal } = controller;
    // setLoading(true);
    setSummary(undefined);
    setSummaryLoading(true);
    DoctorAPI.getUserDaySummary(id, date, isDemo, signal)
      .then((pld) => {
        // setLoading(false);
        // setSummaryLoading(false);
        setSummary(pld);
        setNtrainSleepStages(undefined);
        setNtrainId(undefined);

        if (
          !(
            pld === undefined ||
            pld.sleep === undefined ||
            pld.sleep.slots === undefined
          )
        ) {
          // if (isDebugMode() === true || isSleepDebugMode() === true) {
          //   CalcAPI.getNTrainSleepStages3(id, date)
          //     .then((d) => {
          //       setNtrainSleepStages(
          //         (d.points || []).map((x) => ({ ...x, sleepStage: x.value }))
          //       );
          //       setNtrainId(d.id);
          //       setAiSleepLoading(false);
          //     })
          //     .catch((e) => console.error(e));
          // }
        }
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error("gUDS", e);
      })
      .finally(() => {
        setLoading(false);
        setSummaryLoading(false);
        setNtrainSleepStages(undefined);
        setNtrainId(undefined);
      });
    setWorkouts([]);

    DoctorAPI.getUserDayWorkouts(id, date, false, signal)
      .then((arr) => {
        setWorkouts(arr);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error("gUDW", e);
      });
    // return () => {
    //   controller.abort();
    // };

    DoctorAPI.getUserDayWorkoutsInRange(id, yearAgo, today, signal)
      .then((arr) => {
        const calendar_dates = arr.map((x) => x.calendar_date);
        // console.log({ calendar_dates });
        // console.log({ arr });
        let workoutArr = [];
        // Minutes / Steps / Calories / Distance / Avg PR / Max PR / Avg Speed / Max Speed

        calendar_dates.forEach((date, i) => {
          const _arr = arr.filter((x) => x.calendar_date === date)[0];
          // console.log(_arr);
          const {
            max_heart_rate,
            avg_heart_rate,
            avg_bracelet_speed,
            avg_gps_speed,
            is_finished,
            end_date,
            start_date,
            total_calories,
            total_distance,
            total_steps,
          } = _arr;

          const startDateTimestamp = +moment(start_date).valueOf();
          const endDateTimestamp = +moment(end_date).valueOf();
          const duration = endDateTimestamp - startDateTimestamp;
          const durationInMinute = Math.ceil(duration / (1000 * 60));

          // console.log(durationInMinute);

          // console.log({
          //   startDateTimestamp,
          //   start_date,
          //   endDateTimestamp,
          //   end_date,
          //   duration,
          // });

          const workoutObj = {
            date: date,
            minutes: durationInMinute,
            max_heart_rate,
            avg_heart_rate,
            avg_bracelet_speed,
            avg_gps_speed,
            is_finished,
            end_date,
            start_date,
            total_calories,
            total_distance,
            total_steps,
          };
          workoutArr.push({ date: date, workout: workoutObj });
        });
        // console.log(workoutArr);
        const filledWorkoutArr = fillSummariesEmptyDates(workoutArr);

        setAllWorkouts(filledWorkoutArr);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error("gUDWIR", e);
      });

    DoctorAPI.getUserBloodPressureSummariesInRange(id, yearAgo, today, signal)
      .then((arr) => {
        const _arr = arr;
        // console.log({ bpsummary: arr });
        const filledArr = fillSummariesEmptyDates(_arr);
        //   .filter((a) => a?.non_invasive_blood_pressure)
        //   .filter(
        //     (a) =>
        //       a.non_invasive_blood_pressure.awaken_average_diastolic_pressure &&
        //       a.non_invasive_blood_pressure.awaken_average_systolic_pressure &&
        //       a.non_invasive_blood_pressure
        //         .sleeping_average_diastolic_pressure &&
        //       a.non_invasive_blood_pressure.sleeping_average_systolic_pressure
        //   );

        setUserBpSummaries(filledArr);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error("gUBPSIR", e);
      });

    DoctorAPI.getUserSleepSpo2Summaries(id, yearAgo, today, signal)
      .then((arr) => {
        // console.log({ arr });
        const _arr = arr.map((x, i) => {
          const { spo2 = {}, sleep = {} } = x;
          let spo2Summary = {};
          const slots = spo2?.slots || [];
          // console.log({ slots });

          const average_spo2 = slots
            .filter((x) => x?.spo2 && x.spo2 != null && x.spo2 != undefined)
            .reduce((sum, obj, _, arr) => sum + obj.spo2 / arr.length, 0);

          let night_spo2 = 0;

          const { end_date, start_date } = sleep;
          if (!end_date || !start_date) {
            spo2Summary = {
              average_spo2: average_spo2,
            };
          } else {
            const endTimestamp = +moment(end_date).valueOf();
            const startTimestamp = +moment(start_date).valueOf();
            const spo2SlotsWithinSleep = slots.filter(
              (x) =>
                +x.timestamp < endTimestamp && +x.timestamp > startTimestamp
            );
            // console.log({ slots, endTimestamp, startTimestamp });

            // console.log({
            //   slp: spo2SlotsWithinSleep.length,
            //   full: slots.length,
            // });

            const night_spo2 = spo2SlotsWithinSleep
              .filter((x) => x?.spo2 && x.spo2 != null && x.spo2 != undefined)
              .reduce((sum, obj, _, arr) => sum + obj.spo2 / arr.length, 0);

            spo2Summary = {
              average_spo2,
              night_spo2,
            };
          }

          return { ...x, spo2: { ...x.spo2, ...spo2Summary } };
        });
        // console.log({ _arr });
        // console.log(_arr.map(x=>x.date));

        // const filledArr = fillSummariesEmptyDates(_arr);
        const filledArr = fillSummariesEmptyDates(_arr);
        setUserSleepSpo2Summaries(filledArr);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error("gUSSS", e);
      });
    return () => {
      controller.abort();
    };
  }, [date]);

  useEffect(() => {
    if (date === undefined) {
      return;
    }
    const controller = new AbortController();
    const { signal } = controller;
    let dayTimestamp = +moment(date).startOf("day");
    let activityDataFrom = moment(+dayTimestamp)
      .startOf("day")
      .subtract(0, "hour")
      .valueOf();
    let activityDataTo = moment(+dayTimestamp)
      .startOf("day")
      .add(24.5 * 60, "minute")
      .valueOf();
    let cloudDataFrom = +moment(+dayTimestamp).startOf("day").add(-1, "hours");
    let cloudDatato = +moment(+dayTimestamp).endOf("day");

    if (bpTimeMode != "day") {
      activityDataFrom = +moment(+dayTimestamp)
        .startOf("day")
        .add(-12, "hours");
      activityDataTo = +moment(+dayTimestamp).startOf("day").add(12, "hours");
      cloudDataFrom = +moment(+dayTimestamp).startOf("day").add(-12, "hours");
      cloudDatato = +moment(+dayTimestamp).startOf("day").add(12, "hours");
    }

    // console.log(moment(rrDataFrom).format("DD HH:mm"));
    // console.log(moment(rrDataTo).format("DD HH:mm"));

    setActivityPoints([]);
    setActivityPointsLoading(true);
    setCuffInit([]);
    DoctorAPI.getAIBpInitDataMeasurements(id, signal)
      .then((arr) => {
        setCuffInit(arr);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error("gAIBIDM", e);
      });
    const start = performance.now();

    DoctorAPI.getActivityPoints(id, activityDataFrom, activityDataTo, signal)
      .then((d) => {
        // console.debug(
        //   `getActivityPoints took ${(performance.now() - start).toFixed(2)} ms`
        // );
        setActivityPointsLoading(false);
        if (!d || d.length === 0) {
          return;
        }
        let _ActivityPoints = d;
        _ActivityPoints = _ActivityPoints.map((s) => {
          return {
            ...s,
            t: moment(s.timestamp).valueOf(),
            slot: moment(s.timestamp).valueOf(),
            date: moment(s.timestamp).format("HH:mm:ss"),
          };
        });

        setActivityPoints(_ActivityPoints);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error("gAP", e);
      });
    // setCloudBpPoints
    DoctorAPI.getAIBpData(id, cloudDataFrom, cloudDatato, signal)
      .then((arr) => {
        setCloudBpPoints(arr);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error("gAIBD", e);
      });
    return () => {
      controller.abort();
    };
  }, [date, id, bpTimeMode]);

  useEffect(() => {
    // getting freshest ai bp
    const controller = new AbortController();
    const { signal } = controller;
    let n = 10;
    setFreshestData([]);
    setFreshestNotification([]);
    DoctorAPI.getFreshestAIBpData(id, n, signal)
      .then((arr) => {
        if (arr.length === 0) {
        } else {
          let _freshestNotification = arr[0].notification_codes;
          setFreshestData(arr[0]);
          setFreshestNotification(_freshestNotification || []);
        }
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error("gFAIBD", e);
      });
    return () => {
      controller.abort();
    };
  }, [date, id]);

  useEffect(() => {
    if (date === undefined) {
      return;
    }
    const controller = new AbortController();
    const { signal } = controller;
    let dataFrom = moment(date).startOf("day").subtract(1, "hour").valueOf();
    let dataTo = moment(date).endOf("day").add(tz, "hour").valueOf();
    DoctorAPI.getAggregatedPoints(
      id,
      "temperature",
      ["temp_sk1", "temp_sk2"],
      10,
      dataFrom,
      dataTo,
      signal
    ).then((d) => {
      if (!d || d.length === 0) {
        return;
      }
      // console.log("DoctorAPI.getAggregatedPoints.temperature", d);
      let _Temperature = d;
      _Temperature = _Temperature.map((s) => {
        return {
          temp_sk1:
            s?.temp_sk1 && s?.temp_sk1 >= 33 && s?.temp_sk1 <= 45
              ? s.temp_sk1.toFixed(1)
              : null,

          slot: s.slot,
          t: moment(s.slot).valueOf(),
        };
      });
      setTempPoints(_Temperature);
      setTempPointsLoading(false);
    });
    return () => {
      controller.abort();
    };
  }, [date, id]);

  useEffect(() => {
    if (date === undefined) {
      return;
    }
    // if (!isDebugMode()) {
    //   return;
    // }
    const controller = new AbortController();
    const { signal } = controller;
    let dataFrom = moment(date).startOf("day").subtract(1, "hour").valueOf();
    let dataTo = moment(date).endOf("day").add(tz, "hour").valueOf();
    let rrDataFrom = moment(date).startOf("day").subtract(0, "hour").valueOf();
    let rrDataTo = moment(date).endOf("day").subtract(0, "hour").valueOf();
    setTempActivityPointsLoading(true);
    setTempPointsLoading(true);

    setTempActivityPoints([]);
    DoctorAPI.getTempActivityPoints(id, dataFrom, dataTo, signal)
      .then((d) => {
        // console.log("let _tempActivityPoints", d);
        if (!d || d.length === 0) {
          return;
        }

        let _tempActivityPoints = d;

        let _tzs = d.map((s) => s?.date).filter((s) => s);
        let currentZone = moment().utcOffset() / 60;
        // console.log("tzs", moment().utcOffset());

        // getting how many hour difference between current client and browser
        // console.log("_tzs", _tzs);

        if (_tzs.length === 0) {
          setTz(currentZone);
        } else {
          let _tz = _tzs[_tzs.length - 1];
          let zone = moment.parseZone(_tz).utcOffset() / 60;
          setTz(currentZone - zone);
        }
        // console.log("timezone info", tz);

        _tempActivityPoints = _tempActivityPoints.map((s) => {
          return {
            ...s,
            bpm: s.bpm_q ? s.bpm : null,
            bpm_q: s?.bpm_q,
            t: moment(s.date).valueOf(),
            timestamp: moment(s.date).valueOf(),
            date: s.date,
            slot: s.date,
            slot_with_tz: s.date,
            spo2: s.spo2_q ? s.spo2 : null,
            spo2_q: s?.spo2_q,
          };
        });

        setTempActivityPoints(_tempActivityPoints);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error("gTAP", e);
      });
    return () => {
      controller.abort();
    };
  }, [date, id]);

  useEffect(() => {
    if (id == undefined) {
      return;
    }
    const controller = new AbortController();
    const { signal } = controller;
    const defaultSelectedOption = "1Y";
    const { from, to } = getFromToFromOption(defaultSelectedOption);
    // console.log({from, to});

    setVitalSummaryInRange([]);
    DoctorAPI.getUserLightSummariesInRange(id, from, to, false, signal)
      .then((d) => {
        // const filledD = fillSummariesEmptyDates(d);
        const filledD = d;
        setVitalSummaryInRange(filledD);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error("gULSIR", e);
      });
    return () => {
      controller.abort();
    };
  }, [date, id]);

  useEffect(() => {
    if (window.location.href.indexOf("portal.corsano") > 0) {
      return;
    }

    if (!summary?.activity?.slots || summary?.activity?.slots.length === 0) {
      return;
    }
    const controller = new AbortController();
    const { signal } = controller;

    const firstAcvititySlotDate = summary?.activity?.slots[0];
    // console.log("firstAcvititySlotDate", firstAcvititySlotDate);
    const firstDateOfPatientLocation = firstAcvititySlotDate?.date;
    let browserTimestampHour = 0;
    if (firstDateOfPatientLocation === "00:00") {
      const timestamp = firstAcvititySlotDate?.timestamp;
      const browserTimestampDate = moment(timestamp).format("Do HH:mm");
      const offsetHour = +moment(timestamp).hour();

      if (offsetHour > 12) {
        browserTimestampHour = -(24 - offsetHour);
      } else {
        browserTimestampHour = offsetHour;
      }
      // console.log({ timestamp, browserTimestampDate, browserTimestampHour });
    }

    let offsetActivityDataFrom = moment(date)
      .startOf("day")
      .subtract(12 - browserTimestampHour, "hour")
      .valueOf();
    let offsetActivityDataTo = moment(date)
      .startOf("day")
      .add(12 + browserTimestampHour, "hour")
      .valueOf();

    // console.log(moment(offsetActivityDataFrom).format("Do HH:mm"));
    // console.log(moment(offsetActivityDataTo).format("Do HH:mm"));

    setNoonToNoonActivityCount([]);
    DoctorAPI.getActivityPoints(
      id,
      offsetActivityDataFrom,
      offsetActivityDataTo,
      signal
    )
      .then((d) => {
        setActivityPointsLoading(false);
        if (!d || d.length === 0) {
          return;
        }

        const _noonToNoonActivityCount = d.map((x) => {
          return {
            ...x,
            activity_count: x?.activity_count,
            timestamp: x?.timestamp,
            t: moment(x.timestamp).valueOf(),
            slot: moment(x.timestamp).valueOf(),
            date: moment(x.timestamp).format("HH:mm:ss"),
          };
        });

        setNoonToNoonActivityCount(_noonToNoonActivityCount);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error("offset gNTN", e);
      })
      .finally(() => {
        // console.log("force stop");
      });
    return () => {
      controller.abort();
    };
  }, [date, id, summary]);

  useEffect(() => {
    // console.log("RUN noontonoon loading...");

    setNoonToNoonSleepData([]);
    if (window.location.href.indexOf("portal.corsano") > 0) {
      return;
    }
    if (!summary?.activity?.slots || summary?.activity?.slots.length === 0) {
      return;
    }
    const controller = new AbortController();
    const { signal } = controller;

    const firstAcvititySlotDate = summary?.activity?.slots[0];
    // console.log("firstAcvititySlotDate", firstAcvititySlotDate);
    const firstDateOfPatientLocation = firstAcvititySlotDate?.date;
    let browserTimestampHour = 0;
    if (firstDateOfPatientLocation === "00:00") {
      const timestamp = firstAcvititySlotDate?.timestamp;
      const browserTimestampDate = moment(timestamp).format("Do HH:mm");
      const offsetHour = +moment(timestamp).hour();
      if (offsetHour > 12) {
        browserTimestampHour = 24 - offsetHour;
      } else {
        browserTimestampHour = offsetHour;
      }
      // console.log({ timestamp, browserTimestampDate, browserTimestampHour });
    }

    // console.log({ browserTimestampHour });

    setNoonToNoonSleepDataLoading(true);
    // TODO: (2025 Feb 26) should have tz here before making request
    DoctorAPI.getPatientNoonToNoonSleep(id, date, browserTimestampHour, signal)
      .then((d) => {
        setNoonToNoonSleepDataLoading(false);
        setNoonToNoonSleepData(d);
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return;
        }
        console.error("gPNTNS", e);
      })
      .finally(() => {
        setNoonToNoonSleepDataLoading(false);
      });
    // DoctorAPI.getActivityPoints(
    //   id,
    //   offsetActivityDataFrom,
    //   offsetActivityDataTo
    // ).then((d) => {});
    return () => {
      controller.abort();
    };
  }, [date, id, summary]);

  // useEffect(() => {
  //   DoctorAPI.getPatientDaySleep(id, date)
  //     .then((d) => {
  //       console.log({ d });
  //     })
  //     .catch((e) => {})
  //     .finally(() => {});
  // }, [date, id]);

  // useEffect(() => {
  //   DoctorAPI.getUserBaselineAllType(id, rrDataFrom, rrDataTo).then((d) => {
  //     if (!d || d.length === 0) {
  //       return;
  //     }
  //     console.log("DoctorAPI.getUserBaselineAllType", d);
  //   });
  // }, [date, id]);

  let hasAfTab =
    vitalParams && vitalParams.hasOwnProperty("afib")
      ? vitalParams["afib"] === 0
      : false;

  const deviceType = appAndDeviceInfo?.deviceInfo?.device_type || "";
  let hasNIBPTab = !deviceType.includes("B1");

  // const vitalFrequency = ["respiration_rate", "spo2", "heart_rate"].map((x) => {
  //   const _item = ITEMS.find((item) => item.name === x);
  //   const options = _item.options;
  //   const enabledOption = options.find(
  //     (option) => option.value === vitalParams[x]
  //   );
  //   // vitalParams[x]
  //   // console.log(enabledOption);
  //   return options?.label;
  // });
  // console.log(vitalFrequency);

  if (window.location.href.indexOf("portal.corsano") < 0) {
    hasAfTab = true;
  }
  // console.log("hasAfTab", hasAfTab);

  let seltT = +moment(date);

  let expUserId = user === undefined ? undefined : user.uuid;

  let newTempPoints = getTemperaturePoints(summary);

  // let hasNoRRData =
  //   !allTypeBaselineMap || !allTypeBaselineMap?.respiration_rate;

  let hasNoRRData =
    summary === undefined ||
    summary?.respiration_rate === undefined ||
    summary?.respiration_rate.slots === undefined;

  let hasNoTempData =
    summary === undefined ||
    summary.temperature === undefined ||
    summary.temperature.slots === undefined;

  let hasNoActivityData =
    summary === undefined ||
    summary.activity === undefined ||
    summary.activity.slots === undefined;

  // console.log("render: canShowPhilipsSpo2 = ", canShowPhilipsSpo2);

  let showCloud = false;
  const lastestCloudDate = moment("2024/10/02", "YYYY/MM/DD");
  if (
    moment(date).isBefore(lastestCloudDate) &&
    !window.location.href.includes("B2WZ9")
  ) {
    showCloud = true;
  }
  // console.log("Show cloud", showCloud);
  let isToday = moment(date).isSame(new Date(), "day");

  const memoizedBp = useMemo(() => {
    return (
      <PatientDayBpTool
        dayTimestamp={+moment(date).startOf("day")}
        uuid={id}
        min={+moment(date).startOf("day")}
        max={+moment(date).startOf("day").add(1, "day").valueOf()}
        cuffInit={cuffInit}
        activityPoints={activityPoints}
        cloudBpPoints={cloudBpPoints}
        activityPointsLoading={activityPointsLoading}
        mode={bpTimeMode}
        toggleBpMode={toggleBpMode}
        freshestData={freshestData}
        freshestNotification={freshestNotification}
        userBpSummaries={userBpSummaries}
      />
    );
  }, [id, date, cuffInit, activityPoints, activityPointsLoading]);

  let weight = healthUser == undefined ? undefined : healthUser.weight;
  let caloriesPerMile = +weight * 2.20462 * 0.5;
  let caloriesPerStep = caloriesPerMile / 1200;
  let stepsToday = 0;

  // let calorieByHour = [];
  // let calorieBy5Mins = [];
  // let stepsByHalfHour = [];

  const FIVE_MINUTES = 5 * 60 * 1000;
  let stepsTotal = {
    cnTotal: 0,
    phTotal: 0,
    clrActive: 0,
    clrTotal: 0,
  };

  // const sss = activityPoints.map((x) => {
  //   const inToday = moment(x.timestamp).isSame(new Date(date), "day");
  //   return {
  //     t: x.timestamp,
  //     c: moment(x.timestamp).format("DD HH:mm:ss"),
  //     steps: x.steps,
  //     today: inToday,
  //   };
  // });
  // console.log({ sss });

  let _stepsTotalPh = activityPoints.reduce((acc, { steps, timestamp }) => {
    const inToday = moment(timestamp).isSame(new Date(date), "day");
    if (!inToday) {
      return acc;
    }
    if (steps) {
      return acc + steps;
    }
    return acc;
  }, 0);

  let _clrTotal = activityPoints.reduce((acc, { energy, timestamp }) => {
    const inToday = moment(timestamp).isSame(new Date(date), "day");
    if (!inToday) {
      return acc;
    }
    if (energy) {
      return acc + energy;
    }
    return acc;
  }, 0);

  let _stepsTotalCn = tempActivityPoints.reduce(
    (acc, { last_steps, timestamp }) => {
      const inToday = moment(timestamp).isSame(new Date(date), "day");
      if (!inToday) {
        return acc;
      }
      if (last_steps) {
        return acc + last_steps;
      }
      return acc;
    },
    0
  );

  // console.log({ _clrTotal, _stepsTotalPh, _stepsTotalCn });

  const sumCal = (_clrTotal / 1000).toFixed(0);

  stepsTotal.cnTotal = _stepsTotalCn;
  stepsTotal.phTotal = _stepsTotalPh;
  stepsTotal.clrTotal = sumCal;

  const summedBy5MinStepsPh = activityPoints.reduce(
    (acc, { timestamp, steps }) => {
      const bucket = Math.floor(timestamp / FIVE_MINUTES) * FIVE_MINUTES;
      acc[bucket] = (acc[bucket] || 0) + steps;
      return acc;
    },
    {}
  );

  const stepsByFiveMinutesResult = Object.entries(summedBy5MinStepsPh) // ph
    .map(([startTimestamp, steps]) => {
      const label = `${moment(+startTimestamp).format("Do HH:mm")} - ${moment(
        +startTimestamp + FIVE_MINUTES
      ).format("Do HH:mm")}`;
      return {
        startTimestamp: Number(startTimestamp),
        timestamp: Number(startTimestamp),
        endTimestamp: Number(startTimestamp) + FIVE_MINUTES,
        steps,
        label: label,
      };
    })
    .sort((a, b) => a.startTimestamp - b.startTimestamp);
  // console.log({ summedBy5MinStepsPh, stepsByFiveMinutesResult });

  const summedBy5MinTempCn = tempActivityPoints.reduce(
    (acc, { timestamp, last_steps }) => {
      if (!moment(timestamp).isSame(moment(date), "day")) {
        return acc;
      }
      const bucket = Math.floor(timestamp / FIVE_MINUTES) * FIVE_MINUTES;
      acc[bucket] = (acc[bucket] || 0) + last_steps;
      return acc;
    },
    {}
  );

  const stepsByFiveMinutesResultCn = Object.entries(summedBy5MinTempCn) // ph
    .map(([startTimestamp, steps]) => {
      const label = `${moment(+startTimestamp).format("Do HH:mm")} - ${moment(
        +startTimestamp + FIVE_MINUTES
      ).format("Do HH:mm")}`;
      return {
        startTimestamp: Number(startTimestamp),
        timestamp: Number(startTimestamp),
        endTimestamp: Number(startTimestamp) + FIVE_MINUTES,
        steps,
        label: label,
      };
    })
    .sort((a, b) => a.startTimestamp - b.startTimestamp);
  // console.log({ summedBy5MinTempCn, stepsByFiveMinutesResultCn });

  const summedBy5MinClrPh = activityPoints.reduce(
    (acc, { timestamp, steps }) => {
      const bucket = Math.floor(timestamp / FIVE_MINUTES) * FIVE_MINUTES;
      acc[bucket] = (acc[bucket] || 0) + steps * caloriesPerStep;
      // steps * caloriesPerStep
      return acc;
    },
    {}
  );

  const clrByFiveMinutesResultPh = Object.entries(summedBy5MinClrPh) // ph
    .map(([startTimestamp, energy]) => {
      const label = `${moment(+startTimestamp).format("Do HH:mm")} - ${moment(
        +startTimestamp + FIVE_MINUTES
      ).format("Do HH:mm")}`;
      const _energy = Math.floor(energy);
      return {
        startTimestamp: Number(startTimestamp),
        timestamp: Number(startTimestamp),
        endTimestamp: Number(startTimestamp) + FIVE_MINUTES,
        energy: _energy,
        label: label,
      };
    })
    .sort((a, b) => a.startTimestamp - b.startTimestamp);
  // console.log({ summedBy5MinClrPh, clrByFiveMinutesResultPh });

  let caloriesToday = isToday
    ? parseInt(_stepsTotalPh * caloriesPerStep)
    : undefined;

  stepsTotal.clrActive = caloriesToday;
  // console.log({ stepsToday, _stepsToday: _stepsTotalPh, caloriesToday });

  let motionMarkerTimestamps = [];

  return (
    <Wrapper
      className="statistics-tab-wrapper"
      style={{
        opacity: loading === true ? 0.5 : 1,
      }}
    >
      {hasTopTabs === false ? null : (
        <TopPlaceholder>
          <TabNamesPlaceholder>
            {TABS.filter((a) => isDemo === false || a.value !== "hrv")
              .filter((a) => !(hasSleepTab === false && a.value === "sleep"))
              .filter((a) => !(hasAfTab === false && a.value === "af"))
              .filter(
                (a) =>
                  !(
                    hasNIBPTab === false &&
                    (a.value === "blood_pressure" ||
                      a.value === "bioz" ||
                      a.value === "af" ||
                      a.value === "spo2" ||
                      a.value === "spiro")
                  )
              )
              .map((a, i) => {
                let isSelected = a.value === selectedTab;

                return (
                  <TabNameItem
                    className="statistics-tab-name"
                    theme={theme}
                    key={i}
                    selected={isSelected}
                    onClick={() => {
                      setSelectedTab(a.value);
                    }}
                    style={{ flexDirection: a?.value === "mpi" ? "row" : "" }}
                  >
                    {a.label}
                    {a.value === "mpi" && (
                      <i style={{ fontSize: "small", fontWeight: 400 }}>
                        &nbsp;{`beta`}
                      </i>
                    )}
                  </TabNameItem>
                );
              })}
          </TabNamesPlaceholder>
        </TopPlaceholder>
      )}

      {isAFMode === false ? null : (
        <AfNsrTool uuid={id} t={+moment(date).startOf("day")} />
      )}

      {hasTopTabs === false ? null : (
        <ContentPlaceholder
          key={`ssst_${selectedTab}`}
          className="patient-statistics-content"
        >
          {selectedTab !== "overview" ? null : (
            <OverviewPanel
              id={id}
              date={date}
              theme={theme}
              summary={summary}
              goals={goals}
              isDemo={isDemo}
              loading={loading}
              seltT={seltT}
              isEmbedMode={isEmbedMode}
              workouts={workouts}
              sessionMode={sessionMode}
              appAndDeviceInfo={appAndDeviceInfo}
              AppLogsTool={AppLogsTool}
              setSessionMode={setSessionMode}
              gapAndNotWearingSpans={gapAndNotWearingSpans}
              chargingSpans={chargingSpans}
              lowBatterySpans={lowBatterySpans}
            />
          )}
          {selectedTab !== "hrv" ? null : (
            <HrvPanel id={id} theme={theme} seltT={seltT} />
          )}

          {selectedTab !== "af" ? null : <AfPanel id={id} date={date} />}

          {selectedTab !== "heart_rate" ? null : (
            <PulseRatePanel
              id={id}
              date={date}
              seltT={seltT}
              user={user}
              summary={summary}
              loading={loading}
              theme={theme}
              baselineWindow={baselineWindow}
              tz={tz}
              loadingAllTypeBaseline={loadingAllTypeBaseline}
              allTypeBaselineMap={allTypeBaselineMap}
              gapAndNotWearingSpans={gapAndNotWearingSpans}
              chargingSpans={chargingSpans}
              lowBatterySpans={lowBatterySpans}
              vitalSummaryInRange={vitalSummaryInRange}
              tempActivityPoints={tempActivityPoints}
              isDemo={isDemo}
              externalMeasurements={
                externalDeviceMeasurements?.pulse_rate ?? []
              }
              allWorkouts={allWorkouts}
            />
          )}

          {selectedTab !== "bioz" ? null : <BiozPanel id={id} date={date} />}

          {selectedTab !== "activity" ? null : (
            <ActivityPanel
              id={id}
              date={date}
              // clrByFiveMinutesResultPh
              // stepsByFiveMinutesResult
              // stepsByFiveMinutesResultCn
              activityPoints={activityPoints}
              stepPhBy5Min={stepsByFiveMinutesResult}
              stepCnBy5Min={stepsByFiveMinutesResultCn}
              clrPhBy5Min={clrByFiveMinutesResultPh}
              stepsTotal={stepsTotal}
              // calorieByHour={calorieBy5Mins} // deprecated
              // stepsByHalfHour={stepsByHalfHour} // deprecated
              caloriesToday={caloriesToday}
              summary={summary}
              summaryLoading={summaryLoading}
              hasNoActivityData={hasNoActivityData}
              workouts={workouts}
              tempActivityPoints={tempActivityPoints}
            />
          )}

          {selectedTab !== "sleep" ? null : (
            <SleepTabPanel
              id={id}
              date={date}
              summary={summary}
              theme={theme}
              loading={loading}
              vitalSummaryInRange={vitalSummaryInRange}
              displayFullDay={displayFullDay}
              noonToNoonSleepData={noonToNoonSleepData}
              noonToNoonSleepDataLoading={noonToNoonSleepDataLoading}
              noonToNoonActivityCount={noonToNoonActivityCount}
              // isSleepDebugMode={isSleepDebugMode}
            />
          )}

          {/* {selectedTab !== "sleep" ? null : MemorizedSleepTabPanel} */}

          {selectedTab !== "temperature" ? null : (
            <CbtPanel
              id={id}
              date={date}
              unit={unit}
              theme={theme}
              tz={tz}
              hasNoTempData={hasNoTempData}
              loading={loading}
              baselineWindow={baselineWindow}
              tempPoints={tempPoints}
              tempPointsLoading={tempPointsLoading}
              summary={summary}
              vitalSummaryInRange={vitalSummaryInRange}
              newTempPoints={newTempPoints}
              allTypeBaselineMap={allTypeBaselineMap}
              isDemo={isDemo}
              gapAndNotWearingSpans={gapAndNotWearingSpans}
              chargingSpans={chargingSpans}
              lowBatterySpans={lowBatterySpans}
              temperatureSpot={
                externalDeviceMeasurements?.temperature_spots ?? []
              }
              externalMeasurements={
                externalDeviceMeasurements?.temperature ?? []
              }
            />
          )}

          {selectedTab !== "spo2" ? null : (
            <Spo2Panel
              id={id}
              date={date}
              summary={summary}
              sleepSpo2Summary={userSleepSpo2Summaries}
              theme={theme}
              baselineWindow={baselineWindow}
              allTypeBaselineMap={allTypeBaselineMap}
              tz={tz}
              vitalSummaryInRange={vitalSummaryInRange}
              loadingAllTypeBaseline={loadingAllTypeBaseline}
              motionMarkerTimestamps={motionMarkerTimestamps}
              gapAndNotWearingSpans={gapAndNotWearingSpans}
              chargingSpans={chargingSpans}
              lowBatterySpans={lowBatterySpans}
              loading={loading}
              vitalParams={vitalParams}
              externalMeasurements={externalDeviceMeasurements?.spo2 ?? []}
            />
          )}

          {selectedTab !== "respiration" ? null : (
            <BrpmPanel
              id={id}
              date={date}
              hasNoRRData={hasNoRRData}
              theme={theme}
              baselineWindow={baselineWindow}
              tz={tz}
              allTypeBaselineMap={allTypeBaselineMap}
              loadingAllTypeBaseline={loadingAllTypeBaseline}
              vitalSummaryInRange={vitalSummaryInRange}
              summary={summary}
              loading={loading}
              gapAndNotWearingSpans={gapAndNotWearingSpans}
              chargingSpans={chargingSpans}
              lowBatterySpans={lowBatterySpans}
              externalMeasurements={
                externalDeviceMeasurements?.respiration_rate ?? []
              }
            />
          )}

          {selectedTab !== "weight" ? null : <WeightPanel id={id} />}

          {selectedTab !== "mpi" ? null : (
            <RiskPanel id={id} unit={unit} date={date} />
          )}

          {selectedTab !== "raw" ? null : (
            <div>
              <div style={{ width: "100%" }}>
                <BinaryMeasurementsPanel uuid={id} isDemo={isDemo} />
              </div>
            </div>
          )}

          {selectedTab !== "blood_pressure" ? null : (
            <NibpPanel
              id={id}
              date={date}
              seltT={seltT}
              bpTimeMode={bpTimeMode}
              toggleBpMode={toggleBpMode}
              cloudBpPoints={cloudBpPoints}
              cuffInit={cuffInit}
              activityPoints={activityPoints}
              activityPointsLoading={activityPointsLoading}
              freshestData={freshestData}
              freshestNotification={freshestNotification}
              memoizedBp={memoizedBp}
              showCloud={showCloud}
              userBpSummaries={userBpSummaries}
              isDebugMode={isDebugMode}
              externalMeasurements={
                externalDeviceMeasurements?.blood_pressure ?? []
              }
            />
          )}

          {selectedTab !== "spiro" ? null : <SpiroPanel id={id} />}

          {selectedTab !== "health_battery" ? null : (
            <div>
              <TabHeading className="patient-statistics-heading">
                Energy reserve
              </TabHeading>

              <HealthBatteryWidgetTool uuid={id} date={date} />
              {/*<HealthBatteryTool*/}
              {/*    uuid={id}*/}
              {/*    from={+moment(date).startOf('day')}*/}
              {/*    to={+moment(date).endOf('day')}*/}
              {/*/>*/}
            </div>
          )}

          {selectedTab !== "surveys" ? null : (
            <SurveyPanel id={id} date={date} />
          )}

          <Sidebar
            visible={exportPpg2Visible && debugVisible}
            width={Math.min(720, window.innerWidth)}
            onCloserClick={() => {
              setDebugVisible(false);
            }}
          >
            {exportPpg2Visible === false ? null : (
              <div>
                <ContinuousMeasurementsPanel
                  type={"ppg2"}
                  userId={id}
                  from={+moment(date).startOf("day")}
                  to={+moment(date).endOf("day")}
                  canExportMergedWiff={true}
                />
              </div>
            )}
          </Sidebar>

          {exportPpg2Visible === false ? null : (
            <NiceModal
              onClose={() => {
                setExportPpg2Visible(false);
              }}
            >
              <h3 style={{ paddingLeft: 5 }}>Export PPG</h3>
              <PpgTypesSelector
                onExport={(types) => {
                  // console.log("onExport: types = ", types);

                  setExportToolData({
                    types: types,
                    from: +moment(date).startOf("day"),
                    to: +moment(date).endOf("day"),
                    users: [user],
                    name: `${types.join("_")}_${moment(date)
                      .startOf("day")
                      .format("YYYY-MM-DD-HH-mm-ss")}_${moment(date)
                      .endOf("day")
                      .format("YYYY-MM-DD-HH-mm-ss")}_${expUserId}.zip`,
                    expUserId: expUserId,
                  });

                  // let from = +moment(date).startOf('day');
                  // let to = +moment(date).endOf('day');
                  // let userId = id;
                  // let type = 'ppg2';
                  // let url = BinaryMeasurementsAPI.getInstance().getContinuousMeasurementsPPG2FileUrl(userId, type, from, to, types);
                  // console.log('--->>> url = ', url);
                  // window.open(url, '_blank').focus();
                }}
              />

              <div style={{ marginTop: 20, opacity: 0.5, textAlign: "center" }}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setDebugVisible(true);
                  }}
                >
                  show debug info
                </span>
              </div>
            </NiceModal>
          )}

          {/*<Sidebar visible={exportBiozVisible} onCloserClick={() => {*/}
          {/*    setExportBiozVisible(false);*/}
          {/*}}>*/}
          {/*    {exportBiozVisible === false ? null :*/}
          {/*        <div>*/}
          {/*            <ContinuousMeasurementsPanel*/}
          {/*                type={'bioz'}*/}
          {/*                userId={id}*/}
          {/*                from={+moment(date).startOf('day')}*/}
          {/*                to={+moment(date).endOf('day')}*/}
          {/*            />*/}
          {/*        </div>*/}
          {/*    }*/}
          {/*</Sidebar>*/}
        </ContentPlaceholder>
      )}

      {exportToolData === undefined ? null : (
        <NiceModal
          onClose={() => {
            setExportToolData(undefined);
          }}
        >
          <LogsInnerPlaceholder>
            <ExportServiceTool {...exportToolData} />
          </LogsInnerPlaceholder>
        </NiceModal>
      )}

      {newExportToolData === undefined ? null : (
        <NiceModal
          onClose={() => {
            setNewExportToolData(undefined);
          }}
        >
          <LogsInnerPlaceholder>
            <NewExportServiceTool {...newExportToolData} />
          </LogsInnerPlaceholder>
        </NiceModal>
      )}
    </Wrapper>
  );
}
