import moment from "moment";
export const mergeReadiness = (summaries = []) => {
  let arr = JSON.parse(JSON.stringify(summaries));
  for (let i in arr) {
    let { recovery = {}, stress = {} } = arr[i];
    let date1 = recovery?.date;
    let date2 = stress?.date;
    let date = date1 || date2;
    if (date == undefined) {
      continue;
    }
    if (stress != undefined && recovery != undefined) {
      arr[i].stress.readiness = recovery?.value;
    }
  }
  // console.log("after merging: arr = ", arr);
  return arr;
};

export function getFromToFromOption(opt) {
  function getFormatted(t) {
    return moment(+t).format("YYYY-MM-DD");
  }

  let to = +moment().endOf("day");
  let dd = { to: getFormatted(+to) };
  if (opt == "W") {
    dd.from = getFormatted(+moment(to).add(-1, "week"));
  }
  if (opt == "M") {
    dd.from = getFormatted(+moment(to).add(-1, "month"));
  }
  if (opt == "3M") {
    dd.from = getFormatted(+moment(to).add(-3, "month"));
  }
  if (opt == "6M") {
    dd.from = getFormatted(+moment(to).add(-6, "month"));
  }
  if (opt == "MTD") {
    dd.from = getFormatted(+moment(to).startOf("month").startOf("day"));
  }
  if (opt == "YTD") {
    dd.from = getFormatted(+moment(to).startOf("year").startOf("day"));
  }
  if (opt == "1Y") {
    dd.from = getFormatted(+moment(to).add(-12, "month"));
  }
  return dd;
}

function sortedLightSummaryPoints(points) {
  if (!points || points.length === 0) {
    return [];
  }
  const sortedPoints = points.sort((a, b) => {
    const date1 = moment(a.date, "YYYY-MM-DD");
    const date2 = moment(b.date, "YYYY-MM-DD");
    return +date1 - +date2;
  });
  return sortedPoints;
}

export function getPointsFromOption(points, opt) {
  let sortedPoints = sortedLightSummaryPoints(points);
  let to = +moment().endOf("day");

  let selectedPoints = sortedPoints.filter((x, i) => {
    // let _date = getFormatted(x.date);
    const itemDate = moment(x.date, "YYYY-MM-DD");
    let earliest;
    if (opt == "W") {
      earliest = +moment(to).add(-1, "week");
    }
    if (opt == "M") {
      earliest = +moment(to).add(-1, "month");
    }
    if (opt == "3M") {
      earliest = +moment(to).add(-3, "month");
    }
    if (opt == "6M") {
      earliest = +moment(to).add(-6, "month");
    }
    if (opt == "MTD") {
      earliest = +moment(to).startOf("month").startOf("day");
    }
    if (opt == "YTD") {
      earliest = +moment(to).startOf("year").startOf("day");
    }
    if (opt == "1Y") {
      earliest = +moment(to).add(-12, "month");
    }
    return itemDate.isAfter(earliest);
  });

  return selectedPoints;
}

export function mapSummariesZerosToNull(points) {

}

export function fillSummariesEmptyDates(points) {
  let _points = Array(Last365DatesArray.length).fill({});
  Last365DatesArray.forEach((d, i) => {
    const p = points.find((x) => x.date === d);
    const filledObj = {
      ...(p || {}),
      date: p ? p?.date : d,
      filled: !p ? true : false,
    };
    _points[i] = filledObj;
  });
  // console.log({ _points });
  return _points
}

const Last365DatesArray = Array.from({ length: 365 }, (_, i) =>
  moment()
    .subtract(364 - i, "days")
    .format("YYYY-MM-DD")
);
