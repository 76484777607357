import axios from "axios";
import env from "react-dotenv";

const { DOCTORS_API_ENDPOINT = "https://api.study-integration.corsano.com" } =
  env;

const SurveysAPI = {
  async getSurveysInRange(userUUID, from, to, signal) {
    let pld = (
      await axios.get(
        `${DOCTORS_API_ENDPOINT}/surveys/users/${userUUID}/answers`,
        {
          params: {
            from: from,
            to: to,
          },
          signal: signal,
        }
      )
    ).data;
    return pld;
  },
};

export default SurveysAPI;
