import { useEffect, useState } from "react";
import DoctorAPI from "../../../api/DoctorAPI";
import { mergeReadiness, getFromToFromOption } from "./TrendsUtils";
import SimpleTrendsChartTool from "./SimpleTrendsChartTool";
import styled from "styled-components";

export default function ActivityTrendsChartTool(props) {
  const { uuid } = props;
  const dataType = "intab_activity";
  const [selectedOption, setSelectedRangeOption] = useState("M");
  // const [lightSummaryInRange, setLightSummaryInRange] = useState([])
  const [activitySummaryInRange, setActivitySummaryInRange] = useState([]);
  const [loading, setLoading] = useState(false);

  const onChangeSelectedOption = (newOption) => {
    setSelectedRangeOption(newOption);
  };

  useEffect(() => {
    if (uuid == undefined) {
      return;
    }
    const controller = new AbortController();
    const { signal } = controller;
    const { from, to } = getFromToFromOption(selectedOption);
    setLoading(true);
    setActivitySummaryInRange([]);
    setLoading(true);
    // console.log(from, to);

    DoctorAPI.getUserLightSummariesInRange(uuid, from, to, false, signal)
      .then((d) => {
        setLoading(false);
        let d_ = mergeReadiness(d);
        let _activityArr = d_.map((x) => ({ date: x.date, ...x["activity"] }));
        setActivitySummaryInRange(_activityArr);
        // console.log("_heartRateArr", _heartRateArr);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, [selectedOption, uuid]);
  return (
    <Wrapper>
      <SimpleTrendsChartTool
        {...props}
        // dataType={"heart_rate"}
        dataType={dataType}
        loading={loading}
        summaries={activitySummaryInRange}
        selectedOption={selectedOption}
        onChangeSelectedOption={onChangeSelectedOption}
      />
      <span
        style={{
          fontSize: 14,
          fontWeight: 500,
          top: -14,
          position: "relative",
        }}
      >
        Average Daily Steps
      </span>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  display: block;
`;
