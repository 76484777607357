import styled from "styled-components";
import moment from "moment";

export default function BaselineToolTipTool(props) {
  const { active, payload, label, field } = props;
  // console.log(props);

  let showSteps = false;
  let showSeconds = false;
  let showMovement = false;
  if (window.location.href.includes("localhost")) {
    showSteps = true;
    showSeconds = true;
    showMovement = true;
  }
  let baseline_q_key = undefined;
  if (field === "spo2") {
    baseline_q_key = "spo2_baseline_q";
  }
  let spo2_spot = payload[0]?.payload?.spo2_spot;
  // console.log({ spo2_spot });

  if (active && payload && payload.length) {
    let q = payload[0]?.payload?.[`${field}_q`];
    let baseline_q = payload[0]?.payload?.[baseline_q_key];
    if (q && baseline_q && (q > 100 || baseline_q > 5)) {
      q = 1;
      baseline_q = 1;
    }
    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{`${moment(label).format(
          showSeconds ? "HH:mm:ss" : "HH:mm"
        )}`}</TooltipRow>
        {spo2_spot ? (
          <TooltipRow className="label">{`Spot Measurement: ${spo2_spot}`}</TooltipRow>
        ) : (
          <>
            <TooltipRow className="label">{`Raw ${fieldMap[field]}: ${payload[0]?.payload?.[field]}`}</TooltipRow>
            <TooltipRow className="label">{`Baseline: ${payload[0]?.payload?.baseline}`}</TooltipRow>
            <TooltipRow className="label">{`Q: ${q}`}</TooltipRow>
            {baseline_q_key && (
              <TooltipRow className="label">{`Baseline Q: ${baseline_q}`}</TooltipRow>
            )}
            {showSteps && (
              <TooltipRow className="label">{`Wearing: ${payload[0]?.payload?.wearing}`}</TooltipRow>
            )}
          </>
        )}
      </TooltipWrapper>
    );
  }

  return null;
}

const fieldMap = {
  spo2: "SpO2",
};

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;
