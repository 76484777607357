import SimpleNoDataMessageTool from "../../../sleep/tools/SimpleNoDataMessageTool";
import { ComplianceHeading, TabHeading } from "../UiComponents";
import { isDebugMode } from "../../../../helpers/UiHelper";
import SabirBarChart from "../../../activity/tools/SabirBarChart";
import ContinuousMeasurementsPanel from "../ContinuousMeasurementsPanel";
import DoctorAPI from "../../../../api/DoctorAPI";
import InfoToolBadge from "../../../info/tools/InfoToolBadge";
import SummaryActivityGaitViewTool from "../../../gait/tools/SummaryActivityGaitViewTool";
import ActivityTrendsChartTool from "../../../trends/tools/ActivityTrendsChartTool";
import WorkoutViewTool from "../../../workouts/tools/WorkoutViewTool";
import { Sidebar } from "arui-feather/sidebar";
import moment from "moment";
import { useEffect, useState } from "react";
import { STEP_BAR_COLOR } from "../../../ui/templates/Charts";
import CsnStepsTool from "../../tools/CsnStepsTool";
import CsnMixStepsTool from "../../tools/CsnMixStepsTool";
import CommonHelper from "../../../../helpers/CommonHelper";

const FIVE_MINUTES = 5 * 60 * 1000;

export default function ActivityPanel(props) {
  const {
    id,
    date,
    calorieByHour,
    // caloriesToday,
    // stepsByHalfHour,
    stepCnBy5Min = [],
    stepPhBy5Min = [],
    clrPhBy5Min = [],
    stepsTotal = {},
    summary,
    summaryLoading,
    hasNoActivityData,
    activityPoints = [],
    workouts = [],
    tempActivityPoints = [],
  } = props;

  // console.log(stepsTotal);

  // console.log({ clrPhBy5Min });

  // console.log({ sumCal });

  const [selectedWorkoutId, setSelectedWorkoutId] = useState(undefined);

  const [tempActivityLoading, setTempActivityLoading] = useState(false);
  // const [tempActivity, setTempActivity] = useState([]);
  // const [stepsTotal, setStepsTotal] = useState({
  //   cnTotal: 0,
  //   phTotal: 0,
  //   clrActive: 0,
  //   clrTotal: 0,
  // });

  let selectedWorkout =
    selectedWorkoutId == undefined
      ? undefined
      : workouts.find((x) => x._id == selectedWorkoutId);

  // console.log({ stepCnBy5Min, stepPhBy5Min });

  const mergedObj = {};

  // Add items from list1

  stepPhBy5Min.forEach((item) => {
    mergedObj[item?.label] = { ...item, ph_steps: item?.steps };
  });

  // Merge items from list2
  stepCnBy5Min.forEach((item) => {
    if (mergedObj[item?.label]) {
      mergedObj[item?.label] = {
        ...mergedObj[item?.label],
        ...item,
        last_steps: item?.steps,
      };
    } else {
      mergedObj[item?.label] = { ...item, last_steps: item?.steps };
    }
  });

  // Convert back to an array
  const mixedSteps = Object.values(mergedObj);
  // console.log(mixedSteps);

  return (
    <div className="activity-tab-container">
      {false && hasNoActivityData ? (
        <SimpleNoDataMessageTool
          loading={summaryLoading}
          message={"Sorry, there is no activity data for this day."}
          hasData={!hasNoActivityData}
        />
      ) : (
        <div></div>
      )}

      <div
        className="energy-expenditure-area"
        style={{ width: "100%", height: "330px", marginTop: 0 }}
      >
        <TabHeading className="patient-statistics-heading">Steps</TabHeading>
        <CsnStepsTool
          points={stepPhBy5Min}
          uuid={id}
          from={+moment(date).startOf("day")}
          to={+moment(date).endOf("day")}
          tempActivityLoading={false}
          stepsTotal={stepsTotal}
          dataKey={"steps"}
          totalLabel={"Total Steps: "}
          totalKey={"phTotal"}
        />
      </div>

      <div
        className="energy-expenditure-area"
        style={{ width: "100%", height: "330px", marginTop: 30 }}
      >
        <TabHeading className="patient-statistics-heading">
          Energy Expenditure
        </TabHeading>
        <CsnStepsTool
          points={clrPhBy5Min}
          uuid={id}
          from={+moment(date).startOf("day")}
          to={+moment(date).endOf("day")}
          tempActivityLoading={false}
          stepsTotal={stepsTotal}
          dataKey={"energy"}
          totalLabel={"Total Calorie:"}
          totalKey={"clrActive"}
        />
      </div>

      {isDebugMode() && (
        <div className="activity-temp-5min-area" style={{ marginTop: 50 }}>
          <TabHeading className="patient-statistics-heading">
            Activity Temp (Cn)
          </TabHeading>

          <CsnStepsTool
            uuid={id}
            from={+moment(date).startOf("day")}
            to={+moment(date).endOf("day")}
            points={stepCnBy5Min}
            tempActivityLoading={false}
            stepsTotal={stepsTotal}
            dataKey={"steps"}
            // totalLabel={"Total :"}
            totalKey={"cnTotal"}
          />
        </div>
      )}

      {isDebugMode() && (
        <div className="mix-steps-area" style={{ marginTop: 50 }}>
          <TabHeading className="patient-statistics-heading">
            Steps (Mix)
          </TabHeading>
          <CsnMixStepsTool
            uuid={id}
            from={+moment(date).startOf("day")}
            to={+moment(date).endOf("day")}
            // phSteps={stepsByHalfHour}
            points={mixedSteps}
            tempActivityLoading={false}
            stepsTotal={stepsTotal}
            totalKey={"cnTotal"}
          />
        </div>
      )}

      {isDebugMode() && (
        <div style={{ marginTop: 40, marginLeft: 40 }}>
          <ExportButton
            id={id}
            currentDate={date}
            activityPoints={activityPoints}
            tempActivityPoints={tempActivityPoints}
          />
        </div>
      )}

      {summary?.activity === undefined ? null : (
        <div className="activity-gait-area" style={{ marginTop: 40 }}>
          <TabHeading className="patient-statistics-heading">Gait</TabHeading>
          <SummaryActivityGaitViewTool {...(summary?.activity || {})} />
        </div>
      )}

      <div
        className="activity-trends-area"
        style={{ width: "100%", height: 400, marginTop: 50 }}
      >
        <TabHeading className="patient-statistics-heading">Trends</TabHeading>
        <ActivityTrendsChartTool uuid={id} />
      </div>
      {isDebugMode() === false &&
      window.location.href.indexOf("wiff") === -1 ? null : (
        <ContinuousMeasurementsPanel
          type={"acc"}
          userId={id}
          from={+moment(date).startOf("day")}
          to={+moment(date).endOf("day")}
        />
      )}
    </div>
  );
}

const ExportButton = ({
  id,
  currentDate,
  activityPoints,
  tempActivityPoints,
}) => {
  if (!activityPoints && !tempActivityPoints) {
    return;
  }

  // console.log({ activityPoints });
  // console.log({ tempActivity });

  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [error, setError] = useState(null);

  return (
    <div
      onClick={() => {
        setClicked(!clicked);
        setError(null);
        const optimizedActivityPoints = activityPoints.map((item) => {
          const {
            timestamp,
            steps,
            wearing,
            bpm,
            bpm_q,
            date,
            energy,
            activity_count,
          } = item;
          return {
            timestamp: +timestamp,
            ph_step: steps,
            wearing,
            bpm_ph: bpm,
            bpm_ph_q: bpm_q,
            date,
            d: moment(+timestamp).toISOString(),
            energy,
            activity_count_ph: activity_count,
            activity_type_ph: "",
          };
        });

        let optimizedTempActivity = tempActivityPoints.map((item) => {
          if (!moment(item.timestamp).isSame(moment(currentDate), "day")) {
            // console.log(item.date);
            return {};
          }
          const {
            activity_count,
            activity_type,
            last_steps,
            date_without_tz,
            date,
            created_at,
            bpm,
            bpm_q,
            timestamp,
            wearing,
          } = item;
          return {
            cn_step: last_steps,
            date_without_tz,
            date_with_tz: date,
            // created_at,
            d: moment(+timestamp).toISOString(),
            bpm_cn: bpm,
            bpm_q_cn: bpm_q,
            timestamp: +timestamp,
            wearing,
            activity_count_cn: activity_count,
            activity_type_cn: activity_type,
          };
        });

        optimizedTempActivity = optimizedTempActivity.filter(
          (obj) => Object.keys(obj).length > 0
        );

        try {
          const mixedActivity = [
            ...optimizedTempActivity,
            ...optimizedActivityPoints,
          ];

          const sortedMixedActivity = mixedActivity.sort(
            (a, b) => a.timestamp - b.timestamp
          );

          const merged = Object.values(
            sortedMixedActivity.reduce((acc, item) => {
              const t = item.timestamp;
              acc[t] = acc[t] || { time: t };
              Object.assign(acc[t], item);
              return acc;
            }, {})
          );
          // console.log();
          // console.log(sortedMixedActivity);
          let head = `ph_step;cn_step;date;date_with_tz;timestamp;bpm_ph;bpm_ph_q;bpm_cn;bpm_q_cn;activity_type_ph;activity_type_cn;activity_count_ph;activity_count_cn;energy`;
          let csv = `${head}\n${merged
            .map(
              (x, i) =>
                `${x?.ph_step || ""};${x?.cn_step || ""};${x?.d};${
                  x?.date_with_tz
                };${x?.timestamp};${x?.bpm_ph};${x?.bpm_ph_q};${x?.bpm_cn};${
                  x?.bpm_q_cn
                };${x?.activity_type_ph};${x?.activity_type_cn};${
                  x?.activity_count_ph
                };${x?.activity_count_cn};${x?.energy}`
            )
            .join("\n")}`;

          CommonHelper.downloadFile(`steps_data_${id}_${currentDate}.csv`, csv);
        } catch (error) {
          console.error(error);

          setError("Export Error!");
        }
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        cursor: "pointer",
        border: "1px solid black",
        width: "max-content",
        paddingBlock: 10,
        paddingInline: 20,
        borderRadius: 5,
        borderColor: "#ccc",
        color: clicked ? "#000" : hovered ? "#555" : "#888",
        transition: "all 0.2s ease", // smooth effect
        backgroundColor: hovered ? "#eee" : "transparent", // hover and click styles
      }}
    >
      Export Steps Data {error ? `(${error})` : ""}
    </div>
  );
};
