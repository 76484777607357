import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import ls from "local-storage";

/*
Date          Time         Patient          			Vital     		Value          	Status
20230306 11:03       All audio paused	  	NA		NA 		All audio off


Add when periodic notification is displayed:

20230306 12:22       Notification All muted	  	NA		NA 		Active


Add when periodic notification is dismissed:

20230306 12:23       Notification All muted	  	NA		NA 		Dismissed

 */

const ALARMS_0 = [];

const ALARMS = [];

export default function FakeAlarmsTool(props) {
  const darkmode = ls.get("theme") === "dark";
  return (
    <Wrapper>
      <Table>
        <Thead>
          <Tr style={{ color: darkmode ? "white" : "" }}>
            <Th>Event Type</Th>
            <Th>Date</Th>
            <Th>Time</Th>
            <Th>Patient</Th>
            <Th>Vital</Th>
            <Th>Value</Th>
            <Th>Limit</Th>
            <Th>Priority</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>

        <Tbody>
          {ALARMS_0.map((a, i) => {
            return (
              <Tr key={i} style={{ color: darkmode ? "white" : "" }}>
                <Td>{a.eventType}</Td>
                <Td>{a.date}</Td>
                <Td>{a.time}</Td>
                <Td>{a.name}</Td>
                <Td>{a.vital}</Td>
                <Td
                  style={{
                    color: a.color,
                    fontWeight: a.color == undefined ? "normal" : "bold",
                  }}
                >
                  {a.value}
                </Td>
                <Td>{a.limit}</Td>
                <Td>{a.priority}</Td>
                <Td>{a.status}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  box-sizing: border-box;
`;

const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr``;

const Th = styled.th`
  border: 1px solid lightgrey;
  text-align: left;
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 3px;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;
