import { createGlobalStyle } from "styled-components";
import CommonHelper from "./CommonHelper";
import moment from "moment";

/**
 * for sleep data
 */

const TarnsformerHelper = {
  // end_date: "2021-01-18T21:35:59"
  // start_date: "2021-01-18T21:35:00"
  // timestamp: 1611005700000
  // type: 1

  // calendar_date: "2021-02-17"
  // created_at: "2021-02-17 21:26:03"
  // data_source_id: null
  // date: "2021-02-17T02:22:14.000+01:00"
  // date_without_tz: "2021-02-17T02:22:14.000"
  // is_converted_from_old_data: false
  // sleep_q: null
  // sleep_state: 1
  // timestamp: 1613524934000
  // updated_at: "2021-02-17 21:26:03"
  // user_id: "600830d0c24dd44bf5214847"
  // _id: "602d89eb85f5d2aaf53d3dfc"

  getSleepPointsFromRawSleepPoints(raw_points: Array<any>): Array<any> {
    let arr: Array<any> = [];
    for (let i = 0; i < raw_points.length - 1; i++) {
      let currPoint = raw_points[i];
      let nextPoint = raw_points[i + 1];
      let start_date = currPoint.date;
      let end_date = nextPoint.date;
      let type = currPoint.sleep_state;
      arr = arr.concat([{ start_date, end_date, type }]);
    }
    return arr;
  },

  get24HoursStepsArray(
    slots: Array<{ date: string; step_count: number; timestamp: number }>
  ): Array<{ time: string; value: number }> {
    // console.log("get24HoursStepsArray occured! slots = ", slots);
    let arr = Array.from({ length: 24 }).map((a, i) => ({
      time: `${i}`.padStart(2, "0") + ":00",
      value: 0,
    }));
    let getItems = (x: string) =>
      slots.filter((aa) => aa.date.split(":")[0] == x.split(":")[0]);
    arr = arr.map((x) => ({
      ...x,
      value: getItems(x.time).reduce((sum, q) => +sum + +q.step_count, 0),
    }));
    return arr;
  },

  getHeightBySleepStageName(stage: string): number {
    if (stage == "deep") {
      // DEEP
      return 1;
    }
    if (stage == "light") {
      // LIGHT
      return 2;
    }
    if (stage == "rem") {
      // REM
      return 3;
    }
    if (stage == "wake") {
      // AWAKE
      return 4;
    }
    return 0;
  },

  getHeightOfSleepBarByType(type: number): number {
    if (type == 1) {
      // AWAKE
      return 4;
    }
    if (type == 2) {
      // LIGHT
      return 2;
    }
    if (type == 3) {
      // DEEP
      return 1;
    }
    if (type == 4) {
      // REM
      return 3;
    }
    return 0;
  },

  getSleepStageNameByType(type: number): string {
    if (type == 1) {
      // AWAKE
      return "Awake";
    }
    if (type == 2) {
      // LIGHT
      return "Light";
    }
    if (type == 3) {
      // DEEP
      return "Deep";
    }
    if (type == 4) {
      // REM
      return "REM";
    }
    if (type == 101) {
      return "Go to Sleep";
    }
    if (type == 102) {
      return "Sleep Onset";
    }
    if (type == 103) {
      return "Wake up";
    }
    if (type == 104) {
      return "Out of Bed";
    }
    return "";
  },

  getColorOfSleepBar(type: number): string {
    let height = this.getHeightOfSleepBarByType(type);
    if (height == 4) {
      return AWAKE_COLOR;
    }
    if (height == 3) {
      return REM_COLOR;
    }
    if (height == 2) {
      return LIGHT_COLOR;
    }
    if (height == 1) {
      return DEEP_COLOR;
    }
    return AWAKE_COLOR;
  },
  getColorOfSleepBarByHeight(height: number): string {
    if (height == 4) {
      return AWAKE_COLOR;
    }
    if (height == 3) {
      return REM_COLOR;
    }
    if (height == 2) {
      return LIGHT_COLOR;
    }
    if (height == 1) {
      return DEEP_COLOR;
    }
    return AWAKE_COLOR;
  },

  getGroupedSleepPoints(points: any[] = []) {
    // console.log("getGroupedSleepPoints");
    let result: any[] = [];
    if (points.length == 0) {
      return [];
    }

    let currPoint = points[0];
    for (let i in points) {
      let p = points[i];
      if (currPoint.type == p.type) {
        currPoint.end_date = p.end_date;
      } else {
        result.push(currPoint);
        currPoint = p;
      }
    }
    result.push(currPoint);
    result = result.map((x) => ({
      ...x,
      // from: +new Date(x.start_date),
      // to: +new Date(x.end_date),
      from: CommonHelper.getTimestampFromDateString(x.start_date),
      to: CommonHelper.getTimestampFromDateString(x.end_date),
      width:
        +CommonHelper.getTimestampFromDateString(x.end_date) -
        +CommonHelper.getTimestampFromDateString(x.start_date),
    }));
    if (result.length == 0) {
      return [];
    }
    let totalWidth = +result[result.length - 1].to - +result[0].from;
    let globalFrom = +result[0].from;
    if (totalWidth <= 0) {
      return [];
    }
    result = result
      .map((x) => ({
        ...x,
        width_percent: (100.0 * x.width) / totalWidth,
        left_percent: (100.0 * (x.from - globalFrom)) / totalWidth,
      }))
      .map((x) => ({
        ...x,
        height: this.getHeightOfSleepBarByType(x.type),
        color: this.getColorOfSleepBar(x.type),
      }));
    return result;
  },

  getGroupedSleepPointsFullDay(
    points: any[] = [],
    date: string,
    offset: number
  ) {
    const dateFrom: moment.Moment = moment(date);
    const twelveHoursBefore: moment.Moment = dateFrom
      .clone()
      .subtract(12 + offset, "hours");
    let result: any[] = [];
    if (points.length == 0) {
      return [];
    }
    let currPoint = points[0];
    for (let i in points) {
      let p = points[i];
      if (currPoint.type == p.type) {
        currPoint.end_date = p.end_date;
      } else {
        result.push(currPoint);
        currPoint = p;
      }
    }
    result.push(currPoint);
    result = result.map((x) => ({
      ...x,
      // from: +new Date(x.start_date),
      // to: +new Date(x.end_date),
      from: CommonHelper.getTimestampFromDateString(x.start_date),
      to: CommonHelper.getTimestampFromDateString(x.end_date),
      _width:
        +CommonHelper.getTimestampFromDateString(x.end_date) -
        +CommonHelper.getTimestampFromDateString(x.start_date),
      width:
        1000 *
        60 *
        (moment(x.end_date).diff(moment(x.start_date), "minutes") + 1),
    }));
    if (result.length == 0) {
      return [];
    }
    let totalWidth = +result[result.length - 1].to - +result[0].from;
    let globalFrom = +result[0].from;
    if (totalWidth <= 0) {
      return [];
    }
    result = result
      .map((x) => ({
        ...x,
        // _width_percent: (100.0 * x.width) / totalWidth,
        // _left_percent: (100.0 * (x.from - globalFrom)) / totalWidth,
        width_percent:
          (100 *
            (moment(x.end_date).diff(moment(x.start_date), "minutes") + 0)) /
          (24 * 60),
        left_percent:
          (100 * moment(x.from).diff(twelveHoursBefore, "minutes")) / (24 * 60),
      }))
      .map((x) => ({
        ...x,
        height: this.getHeightOfSleepBarByType(x.type),
        color: this.getColorOfSleepBar(x.type),
      }));
    return result;
  },

  getGroupedAISleepPoints(points: any[] = []) {
    // console.log("getGroupedAISleepPoints");
  },

  findSleepStateChange(sleepData: any[] = []) {
    let changePoints: any[] = [];
    if (sleepData.length === 0) {
      return changePoints;
    }
    let lastValue = sleepData[0].sleepState;
    // let lastValue = SleepStatusMap.sleepState
    let lastIndex = 0;

    for (let i = 1; i < sleepData.length; i++) {
      if (sleepData[i].sleepState !== lastValue) {
        changePoints.push({
          index: i,
          indexFrom: lastIndex,
          indexTo: i,
          sleepState: sleepData[i - 1].sleepState,
          // dateFrom: sleepData[i - 1].date,
          dateFrom: sleepData[lastIndex].date,
          _dateFrom: moment(sleepData[i - 1].date).format("DD HH:mm Z"),
          dateTo: sleepData[i].date,
          timestamp: sleepData[i].timestamp,
        });
        lastIndex = i;
        lastValue = sleepData[i].sleepState;
      }
    }
    changePoints.push({
      index: sleepData.length - 1,
      indexFrom: lastIndex,
      indexTo: sleepData.length - 1,
      dateFrom: sleepData[sleepData.length - 2].date,
      dateTo: sleepData[sleepData.length - 1].date,
      sleepState: sleepData[sleepData.length - 1].sleepState,
      timestamp: sleepData[sleepData.length - 1].timestamp,
    });
    return changePoints;
  },

  getSleepStatus(input: number): string {
    if (VALID_SLEEP_STATES_VALUES.includes(input)) {
      return SleepStatusMap[input];
    } else {
      return "unknown";
    }
  },
};

const AWAKE_COLOR = "#FC0903";
const REM_COLOR = "#0FA9FC";
const LIGHT_COLOR = "#3763B5";
const DEEP_COLOR = "#374D89";

export const VALID_SLEEP_STATES_VALUES = [0, 1, 2, 3, 4, 101, 102, 103, 104];
export const SLEEP_VALUES = [2, 3, 4];

type SleepStage =
  | "undefined"
  | "Awake"
  | "Light"
  | "Asleep"
  | "REM"
  | "GoToSleep"
  | "SleepOnset"
  | "Wake"
  | "OutOfBed";

const SleepStatusMap: Record<number, string> = {
  0: "undefined",
  1: "Awake",
  2: "Light",
  3: "Deep",
  4: "REM",
  101: "GoToSleep",
  102: "SleepOnset",
  103: "Wake",
  104: "OutOfBed",
};

const SleepStatusName = [
  // index suggest state
  // SS_AWAKE = 1,
  // SS_LIGHT = 2,
  // SS_DEEP = 3,
  // SS_REM = 4,
  // #define GO_TO_BED_STAGE   101
  // #define SLEEP_ONSET_STAGE 102
  // #define WAKE_STAGE        103
  // #define OUTFBED_STAGE     104
  "undefined", // 0
  "Awake", // 1
  "Light", // 2
  "Asleep", // 3
  "REM", //4
  "GoToSleep", // 5
];

export const IS_AWAKE_COLOR = "#FC0903ff";
export const ONSET_COLOR = "#aC3913ff";
export const ONSET_END_COLOR ="#8EF913ff"
export const IS_ASLEEP_COLOR = "#3763B5ff";
export const OFFSET_COLOR = "#376385ff";

export const IS_ASLEEP_HEIGHT = 1;
export const IS_AWAKE_HEIGHT = 4;
export const IS_ONSET_OR_OFFSET_HEIGHT = 4;
export const IS_LIGHT_HEIGHT = 2; // added 2025-02-12
export const IS_REM_HEIGHT = 3; // added 2025-02-12

export const SLEEP_STATUS_PROPERTIES = {
  undefined: {
    color: "#657",
    height: 0.0,
    isAsleep: undefined,
  },
  Asleep: {
    color: DEEP_COLOR,
    height: IS_ASLEEP_HEIGHT,
    isAsleep: true,
  },
  Awake: {
    color: IS_AWAKE_COLOR,
    height: IS_AWAKE_HEIGHT,
    isAsleep: false,
  },
  Light: {
    color: LIGHT_COLOR,
    height: IS_LIGHT_HEIGHT,
    isAsleep: true,
  },
  Deep: {
    color: DEEP_COLOR,
    height: IS_ASLEEP_HEIGHT,
    isAsleep: true,
  },
  REM: {
    color: REM_COLOR,
    height: IS_REM_HEIGHT,
    isAsleep: true,
  },
  GoToSleep: {
    color: ONSET_COLOR,
    // height: IS_ASLEEP_HEIGHT,
    height: IS_ONSET_OR_OFFSET_HEIGHT,
    // isAsleep: true,
  },
  Onset: {
    color: ONSET_COLOR,
    height: IS_AWAKE_HEIGHT,
  },
  Offset: {
    color: ONSET_COLOR,
    height: IS_ONSET_OR_OFFSET_HEIGHT,
  },
  SleepOnset: {
    color: ONSET_COLOR,
    height: IS_ONSET_OR_OFFSET_HEIGHT,
  },
  Wake: {
    color: ONSET_COLOR,
    height: IS_ONSET_OR_OFFSET_HEIGHT,
  },
  OutOfBed: {
    color: ONSET_COLOR,
    height: IS_ONSET_OR_OFFSET_HEIGHT,
  },
};

export default TarnsformerHelper;
