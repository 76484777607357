import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import uuid from "uuid";
import { Map, Set } from "immutable";
import styled from "styled-components";
import BinaryMeasurementsAPI from "../../../../api/BinaryMeasurementsAPI";
import CommonHelper from "../../../../helpers/CommonHelper";

import Sidebar from "arui-feather/sidebar";
import CardiolyseWiffMeasurementTool from "../../../cardiolyse/tools/CardiolyseWiffMeasurementTool";
import Tabs, { TabItem } from "../../../ui/Tabs";
import ViewEcgRawChartTool from "../../../cardiolyse/tools/ViewEcgRawChartTool";
import ViewPpg2RawChartTool from "../../../cardiolyse/tools/ViewPpg2RawChartTool";
import LottieLoader from "../../../loaders/LottieLoader";

import cardio_json from "../../../../assets/lottie/cardiogram.json";
import { isDebugMode } from "../../../../helpers/UiHelper";
import EcgRecordPanel from "../../../ecg/panels/EcgRecordPanel";
import { Button } from "../../../trials/panels/StudySessionsPanel";
import SZobinAPI from "../../../../api/SZobinAPI";
import CardiolyseAPI from "../../../../api/CardiolyseAPI";
import ViewEcgTool from "../../sz/ViewEcgTool";
import DescriptionTool from "../tools/DescriptionTool";
import NiceModal from "../../../modals/NiceModal";
import axios from "axios";
import { Input } from "../../../ui/templates";

export default function BinaryMeasurementsPanel(props) {
  const {
    uuid,
    filterType,
    showDescription = true,
    isDemo = false,
    user,
  } = props;
  const [measurements, setMeasurements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ecgProcessingId, setEcgProcessingId] = useState(undefined);
  const [showNotePopup, setShowNotePopup] = useState(undefined);
  const [noteOriginalDescription, setNoteOriginalDescription] = useState("");
  const [viewId, setViewId] = useState();
  const [noteLoading, setNoteLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const controller = new AbortController();
    const { signal } = controller;
    BinaryMeasurementsAPI.getInstance()
      .getBinaryMeasurements(uuid, isDemo, signal)
      .then((arr) => {
        if (filterType != undefined) {
          arr = arr.filter((x) => x?.type == filterType);
        }
        setMeasurements(arr.reverse());
        setLoading(false);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setLoading(false);
      });
    return () => {
      controller.abort();
    };
  }, []);

  const handleAddDescription = (ecgID, input) => {
    const url = `https://api.study-integration.corsano.com/health/user/${uuid}/binary-measurements/update-description`;
    axios.put(url, { id: ecgID, description: input });
  };

  const [selectedId, setSelectedId] = useState(undefined);
  const [selMesData, setSelMesData] = useState(undefined);

  useEffect(() => {
    setEcgProcessingId(undefined);
  }, [selectedId]);

  const selectedItem =
    selectedId == undefined
      ? undefined
      : measurements.filter((x) => x._id == selectedId)[0];

  // console.log("measurements = ", measurements);
  // console.log("selectedItem = ", selectedItem);
  // console.log("selMesData = ", selMesData);

  if (loading == false && measurements.length == 0) {
    return (
      <div style={{ width: "100%", height: 280 }}>
        <div style={{ width: "100%", height: 260 }}>
          <LottieLoader json={cardio_json} />
        </div>
        <div style={{ textAlign: "center", opacity: 0.5 }}>
          No records found
        </div>
      </div>
    );
  }
  let recordId = `${uuid}_${selectedItem?.uuid}`;

  let viewItem =
    viewId == undefined
      ? undefined
      : measurements.find((x) => x.uuid == viewId);

  // let isError = (description == undefined && description.indexOf('ERROR_') > -1);

  let xMeasurements = measurements.map((x) => {
    let isError =
      x.description != undefined && x.description.indexOf("ERROR_") > -1;
    return {
      ...x,
      isError: isError,
    };
  });

  // console.log('xMeasurements before sorting = ', xMeasurements);

  xMeasurements = xMeasurements.sort((a, b) => {
    // if (!a.start_timestamp) {
    //   return 1;
    // }
    // if (!b.start_timestamp) {
    //   return -1;
    // }
    return (b.start_timestamp || 0) - (a.start_timestamp || 0);
  });

  // console.log(
  //   "xMeasurements",
  //   xMeasurements
  // );

  // let isDebug = isDebugMode();
  // if (isDebug == false) {
  //     xMeasurements = xMeasurements.filter(x => (x.isError != true));
  // }

  return (
    <Wrapper>
      <SessionsHeading>
        <Num>
          <BoldSpan>{`№`}</BoldSpan>
        </Num>
        <Start>
          <BoldSpan>{`Start`}</BoldSpan>
        </Start>

        {filterType != undefined ? null : (
          <>
            <Status>
              <BoldSpan style={{ color: "#000F4B" }}>{`Type`}</BoldSpan>
            </Status>
            <Status>
              <BoldSpan style={{ color: "#000F4B" }}>{`Files number`}</BoldSpan>
            </Status>
          </>
        )}

        {/* {showDescription === false ? null : (
          <Description style={{ marginLeft: "400px" }}>
            <BoldSpan>{"Description"}</BoldSpan>
          </Description>
        )} */}
      </SessionsHeading>

      <MeausurementsList>
        {xMeasurements.map((s, i) => {
          // {items.map((s, i) => {
          let {
            _id,
            type,
            start_timestamp,
            description,
            files = [],
            isError = false,
          } = s;
          let hasDescription = description !== null;
          // if (isError) {
          //   console.log("xMeasurements.map", s);
          // }

          return (
            <SessionItem
              $isError={isError}
              key={`${s._id}_${i}`}
              className="ecg-session-item"
              onClick={() => {
                console.log(`sel: ${_id}`);
                // setSelectedId(_id);
                // // CommonHelper.linkTo(`/user/${id}/session/${s._id}`, true);
                // CommonHelper.linkTo(`/user/${_id}/session/${s._id}`, false);
              }}
            >
              <Num
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedId(_id);
                }}
              >
                {+i + 1}
              </Num>
              <Start
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedId(_id);
                }}
              >
                {start_timestamp == undefined
                  ? ""
                  : moment(start_timestamp).format("YYYY-MM-DD | HH:mm")}
              </Start>

              {type != "ecg" ? null : (
                <>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={async () => {
                      // let points = awa
                      let points =
                        await CardiolyseAPI.getMeasurementEcgPointsEnhanced(
                          uuid,
                          s.uuid,
                          false
                        );
                      let csv = `timestamp;ecg;value`;
                      csv = `${csv}\n${points
                        .map((x) => `${x.timestamp};${x.ecg};${x.value}`)
                        .join("\n")}`;
                      console.log("ecg points = ", points);
                      // let csv = points.join('\n');
                      CommonHelper.downloadFile("ecg.csv", csv);
                    }}
                  >
                    export raw signal .csv
                  </span>
                  <span
                    style={{ cursor: "pointer", marginLeft: 20 }}
                    onClick={async () => {
                      let d = await SZobinAPI.getBeatsInfo(
                        uuid,
                        `${uuid}_${s.uuid}`,
                        "json"
                      );
                      console.log("exported summary csv = ", d);
                      let beats = d?.result?.record?.beats || [];
                      let cl = (x) => (x == undefined ? "" : x);
                      let head = `nb;t;rr;hr;snr;qi;di;nn;qrs;pq;qt;st;st_dev`;
                      let csv = `${head}\n${beats
                        .map(
                          (x, i) =>
                            `${x.n};${cl(x.r)};${cl(x.rr)};${cl(x.hr)};${cl(
                              x.snr
                            )};${cl(x.qi)};${cl(x.di)};${cl(x.nn)};${cl(
                              x.qrs
                            )};${cl(x.pq)};${cl(x.qt)};${cl(x.st)};${cl(
                              x.st_d
                            )}`
                        )
                        .join("\n")}`;
                      CommonHelper.downloadFile(
                        "processed_summary_ecg.csv",
                        csv
                      );
                    }}
                  >
                    export processed summary .csv
                  </span>
                </>
              )}

              {type != "ecg" || isDebugMode() == false ? null : (
                <span
                  style={{ cursor: "pointer", marginLeft: 15 }}
                  onClick={async () => {
                    setViewId(s.uuid);
                  }}
                >
                  view
                </span>
              )}

              {filterType != undefined ? null : (
                <>
                  <Status type={type}>
                    <span style={{ opacity: 0.7 }}>{type}</span>
                  </Status>
                  <Status type={type}>
                    <span style={{ opacity: 0.7 }}>{files.length}</span>
                  </Status>
                </>
              )}

              {showDescription === false ? null : (
                <>
                  {hasDescription ? (
                    <Description
                      style={{ cursor: "pointer", marginLeft: 15 }}
                      onClick={() => {
                        if (isError) {
                          return;
                        }
                        // handleAddDescription(_id, "");
                        setShowNotePopup(_id);
                        setNoteOriginalDescription(description);
                      }}
                    >
                      {description}
                    </Description>
                  ) : (
                    <Description
                      style={{ cursor: "pointer", marginLeft: 15 }}
                      onClick={() => {
                        // handleAddDescription(_id, "");
                        setShowNotePopup(_id);
                        setNoteOriginalDescription("");
                      }}
                    >
                      Add Description
                    </Description>
                  )}
                </>
              )}
            </SessionItem>
          );
        })}
      </MeausurementsList>

      {showNotePopup !== undefined ? (
        <NiceModal
          onClose={() => {
            setShowNotePopup(undefined);
            setNoteOriginalDescription("");
          }}
        >
          <DescriptionTool
            sessionId={showNotePopup}
            originalDescription={noteOriginalDescription}
            onSave={async (d) => {
              setNoteLoading(true);
              const url = `https://api.study-integration.corsano.com/health/user/${uuid}/binary-measurements/update-description`;
              try {
                await axios.put(url, d);
              } catch (error) {
                console.err(error);
              }
              setShowNotePopup(undefined);
              setNoteOriginalDescription("");
              setNoteLoading(false);
              BinaryMeasurementsAPI.getInstance()
                .getBinaryMeasurements(uuid, isDemo)
                .then((arr) => {
                  if (filterType != undefined) {
                    arr = arr.filter((x) => x?.type == filterType);
                  }
                  setMeasurements(arr.reverse());
                  setLoading(false);
                })
                .catch((e) => console.error(e));
            }}
          />
        </NiceModal>
      ) : null}

      <Sidebar
        width={Math.min(1020, window.innerWidth)}
        visible={selectedItem != undefined}
        onCloserClick={() => {
          setSelectedId(undefined);
        }}
      >
        {selectedItem == undefined ? null : (
          <div>
            <h2>
              {moment(selectedItem.start_timestamp).format(
                "DD.MM.YYYY HH:mm:ss"
              )}{" "}
              {selectedItem.type}
            </h2>
            <Tabs
              tabs={[
                {
                  label: "Files",
                  content: (
                    <TabItem>
                      {selectedItem?.files
                        ? selectedItem.files.map((x, j) => {
                            return (
                              <TrRow
                                key={j}
                                onClick={async () => {
                                  // console.log("x = ", x);
                                  console.log(
                                    "cardiolyse url = ",
                                    `https://api.study-integration.corsano.com/cardiolyse/health/user/${uuid}/binary-measurements/${selectedItem?.uuid}/${x.sequence_timestamp}`
                                  );
                                }}
                              >
                                <DownloadSpan
                                  onClick={() => {
                                    let fileUrl = `https://api.study-integration.corsano.com/health/user/${uuid}/binary-measurements/${selectedItem?.uuid}/${x.sequence_timestamp}/file`;
                                    window.open(fileUrl, "_blank").focus();
                                  }}
                                >
                                  {`${+j + 1}) ${moment(
                                    x.sequence_timestamp
                                  ).format("YYYY-MM-DD HH:mm:ss")}`}
                                </DownloadSpan>
                                {selectedItem?.type != "ecg" ? null : (
                                  <DownloadSpan
                                    onClick={() => {
                                      // CommonHelper.linkTo(`/cardiolyse/${uuid}/measurements/${selectedItem?.uuid}/${x.sequence_timestamp}`, true);
                                      setSelMesData({
                                        userUUID: uuid,
                                        measurementUUID: selectedItem?.uuid,
                                        t: x.sequence_timestamp,
                                      });
                                    }}
                                  >
                                    analyse
                                  </DownloadSpan>
                                )}
                              </TrRow>
                            );
                          })
                        : null}

                      {selectedItem?.type != "ecg" ? null : (
                        <div>
                          <ViewEcgRawChartTool
                            userUUID={uuid}
                            measurementUUID={selectedItem?.uuid}
                          />
                        </div>
                      )}

                      <TrRow
                        style={{
                          marginTop: 30,
                          paddingTop: 10,
                          borderTop: "1px dashed lightgrey",
                          justifyContent: "center",
                        }}
                      >
                        <DownloadSpan
                          style={{ marginRight: 15 }}
                          onClick={() => {
                            let fileUrl = `https://api.study-integration.corsano.com/health/user/${uuid}/binary-measurements/${selectedItem?.uuid}/wiff`;
                            window.open(fileUrl, "_blank").focus();
                          }}
                        >
                          Download merged .wiff
                        </DownloadSpan>
                        {selectedItem?.type != "ecg" ? null : (
                          <>
                            <DownloadSpan
                              style={{ marginRight: 15 }}
                              onClick={() => {
                                let fileUrl = `https://api.study-integration.corsano.com/health/user/${uuid}/binary-measurements/${selectedItem?.uuid}/csv`;
                                window.open(fileUrl, "_blank").focus();
                              }}
                            >
                              Download parsed csv
                            </DownloadSpan>
                            <DownloadSpan
                              onClick={() => {
                                let fileUrl = `https://api.study-integration.corsano.com/health/user/${uuid}/binary-measurements/${selectedItem?.uuid}/csv?filtered=1`;
                                window.open(fileUrl, "_blank").focus();
                              }}
                            >
                              Download filtered csv
                            </DownloadSpan>
                          </>
                        )}
                        {selectedItem?.type != "ppg2" ? null : (
                          <>
                            {["0x7b", "0x7c", "0x7e", "0x7f", "0x2b"].map(
                              (a, i) => {
                                return (
                                  <div key={i}>
                                    <DownloadSpan
                                      style={{ marginRight: 15 }}
                                      onClick={() => {
                                        let fileUrl = `https://api.study-integration.corsano.com/health/user/${uuid}/binary-measurements/${selectedItem?.uuid}/csv?pld_key=${a}`;
                                        window.open(fileUrl, "_blank").focus();
                                      }}
                                    >
                                      {`Download PPG2 csv (${a})`}
                                    </DownloadSpan>
                                    <br />
                                    <DownloadSpan
                                      style={{ marginRight: 15 }}
                                      onClick={() => {
                                        setSelMesData({
                                          userUUID: uuid,
                                          measurementUUID: selectedItem?.uuid,
                                          metric: `0x7b`,
                                        });
                                      }}
                                    >
                                      {`view (${a})`}
                                    </DownloadSpan>
                                  </div>
                                );
                              }
                            )}
                          </>
                        )}
                      </TrRow>
                    </TabItem>
                  ),
                },
                // (selectedItem?.type != 'ecg' ? null :
                //         {
                //             label: 'Experimental',
                //             content: (
                //                 <TabItem>
                //                     <ViewEcgRawChartTool
                //                         hasExperimental={true}
                //                         userUUID={uuid}
                //                         measurementUUID={selectedItem?.uuid}
                //                     />
                //                 </TabItem>
                //             )
                //         }
                // ),
                selectedItem?.type != "ecg"
                  ? null
                  : {
                      label: "Cardiolyse Analytics",
                      content: (
                        <TabItem>
                          <CardiolyseWiffMeasurementTool
                            userUUID={uuid}
                            measurementUUID={selectedItem?.uuid}
                          />
                        </TabItem>
                      ),
                    },
              ].filter((x) => x != undefined)}
            />
          </div>
        )}
      </Sidebar>

      <Sidebar
        visible={selMesData != undefined}
        width={Math.min(1020, window.innerWidth)}
        onCloserClick={() => {
          setSelMesData(undefined);
        }}
      >
        {selMesData?.t == undefined ? null : (
          <CardiolyseWiffMeasurementTool {...selMesData} />
        )}

        {selMesData?.t != undefined ? null : (
          <div>
            <ViewPpg2RawChartTool {...selMesData} />
            {/*{JSON.stringify(selMesData)}*/}
          </div>
        )}
      </Sidebar>

      <Sidebar
        visible={viewItem != undefined}
        width={window.innerWidth}
        onCloserClick={() => {
          setViewId(undefined);
        }}
      >
        {viewItem == undefined ? null : (
          <div style={{ width: "100%" }}>
            <h2 style={{ marginTop: -30 }}>
              {user == undefined ? null : (
                <span style={{ marginRight: 20 }}>
                  {CommonHelper.getPatientHeadingString(user)}
                </span>
              )}
              {moment(viewItem?.start_timestamp).format("DD.MM.YYYY HH:mm")}
            </h2>

            <EcgRecordPanel userUUID={uuid} measurementUUID={viewItem?.uuid} />
          </div>
        )}
      </Sidebar>
    </Wrapper>
  );
}

function download(fileUrl, fileName) {
  var a = document.createElement("a");
  a.href = fileUrl;
  a.setAttribute("download", fileName);
  a.click();
}

const TrRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const DownloadSpan = styled.span`
  cursor: pointer;
  opacity: 0.7;

  :hover {
    opacity: 0.99;
  }
`;

const HeadingPlaceholder = styled.div``;

const Wrapper = styled.div``;

const MeausurementsList = styled.div``;

const ListItem = styled.div`
  margin-bottom: 15px;
  box-sizing: border-box;
`;

const SessionItem = styled.div`
  margin-bottom: 2px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  color: ${(props) => (props.$isError == true ? "red" : "#8798AD")};
  font-size: 14px;

  :hover {
    opacity: 0.8;
  }
`;

const SessionsListPlaceholder = styled.div`
  margin-bottom: 20px;
`;

const Description = styled.div`
  flex: 1;
  // cursor: pointer;
`;
const AddDescription = styled.div`
  flex: 1;
  cursor: pointer;
`;

const AddNote = styled.div`
  flex: 1;
  cursor: pointer;
`;
const Status = styled.div`
  width: 140px;
  margin-right: 20px;
  color: ${(props) =>
    props.inProgress == true
      ? "#147AFF"
      : props.isUploaded == true
      ? "#01C2A9"
      : "#8798AD"};
`;

const Start = styled.div`
  width: 140px;
  margin-right: 40px;
`;

const Stop = styled.div`
  width: 140px;
  margin-right: 40px;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const SessionsHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000f4b;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 15px;
`;

const Num = styled.div`
  width: 40px;
  padding-left: 4px;
  margin-right: 10px;
`;
