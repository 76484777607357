import { isDebugMode } from "../../../../helpers/UiHelper";
import InfoToolBadge from "../../../info/tools/InfoToolBadge";
import PatientDayBRPMBaselineDebugsTool from "../../../baselines/PatientDayBRPMBaselineDebugsTool";
import PatientDayBRPMBaselineTool from "../../../baselines/PatientDayBRPMBaselineTool";
import VitalTrendsChartTool from "../../../trends/tools/VitalTrendsChartTool";
import SimpleNoDataMessageTool from "../../../sleep/tools/SimpleNoDataMessageTool";
import SimpleBpmSlotsChart from "../../../heart_rate/tools/SimpleBpmSlotsChart";
import TempRespTool from "../../tools/TempRespTool";
import { ComplianceHeading, ComponentHeight } from "../UiComponents";
import moment from "moment";
import { isSameDay } from "../../../baselines/BaselineUtils";
import VitalMultiTrendsChartTool from "../../../trends/tools/VitalMultiTrendsChartTool";

export default function BrpmPanel(props) {
  const {
    id,
    date,
    hasNoRRData,
    theme,
    baselineWindow,
    tz,
    allTypeBaselineMap,
    loadingAllTypeBaseline,
    vitalSummaryInRange,
    summary,
    loading,
    gapAndNotWearingSpans = [],
    chargingSpans = [],
    externalMeasurements = [],
    lowBatterySpans = [],
  } = props;
  // console.log({ vitalSummaryInRange });

  return (
    <div className="brpm-tab-container">
      <div style={{ width: "100%" }} className="respiration-rate-area">
        <div
          style={{
            height:
              ComponentHeight.chartHeight +
              ComponentHeight.lineFilterHeight +
              ComponentHeight.titleHeight ,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ComplianceHeading className="patient-statistics-heading">
              Respiration Rate
            </ComplianceHeading>
            <InfoToolBadge type={"BRPM"} />
          </div>
          {hasNoRRData ? (
            <SimpleNoDataMessageTool
              loading={loading}
              message={"Sorry, there is no respiration data for this day."}
            />
          ) : (
            <PatientDayBRPMBaselineTool
              uuid={id}
              date={date}
              theme={theme}
              baselineWindow={baselineWindow}
              tz={tz}
              points={allTypeBaselineMap["respiration_rate"]}
              loading={loadingAllTypeBaseline}
              hasNoData={hasNoRRData}
              gapAndNotWearingSpans={gapAndNotWearingSpans}
              chargingSpans={chargingSpans}
              lowBatterySpans={lowBatterySpans}
              externalMeasurements={externalMeasurements.filter((x) =>
                isSameDay(x.timestamp, date)
              )}
            />
          )}
        </div>

        {/* {hasNoRRData ? (
        ) : (
        )} */}
      </div>

      <div className="respiration-rate-trend-area">
        <ComplianceHeading className="patient-statistics-heading">
          Respiration Rate Trend
        </ComplianceHeading>
        <div
          style={{
            width: "100%",
            height: 360,
          }}
        >
          <VitalMultiTrendsChartTool
            dataType={"respiration_rate"}
            theme={theme}
            subTypes={["avg_respiration_rate"]}
            uuid={id}
            points={vitalSummaryInRange}
          />
        </div>
      </div>

      {isDebugMode() ? (
        <div>
          {hasNoRRData ? null : (
            <div
              className="temp-respiration-rate-area"
              style={{ height: 300, marginTop: "1rem" }}
            >
              <SimpleBpmSlotsChart
                slots={summary?.respiration_rate.slots
                  .map((x) => ({
                    ...x,
                    t: x.timestamp,
                  }))
                  .map((x) => ({
                    ...x,
                    respiration_rate:
                      x.respiration_rate === 0 ? null : x.respiration_rate,
                  }))}
                dataKey={"respiration_rate"}
                min={+moment(date).startOf("day")}
                max={+moment(date).startOf("day").add(1, "day").valueOf()}
              />
            </div>
          )}
        </div>
      ) : null}

      {isDebugMode() && !hasNoRRData && (
        <div
          className="temp-resp-area-1"
          style={{ width: "100%", height: 300, marginTop: "1rem" }}
        >
          <TempRespTool
            downsampleMode={true}
            uuid={id}
            from={+moment(date).startOf("day")}
            to={+moment(date).endOf("day")}
            min={+moment(date).startOf("day")}
            max={+moment(date).startOf("day").add(1, "day").valueOf()}
          />
        </div>
      )}

      {isDebugMode() && !hasNoRRData && (
        <div
          className="temp-resp-area-2"
          style={{ width: "100%", height: 300 }}
        >
          <TempRespTool
            downsampleMode={true}
            isActivityRaw={true}
            uuid={id}
            from={+moment(date).startOf("day")}
            to={+moment(date).endOf("day")}
            min={+moment(date).startOf("day")}
            max={+moment(date).startOf("day").add(1, "day").valueOf()}
          />
        </div>
      )}
    </div>
  );
}
