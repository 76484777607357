import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import styled from "styled-components";
// import GithubSleepSessionsView from "../GithubSleepSessionsView";

import * as actions from "../../../redux/actions/DataActions";
import InlineDateSelector from "../../dates/tools/InlineDateSelector";
import StepsAPI from "../../../api/StepsAPI";
import HeartRateAPI from "../../../api/HeartRateAPI";
import SimpleBpmSlotsChart from "../../heart_rate/tools/SimpleBpmSlotsChart";
import GithubActivitySessionsView from "../tools/GithubActivitySessionsView";
import SevenDaysActivityTool from "../tools/SevenDaysActivityTool";
import SabirBarChart from "../tools/SabirBarChart";
import { Code } from "react-content-loader";
import SimpleNoDataMessageTool from "../../sleep/tools/SimpleNoDataMessageTool";
import RonasBasicAPI from "../../../api/RonasBasicAPI";
import SleepGoalsTool from "../../sleep/tools/SleepGoalsTool";
import ActivityGoalsTool from "../tools/ActivityGoalsTool";

export default function ActivityPanelOld(props) {
  let { loading, selectedTimestamp, goals } = useMappedState(
    
    useCallback((state) => {
      return {
        currentUserObj: state.auth.currentUserObj,
        loading: state.auth.loading || state.data.loading,
        updatedTimestamp: state.data.updatedTimestamp,
        selectedTimestamp: state.data.selectedTimestamp,
        goals:
          state.auth.currentUserObj?.goals == undefined
            ? []
            : state.auth.currentUserObj?.goals,
      };
    }, [])
  );
  const dispatch = useDispatch();
  const [yearTimestamp, setYearTimestamp] = useState(+new Date());
  const [selectedDaySlots, setSelectedDaySlots] = useState([]);
  const [selectedDayHeartRateSlots, setSelectedDayHeartRateSlots] = useState();
  const [heartRateFetching, setHeartRateFetching] = useState(false);
  const [activityDayData, setActivityDayData] = useState(undefined);

  useEffect(() => {
    console.log("---((((");
    dispatch(actions.loadYearSummaries(yearTimestamp));
  }, [+moment(yearTimestamp).startOf("year")]);

  useEffect(() => {
    console.log("---((((");
    dispatch(actions.loadYearSummaries(yearTimestamp)).then((yPld) => {
      let pts = (yPld?.data ? yPld.data : [])
        .filter((x) => x.activity != undefined)
        .sort((a, b) => +new Date(a.date) - +new Date(b.date));
      console.log("--->>> pts = ", pts);
      if (pts.length > 0) {
        dispatch(actions.selectDay(+new Date(pts[pts.length - 1].date)));
      }
    });
  }, []);

  useEffect(() => {
    let mounted = true;
    if (loading == true || selectedTimestamp == undefined || mounted == false) {
      return;
    }

    setHeartRateFetching(true);

    RonasBasicAPI.getInstance()
      .getUserSummariesFor7DaysFromCloud(selectedTimestamp, true)
      .then(() => {
        if (mounted == false) {
          return;
        }
        StepsAPI.getInstance()
          .getActivityForDayFromCache(selectedTimestamp)
          .then((dSlots) => {
            if (mounted == false) {
              return;
            }
            setSelectedDaySlots(dSlots);
            HeartRateAPI.getInstance()
              .getHeartRateSlotsFromYesterdayAndToday(selectedTimestamp)
              .then((hrSlots) => {
                if (mounted == false) {
                  return;
                }
                setSelectedDayHeartRateSlots(hrSlots);
              });
            StepsAPI.getInstance()
              .getStepsItemsForDayFromCloud(selectedTimestamp)
              .then((d2) => {
                console.log("!! d2 = ", d2);
                if (mounted == false) {
                  return;
                }
                if (d2?.slots == undefined) {
                  setSelectedDaySlots([]);
                } else {
                  setSelectedDaySlots(d2.slots);
                }
                setActivityDayData(d2);
                // setSelectedDaySlots(d2);
                HeartRateAPI.getInstance()
                  .getHeartRateSlotsFromYesterdayAndToday(selectedTimestamp)
                  .then((hrSlots) => {
                    console.log("--->>> hrSlots = ", hrSlots);
                    setSelectedDayHeartRateSlots(hrSlots);
                    setHeartRateFetching(false);
                  });
              });
          });
      });

    return () => {
      mounted = false;
    };
  }, [selectedTimestamp, loading]);

  let isSomethingLoading = heartRateFetching || loading;

  return (
    <Wrapper>
      <YearPlaceholder>
        <GithubActivitySessionsView
          loading={loading}
          onYearTimestampChange={(t) => {
            if (isSomethingLoading == true) {
              return;
            }
            setYearTimestamp(t);
          }}
          onDayClick={(t) => {
            if (isSomethingLoading == true) {
              return;
            }
            dispatch(actions.selectDay(t));
          }}
        />
      </YearPlaceholder>

      {selectedTimestamp == undefined ? null : (
        <React.Fragment>
          <MainContentPlaceholder
            key={`qq${yearTimestamp}_${selectedTimestamp}`}
          >
            <MainContentHeading>
              <TopSleepHeadingName>Activity summary</TopSleepHeadingName>

              <InlineDateSelector
                loading={isSomethingLoading}
                selectedTimestamp={selectedTimestamp}
                onDaySelect={(t) => {
                  dispatch(actions.selectDay(t));
                }}
              />
            </MainContentHeading>

            <CurrentDaySleepChartPlaceholder
              className={`${loading}_c-${yearTimestamp}_${selectedTimestamp}`}
            >
              {isSomethingLoading == true ? (
                <Code />
              ) : (
                <>
                  {selectedDaySlots == undefined ||
                  selectedDaySlots.length == 0 ? (
                    <SimpleNoDataMessageTool
                      message={"Sorry, there is no activity data for this day."}
                    />
                  ) : (
                    <SabirBarChart
                      items={selectedDaySlots}
                      renderItem={(it) => (
                        <span
                          style={{
                            fontWeight: "normal",
                            color: "grey",
                            fontSize: 10,
                          }}
                        >
                          {it.time}
                        </span>
                      )}
                    />
                  )}
                </>
              )}
            </CurrentDaySleepChartPlaceholder>

            <HeartRateChartPlaceholder>
              {isSomethingLoading ? (
                <Code />
              ) : (
                <>
                  {selectedDaySlots == undefined ||
                  selectedDaySlots.length == 0 ? (
                    <SimpleNoDataMessageTool
                      message={
                        "Sorry, there is no heart rate data for this day"
                      }
                    />
                  ) : (
                    <SimpleBpmSlotsChart
                      slots={selectedDayHeartRateSlots}
                      min={+moment(selectedTimestamp).startOf("day")}
                      max={+moment(selectedTimestamp).endOf("day")}
                    />
                  )}
                </>
              )}
            </HeartRateChartPlaceholder>
          </MainContentPlaceholder>

          <RowPlaceholder></RowPlaceholder>
        </React.Fragment>
      )}

      <RowPlaceholder>
        <Half>
          <TopSleepHeadingName style={{ marginBottom: 10 }}>
            Activity Goals
          </TopSleepHeadingName>

          <div
            style={{
              boxSizing: "border-box",
              paddingRight: 20,
              marginBottom: 20,
            }}
          >
            {isSomethingLoading == true ? (
              <>
                <Code />
                <Code />
              </>
            ) : (
              <ActivityGoalsTool {...activityDayData} goals={goals} />
            )}
          </div>

          <TopSleepHeadingName style={{ marginBottom: 10 }}>
            Steps per day
          </TopSleepHeadingName>
          <SevenDaysChartPlaceholder>
            {isSomethingLoading == true ? (
              <Code />
            ) : (
              <SevenDaysActivityTool
                loading={loading}
                dayTimestamp={selectedTimestamp}
              />
            )}
          </SevenDaysChartPlaceholder>
        </Half>

        <Half>
          <TopSleepHeadingName style={{ marginBottom: 10 }}>
            Distance per day
          </TopSleepHeadingName>
          <SevenDaysChartPlaceholder>
            {isSomethingLoading == true ? (
              <Code />
            ) : (
              <SevenDaysActivityTool
                loading={loading}
                dayTimestamp={selectedTimestamp}
                param={"distance"}
              />
            )}
          </SevenDaysChartPlaceholder>

          <TopSleepHeadingName style={{ marginBottom: 10 }}>
            Calories per day
          </TopSleepHeadingName>
          <SevenDaysChartPlaceholder>
            {isSomethingLoading == true ? (
              <Code />
            ) : (
              <SevenDaysActivityTool
                loading={loading}
                dayTimestamp={selectedTimestamp}
                param={"calories"}
              />
            )}
          </SevenDaysChartPlaceholder>
        </Half>
      </RowPlaceholder>
    </Wrapper>
  );
}

const SevenDaysChartPlaceholder = styled.div`
  margin-bottom: 20px;
  height: 200px;
`;

const RowPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-left: 20px;
`;

const Half = styled.div`
  flex: 1;
  box-sizing: border-box;

  :first-of-type {
    padding-right: 20px;
  }

  :last-of-type {
    padding-right: 20px;
  }
`;

const HeartRateChartPlaceholder = styled.div`
  height: 200px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
`;

const CurrentDaySleepChartPlaceholder = styled.div`
  height: 200px;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 0px;
`;

const MainContentHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopSleepHeadingName = styled.div`
  font-weight: bold;
`;

const MainContentPlaceholder = styled.div`
  margin-top: 40px;
`;

const Wrapper = styled.div``;

const YearPlaceholder = styled.div``;
