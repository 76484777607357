import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import moment from "moment";
import ls from "local-storage";
import styled from "styled-components";
import DrawHelper from "../../../helpers/DrawHelper";
import {
  RONAS_BLUE_MOUSE_OVER,
  RONAS_DARK_GREEN,
} from "../../../constants/config";
import { AXES_COLOR, AXES_COLORX } from "../../ui/templates";
import energy_img from "../../../assets/images/energy.svg";

import useDimensions from "react-use-dimensions";

const getRandomItems = (num = 7, step = 86400000) => {
  let arr = Array.from({ length: num }).map((a, i) => 0);
  let from = moment()
    .add((-step * num) / 1000.0, "seconds")
    .startOf("day");
  for (let i in arr) {
    arr[i] = {
      t: from + step * i,
      value: Math.round(Math.random() * 10000),
    };
  }
  return arr;
};

const getMinMax = (items) => {
  if (items == undefined || items.length == 0 || items[0] == undefined) {
    return {};
  }
  try {
    let r0 = items[0];
    let min = items[0].value;
    let max = items[0].value;
    for (let i in items) {
      let v = items[i].value;
      if (+v > +max) {
        max = +v;
      }
      if (+v < +max) {
        min = +v;
      }
    }
    return { min, max };
  } catch (exc) {}
};

export default function SabirBarChart(props) {
  const {
    shouldChangeColorOfSelected = true,
    isHoursChart = false,
    valueSuffix = "",
    loading = false,
    items = getRandomItems(24),
    barColor = RONAS_DARK_GREEN,
    hoverBarColor = RONAS_BLUE_MOUSE_OVER,
    shouldHighlightToday = true,
    onItemClick = (item) => {},
    boxPaddingFraction = 0.175,
    maxLabelWidth = 30,
    renderItem = (it) => {
      return <span>{moment(it.t).format("HH:mm")}</span>;
    },
    selectedTimestamp = undefined,
    valueImg = undefined,
    type = "",
    caloriesToday = undefined,
    workouts = [],
    onWorkoutClick = () => {},
  } = props;
  const theme = ls.get("theme");
  const [isZero, setIsZero] = useState(true); // for animation
  // console.log(items);

  useEffect(() => {
    let mounted = true;
    if (mounted == false) {
      return;
    }
    setIsZero(true);
    setTimeout(() => {
      if (mounted == false) {
        return;
      }
      setIsZero(false);
    }, 100);
    return () => {
      mounted = false;
    };
  }, [`${items.length}`]);

  const [contentRef, size] = useDimensions();
  // let size = useComponentSize(contentRef);
  let { min, max } = getMinMax(items);
  // console.log("min, max", min , max);

  if (min == undefined || max == undefined) {
    return null;
  }

  // let showDashedBorder = items.length < 24 * 3;
  let showDashedBorder = true;
  let boxWidth = (size.width / items.length).toFixed(5);
  let boxSizePadding = (boxWidth * boxPaddingFraction).toFixed(4);
  let maxNum = (1.0 * size.width) / maxLabelWidth;
  let skip = Math.ceil((1.0 * items.length) / maxNum);
  skip = items.length > 24 * 3 ? 12 : skip;
  if (type === "sleep-activity") {
    skip = 12;
  }
  let vertItems = DrawHelper.getStepsGridValues(max, 4);

  let maxY = vertItems[vertItems.length - 1];

  let sumVals = 0;
  let sumCal = 0;
  if (["steps", "energy_expenditure", "steps30"].includes(type)) {
    sumVals = items.reduce((accu, curr) => {
      return accu + curr.value;
    }, 0);
    sumCal = items.reduce((accu, curr) => {
      return accu + curr.calorie;
    }, 0);
    sumVals = Math.ceil(sumVals);
  }
  // console.log(`${type} SabirBarChart`, items, );
  // console.log(`${type} sumCal`, sumCal);
  // console.log({type});

  // console.log("caloriesToday =", caloriesToday);

  return (
    <Wrapper className="bar-chart-wrapper">
      {(type === "steps" || type === "steps30") && (
        <div style={{ textAlign: "right" }}>{sumVals} Steps</div>
      )}
      {type === "energy_expenditure" && caloriesToday !== undefined && (
        <div style={{ textAlign: "right" }}>
          Active {caloriesToday} Calories
        </div>
      )}
      {type === "energy_expenditure" && (
        <div style={{ textAlign: "right" }}>
          Total {(sumCal / 1000).toFixed(0)} Calories
        </div>
      )}
      <ContPlaceholder>
        <VertAxisPlaceholder $color={`${AXES_COLORX(theme)}80`}>
          {vertItems.map((a, i) => {
            let dperc = size.height == 0 ? 0 : (12.0 * 100.0) / size.height;
            let perc = (100.0 * a) / maxY;
            if (i == vertItems.length - 1) {
              perc = perc - dperc;
            }
            // if (isNaN(perc)) {
            // console.log("SabirBarChart: bottoms", perc, size.height, dperc, maxY);
            // TODO: debug nan values here
            // }

            return (
              <VertItem key={a} $bottom={perc} className="chart-vert-item">
                <VertItemInner>
                  {loading == true
                    ? "..."
                    : `${a.toFixed(1)}`.replace(".0", "")}
                </VertItemInner>
              </VertItem>
            );
          })}
        </VertAxisPlaceholder>
        <Content ref={contentRef} $color={`${AXES_COLORX(theme)}80`}>
          {items.map((it, i) => {
            let isSelected = selectedTimestamp == it.t;
            let hasLabel = +i % skip == 0;
            let isToday =
              +moment().startOf("day") == +moment(it.t).startOf("day");
            // console.log(i, (i + 1) % 12);
            // console.log({ boxWidth, boxSizePadding });
            // console.log(it.t, it.value);

            let hasRightBorder =
              items.length <= 72 ? true : (i + 1) % 12 === 0 ? true : false;
            return (
              <Box
                key={`it_${i}`}
                $pad={boxSizePadding < 1 ? 1 : boxSizePadding}
                // pad={boxSizePadding}
                className="chart-item"
                style={{
                  borderRight: hasRightBorder ? "1px dashed whitesmoke" : "",
                  // marginRight: "1px"
                  width: "90%",
                }}
              >
                <BoxInner
                  className="chart-inner-item"
                  style={{
                    position: items.length > 24 * 3 ? "relative" : "relative",
                  }}
                >
                  <Bar
                    className="chart-bar-item"
                    height={isZero ? 0 : (100.0 * it.value) / maxY}
                    $color={barColor}
                    $hovercolor={hoverBarColor}
                    $today={isToday}
                    $isselected={shouldChangeColorOfSelected && isSelected}
                    onClick={() => {
                      onItemClick(it);
                    }}
                  >
                    <BarInner className="chart-item">
                      <BarTooltip
                        className="chart-item chart-item-tooltip"
                        style={{
                          background: "#fdfdfd",
                          // display: "block",
                          color: "black",
                          padding: "10px",
                          border: "1px solid lightgrey",
                          textAlign: "left",
                          fontWeight: 400,
                          fontSize: 16,
                        }}
                      >
                        {valueImg == undefined ? null : (
                          <>
                            <StepsTooltipImg
                              src={valueImg}
                              className="chart-item"
                            />
                            <br />
                          </>
                        )}
                        {it?.label && (
                          <div
                            className="chart-item-tooltip-item"
                            style={{
                              marginBottom: "10px",
                            }}
                          >
                            {"Time: "}
                            {it?.label ? it.label : ""}
                          </div>
                        )}
                        <div className="chart-item-tooltip-item">{`${typeToTooltipKey(
                          type
                        )}: ${it.value}`}</div>
                        {valueSuffix == undefined ||
                        valueSuffix.trim() == "" ? null : (
                          <span
                            style={{ marginLeft: 4 }}
                            className="chart-item"
                          >
                            {valueSuffix}
                          </span>
                        )}
                      </BarTooltip>
                    </BarInner>
                  </Bar>
                </BoxInner>
                {hasLabel == false ? null : (
                  <BarLabel
                    className="chart-item"
                    $today={isToday && shouldHighlightToday}
                    style={{
                      left:
                        type === "steps30" || type === "sleep-activity"
                          ? "25px"
                          : 0,
                    }}
                  >
                    {loading == true ? "..." : renderItem(it)}
                  </BarLabel>
                )}
              </Box>
            );
          })}
          {workouts == undefined || workouts.length == 0 ? null : (
            <div
              style={{ position: "absolute", left: 0, bottom: 20, right: 0 }}
            >
              <WorkoutsPlaceholder>
                {workouts.map((a, i) => {
                  function getOffsetFromStartOfTheDay(dateString) {
                    let startOfTheDay = moment(dateString).startOf("day");
                    let now = moment(dateString);
                    return +now.diff(startOfTheDay, "seconds");
                  }
                  let seconds = getOffsetFromStartOfTheDay(a.start_date);
                  let perc = (100.0 * seconds) / (24 * 60 * 60);

                  return (
                    <WorkoutImg
                      key={`wk-${i}`}
                      src={energy_img}
                      style={{
                        position: "absolute",
                        bottom: "-30px",
                        left: `${perc}%`,
                      }}
                      onClick={() => {
                        onWorkoutClick(a);
                      }}
                    />
                  );
                })}
              </WorkoutsPlaceholder>
            </div>
          )}
        </Content>
      </ContPlaceholder>

      <HorAxisPlaceholder
        $color={`${AXES_COLORX(theme)}80`}
      ></HorAxisPlaceholder>
    </Wrapper>
  );
}

const typeToTooltipKey = (type) => {
  if (type.includes("step")) {
    return "Steps";
  } else if (type.includes("energy")) {
    return "Calories";
  } else {
    return "Steps";
  }
};

const StepsTooltipImg = styled.img`
  height: 16px;
  width: 16px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: white;
`;

const horHeight = 20;
const vertWidth = 20;

const VertItem = styled.div`
  position: absolute;
  bottom: ${(props) => props.$bottom}%;
  right: 0px;
  // right: ${4}px;
  // color: grey;
`;

const VertItemInner = styled.div`
  height: 12px;
  line-height: 12px;
  margin-top: -6px;
  padding-right: 4px;
  box-sizing: border-box;
  font-size: 10px;
  opacity: 50%;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  // opacity: 50%;
  border-bottom: 2px solid ${(props) => props.$color};
  position: relative;
`;

const Box = styled.div`
  box-sizing: content-box;
  // box-sizing: border-box;
  flex: 1;
  padding-left: ${(props) => props.$pad}px;
  padding-right: ${(props) => props.$pad}px;
  position: relative;
`;

const BoxInner = styled.div`
  // position: relative;
  box-sizing: content-box;

  width: 100%;
  height: 100%;
`;

const Bar = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: ${(props) => props.height}%;
  background: ${(props) => (props.$isselected == true ? "blue" : props.$color)};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
  transition: height 0.35s linear;

  :hover:not(.chart-item-tooltip-item) {
    background: ${(props) =>
      props.$hovercolor != undefined ? props.$hovercolor : props.$color};
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
`;

const BarInner = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const BarTooltip = styled.div`
  display: none;
  position: absolute;
  top: -20px;
  line-height: 12px;
  font-size: 12px;
  text-align: center;
  left: 10px;
  right: 0px;
  color: ${RONAS_BLUE_MOUSE_OVER};
  border-radius: 2px;
  font-weight: bold;
  width: max-content;
  z-index: 10;

  ${BarInner}:hover & {
    display: block;
  }
`;

const ContPlaceholder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;
`;

const BarLabel = styled.div`
  bottom: -${horHeight}px;
  height: ${horHeight}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0px;
  right: 0px;
  text-align: right;
  font-size: 11px;
  font-weight: ${(props) => (props.$today ? "bold" : "normal")};
  color: ${(props) => (props.$today ? RONAS_DARK_GREEN : "grey")};
`;

const VertAxisPlaceholder = styled.div`
  width: ${vertWidth}px;
  box-sizing: border-box;
  // opacity: 50%;
  border-right: 2px solid ${(props) => props.$color}; // yaxis color
  // border-right: 2px solid whitesmoke; // yaxis color
  position: relative;
`;

const HorAxisPlaceholder = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: ${horHeight}px;
  opacity: 50%;
  // border-top: 2px solid ${(props) => props.$color};
  // border-right: 2px solid ${(props) => props.$color};
`;

const WorkoutsPlaceholder = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
`;

const WorkoutImg = styled.img`
  width: 20px;
  height: 20px;
  opacity: 0.5;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;
