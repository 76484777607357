import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import styled from "styled-components";
import NewVouchersForm from "../forms/NewVouchersForm";
import RonasBasicAPI from "../../../api/RonasBasicAPI";
import StudySessionsAPI from "../../../api/StudySessionsAPI";
import axios from "axios";
import env from "react-dotenv";

import ls from "local-storage";

const { DOCTORS_API_ENDPOINT = "https://api.study-integration.corsano.com" } =
  env;

export default function NewVouchersPanel(props) {
  const {
    tag = "",
    onAdded = () => {},
    isGwMode = false,
    isCorsanoDemo = false,
  } = props;
  const [loading, setLoading] = useState(false);

  return (
    <Wrapper>
      <NewVouchersForm
        loading={loading}
        onSave={async (k) => {
          if (isCorsanoDemo == true) {
            return window.alert(
              "This is a demo study. You can not add vouchers."
            );
          }
          if (loading == true) {
            return;
          }
          setLoading(true);
          for (let i = 0; i < k; i++) {
            let pld = await axios.post(
              `${DOCTORS_API_ENDPOINT}/create-pi-user`,
              {
                tag: tag,
                isGwMode: isGwMode,
              }
            );
            // console.log('pld = ', pld);
            let newUUID = pld?.data?.result?.uuid;
            let code = pld?.data?.result?.code;

            // if (newUUID != undefined) {
            //     // window.alert(newUUID);
            //     let dd = {
            //         first_name: 'Patient',
            //         last_name: code,
            //         weight: 70,
            //         height: 175
            //     };
            //     let pld1 = (await axios.put(`${DOCTORS_API_ENDPOINT}/patients/${newUUID}/users-profile`, {
            //         ...dd
            //     }, {
            //         params: dd
            //     })).data.result;
            //     console.log('pld1 = ', pld1);
            // }
          }
          setLoading(false);
          onAdded();
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;
