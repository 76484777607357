import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  useMemo,
} from "react";
import { useDispatch, useMappedState } from "redux-react-hook";
import moment from "moment";
import styled from "styled-components";
import GroupsAPI from "../../../api/GroupsAPI";

import { Code } from "react-content-loader";

import Select from "react-select";
import leftChevronImage from "../../patients/icons/left_chevron.svg";
import ls from "local-storage";
import triangle_up from "../../../assets/images/badges/triangle_up.svg";
import triangle from "../../../assets/images/badges/triangle.svg";
import { isDebugMode } from "../../../helpers/UiHelper";
import { getTrackBackground } from "react-range";
import DoctorAPI from "../../../api/DoctorAPI";
import NiceModal from "../../modals/NiceModal";
import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  ReferenceArea,
  Legend,
  Tooltip,
} from "recharts";
import axios from "axios";
import CommonHelper from "../../../helpers/CommonHelper";

const DES_ARROW = "⬎";
const DEF_ARROW = "";
const ASC_ARROW = "⬏";

export default function DashboardVitalParametersAlarmsTool(props) {
  const darkmode = ls.get("theme") === "dark";
  const debug = isDebugMode();
  const [allGroups, setAllGroups] = useState([]);
  const [selectedCode, setSelectedCode] = useState(undefined);

  // const [uniquePriority, setUniquePriority] = useState([]);
  // const [uniqueVital, setUniqueVital] = useState([]);
  const [uniquePatient, setUniquePatient] = useState([]);

  const uniquePriority = [
    {
      label: "Low",
      value: `low`,
      color: PR_3.color,
    },
    {
      label: "Medium",
      value: "medium",
      color: PR_2.color,
    },
    {
      label: "High",
      value: "high",
      color: PR_1.color,
    },
    {
      label: "No Alarm",
      value: "no_alarm",
      color: NO_PRIORITY.color,
    },
    // {
    //   label: "All",
    //   value: "clear",
    //   color: "#8a8a8a",
    // },
  ];
  const uniqueVital = [
    "spo2",
    "pulse_rate",
    "respiration_rate",
    "blood_pressure",
    "temperature",
  ].map((x) => {
    return {
      label: VITALS_NAMES_NAME[x],
      value: x,
    };
  });

  const eventsOptions = [
    {
      label: "Alarm Condition",
      value: `alarm condition`,
    },
    {
      label: "Alarm Inactivation",
      value: "alarm inactivation",
    },
    {
      label: "NEWS2 History",
      value: "news2 history",
    },
    {
      label: "All",
      value: "clear",
    },
  ];

  const [selectedKeyValue, setSelectedKeyValue] = useState({
    event: {
      value: "",
    },
    priority: {
      value: ["high", "medium"],
    },
    type: {
      value: "",
    },
    patient: {
      value: "",
    },
  });

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortKey, setSorkKey] = useState("name");
  const [sortOrder, setSortOrder] = useState("");
  const [sortedItems, setSortedItems] = useState([]);
  const [selectedPatientUUID, setSelectedPatientUUID] = useState("");

  const [patients, setPatients] = useState([]);

  const [selectedDayTimestamp, setSelectedDayTimestamp] = useState(
    +moment().startOf("day")
  );
  // const startOfDay

  const [news2HistoryMap, setNEWS2HistoryMap] = useState({});
  const [news2HistoryList, setNEWS2HistoryList] = useState([]);
  const [total, setTotal] = useState(0);
  const [progress, setProgress] = useState(0);

  const priorityToNum = (priority) => {
    switch (priority) {
      case "low":
        return 1;
      case "medium":
        return 2;
      case "high":
        return 3;
      default:
        return 0;
    }
  };

  useEffect(() => {
    setLoading(true);
    GroupsAPI.getGroups().then((res) => {
      setAllGroups(res);
      setLoading(false);
      if (res != undefined && res.length > 0) {
        // console.log("res = ", res);
        setSelectedCode(res[0]?.code);
      }
    });
  }, []);

  let patientsMap = patients.reduce((mp, x) => ({ ...mp, [x.uuid]: x }), {});

  useEffect(() => {
    setTotal(uniquePatient.length);
    setProgress(0);
    const fetchNEWS2HistoryByUUID = async () => {
      const from = selectedDayTimestamp;
      const to = moment(selectedDayTimestamp).add(1, "day").valueOf();
      const dateFrom = new Date(from).toISOString();
      const dateTo = new Date(to).toISOString();

      try {
        const fetchedData = new Array(uniquePatient.length);
        // let fetchedData = [];
        const requests = uniquePatient.map((uuid, index) => {
          const _endpoint = `${"https://api.study-integration.corsano.com"}/users/${
            uuid.value
          }/news-score-history`;
          axios
            .get(_endpoint, {
              params: {
                from: from,
                to: to,
              },
            })
            .then((response) => {
              // console.log({ [index]: response.data.length });
              fetchedData[index] = response.data;
              setNEWS2HistoryMap((oldMap) => {
                return { ...oldMap, [uuid.value]: response.data };
              });
              setProgress((prev) => prev + 1);
            });
        });

        await Promise.all(requests);
        // console.log({ fetchedData });
        setNEWS2HistoryList(fetchedData);
      } catch (error) {
        console.error("Error fetching NEWS2 History:", error);
      }
    };

    // fetchNEWS2HistoryByUUID();
  }, [uniquePatient]);

  // useEffect(() => {
  //   uniquePatient.forEach((p, i) => {
  //     if (p?.value === "clear" || !p?.value) return;
  //     const uuid = p.value;
  //     const name = p.label;
  //     console.log(selectedDayTimestamp);
  //     const from = selectedDayTimestamp;
  //     const to = moment(selectedDayTimestamp).add(1, "day").valueOf();
  //     const dateFrom = new Date(from).toISOString();
  //     const dateTo = new Date(to).toISOString();

  //     DoctorAPI.getPatientNEWS2History(uuid, from, to).then((d) => {
  //       console.log({ d, l: name });
  //       setNEWS2HistoryMap((oldMap) => {
  //         return { ...oldMap, [uuid]: d };
  //       });
  //     });
  //   });
  // }, [selectedDayTimestamp, uniquePatient]);

  useEffect(() => {
    let newsList = [];
    for (const uuidKey in news2HistoryMap) {
      const patientNews2History = news2HistoryMap[uuidKey];
      newsList = [...newsList, ...patientNews2History];
    }
    newsList = newsList.sort((a, b) => +a?.timestamp - +b?.timestamp);
    // console.log(newsList);

    // setNEWS2HistoryList(newsList);
  }, [selectedDayTimestamp, uniquePatient, news2HistoryMap]);

  useEffect(() => {
    setNEWS2HistoryMap({});
    const fetchAllAlarm = async () => {
      try {
        const from = +moment(selectedDayTimestamp).startOf("day");
        const to = +moment(selectedDayTimestamp).endOf("day");
        const news2From = selectedDayTimestamp;
        const news2To = moment(selectedDayTimestamp).add(1, "day").valueOf();
        const groupAlarms = await GroupsAPI.getGroupAlarmsHistory(
          selectedCode,
          from,
          to
        );
        const uuidList = groupAlarms.map((x) => x.user_uuid);
        const uuidSet = [...new Set(uuidList)];
        // console.log(uuidSet);
        // getPatientNEWS2History

        const news2DataPromises = uuidSet.map((uuid) => {
          const _endpoint = `${"https://api.study-integration.corsano.com"}/users/${uuid}/news-score-history`;
          return axios.get(_endpoint, {
            params: {
              from: news2From,
              to: news2To,
            },
          });
        });
        const news2DataResponses = await Promise.all(news2DataPromises);
        const news2DataHistory = news2DataResponses.map((res) => {
          const rd = res.data;
          // console.log(rdƒ);
          if (rd && rd.length > 0) {
            let _rd = rd.filter((x) => x?.value !== null);
            setNEWS2HistoryMap((oldMap) => {
              return { ...oldMap, [rd[0].user_uuid]: _rd };
            });
          }

          return rd;
        });
        const flattedNEWS2DataHistory = news2DataHistory.flat();
        const flattedNEWS2DataHistoryWithExtraInfo = flattedNEWS2DataHistory
          // .filter((x) => {
          //   return x.value !== null;
          // })
          .map((x) => {
            const { value } = x;
            const color = getColorByScore(value);
            const priority = NEWS2_PRIORITY_VALUES(value);
            return { ...x, type: "NEWS2", color: color, priority };
          });
        // console.log(flattedNEWS2DataHistoryWithExtraInfo);
        // console.log(groupAlarms);

        const merged = [
          ...groupAlarms,
          ...flattedNEWS2DataHistoryWithExtraInfo,
        ];
        const sortedMerged = merged.sort((a, b) => +a.timestamp - +b.timestamp);
        let _res = merged;
        let filteredResults = _res.filter(
          (a) => a.priority === "medium" || a.priority === "high"
        );

        setItems(_res);
        setSortedItems(filteredResults);
        //   console.log("low", filteredResults.filter((x) => x.quality < 2));

        const vitals = filteredResults.map((x) => x.type);
        const priority = filteredResults.map((x) => x.priority);
        const vitalOptions = [...new Set(vitals)];
        const priorityOptions = [...new Set(priority)];

        const _uniqueVital = vitalOptions.map((x) => {
          return {
            value: x,
            label: VITALS_NAMES_NAME[x] ? VITALS_NAMES_NAME[x] : x,
          };
        });
        const _uniquePriority = priorityOptions.map((x) => {
          return {
            value: x,
            label: PRIORITY_NAMES_NAME[x] ? PRIORITY_NAMES_NAME[x] : x,
            color: PR_MAP[x]?.color,
          };
        });
        _uniqueVital.push({
          value: "clear",
          label: "Clear Filter",
        });
        _uniquePriority.push({
          value: "clear",
          label: "Clear Filter",
        });
        setLoading(false);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchAllAlarm();
  }, [patients, selectedCode, selectedDayTimestamp]);

  // useEffect(() => {
  //   if (selectedCode == undefined) {
  //     return;
  //   }

  //   const news2From = selectedDayTimestamp;
  //   const news2To = moment(selectedDayTimestamp).add(1, "day").valueOf();

  //   setLoading(true);
  //   setItems([]);
  //   let from = +moment(selectedDayTimestamp).startOf("day");
  //   let to = +moment(selectedDayTimestamp).endOf("day");
  //   GroupsAPI.getGroupAlarmsHistory(selectedCode, from, to).then((res) => {
  //     let _res = res;
  //     let filteredResults = _res.filter(
  //       (a) => a.priority === "medium" || a.priority === "high"
  //     );

  //     setItems(_res);
  //     setSortedItems(filteredResults);
  //     //   console.log("low", filteredResults.filter((x) => x.quality < 2));

  //     const vitals = filteredResults.map((x) => x.type);
  //     const priority = filteredResults.map((x) => x.priority);
  //     const vitalOptions = [...new Set(vitals)];
  //     const priorityOptions = [...new Set(priority)];

  //     const _uniqueVital = vitalOptions.map((x) => {
  //       return {
  //         value: x,
  //         label: VITALS_NAMES_NAME[x] ? VITALS_NAMES_NAME[x] : x,
  //       };
  //     });
  //     const _uniquePriority = priorityOptions.map((x) => {
  //       return {
  //         value: x,
  //         label: PRIORITY_NAMES_NAME[x] ? PRIORITY_NAMES_NAME[x] : x,
  //         color: PR_MAP[x]?.color,
  //       };
  //     });
  //     _uniqueVital.push({
  //       value: "clear",
  //       label: "Clear Filter",
  //     });
  //     _uniquePriority.push({
  //       value: "clear",
  //       label: "Clear Filter",
  //     });
  //     setLoading(false);
  //   });
  // }, [patients, selectedCode, selectedDayTimestamp]);

  useEffect(() => {
    // console.log(patients);

    const uuids = items.map((x) => x.user_uuid);
    // console.log("uuids", uuids);

    const nameOptions = [...new Set(uuids)];
    let _uniqueID = nameOptions.map((x) => {
      let _patient = patients.find((pt) => pt.uuid === x);
      const first_name = _patient?.first_name;
      const last_name = _patient?.last_name;
      //   console.log("first_name, last_name", first_name, last_name);
      if (first_name || last_name) {
        return {
          value: x,
          label: `${first_name} ${last_name}`,
        };
      }
      return {};
    });
    _uniqueID.push({
      value: "clear",
      label: "All",
    });
    // console.log("nameOptions", _uniqueID);
    setUniquePatient(_uniqueID.filter((x) => x.value));
  }, [sortedItems]);

  useEffect(() => {
    if (selectedCode == undefined) {
      return;
    }
    GroupsAPI.getGroupShortPatients(selectedCode).then((res) => {
      setPatients(res);
    });
  }, [selectedCode]);

  useEffect(() => {
    const sortItems = () => {
      const sortedArray = [...items].sort((a, b) => {
        let keyA, keyB;
        if (sortKey === "patient") {
          let patientA = patientsMap[a.user_uuid] || {};
          let patientB = patientsMap[b.user_uuid] || {};
          let first_name_a = patientA.first_name;
          let last_name_a = patientA.last_name;
          let email_a = patientA.email;
          let first_name_b = patientB.first_name;
          let last_name_b = patientB.last_name;
          let email_b = patientB.email;
          keyA = first_name_a == undefined ? email_a : first_name_a;
          keyB = first_name_b == undefined ? email_b : first_name_b;
          // console.log("keya", keyA, keyB);
        }
        if (sortKey === "date") {
          keyA = moment(a.date).valueOf();
          keyB = moment(b.date).valueOf();
        }
        if (sortKey === "time") {
          keyA = moment(a.created_at).valueOf();
          keyB = moment(b.created_at).valueOf();
        }
        if (sortKey === "priority") {
          keyA = priorityToNum(a.priority);
          keyB = priorityToNum(b.priority);
        }
        if (sortKey === "vital") {
          keyA = a.type;
          keyB = b.type;
        }
        if (sortKey === "value") {
          keyA = a.value;
          keyB = b.value;
        }

        if (sortKey === "status" || sortKey === "event-type") {
          keyA = a.is_audio_on;
          keyB = b.is_audio_on;
        }

        if (keyA < keyB) return sortOrder === "ASC" ? -1 : 1;
        if (keyA > keyB) return sortOrder === "ASC" ? 1 : -1;
        return 0;
      });
      setSortedItems(sortedArray);
    };
    // sortItems();
  }, [items, sortKey, sortOrder]);

  useEffect(() => {
    const filterItems = () => {
      let filteredItems = items;
      // filteredItems = [
      //   ...filteredItems,
      //   ...news2HistoryList
      //     .filter((x) => x.value !== null)
      //     // .filter((x) => x.value > 4)
      //     .map((x) => {
      //       return { ...x, type: "NEWS2", color: getColorByScore(x.value) };
      //     }),
      // ];
      for (const kv in selectedKeyValue) {
        // console.log(
        //   "KVKV",
        //   kv,
        //   selectedKeyValue[kv],
        //   selectedKeyValue[kv]?.value
        // );
        if (selectedKeyValue[kv]?.value === "") {
          continue;
        }
        if (selectedKeyValue[kv]?.value === "clear") {
          filteredItems = filteredItems.filter((i) => {
            return true;
          });
          continue;
        }
        filteredItems = filteredItems.filter((i) => {
          // console.log(i, i[kv], selectedKeyValue[kv]?.selectedVal);
          if (kv === "patient") {
            return i["user_uuid"] === selectedKeyValue[kv]?.value;
          }
          if (kv === "event") {
            let _alarm =
              i.is_audio_on == true ? "alarm condition" : "alarm inactivation";

            if (i.type === "NEWS2") {
              _alarm = "news2 history";
            }

            // console.log("iiiiiiii", i);
            // console.log(
            //   "selectedKeyValue[kv]?.value",
            //   selectedKeyValue[kv]?.value,
            //   _alarm,
            //   _alarm === selectedKeyValue[kv]?.value
            // );
            return _alarm === selectedKeyValue[kv]?.value;
          }
          if (kv === "priority" && selectedKeyValue[kv]?.value) {
            // console.log(
            //   "selectedKeyValue[kv]?.value",
            //   selectedKeyValue[kv]?.value
            // );
            // console.log(i[kv]);

            if (
              (selectedKeyValue[kv]?.value).length === 0 ||
              (selectedKeyValue[kv]?.value).includes("clear")
            ) {
              return true;
            }
            return (selectedKeyValue[kv]?.value).includes(i[kv]);
            // return i[kv] === "medium" || i[kv] === "high";
          }
          // console.log("ikv", i[kv]);

          return i[kv] === selectedKeyValue[kv]?.value;
          // return true;
        });
      }
      setSortedItems(filteredItems);
    };
    filterItems();
  }, [items, selectedKeyValue]);

  const getPatientNameFromUUID = (uuid) => {
    let patient = patientsMap[uuid] || {};
    let { first_name, last_name, email, code } = patient;
    let uName =
      first_name === null || first_name === undefined
        ? `Patient ${code}`
        : `${first_name} ${last_name}`;
    return uName;
  };

  const options = allGroups.map((x) => ({
    value: x.code,
    label: `${x.name} [${x.code}]`,
  }));

  if (allGroups.length == 0 && loading == true) {
    return <Code />;
  }
  let patientsUUIDSMap = patients
    .map((x) => x.uuid)
    .reduce((mp, x) => ({ ...mp, [x]: 1 }), {});

  let goodItems = sortedItems;

  goodItems = goodItems.filter(
    (s) => patientsUUIDSMap[s.user_uuid] != undefined
  );

  // goodItems = [
  //   ...goodItems,
  //   ...news2HistoryList
  //     .filter((x) => x.value !== null)
  //     // .filter((x) => x.value > 4)
  //     .map((x) => {
  //       return { ...x, type: "NEWS2", color: getColorByScore(x.value) };
  //     }),
  // ];
  // goodItems = goodItems.sort((a, b) => {
  //   return +a?.timestamp - +b?.timestamp;
  // });

  // console.log('render: items = ', items);
  // console.log("render: code = ", selectedCode);

  return (
    <Wrapper>
      <TopPlaceholder>
        <TopLeft>
          <ComplianceMonthPlaceholder>
            <ChevronImage
              className="chevron-icon"
              src={leftChevronImage}
              onClick={() => {
                setSelectedDayTimestamp(
                  +moment(selectedDayTimestamp).add(-1, "days").startOf("day")
                );
              }}
            />
            <MonthSpan
              style={{
                width: 120,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>{moment(selectedDayTimestamp).format("MMMM D")}</span>
            </MonthSpan>
            <ChevronImage
              src={leftChevronImage}
              className="chevron-icon"
              style={{ transform: `rotate(180deg)` }}
              onClick={() => {
                setSelectedDayTimestamp(
                  +moment(selectedDayTimestamp).add(1, "days").startOf("day")
                );
              }}
            />
          </ComplianceMonthPlaceholder>
        </TopLeft>

        <TopRight>
          <Select
            // isClearable
            placeholder={"Select department..."}
            options={options}
            value={options.find((x) => x.value == selectedCode)}
            onChange={(x) => setSelectedCode(x.value)}
          />
          <SelectorContainer>
            <Select
              isClearable
              placeholder={"Event"}
              styles={customStyles}
              options={eventsOptions}
              // value={}
              onChange={(x) =>
                setSelectedKeyValue({
                  ...selectedKeyValue,
                  event: {
                    value: x ? x.value : "clear",
                  },
                })
              }
            />
          </SelectorContainer>
          <SelectorContainer style={{ width: "150px" }}>
            <Select
              isClearable
              // menuIsOpen={true}
              placeholder={"All Patient"}
              options={uniquePatient}
              // styles={{ width: "150px" }}
              styles={customStyles}
              // value={}
              onChange={(x) =>
                setSelectedKeyValue({
                  ...selectedKeyValue,
                  patient: {
                    value: x ? x.value : "clear",
                  },
                })
              }
            />
          </SelectorContainer>
          <SelectorContainer>
            <Select
              isClearable
              contentEditable={false}
              placeholder={"All Vital"}
              options={uniqueVital}
              // value={}
              styles={customStyles}
              onChange={(x) =>
                setSelectedKeyValue({
                  ...selectedKeyValue,
                  type: {
                    value: x ? x.value : "clear",
                  },
                })
              }
            />
          </SelectorContainer>
          <SelectorContainer>
            <Select
              className="alarm-select"
              classNamePrefix={"alarm-select"}
              isMulti
              isClearable
              placeholder={"All Priority"}
              options={uniquePriority}
              styles={customStyles}
              // value={uniquePriority.filter((x) =>
              //   selectedKeyValue.priority.value.includes(x.value)
              // )}
              // defaultValue={uniquePriority.find((x) => x.value === "default")}
              defaultValue={uniquePriority.filter(
                (x) => x.value === "medium" || x.value === "high"
              )}
              onChange={(x) => {
                // console.log(x);
                // console.log(x.map((a) => a.value));
                let _options = x.map((a) => a.value);

                // if (_options.includes("clear")) {
                //   _options = ["clear"];
                // }

                setSelectedKeyValue({
                  ...selectedKeyValue,
                  priority: {
                    value: x ? _options : "clear",
                  },
                });
              }}
            />
          </SelectorContainer>
        </TopRight>
      </TopPlaceholder>

      <Wrapper>
        <Table>
          <Thead>
            <Tr style={{ color: darkmode ? "white" : "" }}>
              <Th
              // clickable
              // onClick={(e) => {
              //   // setSorkKey("event-type");
              //   // setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
              //   setSortedItems(items);
              // }}
              >
                Event Type
                {sortKey === "event-type"
                  ? sortOrder === "DES"
                    ? DES_ARROW
                    : ASC_ARROW
                  : DEF_ARROW}
              </Th>
              <Th
              // clickable
              // onClick={(e) => {
              //   setSorkKey("date");
              //   setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
              // }}
              >
                Date
                {sortKey === "date"
                  ? sortOrder === "DES"
                    ? DES_ARROW
                    : ASC_ARROW
                  : DEF_ARROW}
              </Th>
              <Th
              // clickable
              // onClick={(e) => {
              //   setSorkKey("time");
              //   setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
              // }}
              >
                Time
                {sortKey === "time" ? (
                  sortOrder === "DES" ? (
                    <img src={triangle} width={"14px"} height={"14px"} />
                  ) : (
                    // DES_ARROW
                    // ASC_ARROW
                    <img src={triangle_up} width={"14px"} height={"14px"} />
                  )
                ) : (
                  DEF_ARROW
                )}
              </Th>
              <Th>Study</Th>
              <Th
              // clickable
              // onClick={(e) => {
              //   setSorkKey("patient");
              //   setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
              // }}
              >
                Patient
                {sortKey === "patient" ? (
                  sortOrder === "DES" ? (
                    <img src={triangle} width={"14px"} height={"14px"} />
                  ) : (
                    // DES_ARROW
                    // ASC_ARROW
                    <img src={triangle_up} width={"14px"} height={"14px"} />
                  )
                ) : (
                  DEF_ARROW
                )}
              </Th>
              <Th
              // clickable
              // onClick={(e) => {
              //   setSorkKey("vital");
              //   setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
              // }}
              >
                Vital
                {sortKey === "vital" ? (
                  sortOrder === "DES" ? (
                    <img src={triangle} width={"14px"} height={"14px"} />
                  ) : (
                    // DES_ARROW
                    // ASC_ARROW
                    <img src={triangle_up} width={"14px"} height={"14px"} />
                  )
                ) : (
                  DEF_ARROW
                )}
              </Th>
              <Th
              // clickable
              // onClick={(e) => {
              //   setSorkKey("value");
              //   setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
              // }}
              >
                Value
                {sortKey === "value" ? (
                  sortOrder === "DES" ? (
                    <img src={triangle} width={"14px"} height={"14px"} />
                  ) : (
                    // DES_ARROW
                    // ASC_ARROW
                    <img src={triangle_up} width={"14px"} height={"14px"} />
                  )
                ) : (
                  DEF_ARROW
                )}
              </Th>
              <Th>Q</Th>
              <Th>M</Th>
              {/*<Th>Limit</Th>*/}
              <Th
              // clickable
              // onClick={(e) => {
              //   setSorkKey("priority");
              //   setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
              // }}
              >
                Priority
                {sortKey === "priority" ? (
                  sortOrder === "DES" ? (
                    <img src={triangle} width={"14px"} height={"14px"} />
                  ) : (
                    // DES_ARROW
                    // ASC_ARROW
                    <img src={triangle_up} width={"14px"} height={"14px"} />
                  )
                ) : (
                  DEF_ARROW
                )}
              </Th>
              <Th
              // clickable
              // onClick={(e) => {
              //   setSorkKey("status");
              //   setSortOrder(sortOrder === "DES" ? "ASC" : "DES");
              // }}
              >
                Status
                {sortKey === "status" ? (
                  sortOrder === "DES" ? (
                    <img src={triangle} width={"14px"} height={"14px"} />
                  ) : (
                    // DES_ARROW
                    // ASC_ARROW
                    <img src={triangle_up} width={"14px"} height={"14px"} />
                  )
                ) : (
                  DEF_ARROW
                )}{" "}
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {goodItems.map((a, i) => {
              // {sortedItems.map((a, i) => {
              // console.log("Alarm item", a);

              let patient = patientsMap[a.user_uuid] || {};
              let { first_name, last_name, email, code } = patient;
              let uName =
                first_name === null || first_name === undefined
                  ? `Patient ${code}`
                  : `${first_name} ${last_name}`;
              let pr = PR_MAP[a.priority];
              let color = pr?.color;
              let vitalValue = a.value;
              let priority = PRIORITY_NAMES_NAME[a?.priority];
              let status =
                a?.is_audio_on == true
                  ? a?.priority === "low"
                    ? "Alarm on"
                    : "Audio on"
                  : "";
              if (a.type === "blood_pressure") {
                let bpParts = vitalValue.split("/");
                vitalValue = parseInt(bpParts[0]);
                // console.log(bpParts);
              }
              let eventType =
                a?.is_audio_on == true
                  ? "Alarm condition"
                  : "Alarm inactivation";

              let vitalType = VITALS_NAMES_NAME[a?.type] || a?.type;
              if (a.type === "NEWS2") {
                color = a.color;
                // console.log({ color });
                eventType = "NEWS2";
                vitalType = "";
                priority = NEWS2_PRIORITY_NAMES(a.value);
                const {
                  score_heart_rate,
                  score_spo2,
                  score_respiration_rate,
                  score_temperature,
                  score_blood_pressure,
                } = a;
                // status = `${score_heart_rate}_${score_spo2}_${score_respiration_rate}_${score_temperature}_${score_blood_pressure}`;
              }
              return (
                <Tr key={i} style={{ color: darkmode ? "white" : "" }}>
                  <Td>{eventType}</Td>
                  <Td>{moment(a?.timestamp).format("YYYY-MM-DD")}</Td>
                  <Td>{moment(a?.timestamp).format("HH:mm:ss")}</Td>
                  <Td>{selectedCode}</Td>
                  <Td
                    onClick={() => {
                      setSelectedPatientUUID(a?.user_uuid);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {uName}
                  </Td>
                  <Td>{vitalType}</Td>
                  <Td
                    style={{
                      color: color,
                      fontWeight: "bold",
                    }}
                  >
                    {vitalValue != undefined &&
                    window.isNaN(vitalValue) == false
                      ? a?.type !== "temperature"
                        ? Math.round(vitalValue)
                        : vitalValue.toFixed(1)
                      : vitalValue}
                    {/* ,{a.value} */}
                  </Td>
                  <Td>{a?.quality}</Td>
                  <Td>{a?.movement_level}</Td>
                  {/*<Td>{a.limit}</Td>*/}
                  <Td>{priority}</Td>
                  {/*<Td>{a.status}</Td>*/}
                  <Td>{status}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Wrapper>

      {/*<div>*/}
      {/*    Alarms*/}
      {/*</div>*/}
      {/*<div style={{maxHeight: '400px', overflowY: 'auto'}}>*/}
      {/*    <pre dangerouslySetInnerHTML={{__html: JSON.stringify(items, null, 2)}}></pre>*/}
      {/*</div>*/}

      {/*<div>*/}
      {/*    Patients map*/}
      {/*</div>*/}
      {/*<div style={{maxHeight: '400px', overflowY: 'auto'}}>*/}
      {/*    <pre dangerouslySetInnerHTML={{__html: JSON.stringify(patientsMap, null, 2)}}></pre>*/}
      {/*</div>*/}
      {selectedPatientUUID && selectedPatientUUID.length > 0 && (
        <NiceModal
          onClose={() => {
            setSelectedPatientUUID("");
          }}
        >
          <div style={{ margin: "0 auto", textAlign: "center" }}>
            NEWS2 Score History for{" "}
            {getPatientNameFromUUID(selectedPatientUUID)}
            {` on ${moment(selectedDayTimestamp).format("MMM Do")}`}
          </div>
          <div style={{ height: 200, width: 500 }}>
            <LineChart
              width={500}
              height={200}
              margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
              data={news2HistoryMap[selectedPatientUUID]}
            >
              <XAxis
                type="number"
                dataKey={"timestamp"}
                domain={[
                  selectedDayTimestamp,
                  selectedDayTimestamp + 60 * 60 * 1000 * 24,
                ]}
                ticks={[
                  selectedDayTimestamp,
                  selectedDayTimestamp + 60 * 60 * 1000 * 6,
                  selectedDayTimestamp + 60 * 60 * 1000 * 12,
                  selectedDayTimestamp + 60 * 60 * 1000 * 18,
                  selectedDayTimestamp + 60 * 60 * 1000 * 24,
                ]}
                tickFormatter={(x) => {
                  return moment(+x).format("Do HH:mm");
                }}
              />

              <YAxis
                domain={([min, max]) => {
                  return [min, max];
                }}
                dataKey={"value"}
                allowDecimals={false}
              />
              <Line
                type="linear"
                dataKey="value"
                stroke={"#111"}
                connectNulls={true}
                animationDuration={0.1}
                dot={(dotProps) => {
                  const { cx, cy, payload } = dotProps;
                  const v = payload.value;

                  if (v === null) {
                    return null;
                  }
                  let dotColor = "#ccc";
                  if (v === 5 || v === 6) {
                    dotColor = "#F6C387";
                  } else if (v > 6) {
                    dotColor = "#EB9079";
                  }

                  return (
                    <circle
                      key={`${cx}${cy}`}
                      cx={cx}
                      cy={cy}
                      r={3}
                      stroke={dotColor}
                      fill={dotColor}
                    />
                  );
                }}
              />
              <Tooltip
                content={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    const score = payload[0]?.value;
                    const ts = payload[0]?.payload?.timestamp;
                    const ml = payload[0]?.payload?.movement_level;
                    // console.log(payload[0]);

                    const date = moment(ts).format("Do HH:mm");
                    return (
                      <div
                        className="custom-tooltip"
                        style={{ background: "#fff", padding: 10 }}
                      >
                        <div className="label">{`NEWS2 Score : ${score}`}</div>
                        <div className="label">{`Date: ${date}`}</div>
                        <div className="label">{`Movement Level: ${ml}`}</div>
                        {/* <p className="intro">{getIntroOfPage(label)}</p>
                        <p className="desc">
                          Anything you want can be displayed here.
                        </p> */}
                      </div>
                    );
                  }

                  return null;
                }}
              />
              {/* <ReferenceArea
                x1={selectedDayTimestamp}
                x2={selectedDayTimestamp + 60 * 60 * 1000 * 24}
                y1={1}
                y2={4}
                stroke="#ccc"
                fill="#ccc"
                strokeOpacity={0.3}
                ifOverflow={"hidden"}
              /> */}
              {/* <ReferenceArea
                x1={selectedDayTimestamp}
                x2={selectedDayTimestamp + 60 * 60 * 1000 * 24}
                y1={5}
                y2={7}
                stroke="#F6C387"
                fill="#F6C387"
                strokeOpacity={0.3}
                ifOverflow={"hidden"}
              />
              <ReferenceArea
                x1={selectedDayTimestamp}
                x2={selectedDayTimestamp + 60 * 60 * 1000 * 24}
                y1={7}
                y2={10}
                strokeOpacity={0.3}
                stroke="#EB9079"
                ifOverflow={"hidden"}
                fill="#EB9079"
              /> */}
              {/* <Legend
                verticalAlign="bottom"
                height={36}
                payload={[
                  {
                    value: "Risk level: Medium",
                    type: "rect",
                    color: "#F6C387",
                  },
                  { value: "Risk level: High", type: "rect", color: "#EB9079" },
                ]}
              /> */}

              {/* <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="value"
                stroke="#8884d8"
                strokeWidth={2}
              /> */}
            </LineChart>
          </div>
          <div
            style={{
              textDecoration: "underline",
              textAlign: "center",
              fontWeight: 500,
              cursor: "pointer",
            }}
            onClick={() => {
              const departmentOrStudy = window.location.href.includes("study")
                ? "studies"
                : "departments";
              CommonHelper.linkTo(
                `${departmentOrStudy}/${selectedCode}/user/${selectedPatientUUID}`
              );
            }}
          >
            Direct To User
          </div>
        </NiceModal>
      )}
    </Wrapper>
  );
}
const customStyles = {
  control: (provided) => ({
    ...provided,
    display: "flex",
    flexWrap: "nowrap",
    // borderColor: "hsl(0deg 78.56% 55.56%);",
    // width: "7em",
  }),
  menu: (provided) => ({
    ...provided,
    width: "100%",
  }),
  dropdownIndicator: (provided) => {
    // console.log(provided);
    return {
      ...provided,
      // width: "1rem",
      // display: "none",
    };
  },
  clearIndicator: (provided) => {
    // console.log(provided);
    return {
      ...provided,
      // display: "none",
    };
  },
  multiValueLabel: (provided, data) => {
    return {
      ...provided,
      backgroundColor: data.data.color,
      color: "#000",
    };
  },
  multiValueRemove: (provided, data) => {
    return {
      ...provided,
      // display: "none",
      backgroundColor: data.data.color,
    };
  },
};

const NEWS2_PRIORITY_NAMES = (news2Score) => {
  if (news2Score === undefined || news2Score === null) {
    return "";
  }
  if (+news2Score > 6) {
    return "High Risk";
  } else if (news2Score > 4) {
    return "Medium Risk";
  } else if (news2Score >= 3) {
    return "Low-Medium Risk";
  } else {
    return "Low Risk";
  }
};

const NEWS2_PRIORITY_VALUES = (news2Score) => {
  if (news2Score === undefined || news2Score === null) {
    return "";
  }
  if (+news2Score > 6) {
    return "high";
  } else if (news2Score > 4) {
    return "medium";
  } else if (news2Score >= 3) {
    return "low-medium";
  } else {
    return "low";
  }
};

const PRIORITY_NAMES_NAME = {
  low: "Low",
  medium: "Medium",
  high: "High",
  no_alarm: "No Alarm",
};

const VITALS_NAMES_NAME = {
  pulse_rate: "Pulse Rate",
  temperature: "Temperature",
  spo2: "SpO2",
  respiration_rate: "Respiration Rate",
  blood_pressure: "Systolic Blood Pressure",
};

const PR_1 = {
  color: "#EA3223",
  priority: 10,
};

const PR_2 = {
  color: "#e5c347",
  priority: 9,
};

const PR_3 = {
  color: "#56c3c3",
  priority: 8,
};

const NO_PRIORITY = {
  color: "#8A98AB",
  priority: 0,
};

const PR_MAP = {
  low: PR_3,
  medium: PR_2,
  high: PR_1,
  no_alarm: NO_PRIORITY,
};

const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-collapse: collapse;
`;

const Tr = styled.tr``;

const Th = styled.th`
  border: 1px solid lightgrey;
  text-align: left;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
`;

const Td = styled.td`
  box-sizing: border-box;
  border: 1px solid lightgrey;
  padding: 3px;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;

const ComplianceMonthPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #8798ad;
  font-size: 18px;
  line-height: 24px;
`;

const ChevronImage = styled.img`
  height: 12px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

const MonthSpan = styled.span`
  margin-left: 28px;
  margin-right: 28px;
  font-weight: bold;
  width: 110px;
  text-align: center;
`;

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TopLeft = styled.div``;

const TopRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SelectorContainer = styled.div`
  // width: 150px;
  flex: 1;
  margin-left: 1rem;
`;

const getColorByScore = (score) => {
  if (score === undefined || score === null) {
    return "white";
  }
  if (+score > 6) {
    return "#EB9079";
  } else if (score > 4) {
    return "#F6C387";
  } else {
    return "#ccc";
  }
};
