import { useState } from "react";
import styled from "styled-components";
import Spin from "arui-feather/spin";
import { Button, Input, Textarea } from "../../ui/templates";

export default function UpdateGroupForm(props) {
  const { loading = false, onSave = (d) => {}, buttonName = "Save" } = props;

  const [name, setName] = useState(props.name ? props.name : "");
  const [description, setDescription] = useState(
    props.description ? props.description : ""
  );

  return (
    <Wrapper>
      <Row>
        <Input
          $mode={"mode2"}
          value={name}
          placeholder={"Name"}
          onChange={(evt) => {
            setName(evt.target.value);
          }}
        />
      </Row>

      <Row>
        <Textarea
          $mode={"mode1"}
          value={description}
          placeholder={"Description"}
          onChange={(evt) => {
            setDescription(evt.target.value);
          }}
        />
      </Row>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => {
            if (loading == true) {
              return;
            }
            if (name == undefined || name == "") {
              window.alert("Name should not be empty");
              return;
            }
            onSave({
              name: name,
              description: description,
            });
          }}
        >
          <Spin visible={loading} />
          {loading == true ? null : buttonName}
        </Button>
      </div>
    </Wrapper>
  );
}

const Row = styled.div`
  margin-bottom: 20px;
`;

const Wrapper = styled.div`
  padding-top: 10px;
`;
